let actions = {

    fetchBookingDetails: async function ({commit}, booking_id) {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/smx/general/get-booking-detail/' + booking_id,
            method: 'GET'
        }).then((resp) => {
            commit('BOOKING_DETAILS', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveBookingDetails: async function ({commit}, data) {

        commit('SHOW_LOADER', null, {root: true});
        commit('SHOW_BOOKING_DETAILS_ERRORS', {error_message: {}, error_status: {}});
        commit('GUEST_INFO_SECTION_DETAIL_ERRORS', {});

        await axios({
            url: '/client/v2/smx/general/save-booking-detail/',
            method: 'POST',
            data
        }).then((resp) => {

            if (resp.data.status) {
                toastr.success(resp.data.message);
            } else {
                if(resp.data.status_code == 422){
                    let error_message = resp.data.data;
                    commit('GUEST_INFO_SECTION_DETAIL_ERRORS', {error_message});
                }
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_BOOKING_DETAILS_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchPaymentsTabInformation: async function ({commit}, booking_id) {

        //commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/smx/general/get-payments-information/' + booking_id,
            method: 'GET'
        }).then((resp) => {
            commit('PAYMENT_TAB_DATA', resp.data);
            //commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

};

export default actions;