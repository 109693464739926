import tac from './client/termAndConditions/mutations';
import paymentRequests from './client/paymentRequests/mutations';
import guideBook from './client/guideBooks/mutations';
import guideBookTypes  from  './client/guideBooks/types/mutations';
import upsell from './client/upsell/mutations';
import upsellRequests from './client/upsellRequests/mutations';
import upsellTypes from './client/upsell/types/mutations';
import client_list_select_properties from './client/client_list_select_properties/mutations';
import emails_client from "./client/emails/mutations";
import sms_client from "./client/sms/mutations";
import auth from './auth/mutations';
import team_member from './client/teammember/mutations';
import chat from './client/chat/mutations';
import pre_checkin from './guest/pre_checkin/mutations';
import guest_portal from './guest/guest_portal/mutation';
import booking_detail from './client/booking_detail/mutations';
import mutations from './general_root_mutations';
import notifications from './client/notifications/mutations';
import questions from './client/questions/mutations';
import booking from './client/booking/mutations';
import dashboard from './client/dashboard/mutations';
import billing from './client/billing/mutations';
import property from './client/property/mutations';
import tags from './client/tags/mutations';


let module_mutations = {
    ...mutations,
    ...team_member,
    ...tac,
    ...upsell,
    ...upsellRequests,
    ...client_list_select_properties,
    ...upsellTypes,
    ...guideBook,
    ...guideBookTypes,
    ...emails_client,
    ...sms_client,
    ...auth,
    ...chat,
    ...notifications,
    ...pre_checkin,
    ...guest_portal,
    ...booking_detail,
    ...paymentRequests,
    ...questions,
    ...booking,
    ...dashboard,
    ...billing,
    ...property,
    ...tags,
};

export default module_mutations;