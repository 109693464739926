let mutations = {

    FETCH_NOTIFICATIONS_DATA(state, data) {
        const elementTop = document.getElementById('upsell_request_count_top');
        const elementInner = document.getElementById('upsell_request_count_inner');

        if (data?.data?.upsellRequestsCount > 0) {
            if (elementTop) {
                elementTop.innerHTML = '<span class="notification-badge">' + data.data.upsellRequestsCount + '</span>';
            }
            if (elementInner) {
                elementInner.innerHTML = '<span class="notification-badge">' + data.data.upsellRequestsCount + '</span>';
            }
        } else {
            if (elementTop) {
                elementTop.innerHTML = '';
            }
            if (elementInner) {
                elementInner.innerHTML = '';
            }
        }

        state.notification = {...state.notification, ...data.data};
        return state;
    },
    ALERT_ACTION_PERFORMED(state, data) {
        state.notification = data.data;
        return state;
    },

    TOGGLE_NOTIFICATION_PANEL(state, payload) {
        if (isNaN(payload)) {
            state.notification.isNotificationPanelOpen = !state.isNotificationPanelOpen;
        } else {
            state.notification.isNotificationPanelOpen = !!payload;
        }
        return state;
    }
};

export default mutations;