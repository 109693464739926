const basic_data= {
    serve_id: 0,
    pms_id: '',
    payment_type: '',
    bs_name: '',
    channel_code:'',
    priority: 999,
    logo:'',
    FETCH_BOOKING: true,
    GUEST_EXPERIENCE: true,
    MANUAL_PAYMENTS: true,
    AUTO_PAYMENTS: false,
    SECURITY_DEPOSIT: false,
    SUPPORT_HTML_MESSAGES_TO_GUEST_THROUGH_OTA: false,
    SUPPORT_NON_HTML_MESSAGES_TO_GUEST_THROUGH_OTA: false,
    assigned_users: [],
    admin_code:'',
    pms_messages: {
        send_message: [],
        log_message: []
    }
};

/** Main STATE */
let state= {
    formData: basic_data
};

export default state;
