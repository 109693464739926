let state = {
    payer: {
        transactions: [],
        selected_transactions: [],
        company_info: [],
        amount_number: '',
        total_amount: 0,
        currency_code: '',
        user_country: 'US',
        error_status: {
            card_error: false,
        },
        error_message: {
            card_error: ''
        }
    },
    auth_to_transform: [],
    available_cards: [],
};

export default state;