export const init_errors = () => {
    return {
        // error_message: {
        //     title: '',
        //     meta: {
        //         from_time: '',
        //         from_am_pm: '',
        //         to_time: '',
        //         to_am_pm: '',
        //     },
        //     price: {
        //         is_tier: '',
        //         flat_plan: '',
        //         tier_plans: '',
        //         per: '',
        //         additional_fees: ''
        //     },
        //     availability: {
        //         availability_type: '',
        //         custom_date: ''
        //     },
        //     notify_guest: '',
        //     required: '',
        //     selected_properties: '',
        // },
        error_message:{},
        error_status: {
            title: false,
            meta: {
                from_time: false,
                from_am_pm: false,
                to_time: false,
                to_am_pm: false,
            },
            price: {
                is_tier: false,
                flat_plan: false,
                additional_fees: false,
                tier_plans: false,
                based_on_booking_amount_plans: false,
                per: false,
            },
            availability: {
                availability_type: false,
                stages: false,
                custom_date: false,
                life_cycle_stage: false,
                minimum_time_to_order: false
            },
            notify_guest: false,
            required: false,
            priority: false,
            selected_properties: false,

        }
    }
};

export const init_state = () => {

    return {
        title: '',
        internal_name: '',
        meta: {
            description: '',
            note: '',
            from_time: '00:00',
            from_am_pm: 'am',
            to_time: '00:00',
            to_am_pm: 'am'
        },
        price: {
            is_tier: false,
            per: "booking",
            tier_plans: [
                {night_stay_from: 1, night_stay_to: 5, value_type: 'percentage', value: 1},
                {night_stay_from: 6, night_stay_to: "infinite", value_type: 'percentage', value: 1}
            ],
            based_on_booking_amount_plans: [{
                amount_from: 1,
                amount_to: "infinite",
                value: 0
            }, ],
            flat_plan: {period: 'onetime', value_type: 'flat', value: 1},
            additional_fees: []
        },
        availability: {
            availability_type: 'always',
            stages: [],
            life_cycle_stage: {
                after_booking: {
                    label: 'After Booking',
                    is_active: false,
                    value: 0,
                },
                before_check_in: {
                    label: 'Before Check-in',
                    is_active: false,
                    value: 0,
                    info: 'Default check-in time is 4:00PM local time.',
                },
                after_check_in: {
                    label: 'After Check-in',
                    is_active: false,
                    value: 0,
                },
                before_check_out: {
                    label: 'Before Check-out',
                    is_active: false,
                    value: 0,
                    info: 'Default check-out time is 11:00AM local time.',
                }
            },
            seasonal_restriction: false,
            custom_date: {
                date_from: null,
                date_to: null,
                repeat_every_year: false
            },
            allow_minimum_time_to_order: false,
            minimum_time_to_order: {
                duration: 1,
                period: 'hours',
                event: 'check-in',
            }
        },

        attached_booking_sources: [],

        notify_guest: [],
        required: false,
        priority: null,
        is_client_approval_required: 0,
        status: true,
        upsell_images:[],
        upsellImagesFiles: [],
        selected_properties: [],
        error_message:init_errors().error_message,
        error_status:init_errors().error_status,
        connected_tags: [],
        billing_identifier: ''
    }
};

const filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: [],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'status',
    },
    constraints: [],
    search: {
        searchInColumn: ['id', 'internal_name', 'title'],
        searchStr: ''
    },
    upsell_type: 'all',
    property: 'all',
    upsell: 'all',
    tag: 'all',
    dateOne: '',
    dateTwo: '',
    dateOneCheckout: '',
    dateTwoCheckout: '',
    purchaseDateOne: '',
    purchaseDateTwo: '',
    filter_count: 0,
}; //Datatable filters Object End

const advance_options = {
    booking_payment_types: true
};


let state = {
    upsell_types: [],
    form_data: init_state(),
    initial_state: init_state(),
    paginationResponse: {}, // List Records With Pagination Meta & Links
    templates: {}, // List of all available upsell templates
    filters: filters, // ALL Regarding Filters for datatable.
    advance_options: advance_options, // ALL Regarding Advance options show/hide
    upsell_config: {},
    library_images: {},
    upsell_image_errors: {},
    firstCallDone: false
};


export default state;
