import { defineAsyncComponent } from 'vue';

/*
*
* These components will compile under "client" chunck.
*
* */
const general_client_base_path = './components/general/client';
const ba_client_base_path = './components/ba/client';
const smx_client_base_path = './components/smx/general/client';
const impala_client_base_path = './components/impala/general/client';
const guesty_client_base_path = './components/guesty/client';
const nopms_client_base_path = './components/noPms/client';
const smoobu_client_base_path = './components/smoobu/client';
const cax_client_base_path = './components/cax/general/client';
const hapicloud_client_base_path = './components/hapicloud/client';
const hostfully_client_base_path = './components/hostfully/client';
const apiconnection_client_base_path = './components/apiConnection/client';
const syncbnb_client_base_path = './components/syncbnb/client';
const hostaway_client_base_path = './components/hostaway/client';
const tokeet_client_base_path = './components/tokeet/client';
const payment_request_client_base_path = './components/PaymentRequestFunctionality/Client';
const payment_request_client_base_path2 = './components/PaymentRequestFunctionality';
const reusable_payment_request_client_base_path = './components/ReusablePaymentRequestFunctionality/Client';
const lock_system_base_path = './components/lockSystem/client';

// Register client components
export function registerClientComponents(app) {
  app.component('custom-popover', defineAsyncComponent(() => import('@cac-gc/reusables/CustomPopover.vue')));
  app.component('edit-full-client', defineAsyncComponent(() => import('@cac-gc/reusables/editFullClient.vue')));
  app.component('edit-team-member', defineAsyncComponent(() => import('@cac-gc/reusables/editTeamMember.vue')));
  app.component('select-language-l', defineAsyncComponent(() => import('@cac-gc/reusables/selectLanguageLarge.vue')));
  app.component('notification-sidebar', defineAsyncComponent(() => import('@cac-gc/NotificationSidebar.vue')));
  app.component('color-picker', defineAsyncComponent(() => import('@cac-gc/reusables/ColorPicker2.vue')));
  app.component('custom-dropdown-select', defineAsyncComponent(() => import('@cac-gc/reusables/CustomDropdownSelect.vue')));
  app.component('add-sms-plan', defineAsyncComponent(() => import('@cac-gc/reusables/AddSmsPlan.vue')));
  app.component('custom-phone-input', defineAsyncComponent(() => import('@cac-gc/reusables/CustomPhoneInputNew.vue')));
  app.component('custom-month-picker', defineAsyncComponent(() => import('@cac-gc/reusables/CustomMonthPicker.vue')));
  app.component('custom-time-picker', defineAsyncComponent(() => import('@cac-gc/reusables/CustomTimePicker.vue')));
  app.component('enable-account', defineAsyncComponent(() => import('@cac-gc/reusables/EnableAccount.vue')));
  app.component('photo-booth-new', defineAsyncComponent(() => import('@cac-gc/reusables/PhotoBooth.vue')));
  app.component('plan-not-supported', defineAsyncComponent(() => import('@cac-gc/reusables/PlanNotSupported.vue')));
  app.component('image-viewer', defineAsyncComponent(() => import('@cac-gc/reusables/ImageViewer.vue')));
  app.component('auto-block-ui', defineAsyncComponent(() => import('@cac-gc/reusables/AutoBlockUI.vue')));
  app.component('lock-account', defineAsyncComponent(() => import('@cac-gc/reusables/LockAccount.vue')));
  app.component('quill-editor', defineAsyncComponent(() => import('@cac-gc/reusables/QuillEditor.vue')));
  app.component('ca-tooltip', defineAsyncComponent(() => import('@cac-gc/reusables/BTooltip.vue')))


  // For testing something in Live
  app.component('test-comp', defineAsyncComponent(() => import('@cac-gc/reusables/Test.vue')));


  /** Account Setup Steps */
  app.component('pms-setup-step1', defineAsyncComponent(() => import('@cac-gc/pms_integration/pms_setup_step1.vue')));
  app.component('pms-setup-step2', defineAsyncComponent(() => import('@cac-gc/pms_integration/pms_setup_step2.vue')));
  app.component('pms-setup-step3', defineAsyncComponent(() => import('@cac-gc/pms_integration/pms_setup_step3.vue')));
  app.component('pms-setup-step4', defineAsyncComponent(() => import('@cac-gc/pms_integration/pms_setup_step4.vue')));
  app.component('pms-setup-steps-navbar', defineAsyncComponent(() => import('@cac-gc/pms_integration/pms_setup_steps_navbar.vue')));
  app.component('loader', defineAsyncComponent(() => import('@cac-gc/Loader.vue')));
  app.component('manageteam', defineAsyncComponent(() => import('@cac-gc/team/Manageteam.vue')));
  app.component('userLogs', defineAsyncComponent(() => import('@cac-gc/team/userLogs.vue')));
  app.component('guest-id-upload', defineAsyncComponent(() => import('@cac-gc/reusables/GuestDocumentUploadModalBox.vue')));
  app.component('guest-credit-card', defineAsyncComponent(() => import('@cac-gc/reusables/GuestCreditCard.vue')));
  app.component('refund-amount-modal', defineAsyncComponent(() => import('@cac-gc/reusables/RefundAmountModal.vue')));
  app.component('capture-security-deposit-amount-modal', defineAsyncComponent(() => import('@cac-gc/reusables/CaptureSecurityDepositAmountModal.vue')));
  app.component('client-booking-source-settings', defineAsyncComponent(() => import('@cac-gc/bookingSourceSetings/ClientBookingSourceSettings.vue')));
  app.component('client-payment-gateway-settings', defineAsyncComponent(() => import('@cac-gc/paymentGatewaysSettings/ClientPaymentGatewaySettings.vue')));
  app.component('client-payment-gateway-chargeback-label', defineAsyncComponent(() => import('@cac-gc/paymentGatewaysSettings/ChargebackLabel.vue')));
  app.component('client-payment-rules-details', defineAsyncComponent(() => import('@cac-gc/properties/PaymentSettingDetails.vue')));
  app.component('edit-payment-schedule-date-modal', defineAsyncComponent(() => import('@cac-gc/reusables/EditPaymentScheduleDate.vue')));
  app.component('additional-charge-modal', defineAsyncComponent(() => import('@cac-gc/reusables/AdditionalChargeModalNew.vue')));
  app.component('additional-charge-credit-card', defineAsyncComponent(() => import('@cac-gc/reusables/AdditionalChargeCreditCard.vue')));
  app.component('calendly-pop-up', defineAsyncComponent(() => import('@cac-gc/reusables/CalendlyPopUp.vue')));
  app.component('reduce-amount-modal', defineAsyncComponent(() => import('@cac-gc/reusables/ReduceAmountModal.vue')));
  app.component('change-amount-modal', defineAsyncComponent(() => import('@cac-gc/reusables/ChangeAmountModal.vue')));
  app.component('change-sd-amount-modal', defineAsyncComponent(() => import('@cac-gc/reusables/ChangeSDAmountModal.vue')));
  app.component('general-settings', defineAsyncComponent(() => import('@cac-gc/settings/GeneralSetting.vue')));
  app.component('preferences-template-var', defineAsyncComponent(() => import('@cac-gc/settings/PreferencesTemplateVar.vue')));
  app.component('chat-panel-right', defineAsyncComponent(() => import('@cac-gc/chat/ChatPanelRight.vue')));
  app.component('all-notifications', defineAsyncComponent(() => import('@cac-gc/allNotifications/AllNotifications.vue')));
  app.component('booking-channel-settings', defineAsyncComponent(() => import('@cac-gc/settings/BookingChannelSetting.vue')));
  app.component('notification-settings', defineAsyncComponent(() => import('@cac-gc/settings/NotificationSetting.vue')));
  app.component('phone-input', defineAsyncComponent(() => import('@cac-gc/reusables/phoneInput.vue')));
  app.component('account-enable-disable-button', defineAsyncComponent(() => import('@cac-gc/pms_integration/AccountEnableDisableButton.vue')));
  app.component('guest-experience-setting', defineAsyncComponent(() => import('@cac-gc/settings/GuestExperienceSetting.vue')));
  app.component('guest-experience-setting-preview', defineAsyncComponent(() => import('@cac-gc/settings/GuestExperienceSettingPreview.vue')));
  app.component('guest-experience-setting-more', defineAsyncComponent(() => import('@cac-gc/settings/GuestExperienceSettingMore.vue')));
  app.component('reminder-setting', defineAsyncComponent(() => import('@cac-gc/settings/ReminderSetting.vue')));
  // app.component('auto-payment-booking-source-modal',defineAsyncComponent(() => import('@cac-gc/bookingSourceSetings/AutoPaymentBookingSourceModal.vue')));// NO SUCH FILE EXISTS
  app.component('add-property-info-modal', defineAsyncComponent(() => import('@cac-gc/properties/AddPropertyInfoModal.vue')));
  app.component('add-booking-source-modal', defineAsyncComponent(() => import('@cac-gc/reusables/AddBookingSourceModal.vue')));
  app.component('copy-property-setting-modal', defineAsyncComponent(() => import('@cac-gc/reusables/CopyPropertySettingModal.vue')));
  app.component('billing-subscription-page', defineAsyncComponent(() => import('@cac-gc/billing_and_subscription/Billing.vue')));
  app.component('billing-subscription-plans-tab', defineAsyncComponent(() => import('@cac-gc/billing_and_subscription/SubscriptionPlanTab.vue')));
  app.component('detailed-plans-comparison', defineAsyncComponent(() => import('@cac-gc/billing_and_subscription/DetailedPlansComparison.vue')));
  app.component('copy-auto-payment-setting-modal', defineAsyncComponent(() => import('@cac-gc/reusables/CopyAutoPaymentSettingModal.vue')));


  /** Upsell Components*/
  app.component('rentals', defineAsyncComponent(() => import('@cac-gc/properties/Rentals.vue')));

  /** Upsell Components*/
  app.component('new-upsell-add', defineAsyncComponent(() => import('@cac-gc/upsell/NewAddUpsell.vue')));
  app.component('create-new-upsell', defineAsyncComponent(() => import('@cac-gc/upsell/CreateNewUpsell.vue')));
  app.component('add-upsell', defineAsyncComponent(() => import('@cac-gc/upsell/AddUpsell.vue')));
  app.component('upsell-add-page', defineAsyncComponent(() => import('@cac-gc/upsell/AddUpsellPage_new.vue')));
  app.component('upsell-list-page', defineAsyncComponent(() => import('@cac-gc/upsell/UpsellListPage.vue')));
  app.component('upsell-requests-page', defineAsyncComponent(() => import('@cac-gc/upsell/UpsellRequestPage.vue')));
  app.component('upsell-order-list-page', defineAsyncComponent(() => import('@cac-gc/upsell/UpsellOrderListPage.vue')));
  app.component('upsell-types-add', defineAsyncComponent(() => import('@cac-gc/upsell/types/upsell-types-add.vue')));
  app.component('upsell-types-list-page', defineAsyncComponent(() => import('@cac-gc/upsell/types/upsell-types-list-page.vue')));
  app.component('refund-upsell-modal', defineAsyncComponent(() => import('@cac-gc/reusables/RefundUpsellModal.vue')));
  /** Term and Conditions Components*/
  app.component('terms-and-conditions-add', defineAsyncComponent(() => import('@cac-gc/terms_and_conditions/terms-and-conditions-add.vue')));
  app.component('terms-and-conditions-list-page', defineAsyncComponent(() => import('@cac-gc/terms_and_conditions/terms-and-conditions-list-page.vue')));

  /** Payment Requests Components*/
  app.component('payment-request-list-page', defineAsyncComponent(() => import('@cac-prfc/payment_request_listing/payment-requests-list-page.vue')));
  app.component('payment-request-detail', defineAsyncComponent(() => import('@cac-prfc/payment_request_details/paymentRequestDetails.vue')));
  app.component('payment-request-add-edit-modal', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestAddEditModal.vue')));
  app.component('payment-request-success-modal', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestSuccessModal.vue')));
  app.component('payment-request-charge-now-modal', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestChargeNowModal.vue')));
  app.component('payment-request-scheduled-success-modal', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestScheduledSuccessModal.vue')));
  app.component('payment-request-scheduled-select-card', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestScheduleSelectCard.vue')));
  app.component('payment-request-sent-modal', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestSentModal.vue')));
  app.component('payment-request-link-modal', defineAsyncComponent(() => import('@cac-prfc/payment_request_add_edit/PaymentRequestLinkModal.vue')));

  /** Reusable Payment Requests Components*/
  app.component('reusable-payment-request-create-page', defineAsyncComponent(() => import('@cac-rprf/payment_request_add_edit/ReusablePaymentRequestAddEdit.vue')));
  app.component('reusable-payment-request-preview', defineAsyncComponent(() => import('@cac-rprf/payment_request_add_edit/ReusablePaymentRequestPreview.vue')));
  app.component('reusable-payment-request-add-product', defineAsyncComponent(() => import('@cac-rprf/products/ProductAddEdit.vue')));
  app.component('reusable-payment-request-list-product', defineAsyncComponent(() => import('@cac-rprf/products/ProductList.vue')));
  app.component('reusable-payment-request-list-page', defineAsyncComponent(() => import('@cac-rprf/PaymenRequests/ReusablePaymentRequestListing.vue')));
  app.component('reusable-payment-request-transaction-list', defineAsyncComponent(() => import('@cac-rprf/Transactions/TransactionListing.vue')));
  app.component('transaction-request-detail', defineAsyncComponent(() => import('@cac-rprf/Transactions/TransactionRequestDetail.vue')));
  app.component('reusable-payment-requestcreate-new-questions-modal', defineAsyncComponent(() => import('@cac-gc/reusables/ReusablePaymentCreateNewQuestionModal.vue')));

  /** GuideBooks Components*/
  app.component('guide-books-add', defineAsyncComponent(() => import('@cac-gc/guideBooks/guide-books-add.vue')));
  app.component('guide-books-list-page', defineAsyncComponent(() => import('@cac-gc/guideBooks/guide-books-list-page.vue')));
  app.component('guidebook-preview', defineAsyncComponent(() => import('@cac-gc/guideBooks/GuidebookPreview.vue')));
  app.component('guidebook-preview-details', defineAsyncComponent(() => import('@cac-gc/guideBooks/GuidebookPreviewDetailsModal.vue')));
  app.component('guide-books-types-add', defineAsyncComponent(() => import('@cac-gc/guideBooks/types/guide-books-types-add.vue')));
  app.component('guide-books-types-list-page', defineAsyncComponent(() => import('@cac-gc/guideBooks/types/guide-books-types-list-page.vue')));
  app.component('booking-sync-time-popup-modal', defineAsyncComponent(() => import('@cac-gc/booking/BookingSyncTimePopupModal.vue')));
  app.component('email-types-nav', defineAsyncComponent(() => import('@cac-gc/emails/EmailTypesNav.vue')));
  //app.component('cancel-bdc-booking-detail',defineAsyncComponent(() => import('@cac-gc/booking/CancelBDCBookingDetail.vue' ));

  /** Tags Components */
  app.component('tags-list-page', defineAsyncComponent(() => import('@cac-gc/tags/tags-list-page.vue')));

  app.component('redsys-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/RedsysAddCard.vue')));
  app.component('stripe-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/StripeAddCard.vue')));
  app.component('stripe-custom-card-element', defineAsyncComponent(() => import('./components/general/gatewayTerminals/StripeCustomCardElement.vue')));
  app.component('stripe-embedded-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/StripeEmbeddedCard.vue')));
  app.component('dummy-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/DummyAddCard.vue')));
  //app.component('cybersource-add-card-old',defineAsyncComponent(() => import('./components/general/gatewayTerminals/CybersourceAddCard-old.vue')));
  app.component('cybersource-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/CybersourceAddCard.vue')));
  app.component('cybersource-device-fingerprinting', defineAsyncComponent(() => import('./components/general/gatewayTerminals/CybersourceDeviceFingerprinting.vue')));
  app.component('payer-authentication-cybersource', defineAsyncComponent(() => import('./components/general/gatewayTerminals/PayerAuthenticationCyberSource.vue')));
  app.component('authorize-net-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/AuthorizeNetAddCard.vue')));
  app.component('pci-booking-net-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/PciBookingAddCard.vue')));
  app.component('mercado-pago-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/MercadoPagoAddCard.vue')));
  app.component('mercado-pago-cvv-verification-modal', defineAsyncComponent(() => import('./components/general/gatewayTerminals/MercadoPagoCvvVerificationModal.vue')));
  app.component('tilopay-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/TilopayAddCard.vue')));
  app.component('yap-stone-hpp-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/YapStoneHPPAddCard.vue')));

  //Questions Components
  app.component('questions-add', defineAsyncComponent(() => import('@cac-gc/questions/questions-add.vue')));
  app.component('custom-question', defineAsyncComponent(() => import('@cac-gc/questions/custom-question.vue')));
  app.component('create-new-questions-modal', defineAsyncComponent(() => import('@cac-gc/reusables/CreateNewQuestionModal.vue')));
  app.component('list-select-properties', defineAsyncComponent(() => import('@cac-gc/reusables/ListSelectProperties.vue')));

  /** Applications Components */
  app.component('applications', defineAsyncComponent(() => import('@cac-gc/applications/applications.vue')));
  app.component('zapier-application', defineAsyncComponent(() => import('@cac-gc/applications/zapierApplication.vue')));
  app.component('api-application', defineAsyncComponent(() => import('@cac-gc/applications/apiApplication.vue')));
  app.component('lock-system-list-page', defineAsyncComponent(() => import('@cac-lok/LockSystemListPage.vue')));
  app.component('lock-system-integration-page', defineAsyncComponent(() => import('@cac-lok/LockSystemIntegrationPage.vue')));
  app.component('webhook-settings-page', defineAsyncComponent(() => import('@cac-gc/webhooks/WebhookSettings.vue')));
  // app.component('webhook-settings-page', () => import('@cac-gc/webhooks/WebhookSettings.vue'));


  app.component('general-property-gateway-settings', defineAsyncComponent(() => import('@cac-gc/properties/GatewaySettingDetails.vue')));
  app.component('general-payment-schedule', defineAsyncComponent(() => import('@cac-gc/booking/bookingList/PaymentSchedule.vue')));
  app.component('general-payment-summary', defineAsyncComponent(() => import('@cac-gc/booking/bookingList/PaymentSummary.vue')));
  app.component('general-guest-experience', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/GuestExperience.vue')));
  app.component('general-documents-detail', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/DocumentDetail.vue')));
  app.component('general-sent-email-detail', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/SentEmailDetail.vue')));
  app.component('general-upsell-detail', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/UpsellDetail.vue')));
  app.component('general-activity-log', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/ActivityLog.vue')));
  app.component('general-payment-attempts-activity-log', defineAsyncComponent(() => import('@cac-gc/reusables/PaymentAttemptsActivityLog.vue')));
  app.component('client-chat-panel', defineAsyncComponent(() => import('@cac-prf/reusables/ClientChatPanel.vue')));
  app.component('booking-detail-page', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/BookingDetailPage.vue')));
  app.component('iframe-booking-detail-page', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/IframeBookingDetailPage.vue')));
  app.component('general-questionnaire-detail', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/QuestionnaireDetail.vue')));
  app.component('guest-details-modal', defineAsyncComponent(() => import('@cac-gc/reusables/GuestDetailsModal.vue')));
  app.component('additional-guest-details-modal', defineAsyncComponent(() => import('@cac-gc/reusables/AdditionalGuestDetails.vue')));
  app.component('passport-or-document-upload', defineAsyncComponent(() => import('@cac-gc/reusables/PassportOrDocumentUpload.vue')));

  /*PMS Preference Settings Components*/
  app.component('pms-preference-settings-page', defineAsyncComponent(() => import('@cac-gc/pms_preferences/PMSPreferenceSettingPage.vue')));
  app.component('pms-modification-settings', defineAsyncComponent(() => import('@cac-gc/pms_preferences/PMSModificationSetting.vue')));

  //general-booking-list-page
  app.component('general-dashboard', defineAsyncComponent(() => import('@cac-gc/dashboard/Dashboard.vue')));
  app.component('general-booking-list-page', defineAsyncComponent(() => import('@cac-gc/booking/bookingList/BookingListPage.vue')));
  app.component('general-booking-list-detail', defineAsyncComponent(() => import('@cac-gc/booking/bookingList/BookingListDetail.vue')));
  app.component('general-booking-details', defineAsyncComponent(()=> import('@cac-gc/booking/booking_detail/BookingDetails.vue')));
  app.component('general-payment-detail', defineAsyncComponent(() => import('@cac-gc/booking/booking_detail/PaymentDetail.vue')));

  /** Booking Automation Components **/
  app.component('ba-pms-setup-step5', defineAsyncComponent(() => import('@cac-ba/pms_integration/pms_setup_step5.vue')));
  app.component('ba-client-properties-list', defineAsyncComponent(() => import('@cac-ba/properties/PropertiesList.vue')));
  app.component('iframe-ba-payment-detail', defineAsyncComponent(() => import('@cac-ba/booking/booking_detail/IframePaymentDetail.vue')));
  app.component('cancel-bdc-booking-detail', defineAsyncComponent(() => import('@cac-ba/booking/CancelBDCBookingDetail.vue')));

  /** SMX Components **/
  app.component('smx-pms-setup-step5', defineAsyncComponent(() => import('@cac-smx/pms_integration/pms_setup_step5.vue')));
  app.component('smx-parent-client-properties-list', defineAsyncComponent(() => import('@cac-smx/properties/PropertiesList.vue')));

  /** Impala Components **/
  app.component('impala-pms-setup-step5', defineAsyncComponent(() => import('@cac-imp/pms_integration/pms_setup_step5.vue')));
  app.component('impala-parent-client-properties-list', defineAsyncComponent(() => import('@cac-imp/properties/PropertiesList.vue')));

  /** Guesty Components **/
  app.component('guesty-client-properties-list', defineAsyncComponent(() => import('@cac-gst/properties/PropertiesList.vue')));
  app.component('guesty-pms-setup-step5', defineAsyncComponent(() => import('@cac-gst/pms_integration/pms_setup_step5.vue')));

  /** Smoobu Components **/
  app.component('smoobu-client-properties-list', defineAsyncComponent(() => import('@cac-smo/properties/PropertiesList.vue')));
  app.component('smoobu-pms-setup-step5', defineAsyncComponent(() => import('@cac-smo/pms_integration/pms_setup_step5.vue')));

  /** No Pms Components **/
  app.component('nopms-client-properties-list', defineAsyncComponent(() => import('@cac-nop/properties/PropertiesList.vue')));
  app.component('nopms-pms-setup-step5', defineAsyncComponent(() => import('@cac-nop/pms_integration/pms_setup_step5.vue')));

  /** ApiConnection Components **/
  app.component('client_api_connection_keys', defineAsyncComponent(() => import('@cac-apc/pms_integration/client_api_connection_keys.vue')));
  app.component('apiconnection-client-properties-list', defineAsyncComponent(() => import('@cac-apc/properties/PropertiesList.vue')));
  app.component('apiconnection-pms-setup-step5', defineAsyncComponent(() => import('@cac-apc/pms_integration/pms_setup_step5.vue')));


  /** Hostaway Components **/
  app.component('hostaway-client-properties-list', defineAsyncComponent(() => import('@cac-haw/properties/PropertiesList.vue')));
  app.component('hostaway-pms-setup-step5', defineAsyncComponent(() => import('@cac-haw/pms_integration/pms_setup_step5.vue')));

  /** Syncbnb Components **/
  app.component('syncbnb-client-properties-list', defineAsyncComponent(() => import('@cac-snb/properties/PropertiesList.vue')));
  app.component('syncbnb-pms-setup-step5', defineAsyncComponent(() => import('@cac-snb/pms_integration/pms_setup_step5.vue')));

  /** Tokeet Components **/
  app.component('tokeet-client-properties-list', defineAsyncComponent(() => import('@cac-tkt/properties/PropertiesList.vue')));
  app.component('tokeet-pms-setup-step5', defineAsyncComponent(() => import('@cac-tkt/pms_integration/pms_setup_step5.vue')));

  app.component('sms-types-nav', defineAsyncComponent(() => import('@cac-gc/sms/SmsTypesNav.vue')));

  /* Hapicloud components */
  app.component('hapicloud-client-properties-list', defineAsyncComponent(() => import('@cac-hap/properties/PropertiesList.vue')));
  app.component('hapicloud-pms-setup-step5', defineAsyncComponent(() => import('@cac-hap/pms_integration/pms_setup_step5.vue')));

  /** Hostfully Components **/
  app.component('hostfully-client-properties-list', defineAsyncComponent(() => import('@cac-hos/properties/PropertiesList.vue')));
  app.component('hostfully-pms-setup-step5', defineAsyncComponent(() => import('@cac-hos/pms_integration/pms_setup_step5.vue')));

  /** CAX Components **/
  // app.component('cax-general-booking-detail', defineAsyncComponent(() => import('@cac-cax/booking/booking_detail/BookingDetails.vue')));
  app.component('cax-client-properties-list', defineAsyncComponent(() => import('@cac-cax/properties/PropertiesList.vue')));
  app.component('cax-pms-setup-step5', defineAsyncComponent(() => import('@cac-cax/pms_integration/pms_setup_step5.vue')));

  app.component('general-properties', defineAsyncComponent(() => import('@cac-gc/properties/PropertiesList.vue')));
  app.component('pms-setup-step5', defineAsyncComponent(() => import('@cac-gc/pms_integration/pms_setup_step5.vue')));


  /** GENERIC CARD Components **/
  app.component('generic-add-card', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/GenericAddCard.vue')));
  app.component('card-input', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardInput.vue')));
  app.component('card-select', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardSelect.vue')));
  app.component('card-element', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardElement.vue')));
  app.component('card-row', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardRow.vue')));
  app.component('card-col', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardCol.vue')));
  app.component('card-section', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardSection.vue')));
  app.component('card-text-block', defineAsyncComponent(() => import('./components/general/gatewayTerminals/generic/subs/CardTextBlock.vue')));

  /** Custom Components **/
  app.component('ca-select', defineAsyncComponent(() => import('@cac-gc/reusables/atoms/CASelect.vue')));

  app.component('branding-custom-domain', defineAsyncComponent(() => import('@cac-gc/branding/CustomDomain.vue')));
}