let actions = {

    addPaymentRequest: async ({state, commit, dispatch}, serve_id) => {

        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
             axios({
                url: '/client/v2/payment-request/' + serve_id,
                method: 'POST',
                data: state.paymentRequests.form_data,
            }).then((response) => {

                commit('HIDE_LOADER', null, {root: true});

                 if (response.data.status) {
                     commit('SET_CREATED_PAYMENT_REQUEST_DATA', response.data.data);
                     dispatch('getPaymentRequestList');
                     return resolve(true);
                 }
                 else
                 {
                     toastr.error(response.data.message);
                 }

            }).catch((err) => {

                let errors = err.response;
                let error_message = {};
                let error_status = {};
                if (errors.status == 422 || errors.status == 429) {
                    if (errors.data) {
                        for (let k1 in errors.data) {
                            if (typeof errors.data[k1] == "object") {
                                let validation_errors = errors.data[k1];
                                for (let k2 in validation_errors) {
                                    error_message[k2] = validation_errors[k2][0];
                                    error_status[k2] = true;
                                }
                            }
                        }
                    }
                }
                else
                {
                    toastr.error(errors.data.message);
                }

                commit('SHOW_ADD_PAYMENT_REQUEST_ERRORS', {error_message, error_status});
                commit('HIDE_LOADER', null, {root: true});
            });

        });
    },

    /**
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    getPaymentRequestList: async ({state, commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        return await axios({
            url: '/client/v2/get-payment-request-list',
            method: 'POST',
            data: {filters: state.paymentRequests.filters},
        }).then((resp) => {
            commit('SET_PAYMENT_REQUEST_LIST', resp.data);
            commit('HIDE_LOADER', null, {root: true});
            return resp;
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            return err;
        });
    },

    /**
     * get list of all supported currencies
     * @param commit
     * @returns {Promise<void>}
     */
    getSupportedCurrencies: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-supported-currencies',
            method: 'GET'
        }).then((resp) => {
            commit('GET_CURRENCIES', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    /**
     * get list of all supported languages
     * @param commit
     * @returns {Promise<void>}
     */
    // getSupportedBookingLanguages: async ({commit}) => {
    //     commit('SHOW_LOADER', null, {root: true});
    //     await axios({
    //         url: '/client/v2/get-supported-booking-languages',
    //         method: 'GET'
    //     }).then((resp) => {
    //         commit('GET_BOOKING_LANGUAGES', resp.data);
    //         commit('HIDE_LOADER', null, {root: true});
    //     }).catch((err) => {
    //         console.log(err);
    //         commit('HIDE_LOADER', null, {root: true});
    //     });
    // },
    
    /**
     * check if chargeback supported by the selected payment gateway
     * @param commit
     * @returns {Promise<void>}
     */
    checkIfChargebackSupported: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-payment-gateway-capabilities',
            method: 'GET'
        }).then((resp) => {
            commit('SET_IF_CHARGEBACK_SUPPORTED', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    /**
     * Load data with key
     */
    loadPaymentRequestFormData: async ({state, commit, dispatch}, serve_id) => {
        commit('SHOW_LOADER', null, {root: true});
        if (serve_id > 0) {

            commit('RESET_PAYMENT_REQUEST_FORM_DATA');

            await axios({
                url: '/client/v2/get-payment-request-form-data',
                method: 'POST',
                data: { serve_id: serve_id },
            }).then((resp) => {
                if (resp.data.status) {
                    commit('LOAD_PAYMENT_REQUEST_FORM_DATA', resp.data.data);
                }
                commit('HIDE_LOADER', null, {root: true});
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(err.response.data.message);
            });
        } else {
            commit('RESET_PAYMENT_REQUEST_FORM_DATA');
            commit('HIDE_LOADER', null, {root: true});
        }
    },
    /**
     * Send email to guest
     */
    sendPaymentRequestEmailToGuest: async ({state, commit, dispatch}, serve_id) => {
        commit('SHOW_LOADER', null, {root: true});
        if (serve_id > 0) {

            return new Promise((resolve, reject) => {
                 axios({
                    url: '/client/v2/send-guest-payment-request-email',
                    method: 'POST',
                    data: {serve_id: serve_id},
                }).then((resp) => {
                    commit('HIDE_LOADER', null, {root: true});
                    commit('SET_CREATED_PAYMENT_REQUEST_DATA', resp.data.data);
                    return resolve(true);
                }).catch((err) => {
                    console.log(err);
                    commit('HIDE_LOADER', null, {root: true});
                    toastr.error(err.response.data.message);
                });
            });
        }
    },

    /**
     * Send reminder email to guest
     */
    sendPaymentRequestReminderEmailToGuest: async ({state, commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        if (data.payment_request_id > 0) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/client/v2/send-guest-payment-request-reminder-email',
                    method: 'POST',
                    data: data,
                }).then((resp) => {
                    commit('HIDE_LOADER', null, {root: true});
                    if (resp.data.status == true && resp.data.status_code == 200) {
                        commit('SET_CREATED_PAYMENT_REQUEST_DATA', resp.data.data);
                        return resolve(true);
                    }
                    else
                    {
                        toastr.error(resp.data.message);
                    }
                }).catch((err) => {
                    console.log(err);
                    commit('HIDE_LOADER', null, {root: true});
                    toastr.error(err.response.data.message);
                });
            });
        }
    },

    paymentRequestActiveLink: ({commit}, payment_request_link) => {
        //console.log(booking_id);
        commit('PAYMENT_REQUEST_ACTIVE_LINK', payment_request_link);
    },

    manuallyVoidPaymentRequest: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/manually-void-payment-request",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('getPaymentRequestList');
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    markAsPaidPaymentRequest: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/mark-as-paid-payment-request",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('getPaymentRequestList');
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    authRelease: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/auth-release-payment-request",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('getPaymentRequestList');
                toastr.success(resp.data.message);
                commit('HIDE_LOADER', null, {root: true});
            } else {
                toastr.error(resp.data.message);
                commit('HIDE_LOADER', null, {root: true});

            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

};

export default actions;