let mutations = {

    GUEST_PORTAL_DATA(state, payload) {
        for(let key in payload){
            if (payload.hasOwnProperty(key)) {
                state.guest_portal[key] = payload[key];
            }
        }
        return state;
    },
    TOGGLE_BASIC_INFO_ERRORS(state, payload) {
        return state.guest_portal.basic_info = {...state.guest_portal.basic_info, ...payload};
    },
    GUIDE_BOOK_TYPE(state, payload) {
        return state.guest_portal.guide_book = payload;
    },
    BASIC_INFO_UPDATE(state, payload) {
        state.guest_portal.email = payload.email;
        state.guest_portal.phone = payload.phone;
        state.guest_portal.arrival_time = payload.arrival_time;
        state.guest_portal.arriving_by = payload.arriving_by;
        state.guest_portal.other_detail = payload.other_detail;
        state.guest_portal.flight_no = payload.flight_no;
        state.guest_portal.show_contact_info_form = false;

        return state;
    },
    BASIC_INFO_SECTION_DETAIL_ERRORS(state, {error_message}) {

        state.guest_portal.section_detail_error_messages = error_message;
        return state;
    },

    TOGGLE_CARD_ERRORS(state, payload) {
        return state.guest_portal.card = {...state.guest_portal.card, ...payload};
    },

    GUEST_CARD_UPDATE(state) {
        state.guest_portal.show_update_card_form = false;
        return state;
    },

    UPDATE_CONTACT_FORM_SHOW(state, value) {

        state.guest_portal.show_contact_info_form = value;
        state.guest_portal.basic_info.email = state.guest_portal.basic_info_tab_data.email;
        state.guest_portal.basic_info.phone = state.guest_portal.basic_info_tab_data.phone;
        state.guest_portal.basic_info.arrival_time = state.guest_portal.basic_info_tab_data.arrival_time;
        state.guest_portal.basic_info.arriving_by = state.guest_portal.basic_info_tab_data.arriving_by;
        state.guest_portal.basic_info.other_detail = state.guest_portal.basic_info_tab_data.other_detail;
        state.guest_portal.basic_info.flight_no = state.guest_portal.basic_info_tab_data.flight_no;

        return state;
    },

    UPDATE_CARD_SHOW(state, value) {
        return state.guest_portal.show_update_card_form = value;
    },

    IMAGES_UPDATED(state, payload) {
        //to remove ATTENTION text after upload
        if(payload.image_type == 'passport_uploaded')
            state.guest_portal.meta.need_guest_verification = false;
        else if(payload.image_type == 'credit_card_uploaded')
            state.guest_portal.meta.need_credit_card_scan = false;

        state.guest_portal.images = payload.request_response;
        return state;
    },

    SHOW_3DS_MODAL_BOX_AT_GUEST_PORTAL(state){
        state.guest_portal._3ds_modal = true;
    },

    HIDE_3DS_MODAL_BOX_AT_GUEST_PORTAL(state){
        state.guest_portal._3ds_modal = false;
    },

    SET_BASIC_INFO_TAB_DATA(state, payload){
        state.guest_portal.basic_info_tab_data = payload;
    },

    SET_PAYMENT_DETAIL_TAB_DATA(state, payload){
        state.guest_portal.payment_detail_tab_data = payload;
    },
    REQUIRED_DOCUMENT_COUNT(state, count){
        state.required_document_count = count
    },
    UPDATE_GUEST_PORTAL_META(state, payload) {
        return state.guest_portal.meta = {...state.guest_portal.meta, ...payload.meta};
    },
    SET_GUIDEBOOKS(state, payload){
        state.guest_portal.guide_books = payload;
    },
};


export default mutations;
