/**
 * The logger object provides logging functionality if logging is enabled.
 * Use logger.group, logger.groupEnd, logger.log, logger.error, and logger.warn to log messages to the console.
 */
const logger = {
    /**
     * Logs a start group message to the console if logging is enabled.
     *
     * @param {string} message - The message to log as the group label.
     * @param {...string} style - Additional styles to apply to the group label.
     */
    group(message, ...style) {
        if (localStorage.getItem('loggingEnabled') === 'true') {
            console.group('%c[Logger - Group]: %c' + message, 'color: Brown; font-weight: bold;', '', ...style);
        }
    },
    /**
     * Ends the current console group if logging is enabled.
     */
    groupEnd() {
        if (localStorage.getItem('loggingEnabled') === 'true') {
            console.groupEnd();
        }
    },
    /**
     * Logs a collapsed group message to the console, if logging is enabled.
     * @param {string} message - The message to be logged within the collapsed group.
     * @param {...string} style - Additional CSS styles to apply to the logged message.
     */
    groupCollapsed(message, ...style) {
        if (localStorage.getItem('loggingEnabled') === 'true') {
            console.groupCollapsed('%c[Logger - Group]: %c' + message, 'color: Brown; font-weight: bold;', '', ...style);
        }
    },
    /**
     * Logs a message to the console if logging is enabled.
     *
     * @param {string} message - The message to log.
     * @param {...string} style - Additional styles to apply to the logged message.
     */
    log(message, ...style) {
        if (localStorage.getItem('loggingEnabled') === 'true') {
            console.log('%c[Logger]: %c' + message, 'color: Gray; font-weight: bold;', '', ...style);
        }
    },
    /**
     * Logs an error message to the console if logging is enabled.
     *
     * @param {string} message - The error message to log.
     * @param {...string} style - Additional styles to apply to the logged message.
     */
    error(message, ...style) {
        if (localStorage.getItem('loggingEnabled') === 'true') {
            console.error('%c[Logger - Error]: %c' + message, 'color: red; font-weight: bold;', '', ...style);
        }
    },
    /**
     * Logs a warning message to the console if logging is enabled.
     *
     * @param {string} message - The warning message to log.
     * @param {...string} style - Additional styles to apply to the logged message.
     */
    warn(message, ...style) {
        if (localStorage.getItem('loggingEnabled') === 'true') {
            console.warn('%c[Logger - Warning]: %c' + message, 'color: yellow; font-weight: bold;', '', ...style);
        }
    },

    /**
     * Logs a message to New Relic if the New Relic object is available.
     *
     * @param {string} message - The message to log to New Relic.
     */
    newrelic(message){
        console.log('newrelic');
        if(window?.newrelic){
            // Capture a single log event
            newrelic.log(message, {level: 'debug'})
        }
    }
};

export default logger;