import moment from "moment";


const tz = () => moment.tz.guess(),
    isValid = g => g && g.trim() != '' && moment(g).isValid(),
    dateOnly = g => isValid(g) != '' ? moment.parseZone(g).utc().format('MMM DD, YYYY') : g,
    timeOnly = g => isValid(g) ? moment.parseZone(g).utc().format('HH:mm') : g,
    dateTime = g => isValid(g) ? moment.parseZone(g).utc().format('MMM DD, YYYY HH:mm') : g,
    today = () => moment().format('MMM DD, YYYY'),
    dateDiff = g => isValid(g) ? moment().diff(g) : null;
//  dateTime = g => isValid(g) ? moment.parseZone(g).utc().local().format('DD MMM YYYY, hh:mm') : g;


export default {
    tz: tz,
    isValid: isValid,
    dateOnly: dateOnly,
    timeOnly: timeOnly,
    dateTime: dateTime,
    today: today,
    dateDiff: dateDiff,
};