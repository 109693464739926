import logger from '../../../../../helpers/logger';

let actions = {
    getUpsellTypes: async ({commit},{for_filters,serve_id}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-upsell-types/'+for_filters+"/"+serve_id,
            method: 'GET'
        }).then((resp) => {
            commit('GET_UPSELL_TYPES', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    getUpsellAvailableOptionsList: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-upsell-advance-option',
            method: 'GET'
        }).then((resp) => {
            commit('SET_UPSELL_ADVANCE_OPTION_STATUS', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    getUpsellTemplatesList: async ({commit}) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-default-templates?type=upsell',
            method: 'GET'
        }).then((resp) => {
            commit('SET_UPSELL_TEMPLATES_LIST', resp.data.data);
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            // commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    loadUpsellTemplateFormData: async ({commit}, template_id) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-template-data',
            method: 'POST',
            data: {
                type: "upsell",
                template_id: template_id
            }
        }).then((resp) => {
            if (resp.data.status) {
                commit('LOAD_UPSELL_FORM_DATA', resp.data.data);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    addUpsell: async ({state, commit, dispatch}, serve_id) => {
        commit('SHOW_LOADER', null, {root: true});

        const formData = new FormData();
        state.upsell.form_data.upsellImagesFiles.forEach(image => {
            formData.append('file[]', image);
        })
        state.upsell.form_data.upsellImagesFiles = [];

        let structuredData = state.upsell.form_data;
        
        /*
        * IF PROPERTY IS SELECTED
        *   1) attached_units is empty              INSERT {"name":"All Rentals","code":0} into attached_units
        *   2) attached_units is 1
        *       a) if all_units is 1                EMPTY attached_units & INSERT {"name":"All Rentals","code":0} into attached_units
        *       b) if all_units is 2 or more        DO NOTHING; SKIP ITERATION
        *   3) attached_units is 2 or more 
        *       a) if attached_units === all_units  EMPTY attached_units & INSERT {"name":"All Rentals","code":0} into attached_units
        *       b) if attached_units < all_units    DO NOTHING; SKIP ITERATION 
        */
        logger.groupCollapsed("🏠 Properties Update Process");
        structuredData.properties_with_rooms_updated.forEach((property, index) => {
            logger.groupCollapsed(`Property #${index + 1}`);
            logger.log(`%cProperty #${index + 1}: ${property}`, "color: navy; font-weight: bold;");
            
            // IF PROPERTY IS SELECTED
            // if (property.attach_status) {
                logger.log("%c✅ Property is selected", "color: green;");
        
                // 1) attached_units is empty
                if (property?.attached_units?.length === 0) {
                    logger.log("%c📦 Attached units are empty", "color: purple;");
                    // a) if all_units is 0, 1 or 2 or more
                    property.attached_units.push({"name":"All Rentals", "code":0});
                    logger.log("%c🔍 Inserting 'All Rentals' into empty attached_units", "color: blue;");
                }
                // 2) attached_units is 1
                else if (property?.attached_units?.length === 1) {
                    logger.log("%c📦 One attached unit exists", "color: purple;");
        
                    // a) if all_units is 1
                    if (property.all_units?.length  === 1) {
                        property.attached_units = [{"name":"All Rentals", "code":0}];
                        logger.log("%c🔄 Replacing single attached unit with 'All Rentals'", "color: orange;");
                    }
                    // b) if all_units is 2 or more
                    // DO NOTHING; SKIP ITERATION
                    else {
                        logger.log("%c🛑 More than one unit, skipping...", "color: red;");
                    }
                }
                // 3) attached_units is 2 or more
                else if (property?.attached_units?.length >= 2) {
                    logger.log("%c📦 Multiple attached units exist", "color: purple;");
                    
                    // a) if attached_units === all_units
                    if (property?.attached_units?.length === property.all_units?.length) {
                        property.attached_units = [{"name":"All Rentals", "code":0}];
                        logger.log("%c🔄 Replacing all attached units with 'All Rentals'", "color: orange;");
                    }
                    // b) if attached_units < all_units
                    // DO NOTHING; SKIP ITERATION
                    else {
                        logger.log("%c🛑 Attached units less than total, skipping...", "color: red;");
                    }
                }
            // } else {
                // logger.log("%c❌ Property not selected", "color: grey;");
            // }
        
            logger.groupEnd();
        });
        logger.groupEnd();

        if (structuredData.all_rentals_selected === true) {
            logger.groupCollapsed("%c🔍 All Rentals Selected", "color: darkblue; font-weight: bold;");
            logger.log("%c✅ All rentals have been selected.", "color: green;");
            
            structuredData.selected_properties.forEach((e, index) => {
                logger.groupCollapsed(`%cProperty #${index + 1}`, "color: navy; font-weight: bold;");
                logger.log("%cBefore Update: ", "color: grey;", JSON.stringify(e.attached_units));
                
                e.attached_units = [0];
                
                logger.log("%cAfter Update: ", "color: blue;", JSON.stringify(e.attached_units));
                logger.groupEnd();
            });
            
            logger.groupEnd();
        }  else if (structuredData.all_rentals_selected === false) {
            structuredData.selected_properties.forEach((property, index) => {
                const units = structuredData.properties_with_rooms_updated[index].attached_units;
                if (units.length === 1 && units[0].name === "All Rentals" && units[0].code === 0) {
                    property.attached_units = [0];
                }
            });
        } else if (structuredData.all_rentals_selected === '') {
            logger.log("%c🔍 Specific Properties Selection", "color: darkblue; font-weight: bold;");
            
            structuredData.selected_properties.forEach((e, index) => {
                logger.groupCollapsed(`%cProperty #${index + 1}`, "color: navy; font-weight: bold;");
                
                const property = structuredData.properties_with_rooms_updated.find(f => f.id === e.id) || null;
                if (property) {
                    logger.log("%cFound matching property:", "color: grey;", JSON.stringify(property));
                    
                    if ((property?.all_units?.length === property?.attached_units?.length) || 
                        (property?.attached_units?.[0] && property?.attached_units[0].name === "All Rentals" && property?.attached_units[0].code === 0)) {
                        
                        logger.log("%cCondition met: Updating attached units.", "color: green;");
                        e.attached_units = [0];
                    } else {
                        logger.log("%cCondition not met: No updates made.", "color: red;");
                    }
                } else {
                    logger.log("%c⚠️ No matching property found.", "color: red;");
                }
                
                logger.groupEnd();
            });
        } else {
            logger.log("%c❌ No rental selection condition met", "color: red;");
        }

        formData.append('form_data', JSON.stringify(structuredData));
        formData.append('serve_id', serve_id);

        return new Promise((resolve, reject) => {
            axios.post('/client/v2/upsell-store/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            // axios({
            //     url: '/client/v2/upsell-store/' + serve_id,
            //     method: 'POST',
            //     data: state.upsell.form_data,
            // })
            .then((resp) => {

                commit('HIDE_LOADER', null, {root: true});

                if (resp.data.status) {
                    resolve(resp);
                } else {
                    
                    if(resp.data.message == undefined) {
                        toastr.error('Please make sure you have completed Account Setup (Integration) and enabled Properties/Rentals.');
                        
                    } else {
                        toastr.error(resp.data.message);
                    }
                    reject(resp);
                }
            }).catch((err) => {
                let errors = err.response;
                let error_message = {};
                let error_status = {};

                if (errors.data && (errors.status == 422 || errors.status == 429)) {

                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];

                            for (let error in validation_errors) {
                                if(error.indexOf('.') !== -1 && !error.includes("price.tier_plans")){

                                    const keys = error.split('.');
                                    let indexOne_key = keys[1];

                                    if (keys[1] === 'additional_fees') {
                                        indexOne_key = error.substring(error.indexOf(".") + 1); //Use format like this "formData.error_message.price['additional_fees.1.value']" to access value
                                    }

                                    //Manage Error Messages
                                    if(!error_message.hasOwnProperty(keys?.[0])) {
                                        Object.defineProperty(error_message, keys?.[0], {
                                            value: {},
                                            writable: true,
                                            enumerable: true,
                                            configurable: true
                                        });
                                    }
                                    Object.defineProperty(error_message[keys?.[0]], indexOne_key, {
                                        value: validation_errors?.[error]?.[0],
                                        writable: true,
                                        enumerable: true,
                                        configurable: true
                                    });

                                    //Manage Error Status
                                    if(!error_status.hasOwnProperty(keys?.[0])){
                                        Object.defineProperty(error_status, keys?.[0], {
                                            value: {},
                                            writable: true,
                                            enumerable: true,
                                            configurable: true
                                        });
                                    }
                                    Object.defineProperty(error_status[keys?.[0]], indexOne_key, {
                                        value: true,
                                        writable: true,
                                        enumerable: true,
                                        configurable: true
                                    });

                                }else{
                                    error_message[error] = validation_errors[error]?.[0];
                                    error_status[error] = true;
                                }
                            }
                        }
                    }

                    // this changes the scrolling behavior to "smooth"
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    $('.modal-body').animate({scrollTop: 0},400);
                }
                //console.log({error_message, error_status});
                let messages = err.response.data.errors;
                commit('SHOW_ADD_UPSELL_ERRORS', {error_message: error_message, error_status: error_status});
                commit('HIDE_LOADER', null, {root: true});
                reject(err);
            });
        });
    },

    loadUpsellFormData: async ({state, commit, dispatch}, serve_id) => {
        commit('SHOW_LOADER', null, {root: true});

        commit('RESET_UPSELL_FORM_DATA');

        //if (serve_id > 0) {
            dispatch('getList', 'get-upsell-list');
            await axios({
                url: '/client/v2/get-upsell-form-data',
                method: 'POST',
                data: {serve_id: serve_id},
            }).then((resp) => {
                if (resp.data.status) {
                    if (resp.data.data === null) { // Check if the response data is null
                        console.error("Null API response or server issue.") // Log an error message to the console
                        window.location.href = "/client/v2/upsells" // Redirect the user to the upsell page
                    } else { // If the response data is not null
                        commit('LOAD_UPSELL_FORM_DATA', resp.data.data); // Load the upsell form data into the store
                        commit('SET_ATTACHED_BOOKING_SOURCES', resp.data.data); // Set the attached booking sources from the response data in the store
                        commit('SET_ATTACHED_BOOKING_TYPES', resp.data.data); // Set the attached booking types from the response data in the store
                    }
                }
                commit('HIDE_LOADER', null, {root: true});
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
            });
        //} else {
            commit('RELOAD_PROPERTIES_WITH_ROOMS', null, {root: true});
        //    commit('HIDE_LOADER', null, {root: true});
        //}
    },
    setAttachedBookingTypes: async ({
        state,
        commit
    }, payload) => {
        commit('SET_ATTACHED_BOOKING_TYPES', payload);
    },
    /**
     *
     * @param state
     * @param commit
     * @param list_type | 'upsell' | 'upsell-orders'
     * @returns {Promise<void>}
     */
    getList: async ({state, commit}, list_type) => {
        commit('SHOW_LOADER', null, {root: true});
        let url = '/client/v2/';
        url += list_type == 'upsell-orders' ? 'get-upsell-order-list' : 'get-upsell-list';
        await axios({
            url: url,
            method: 'POST',
            data: {filters: state.upsell.filters},
        }).then((resp) => {
            commit('SET_UPSELL_LIST', {
                ...resp?.data,
                data: resp?.data?.data?.filter(Boolean) ?? []
              });
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    connectOrDisconnect: async ({state, commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/upsell-status-change',
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status) {
                commit('UPSELL_LIST_STATUS_UPDATE', data);
                swal.mixin({
                    html: `
                    <div class="view-edit-body">                            
                        <h3 class="view-edit-title">${resp.data.message}</h3>                        
                    </div>
                    `,   
                    // text: resp.data.message,
                    showConfirmButton: true,
                    backdrop: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    customClass: {
                        container: "rental-alert-wrap",
                    },
                }).fire();
            } else {
                data.status = data.status ? 0 :1;
                commit('UPSELL_LIST_STATUS_UPDATE', data);
                document.getElementById('checkbox-'+data.id).checked = data.status ? true:false;
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            commit('HIDE_LOADER', null, {root : true});
            data.status = data.status ? 0 :1;
            document.getElementById('checkbox-'+data.id).checked = data.status ? true:false;
            commit('UPSELL_LIST_STATUS_UPDATE', data);
            console.log(err);
        });
    },

    getUpsellConfig: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-upsell-config',
            method: 'POST',
        }).then((resp) => {
            commit('SET_UPSELL_CONFIG', resp.data.data);
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            commit('HIDE_LOADER', null, {root : true});
            console.log(err);
        });
    },

    addUpsellPhoto: async ({commit}, form_data) => {

        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/add-upsell-photo',
            method: 'POST',
            data : form_data
        }).then((resp) => {
            console.log(resp);
            if(resp.data.status) {
                commit('PUSH_UPSELL_IMAGE',resp.data.data.image);
                let uploadImageModalCloseBtn = document.getElementById('upload_image_modal_close_btn');
                if (uploadImageModalCloseBtn) {
                    uploadImageModalCloseBtn.click();
                }

                toastr.success(resp.data.message);
            }else{
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
            commit('SHOW_UPSELL_IMAGE_ERRORS', err.response.data.errors);
        });
    },

    storeUpsellPhoto: async ({
        commit
    }, form_data) => {

        commit('SHOW_LOADER', null, {
            root: true
        });

        let img = '';
        if (window.createObjectURL != undefined) { // basic
            img = window.createObjectURL(form_data);
        } else if (window.webkitURL != undefined) { // webkit or chrome
            img = window.webkitURL.createObjectURL(form_data);
        } else if (window.URL != undefined) { // mozilla(firefox)
            img = window.URL.createObjectURL(form_data);
        }

        let img_obj = {
            url: img,
        }
        commit('PUSH_UPSELL_IMAGE', {
            'image': img_obj,
            'file': form_data
        });
        // const el = document.getElementById("upload_image_modal_close_btn")
        // el && el.click();
        commit('HIDE_LOADER', null, {
            root: true
        });
    }
};

export default actions;
