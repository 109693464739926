/**
 * Vue directive to check div visibility and execute functions based on intersection.
 * @type {Object}
 */
const checkVisibility = {
  /**
   * Called when the directive is mounted to the element.
   * @param {HTMLElement} el - The element the directive is bound to.
   * @param {Object} binding - Directive binding object.
   * @param {Object} vnode - Virtual DOM node.
   */
  mounted(el, binding, vnode) {
    const { onVisible, onHidden } = binding.value;

    if (typeof onVisible === "function" && typeof onHidden === "function") {
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onVisible();
          } else {
            onHidden();
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(el);

      el.__intersectionObserver__ = observer;
    } else {
      console.error("Invalid function names provided in directive binding.");
    }
  },

  unmounted(el) {
    if (el.__intersectionObserver__) {
      el.__intersectionObserver__.disconnect();
      delete el.__intersectionObserver__;
    }
  },
};

const chatScroll = {
  beforeMount(el, binding) {
    const options = binding.value || {};

    el._scrollOptions = {
      always: options.always || false,
      smooth: options.smooth || false,
      scrollonremoved: options.scrollonremoved || false,
      smoothonremoved: options.smoothonremoved || false,
    };

    el._scrollToBottom = (smooth = el._scrollOptions.smooth) => {
      const behavior = smooth ? "smooth" : "auto";
      el.scrollTo({ top: el.scrollHeight, behavior });
    };

    el._mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          el._scrollOptions.always ||
          (mutation.removedNodes.length > 0 &&
            el._scrollOptions.scrollonremoved)
        ) {
          el._scrollToBottom(el._scrollOptions.smoothonremoved);
        }
      });
    });

    el._mutationObserver.observe(el, { childList: true, subtree: true });
  },

  updated(el) {
    if (el._scrollOptions.always) {
      el._scrollToBottom();
    }
  },

  beforeUnmount(el) {
    if (el._mutationObserver) {
      el._mutationObserver.disconnect();
    }
  },
};

export { checkVisibility, chatScroll };
