// reusable_payment_page_list
// ReusablePaymentRequestListing.vue


let actions = {
    
    getAllReusablePaymentPageList: async ({commit, state}, filters) => {

        commit('SHOW_LOADER', null, {root: true});
        console.log(filters);
        await axios({
            url: '/client/v2/reusable-payment-page-list',
            method: 'POST',
            data: {filters},
        }).then((response) => {
            if(response.status == 200) {
                commit('SET_LIST_VALUES', response.data);
            } else {
                toastr.error(response.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });




//         commit('SHOW_LOADER', null, {root : true});
//
//         let url='/client/v2/reusable-payment-page-list';
//
//         await axios.post(url).then((response) => {
//
//             commit('HIDE_LOADER', null, {root : true});
//
//             if(response.data.status) {
//
//                 if(response.data.status_code == 200) {
// //                    toastr.success(response.data.message);
//                     commit('SET_VALUES', response.data.data);
//                 } else {
//                     toastr.info(response.data.message);
//                 }
//
//             } else {
//                 toastr.error(response.data.message);
//             }
//
//         }).catch((err) => {
//             commit('HIDE_LOADER', null, {root : true});
//             if(err.response.status == 422) {
//                 commit('SET_ERRORS', err.response.data.errors);
//             } else {
//                 toastr.error(err);
//             }
//         });
        
    },

};

export default actions;