let getters = {
    /**
     * This getter returns the current billing plan as a string.
     * 
     * @param {Object} state - The state object from the Vuex store containing the billing plan type.
     * @returns {string} The current billing plan.
     */
    currentBillingPlan: (state) => {
        // Retrieve the billing plan type from the state object, remove any whitespace and convert to lowercase.
        const plan = (state.billingPlanType || '').trim().toLowerCase();
        // Check if the billing plan contains an underscore. 
        // If it does, split the plan string on the underscore and return the first part.
        // Otherwise, return the original plan string.
        return plan.includes('_') ? plan.split('_')[0] : plan;
    },


    /**
     * Determines if the current client is on a paid billing plan or not.
     * @param {Object} state - The state object from the Vuex store.
     * @param {object} getters - The getters object from Vuex store.
     * @returns {boolean} - Returns true if the current plan is at index 1 or higher (a paid tier).
     */
    isPaidClient: (state, getters) => {
        // An array of plans for billing
        const plans = ["essentials", "professional", "enterprise"];
        // Check if the current billing plan is a paid tier by getting its index in the plans array and comparing it to 0. 
        // Any paid tier will have an index greater than 0.
        return plans.includes(getters.currentBillingPlan);

    },

    /**
     * Determines if the current user is a Guest.
     * @param {Object} state - The state object from the Vuex store.
     * @param {object} getters - The getters object from Vuex store.
     * @returns {boolean} - Returns true if user is a Guest & billing plan is empty.
     */
    isGuest: (state, getters) => {
        return getters.currentBillingPlan==='';
    },




};

export default getters;