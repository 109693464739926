import booking_detail from "./state";

let mutations = {

    LOCK_SYSTEMS(state, payload) {
        return state.lock_systems = {...state.lock_systems, ...payload};
    },

    BILLING_REQUIRED_SMART_LOCKS(state, payload) {
        return state.billing_required_smart_locks = {...state.billing_required_smart_locks, ...payload};
    },

    PROPERTIES(state, payload) {
        return state.properties = {...state.properties, ...payload};
    },

    CREDENTIALS(state, payload) {
        // Update the original state with the payload
        state.credentials = payload;
        // Create a deep clone of the original state
        state.saved_credentials = JSON.parse(JSON.stringify(state.credentials));
    },

    PROPERTY_UNITS(state, payload) {
        // Update the original state with the payload
        state.property_units = payload;

        // Create a deep clone of the original state
        state.saved_property_units = JSON.parse(JSON.stringify(state.property_units));
    },

    VERIFICATIONS(state, payload) {
        return state.verifications = payload;
    },

    ACCOUNT_NAME(state, payload) {
        return state.account_name = payload;
    },

    LOCK_IDS(state, payload) {
        return state.lock_ids = payload;
    },

    IS_OAUTH_LS(state, payload) {
        return state.is_oauth_ls = payload;
    },

    ERROR_MESSAGE(state, payload) {
        return state.error_message = payload;
    },


};


export default mutations;