let mutations = {
    SET_PAGE_DATA(state, payload){

        state.payer.transactions = payload.data.product;
        state.payer.questions = payload.data.questions;
        state.payer.company_info = payload.data.company_info;
        state.payer.user_account_id = payload.data.company_info.user_account_id;
        state.payer.request = payload.data.request;
        let product = payload.data.product[0];
        state.payer.selected_transactions = {
            id: product.id,
            payment_type: payload.data.request.payment_type,
            currency_symbol: product.currency.symbol,
            amount: product.amount,
            product_image : product.product_image
        };
        return state;
    },
    SET_REUSE_PAYMENT_TRANSACTION_LIST(state, data) {
        state.paginationResponse = data;
        // state.paymentRequests.paginationResponse = data;
        return state;
    },

    SHOW_VALIDATION_ERRORS(state, payload){
        state.payer.questions = payload;
        return state;
    },

    UPDATE_SYSTEM_REQUIRED_QUESTIONS(state, payload) {

        state.payer.questions = payload;
        // for(let key in state.payer.questions) {
        //     if(state.payer.questions.hasOwnProperty(key)) {
        //         let question = state.payer.questions[key];
        //         if(question.backend_name === 'email' && question.form_data.system_generated === true) {
        //             state.payer.questions[key].form_data.guest_answer = payload.email;
        //         }
        //         if(question.backend_name === 'short_text' && question.form_data.system_generated === true) {
        //             state.payer.questions[key].form_data.guest_answer = payload.full_name;
        //         }
        //     }
        // }

        return state;
    }
};

export default mutations;
