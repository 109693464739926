const disabledFields = (string , isReadOnly) => {
    const fields = document.querySelectorAll(string);
    fields.forEach(field => {
        // Skip if data-skip-disabled="true" found in the element. data-skip-disabled is added to skip disabled for certain variables
        if (!field.dataset.skipDisabled && isReadOnly) {
            field.classList.toggle('disabled', isReadOnly);
            field.toggleAttribute('disabled', isReadOnly);
        }
    });
}
const disabledVerificationFields = (string, string2, isReadOnly) => {
    const fields = document.querySelectorAll(string);
    const fields2 = document.querySelectorAll(string2);
    fields.forEach(field => {
        // Skip if data-skip-disabled="true" found in the element. data-skip-disabled is added to skip disabled for certain variables
        if (!field.dataset.skipDisabled) {
            field.classList.toggle('disabled', isReadOnly);
            field.toggleAttribute('disabled', isReadOnly);
        }
    });
    fields2.forEach(field => {
        // Skip if data-skip-disabled="true" found in the element. data-skip-disabled is added to skip disabled for certain variables
        if (!field.dataset.skipDisabled) {
            field.classList.toggle('cursor-not-allowed', isReadOnly);
            field.classList.toggle('cursor-pointer', !isReadOnly);
        }
    });
}

/**
 * Masks a string based on the specified style and count of visible characters.
 * 
 * @param {string} str - The string to be masked.
 * @param {number} [visibleCount=2] - The number of characters to remain unmasked at the start and/or end of the string, based on the style.
 * @param {string} [maskChar='*'] - The character used to mask the string.
 * @param {'both'|'end'|'start'} [style='both'] - The style of masking. 'both' will mask the start and end, leaving `visibleCount` characters visible at both ends. 'end' will mask all but the last `visibleCount` characters. 'start' will mask all but the first `visibleCount` characters.
 * @returns {string} The masked string.
 */
const masked = (str, visibleCount = 2, maskChar = '*', style = 'both') => {
    visibleCount=+visibleCount;

    if (str == false || str === true) {
        str =  String(str)
    }
    if (str == undefined || str === null) {
        return;
    }
    if (str != undefined && str.length <= visibleCount * (style === 'both' ? 2 : 1)) {
        return str;
    }

    let maskedSection, visibleStart = '',
        visibleEnd = '';

    switch (style) {
        case 'both':
            if (str === false || str === true) {
                str =  String(str)
            }
            if (str === undefined) {
               return;
            }
            visibleStart = str.slice(0, visibleCount);
            visibleEnd = str.slice(-visibleCount);
            maskedSection = maskChar.repeat(str.length - visibleCount * 2);
            break;
        case 'end':
            if (str === false || str === true) {
                str =  String(str)
            }
            if (str === undefined) {
               return;
            }
            visibleEnd = str.slice(-visibleCount);
            maskedSection = maskChar.repeat(str.length - visibleCount);
            break;
        case 'start':
            visibleStart = str.slice(0, visibleCount);
            maskedSection = maskChar.repeat(str.length - visibleCount);
            break;
        default:
            maskedSection = maskChar.repeat(str.length);
    }

    return visibleStart + maskedSection + visibleEnd;
};

export default {
    disabledFields: disabledFields,
    disabledVerificationFields: disabledVerificationFields,
    masked: masked,
}