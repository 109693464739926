let mutations = {

    SHOW_AUTH_ERRORS(state, {error_message, error_status, source_state}) {

        if (error_message !== {}) {
            state.auth[source_state].error_message = error_message;
        }
        if (error_status !== {}) {
            state.auth[source_state].error_status = error_status;
        }
        return state;
    },
    RESET_ERRORS(state) {
        state.auth.login.error_message = {
            email: '',
            password: '',
            recaptchaToken: ''
        }
    },
    SET_CLIENT_IP_INFO(state, client_ip_info) {
        state.auth.client_ip_info = client_ip_info
        state.auth.login.client_ip = client_ip_info.ip
        state.auth.register.client_ip = client_ip_info.ip
        state.auth.register.country = client_ip_info.country
    }
};

export default mutations;