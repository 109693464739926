let actions = {

    fetchNotificationsData: ({commit}, notification_shown) => {
        //commit('SHOW_LOADER', null, {root: true});
        axios({
            url: '/client/v2/communicationNotifyAlerts',
            method: 'POST',
            data: {'notification_shown': notification_shown},
            headers: {
                'X-Skip': true // To indicate skipping interceptor
              }
        }).then((resp) => {
            commit('FETCH_NOTIFICATIONS_DATA', resp.data);
            //commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    alertActionPerformed: ({commit}, params) => {

        let eventTarget = params.event.target;
        let alert_id = eventTarget.dataset.alertId;

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: '/client/v2/alert-action-performed',
            method: 'POST',
            data: {
                'alert_id': alert_id,
                'notification_shown': params.notification_shown,
                'is_payment_request_notification': params.is_payment_request_notification
            },
        }).then((resp) => {
            if(resp.data.status){
                toastr.success('Marked read successfully.');
            }
            commit('ALERT_ACTION_PERFORMED', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    toggleNotificationPanel: ({
        commit
    }, payload = null) => {
        commit('TOGGLE_NOTIFICATION_PANEL', payload);
    },


    doNotShowPMSAutoPaymentSettingsAlertNextTime: ({commit}) => {
        axios({
            url: '/set-autoPayment-turn-off-notifier-cookie',
            method: 'POST'
        }).then((resp) => {
            if (resp.data.status) {
                console.log(resp.data);
            }
        }).catch((err) => {
            console.log(err.response.data.message);
        });
    },

    checkPMSAutoPaymentSettingsAlertPopupAvailable: async ({commit}) => {

        return new Promise(function(resolve, reject) {
            commit('HIDE_LOADER', null, {root: true});
            axios({
                url: '/check-pms-auto-payment-settings-alert-popup-available',
                method: 'GET',
            }).then((resp) => {
                if (resp.data.status && resp.data.status_code == 200) {
                    return resolve(resp.data.data);
                }
            })
        });
    },


};


export default actions;