let mutations = {
    
    
    RESET_FORM_DATA: function (state) {
        // state = {
        //     name: '',
        //     supported_currency_id: 0,
        //     amount: 0,
        //     description: ''
        // };
        state.name=''
        state.supported_currency_id=0
        state.selectedCurrency=null
        state.amount=0
        state.description=''
        return state;
    },
    
    RESET_ERRORS: function(state) {
        
        $.each(state.error_message, function(key, value){
            state.error_message[key] = '';
        });
        
        $.each(state.error_status, function(key, value){
            state.error_status[key] = false;
        });
        
    },
    
    SET_ERRORS:function (state, data) {
        
        $.each(data, function(key, value){
            state.error_message[key] = value[0];
            state.error_status[key] = true;
        });
    },

    SET_PRODUCT(state, payload){
        state.name = payload.data.products[0].name;
        state.id = payload.data.products[0].id;
        state.description = payload.data.products[0].description;
        state.amount = payload.data.products[0].amount;
        state.supported_currency_id = payload.data.products[0].supported_currency_id;
        state.selectedCurrency = payload.data.products[0].currency;
        state.product_image = payload.data.products[0].product_image;
        return state;
    },
    
};

export default mutations;
