let actions = {

    fetchLockSystems: async function ({commit}) {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/lock-system/get-lock-systems',
            method: 'POST'
        }).then((resp) => {
            commit('LOCK_SYSTEMS', resp.data.data.lock_systems);
            commit('BILLING_REQUIRED_SMART_LOCKS', resp.data.data.billing_required_smart_locks);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchProperties: async function ({commit}, lock_system_id) {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/lock-system/get-properties/'+lock_system_id,
            method: 'GET'
        }).then((resp) => {
            if (resp.data.status_code == 200) {
                commit('PROPERTIES', resp.data.data.properties);
                commit('HIDE_LOADER', null, {root: true});
            } else {
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(err.data.message);
        });
    },

    fetchLSCredentialForm: async function ({commit}, data) {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/lock-system/get-ls-credential-form',
            method: 'POST',
            data
        }).then((resp) => {
                if (resp.data.status_code == 200) {
                    commit('CREDENTIALS', resp.data.data.credentials);
                    commit('PROPERTY_UNITS', resp.data.data.property_units);
                    commit('VERIFICATIONS', resp.data.data.verifications);
                    commit('ACCOUNT_NAME', resp.data.data.account_name);
                    commit('IS_OAUTH_LS', resp.data.data.is_oauth_ls);
                    commit('LOCK_IDS', resp.data.data.lock_ids);
                    commit('HIDE_LOADER', null, {root: true});
                } else {
                    toastr.error(resp.data.message);
                }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(err.data.message);
        });
    },

    saveLSFormForSelectedProperty: function ({commit}, data) {

        return new Promise((resolve, reject) => {
           commit('ERROR_MESSAGE', []);
           commit('SHOW_LOADER', null, {root: true});
           axios({
                url: '/client/v2/lock-system/save-ls-form-for-selected-property',
                method: 'POST',
                data
           }).then((resp) => {
                if (resp.data.status_code == 200) {
                    commit('HIDE_LOADER', null, {root: true});
                    toastr.success(resp.data.message);
                    resolve(resp.data);

                } else if (resp.data.status_code == 422) {
                    //console.error('in if code 422');
                    let errors = resp.data;

                    if (typeof errors.message != "object" && !errors.data) {
                        toastr.error(errors.message);
                        resolve(resp.data);
                    } else if (errors.data) {
                        var error_message = [];
                        commit('HIDE_LOADER', null, {root: true});
                        for (let k1 in errors.data) {
                            if (typeof errors.data[k1] == "object") {
                                let validation_errors = errors.data[k1];
                                for (let k2 in validation_errors) {
                                    error_message[k1] = validation_errors[k2];
                                    toastr.error(validation_errors[k2]);
                                }
                            }
                        }
                        commit('ERROR_MESSAGE', error_message);
                        resolve(resp.data);
                    }
                    commit('HIDE_LOADER', null, {root: true});
                }

            }).catch((err) => {
                if (err.response && err.response.data && err.response.status == 422 && err.response.data.errors) {
                    let errors = err.response.data.errors;
                    commit('HIDE_LOADER', null, {root: true});
                    if (typeof errors == "object") {
                        if (errors.property_units) {
                            toastr.error(errors.property_units[0]);
                        }
                    }
                }
               resolve({status:false, status_code:422});

           });

        });
    },

    copyLSSettings: function ({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('SHOW_LOADER', null, { root: true });
    
            axios({
                url: '/client/v2/lock-system/copy-ls-settings',
                method: 'POST',
                data
            }).then((resp) => {
                if (resp.data.status_code == 200) {
                    commit('HIDE_LOADER', null, { root: true });
                    toastr.success(resp.data.message);
                    resolve(resp.data);
                } else {
                    toastr.error(resp.data.message);
                    reject(resp.data);
                }
            }).catch((err) => {
                if (err.response && err.response.data && err.response.status == 422 && err.response.data.errors) {
                    let errors = err.response.data.errors;
                    commit('HIDE_LOADER', null, { root: true });
                    toastr.error('Fail to copy configurations');
                    reject(errors);
                }
                console.log(err);
                commit('HIDE_LOADER', null, { root: true });
                reject(err);
            });
        });
    },


    scheduleCodesForFutureCheckins: function ({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('SHOW_LOADER', null, { root: true });

            axios({
                url: '/client/v2/lock-system/schedule-codes-future-checkin/'+data.property_info_id+'/'+data.lock_system_id+'/'+data.schedule_codes,
                method: 'POST'
            }).then((resp) => {

                if (data.schedule_codes) {
                    if (resp.data.status) {
                        toastr.success(resp.data.message);
                        resolve(resp.data);
                    } else {
                        toastr.error(resp.data.message);
                        reject(resp.data);
                    }
                }

                commit('HIDE_LOADER', null, { root: true });
            }).catch((err) => {
                toastr.error('Request failed.');
                console.log(err);
                commit('HIDE_LOADER', null, { root: true });
                reject(err);
            });
        });
    },

};

export default actions;