
const filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: ['questions','product'],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'id',
    },
    payment_status: 'all',
    payment_status_name: '',
    constraints: [],
    search: {
        searchInColumn: ['id', 'product_id'],
        searchStr: ''
    },
    payment_type: 'all',
    filter_count: 0,
    // Date Filters below
    due_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
    created_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
}; //Datatable filters Object End



let states = {

    reusablePaymentPageList: {},
    filters: filters
    
};
export default states;
