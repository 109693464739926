let state = {

    lock_systems: [],
    billing_required_smart_locks: [],
    properties: [],
    credentials: [],
    saved_credentials: [],
    verifications: [],
    account_name: '',
    lock_ids:[],
    is_oauth_ls:false,
    property_units: [],
    saved_property_units: [],
    error_message: [],

};


export default state;