let mutations = {
    SET_TRANSACTION_LIST(state, payload){
        state.payer.transactions = payload.data.transactions;
        state.payer.company_info = payload.data.company_info;
        state.payer.total_amount = payload.data.total_amount;
        state.payer.currency_code = payload.data.currency_code;
        state.payer.user_country = payload.data.user_country;
        return state;
    },

    SET_CUSTOMER_CARD_LIST(state, payload){
        state.available_cards = payload.data.cards;
        return state;
    },
    UPDATE_TRANSACTION_SELECTION_STATUS(state, {index, event}){
        return state.selectable_transactions[index].is_selected = event.target.checked;
    },
    SET_PAYABLE_AMOUNT(state, total_amount){
        state.total_amount = parseFloat(total_amount);
        return state;
    },
    SHOW_PAYMENT_REQUEST_CARD_ERRORS(state, {error_message, error_status})
    {
        if (error_message !== {}) {
            state.error_message = error_message;
        }
        if (error_status !== {}) {
            state.error_status = error_status;
        }
        return state;
    },
    SHOW_PAYMENT_ATTEMPT_ERRORS(state, {error_message, error_status})
    {
        if (error_message !== {}) {
            state.error_message = error_message;
        }
        if (error_status !== {}) {
            state.error_status = error_status;
        }
        return state;
    },
    SET_AUTH_TO_TRANSFORM(state, payload)
    {
        state.auth_to_transform = payload;
        return state;
    }
};

export default mutations;