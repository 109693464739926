import validationHelper from "../../../../../helpers/validation-helpers.js";
let mutations = {


    BOOKING_DETAIL_HEADER(state, payload) {
        return state.booking_detail.header = payload;
    },
    BOOKING_DETAILS(state, payload) {
        state.booking_detail.booking_details = {...state.booking_detail.booking_details, ...payload};
        // If phone number is invalid then  returning empty.
        if (!validationHelper.isPhoneNumberLengthValid(state.booking_detail.booking_details.phone)) {
            state.booking_detail.booking_details.phone = "";
        }
        return true;
    },
    GUEST_INFO_SECTION_DETAIL_ERRORS(state, {error_message}) {
        state.booking_detail.booking_details.section_detail_error_messages = error_message;
        return state;
    },
    SHOW_BOOKING_DETAILS_ERRORS(state, payload) {
        return state.booking_detail.booking_details = {...state.booking_detail.booking_details, ...payload};
    },
    SHOW_UPLOAD_DOCUMENT_ERRORS(state, payload) {
        return state.document_upload = {...state.document_upload, ...payload};
    },

    GUEST_EXPERIENCE_TAB_DATA(state, payload) {
        return state.booking_detail.guest_experience = {...state.booking_detail.guest_experience, ...payload};
    },

    SHOW_GUEST_EXPERIENCE_ERRORS(state, payload) {
        return state.booking_detail.guest_experience = {...state.booking_detail.guest_experience, ...payload};
    },

    ACTIVITY_LOG_TAB_DATA(state, payload) {
        let booking_id = payload.booking_id;
        //state.booking_detail.payment_status = payload.data.payment_status;
        let data = {[booking_id]: payload.data.activity_log};
        return state.booking_detail.activity_logs = {...state.booking_detail.activity_logs, ...data};
    },

    SENT_EMAILS_TAB_DATA(state, payload){
        let booking_id = payload.booking_id;

        let data = {[booking_id]: payload.data};

        return state.booking_detail.sent_emails = {...state.booking_detail.sent_emails, ...data};
    },

    UPSELL_TAB_DATA(state, payload) {
        return state.booking_detail.upsells = payload;
    },
    PAYMENT_TAB_DATA(state, payload) {
        return state.booking_detail.payments = {...state.booking_detail.payments, ...payload};
    },

    OPEN_UPSELL_TAB(state){
        return state.booking_detail.tab_section.current_tab =  {
            component_name: 'general-upsell-detail',
            icon: 'fas fa-cart-arrow-down',
            label: 'Upsell'
        };
    },
    OPEN_PAYMENT_TAB(state){
        return state.booking_detail.tab_section.current_tab =  {
            component_name: 'general-payment-detail',
            icon: 'fas fa-donate',
            label: 'Payments',
        };
    },

    OPEN_DOCUMENT_TAB(state){
        return state.booking_detail.tab_section.current_tab =  {
            component_name: 'general-documents-detail',
            icon: 'fas fa-passport',
            label: 'Documents'
        };
    },
};


export default mutations;