let actions = {
    fetchAllEmailTypes: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.get('/admin/get-all-email-types').then((resp) => {
            commit('SET_ALL_EMAIL_TYPES', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    sendEmails: async ({commit}, data) =>{
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/send-test-emails',
            method: 'POST',
            data
        }).then((resp) => {
            commit('SET_TEST_EMAIL_RESPONSE', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    }
};

export default actions;