let mutations = {
    /**
     * Updates the state's rentals list with the given payload data.
     *
     * @param {Object} state - The Vuex module state object.
     * @param {Array} payload - The array of rental objects to update the rentals list with.
     * @returns {void}
     */
    FETCH_RENTALS(state, payload) {
        state.property.rentals = payload;
    },
}

export default mutations;