let state = {
        reservation_raw_keys : [],
        reservation_keys_json : [],
        rKeys : [], //keys for making reservation form
        pKeys : [], //keys for making property form
        write_access: '',
        property_raw_keys: [],
        property_keys_json: [],
        //pms modification
        pms_preferences_data : [],
        pms_names: [],
        questionnaire_keys: [],

};

export default state;
