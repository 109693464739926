let mutations = {
    SET_FORM_DATA: function (state, data) {
        state.tags.formData=[...data]
        return state;
    },
    RESET_FORM_DATA: function (state) {
        state.tags.formData = [{
            title:"",
        }];
        // document.body.setAttribute("style", "padding-right: 0px;");
        return state;
    },

    SET_TAGS_LIST: async function (state, data) {
        state.tags.paginationResponse = data;
        state.tags.firstCallDone = true;
        return state;
    },

    SET_ALL_TAGS: function (state,data){
        state.tags.allTags = data;
    }


};
export default mutations;