import actions from "./actions";
import mutations from "./mutations";
import states from './states';
import payer from './payer/index';
import chat from './chat/index';

export default {
    namespaced: true,
    modules: {
        payer,
        chat
    },
    states,
    actions,
    mutations,
}