import {BookingData} from './state';
let mutations = {


    RESET_BOOKING_DETAIL(state, {index}) {
        state.booking.booking_detail[index] = {};
        return state.booking.booking_detail;
    },

    FETCH_BOOKING_LIST(state, payload) {
        state.booking.firstCallDone = true
        //console.log(payload);

        state.booking.booking_list.data = [];
        state.booking.booking_detail = {};

        state.booking.booking_list = {...state.booking.booking_list, ...payload}
        state.booking.isSMSPlanActive = payload.isSMSPlanActive;
        return state.booking.booking_list;
    },
    FETCH_BOOKING_DETAIL(state, {payload, index}) {
        return state.booking.booking_detail = {...state.booking.booking_detail, [index]:  {...state.booking.booking_detail[index],...payload.data.booking_detail}};
    },

    FETCH_BOOKING_DETAIL_PAYMENT_SCHEDULE(state, {payload, index}) {
        return state.booking.booking_detail = {...state.booking.booking_detail, [index]: {...state.booking.booking_detail[index],...payload.data.booking_detail}};
    },

    UPDATE_BOOKING_LIST(state, {payload, index}) {
        const bookings_data = state.booking.booking_list.data.map(function (booking) {
            if (booking.id == payload.data[0].id)
                booking = payload.data[0];
            return booking;
        })
        let new_payload = {
            data: bookings_data,
            links: state.booking.booking_list,
            meta: state.booking.booking_list.meta,
            show_tutorial: state.booking.booking_list.show_tutorial
        }
        return state.booking.booking_list = new_payload;
    },

    UPDATE_SINGLE_BOOKING(state, {payload}) {
        const bookingIndex = state.booking.booking_list.data.findIndex(booking => booking.id === payload.id);

        if (bookingIndex !== -1) {
            const bookingToUpdate = state.booking.booking_list.data[bookingIndex];

            state.booking.booking_list.data[bookingIndex] = {
                ...bookingToUpdate,
                ...(payload.guest_name && {guest_name: payload.guest_name}),
                ...(payload.check_in && {check_in: payload.check_in}),
                ...(payload.check_out && {check_out: payload.check_out}),
            };
        }
    },

    UPDATE_GUEST_CREDIT_CARD_COUNTER(state, payload) {

        if(typeof state.dashboard.upcoming_arrivals[payload.booking_index] != "undefined"){
            state.dashboard.upcoming_arrivals[payload.booking_index].total_cc_added = payload.total_credit_card + 1;
        }
        if(typeof state.booking.booking_list.data[payload.booking_index] != "undefined"){
            state.booking.booking_list.data[payload.booking_index].total_cc_added = payload.total_credit_card + 1;
        }
        return state;
    },

    SET_INITIAL_BOOKING_DATA(state, payload){
        state.booking.properties = payload.properties;
        state.booking.sources = payload.sources;

        if(payload.booking?.rental === null) {
            payload.booking.rental = '';
        }
        state.booking.add_booking = {...state.booking.add_booking, ...payload.booking};
        return state;
    },
    SET_BOOKING_LANGUAGES(state,payload){
        state.booking.languages=payload;
        return state;
    },
    SET_NATIONALITIES(state,payload){
        state.booking.nationalities = payload;
        return state;
    },

    SET_ADD_BOOKING_ERROR_MESSAGES(state, {error_message, error_status}){
        state.booking.add_booking.error_message = error_message;
        return state;
    },
    RESET_ADD_BOOKING_FORM(state){
        state.booking.add_booking = BookingData();
        return state;
    }

};

export default mutations;
