let state = {

    loader: {
        msg: 'Please Wait...',
        block: false,
        html: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>',
        force: false
    },
    autoloader: {
        msg: 'Please Wait...',
        block: false,
        html: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>',
        force: false,
        activeLoaders: 0, // to keep track of how many components are using the loader
        callers: [], // The purpose of this array is to store names of the calling functions.
    },
    reload_properties_with_rooms: 0,
    new_property: '',
    zapierTemplatedPmsIds: [194,195,199,203,196,204,197],
    countryCode: "US",
    clientIp: "",
};

export default state;
