import { reject } from 'lodash';
// import Vue from 'vue';
import {bus} from '../../../bus';
import { emailValidator, phoneValidator, requiredValidator, documentValidator, getTranslatedMessage } from './helpers';
import isEmpty from 'lodash/isEmpty'

let general_root_actions = {

    refreshBookingSourceListing: ({commit}, value) => {
        commit('REFRESH_BOOKING_SOURCES', value);
    },

    paymentRequestActiveId: ({commit}, payment_request_id) => {
        commit('PAYMENT_REQUEST_ACTIVE_ID', payment_request_id);
    },

    paymentRequestActiveIdActionType: ({commit}, payment_request_id) => {
        commit('PAYMENT_REQUEST_ACTIVE_ACTION_TYPE', payment_request_id);
    },
    
    paymentRequestActiveData: ({commit}, payload) => {
        commit('PAYMENT_REQUEST_ACTIVE_DATA', payload);
    },

    refundAmountActiveId: ({commit}, data) => {
        commit('REFUND_AMOUNT_ACTIVE_ID', {'id': data.booking_id, 'disable_partial_refund': data.disable_partial_refund});
        commit('REFUND_AMOUNT_ACTIVE_TRANSACTION_ID', data.transaction_id);
        commit('REFUND_AMOUNT_MAX_VALID_VALUE', data.amount_valid_to_refund);
        commit('REFUND_AMOUNT_ACTIVE_PAYMENT_REQUEST_ID', data.payment_request_id);
        commit('REFUND_AMOUNT_ACTIVE_REUSABLE_PAYMENT_REQUEST_ID', data.reusable_payment_request_id);
    },
    
    refundUpsellActiveId: ({commit}, data) => {
        commit('REFUND_UPSELL_ACTIVE_ID', data.upsell_order_detail_id);
        commit('REFUND_UPSELL_MAX_VALID_VALUE', data.amount_valid_to_refund);
        commit('REFUND_UPSELL_ACTIVE_BOOKING_ID', data.booking_info_id);
    },

    captureAmountActiveId: ({commit}, data) => {
        commit('CAPTURE_AMOUNT_ACTIVE_BOOKING_ID', {'id': data.booking_id, 'disable_partial_capture_and_release': data.disable_partial_capture_and_release});
        commit('CAPTURE_AMOUNT_ACTIVE_AUTH_ID', data.cc_auth_id);
        commit('CAPTURE_AMOUNT_MAX_VALID_VALUE', data.amount_valid_to_capture);
        commit('CAPTURE_AMOUNT_ACTIVE_PAYMENT_REQUEST_ID', data.payment_request_id);
        commit('CAPTURE_AMOUNT_ACTIVE_REUSABLE_PAYMENT_REQUEST_ID', data.reusable_payment_request_id);
        commit('CAPTURE_AMOUNT_ACTIVE_MODAL_TITLE', data.modal_title);

    },

    guestUploadActiveID: ({commit}, data) => {
        commit('GUEST_UPLOAD_DOC_ID', data);
    },
    guestCreditCardActiveID: ({commit}, booking_id) => {
        commit('GUEST_CREDIT_CARD_ID', booking_id);
    },
    reduceAmountData: ({commit, dispatch}, data) => {
        commit('REDUCE_AMOUNT_DATA', data);
        // dispatch('fetchActivityLogs', data.booking_id);
        // console.log('pms_prefix'+data.pms_prefix);
        //dispatch(data.pms_prefix+'/fetchBookingDetail', data.booking_info_id, {root: true});
    },
    changeAmountData: ({commit, dispatch}, data) => {
        commit('CHANGE_AMOUNT_DATA', data);
    },
    changeSDAmountData: ({commit, dispatch}, data) => {
        commit('CHANGE_SD_AMOUNT_DATA', data);
    },

    changeDueDateData: ({commit, dispatch}, data) => {
        commit('CHANGE_DUE_DATE_DATA', data);
    },

    additionalChargeActiveId: ({commit}, booking_id) => {
        commit('ADDITIONAL_CHARGE_ACTIVE_ID', booking_id);
    },

    getCompanyDefaultTimezone: async ({state, commit, dispatch}) => {

        // commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/get-company-timezone',
            method: 'GET'
        }).then((resp) => {

            if (resp.data.status) {
                commit('SET_COMPANY_TIMEZONE', resp.data.data);
                // commit('HIDE_LOADER', null, {root: true});
            } else {
                // commit('HIDE_LOADER', null, {root: true});
            }

        }).catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    additionalCharge: async ({state, commit, dispatch}, additional_charge) => {

        commit('SHOW_LOADER', null, {root: true});
        let response = undefined;

        await axios({
            url: '/client/v2/charge-more',
            method: 'POST',
            data: {'data': additional_charge}
        }).then((resp) => {

            let reload_state = false;
            if (resp.data.status) {
                reload_state = true;
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                reload_state = resp.data.status_code != 422;
                if(reload_state){
                    toastr.warning(resp.data.message, { extendedTimeOut: 10000 });
                }else{
                    toastr.error(resp.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});
            }

            if(resp.data.data) {

                if(resp.data.data.require_cvv) {
                    resp.data.require_cvv = true;
                    // This commit of data will cause cvv modal to pop-up in BookingListPage.vue file.
                    let _data = resp.data.data.dataForCvvVerification;
                    _data.meta = additional_charge;
                    commit('SET_CVV_VERIFICATION_DATA', _data);
                }

            } else {
                resp.data.require_cvv = false;
            }

            if(reload_state){
                dispatch('fetchBookingDetail', additional_charge.booking_info_id);
                //dispatching payment tab data at booking detail page
                dispatch('fetchPaymentsTabInformation', additional_charge.booking_info_id);
                dispatch('fetchActivityLogs', additional_charge.booking_info_id);
            }

            response = resp.data;

        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

        return response;
    },

    reload_booking_state: async ({state, commit, dispatch}, booking_info_id) => {
        dispatch('fetchBookingDetail', booking_info_id);
        //dispatching payment tab data at booking detail page
        dispatch('fetchPaymentsTabInformation', booking_info_id);
        dispatch('fetchActivityLogs', booking_info_id);
    },

    additionalRequest: async ({state, commit, dispatch}, additional_charge) => {

        commit('SHOW_LOADER', null, {root: true});
        additional_charge.is_request = 1;
        await axios({
            url: '/client/v2/charge-more',
            method: 'POST',
            data: {'data': additional_charge}
        }).then((resp) => {

            let reload_state = false;
            if (resp.data.status) {
                reload_state = true;
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                reload_state = resp.data.status_code != 422;
                if(reload_state){
                    toastr.warning(resp.data.message, { extendedTimeOut: 10000 });
                }else{
                    toastr.error(resp.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});
            }

            if(reload_state){
                dispatch('fetchBookingDetail', additional_charge.booking_info_id);
                //dispatching payment tab data at booking detail page
                dispatch('fetchPaymentsTabInformation', additional_charge.booking_info_id);
                dispatch('fetchActivityLogs', additional_charge.booking_info_id);
            }

        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    captureAuthAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        let url;
        if(data.payment_request_id > 0){
            url = "/client/v2/capture-payment-request-auth";
        }else if(data.reusable_payment_request_id > 0){
            url = "/client/v2/capture-reusable-payment-request-auth";
        }else {
            url = "/client/v2/capture-auth";
        }

        await axios({
            url: url,
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {

                if (data.payment_request_id > 0) {
                    dispatch('getPaymentRequestList');
                }else if(data.reusable_payment_request_id > 0){
                    dispatch('getReusableTransactionsList');
                }else {
                    dispatch('fetchBookingDetail', data.booking_info_id);
                    dispatch('fetchPaymentsTabInformation', data.booking_info_id);
                    dispatch('fetchActivityLogs', data.booking_info_id);
                }

                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);

            } else {
                toastr.error(resp.data.message);
                dispatch('fetchBookingDetail', data.booking_info_id);
                commit('HIDE_LOADER', null, {root: true});
            }
            document.getElementById('force_modal_close').click();
        }).catch((err) => {
            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_CAPTURE_AMOUNT_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });
    },


    //Payment Request-Amount Refund
    RefundPaymentRequestAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/payment-request-refund-amount",
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                dispatch('getPaymentRequestList');
                dispatch('getReusableTransactionsList');
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        });

    },

    syncBookingWithPms: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/sync-booking-with-pms",
            method: 'POST',
            data,
        }).then((resp) => {
            console.log(resp);
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('fetchBookingDetail', data.booking_id);
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        });

    },

    applyAutoPaymentRules: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        axios({
            url: "/client/v2/apply-auto-payment-rules",
            method: 'POST',
            data:{
                pms_booking_id: data.pms_booking_id,
                booking_id: data.booking_id,
            }
        }).then(async (resp) => {

            if (resp.data.status == true && resp.data.status_code == 200) {

                 // use setinterval to get updated ui status after applying auto payment
                let count = 0;
                let interval = setInterval(() => {
                    axios({
                        url: "/client/v2/booking-ui-payment-auth-status/"+data.booking_id,
                        method: 'GET',
                    }).then( async (res) => {
                          if(res.data.status == true && res.data.data?.ui_payment_status != 'Not Enabled'){
                            clearInterval(interval);
                            dispatch('fetchBookingList', {filter:data.filter});
                            dispatch('fetchBookingDetail', data.booking_id);
                            dispatch('fetchBookingStatus', {id: data.booking_id, type: 'watcher'});
            
                            toastr.success(resp.data.message);
                          }
                    })
                  
                    if(count++ > 5){
                        clearInterval(interval);
                        dispatch('fetchBookingList', {filter:data.filter});
                        dispatch('fetchBookingDetail', data.booking_id);
                        dispatch('fetchBookingStatus', {id: data.booking_id, type: 'watcher'});
        
                        toastr.success(resp.data.message);
                    }
                }, 3000);
               
          
            } else {
                commit('HIDE_LOADER', null, {root: true});
                // toastr.error(resp.data.message);

                if(resp.data.data){
                    let property_id = resp.data.data.property_info_id
                    let localPG = resp.data.data.is_local
                    let source = resp.data.data.source

                    swal.fire({
                        html: `
                            <div class="view-edit-body">
                                <h3 class="view-edit-title">Auto Payment is Not Enabled</h3>
                                <div class="view-edit-desc">
                                    <p>Auto Payment is not enabled for <b>${source}</b>. Enable it by clicking <b>'Edit Auto Payment'</b> below and then reapply Auto Payment to the booking.</p>
                                </div>
                            </div>
                            `,
                        showClass: {
                            popup: 'animated fadeInDown faster'
                        },
                        hideClass: {
                            popup: 'animated fadeOutUp faster'
                        },
                        icon:"warning",
                        showCancelButton: 'Cancel',
                        confirmButtonText: "Edit Auto Payment",
                        customClass: {
                            container: "rental-alert-wrap",
                        },
                    }).then(function (result){
                        if (result.isConfirmed) {
                            if (localPG && data?.propertyId != null) {
                                let url = '/client/v2/property';
                                url += `/update/${data?.propertyId}?expand=auto-payment`;
                                window.open(url, '_blank');
                            } else {
                                let url = '/client/v2/payment-rules';
                                window.open(url, '_blank');
                            }
                        }
                    });
                }
            }
        });
    },

    submitAPSetting(source, property){
        let self = this
        self.property_id = property.pms_property_id
        self.localPG = property.use_bs_settings

        swal.fire({
            html: `
                <div class="view-edit-body">
                    <h3 class="view-edit-title">Auto Payment is Not Enabled</h3>
                    <div class="view-edit-desc">
                        <p>Auto Payment is not enabled for <b>${source}</b>. Enable it by clicking <b>'Edit Auto Payment'</b> below and then reapply Auto Payment to the booking.</p>
                    </div>
                </div>
                `,
            showClass: {
                popup: 'animated fadeInDown faster'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster'
            },
            icon:"warning",
            showCancelButton: 'Cancel',
            confirmButtonText: "Edit Auto Payment",
            customClass: {
                container: "rental-alert-wrap",
            },
        }).then(function (result){
            if (result.isConfirmed) {
                if (self.localPG && self.property_id != null) {
                    let url = '/client/v2/properties';
                    url += `?expand=${self.property_id}`;
                    window.open(url, '_blank');
                } else {
                    let url = '/client/v2/payment-rules';
                    window.open(url, '_blank');
                }
            }
        });
    },

    //Reusable Payment Request-Amount Refund
    RefundReusablePaymentRequestAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/reusable-payment-request-refund-amount",
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                dispatch('getReusableTransactionsList');
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        });

    },
    
    //Upsell Order Detail Refund Single Item
    RefundUpsellAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/upsell-amount-refund",
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                dispatch('getList', 'upsell-orders'); //refresh upsell order listing page
                dispatch('fetchUpsells', data.booking_info_id); //refresh upsell order listing page
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        });

    },

    //Booking-AmountRefund
    RefundAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        axios({
            url: "/client/v2/refund-amount",
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('fetchBookingDetail', data.booking_id);
                dispatch('fetchPaymentsTabInformation', data.booking_id);
                dispatch('fetchActivityLogs', data.booking_id);
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        });

    },

    ReduceAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/reduce-amount",
            method: 'POST',
            data: {data},
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('BOOKING_NEW_BALANCE_AND_TOTAL', resp.data);
                dispatch('fetchBookingDetail', data.booking_info_id);
                dispatch('fetchPaymentsTabInformation', data.booking_info_id);
                dispatch('fetchActivityLogs', data.booking_info_id);
                //commit('HIDE_LOADER', null, {root : true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(err.response.data.message);
        });
    },

    ChangeAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/change-amount",
            method: 'POST',
            data: {data},
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
               
                dispatch('fetchBookingDetail', data.booking_info_id);
                dispatch('fetchPaymentsTabInformation', data.booking_info_id);
                dispatch('fetchActivityLogs', data.booking_info_id);
                //commit('HIDE_LOADER', null, {root : true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(err.response.data.message);
        });
    },

    ChangeSdAmount: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/change-sd-amount",
            method: 'POST',
            data: {data},
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('BOOKING_NEW_SD_AMOUNT', resp.data);
                dispatch('fetchBookingDetail', data.booking_info_id);
                dispatch('fetchPaymentsTabInformation', data.booking_info_id);
                dispatch('fetchActivityLogs', data.booking_info_id);
                //commit('HIDE_LOADER', null, {root : true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(err.response.data.message);
        });
    },

    changeDueDate: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/change-due-date",
            method: 'POST',
            data: {data},
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('fetchBookingDetail', data.booking_info_id);
                dispatch('fetchPaymentsTabInformation', data.booking_info_id);
                dispatch('fetchActivityLogs', data.booking_info_id);
                //commit('HIDE_LOADER', null, {root : true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        });
    },

    markAsPaid: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/mark-as-paid",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }

            dispatch('fetchBookingDetail', data.booking_info_id);
            dispatch('fetchPaymentsTabInformation', data.booking_info_id);
            dispatch('fetchActivityLogs', data.booking_info_id);
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    manuallyVoidTransaction: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/manually-void-transaction",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }

            dispatch('fetchBookingDetail', data.booking_info_id);
            dispatch('fetchPaymentsTabInformation', data.booking_info_id);
            dispatch('fetchActivityLogs', data.booking_info_id);
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    resendEmailLink: async ({commit, dispatch}, data)=>{
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/resend-email-link",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('fetchActivityLogs', data.booking_info_id);
                commit('HIDE_LOADER', null, {root: true});
                if(data.access_type == 'copy_authentication_link'){
                    if(window === top){
                        console.log("🔲 Normal window");
                        setTimeout(() => {
                            navigator.clipboard.writeText(resp.data.data).then(() => {
                                console.log('Link successfully copied to clipboard');
                            }).catch(err => {
                                console.error('Failed to copy link: ', err);
                            });
                        }, 0);
                    }else{
                        console.log("🖼️ Is Iframe");

                        const copyToClipboard = str => {
                            const el = document.createElement('textarea');
                            el.setAttribute('readonly', '');
                            el.value = str;
                            document.body.appendChild(el);
                            el.select();
                            document.execCommand('copy');
                            document.body.removeChild(el);
                        };
                        copyToClipboard(resp.data.data);
                    }

                }
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                dispatch('fetchBookingDetail', data.booking_info_id);
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    voidAuth: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/manually-void-auth",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }

            dispatch('fetchBookingDetail', data.booking_info_id);
            dispatch('fetchPaymentsTabInformation', data.booking_info_id);
            dispatch('fetchActivityLogs', data.booking_info_id);
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchGuestDocuments: async function ({commit}, booking_id) {
        if (booking_id > 0) {
            commit('SHOW_LOADER', "hyper", {root: true});

            await axios({
                url: '/client/v2/get-guest-documents/' + booking_id,
                method: 'GET',
            }).then((resp) => {
                commit('SET_GUESTS', resp.data.guests);
                commit('GUEST_DOCUMENTS_TAB_DATA', resp.data);
                commit('SET_GUEST_DOCUMENT', resp.data);
                commit('SET_GUEST_EXPERIENCE_SETTING', resp.data);
                commit('HIDE_LOADER', null, {root: true});
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
            });
        }
    },

    updateDocumentStatus: async function ({commit}, data) {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/updateStatus/',
            method: 'POST',
            data
        }).then((resp) => {
            
            if(resp.data.status_code == 422) {
                toastr.error(resp.data.message);
            } else {
                commit('SET_GUESTS', resp.data.guests);
                commit('GUEST_DOCUMENTS_TAB_DATA', resp.data);
                commit('SET_GUEST_DOCUMENT', resp.data);
            }
            
            commit('HIDE_LOADER', null, {root: true});
            
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    deleteDocument: async function ({commit, dispatch}, data) {

        swal.fire({
            title: 'Are you sure you want to delete this document?',
            text: 'Deleting cannot be undone',
            icon: "question",
            showCancelButton: !0,
            confirmButtonText: "Proceed",
            customClass: {
                container: "rental-alert-wrap delete-document-subheading",
            },
        }).then(function (e) {

            if (e.value == true) {
                commit('SHOW_LOADER', null, {root: true});
                axios({
                    url: '/v2/guest-image-delete',
                    method: 'POST',
                    data
                }).then((resp) => {
                    dispatch('fetchGuestDocuments', data.booking_id);
                    commit('HIDE_LOADER', null, {root: true});
                }).catch((err) => {
                    console.log(err);
                    commit('HIDE_LOADER', null, {root: true});
                });
            }


        });
    },

    deleteGuestImage: async function ({commit, dispatch}, data) {
        if (!isEmpty(data)) {
            commit('SHOW_LOADER', null, {root: true});
            axios({
                url: '/v2/guest-image-delete',
                method: 'POST',
                data
            }).then((resp) => {
                dispatch('fetchGuestDocuments', data.booking_id);
                commit('HIDE_LOADER', null, {root: true});
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
            });
        }        
    },
    saveDocument: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/guest-images',
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((resp) => {
            if (resp.data.status == true) {
                const message = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${resp.data.message}`, resp.data.message)
                toastr.success(message);
                commit('HIDE_LOADER', null, {root: true});
                // dispatch('fetchGuestDocuments', data.get('booking_id'));
                // dispatch('fetchBookingDetail', data.get('booking_id'));
            } else {
                const message = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${resp.data.message}`, resp.data.message)
                toastr.error(message);
                commit('HIDE_LOADER', null, {root: true});
            }
        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                toastr.error("Image type must be 'JPG', 'PNG', 'JPEG', 'pdf', 'heif', 'hevc'");
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = window.$t(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${validation_errors?.[k2]?.[0]}`);
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_UPLOAD_DOCUMENT_ERRORS', {error_message, error_status});

            commit('HIDE_LOADER', null, {root: true});
        });
    },



    applyPayment: async ({state, commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/pay-now-uri',
            method: 'POST',
            data: {
                'transaction_init_id': data.t_id,
                'process_payment_without_3ds': data.process_payment_without_3ds,
                'cvvCardToken': data.cvvCardToken
            }
        }).then((resp) => {
            let reload_state = false;
            if (resp.data.status) {
                toastr.success(resp.data.message);
                commit('HIDE_LOADER', null, {root: true});
            } else if(resp.data.data && resp.data.data.is_3ds) {
                    toastr.info(resp.data.message);
                // commit('HIDE_LOADER', null, {root: true});
            } else if (resp.data.status === false) {
                reload_state = resp.data.status_code != 422;
                if(reload_state){
                    toastr.warning(resp.data.message, { extendedTimeOut: 10000 });
                }else{
                    toastr.error(resp.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});
                //return
            }

            if(resp.data.data) {
                if(resp.data.data.require_cvv) {
                    // This commit of data will cause cvv modal to pop-up in BookingListPage.vue file.
                    let _data = resp.data.data.dataForCvvVerification;
                    _data.meta = {
                        function: data.function,
                        t_id: data.t_id,
                        process_payment_without_3ds: data.process_payment_without_3ds,
                        booking_id: data.booking_id
                    };
                    commit('SET_CVV_VERIFICATION_DATA', _data);
                }
            }

            if(data.source && data.source =='guest_portal'){
                dispatch('getPaymentDetailTabData', data.booking_id);
            }else{
                dispatch('fetchBookingDetail', data.booking_id);
                dispatch('fetchPaymentsTabInformation', data.booking_id);
                dispatch('fetchActivityLogs', data.booking_id);
            }


        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    applyAuth: async ({state, commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: "/client/v2/pay-now-auth",
            method: 'POST',
            data: {
                'cc_auth_id': data.a_id,
                'process_auth_without_3ds': data.process_auth_without_3ds,
                'cvvCardToken': data.cvvCardToken
            }
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } 
             else if(resp.data.status == false && resp.data.status_code == 407){
                 commit('HIDE_LOADER', null, {root: true});
                toastr.warning(resp.data.message);
             }
            else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }

            if(resp.data.data) {
                if(resp.data.data.require_cvv) {
                    // This commit of data will cause cvv modal to pop-up in BookingListPage.vue file.
                    let _data = resp.data.data.dataForCvvVerification;
                    _data.meta = {
                        function: data.function,
                        cc_auth_id: data.a_id,
                        process_auth_without_3ds: data.process_auth_without_3ds,
                        booking_id: data.booking_id
                    };
                    commit('SET_CVV_VERIFICATION_DATA', _data);
                }
            }
            if(data.source && data.source =='guest_portal'){
                dispatch('getPaymentDetailTabData', data.booking_id);
            }else{
                dispatch('fetchBookingDetail', data.booking_id);
                dispatch('fetchPaymentsTabInformation', data.booking_id);
                dispatch('fetchActivityLogs', data.booking_id);
            }
        });
    },

    booking_id_action_chat: async ({commit}, payload) => {
        commit('BOOKIN_ID_ACTION_CHAT', payload);
    },

    canAddSyncTime: async ({commit}) => {
        axios({
            url: "/client/v2/can-sync-booking",
            method: 'POST',
        }).then((resp) => {

            if (resp.data.status) {
                commit('IS_SYNCING_BOOKING', resp.data.data.is_syncing_booking);
            } else {
                commit('IS_SYNCING_BOOKING', false);
            }

            if (resp.data.data !== undefined && resp.data.data) {
                commit('CAN_SYNC_BOOKING', resp.data.data.can_sync_booking);

                commit('ASK_FOR_IMPORT_CAN_PROCESSABLE', resp.data.data.ask_for_import_can_processable);
            }

        }).catch((err) => {
            console.log(err);
        });
    },

    isPropertySyncing: async ({commit}) => {
        axios({
            url: "/client/v2/is-property-syncing",
            method: 'POST',
        }).then((resp) => {

            if (resp.data.status) {
                commit('IS_PROPERTY_SYNCING', resp.data.data.is_property_syncing);
            } else {
                commit('IS_PROPERTY_SYNCING', false);
            }

        }).catch((err) => {
            console.log(err);
        });
    },

  //getting properties upsells , guides
  getPropertiesUpsellsAndGuides: async ({commit}) => {
        axios({
            url: '/client/v2/get-all-properties-upsells',
            method: 'POST',
        }).then((resp) => {
            
            if (resp.data.status == true && resp.data.status_code == 200){

            commit('PROPERTY_UPSELL_GUIDE', resp.data.data.properties_upsell_and_guide);
            
            }else {
                commit('PROPERTY_UPSELL_GUIDE', false);
            }
            
            }).catch((err) => { 
                console.log(err);
            });
        },

    syncBooking: async ({commit, dispatch}, payload) => {

        if (!payload.can_sync_booking) {
            swal.fire({
                title: 'No Active Properties',
                text: 'No active properties found. Please enable at least one property to import bookings.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return false;
        }

        let desc = '';
        let additional_desc = '';

        if (payload && 'ask_for_import_can_processable' in payload && payload.ask_for_import_can_processable) {
            additional_desc = `
            <label style="display: block;">
              <input type="radio" id="not_processable" name="processable" value="false" checked> <strong>Do not apply my auto payment settings to newly imported reservations.</strong>
              <p style="margin: 0;">This will <u>not</u> trigger any notification to your customers.</p>
            </label> 
            <label style="display: block;">
              <input type="radio" id="is_processable" name="processable" value="true"> <strong>Apply my auto payment settings to newly imported reservations.</strong>
              <p style="margin: 0;">A notification will be sent to the customer to add a card if the customer's credit card is not available with the booking.</p>
            </label> 
            `;
        }
        if(!additional_desc){
            desc = `<p>A notification will be sent to your customer(s) to add a card if the customer’s credit card is not available with the booking.</p>`;
        }
        swal.fire({            
            html: `
            <div class="view-edit-body">                            
                <h3 class="view-edit-title">Import Existing Booking From ${window.pms_prefix.pms_full_name}</h3>
                <div class="view-edit-desc">          
                    <p>${additional_desc}</p>
                    <p>${desc}</p>
                </div>
            </div>
            `, 
            // type: "warning",
            // icon: "warning",
            showCancelButton: !0,
            confirmButtonText: "Import",
            cancelButtonText: window.app.$t("GENERAL.CANCEL"),
            customClass: {
                container: 'rental-alert-wrap'              
            },
        }).then(function (e) {
            let post_data = null;
            if(payload && 'ask_for_import_can_processable' in payload && payload.ask_for_import_can_processable){
                post_data = {
                    is_processable: document.getElementById('is_processable').checked
                }
            }
            if (e.value) {
                commit('SHOW_LOADER', null, {root: true});
                axios({
                    url: "/client/v2/save-booking-sync-time",
                    method: 'POST',
                    data: post_data
                }).then((resp) => {
                    if (resp.data.status) {
                        dispatch('general/canAddSyncTime', payload, {root: true});
                        // commit('IS_SYNCING_BOOKING', resp.data.data.in_queue);
                        // toastr.success(resp.data.message);
                        // document.querySelector('#sync-booking-modal-dismiss').click(); //
                        swal.mixin({
                            //title: resp.data.message,
                            html: `
                            <div class="view-edit-body">                        
                                <div class="view-edit-desc">          
                                    <p>${resp.data.message}</p>                                    
                                </div>
                            </div>
                            `,        
                            text: resp.data.message,
                            showConfirmButton: true,
                            backdrop: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            customClass: {
                                container: "rental-alert-wrap",
                            },
                        }).fire();
                    } else {
                        toastr.error(resp.data.message);
                    }
                    commit('HIDE_LOADER', null, {root: true});
                }).catch((err) => {
                    console.log(err);
                    commit('HIDE_LOADER', null, {root: true});
                });
            }
        });
    },
    
    syncPropertyBooking: async ({commit}, payload) => {
        if (!payload.can_sync_booking) {
            swal.fire({
                title: 'Attention!',
                text: 'Currently, this property is disabled. In order to proceed with syncing bookings, kindly enable this property. Thank you!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return false;
        }

        let desc = '';
        let additional_desc = '';

        if (payload && 'ask_for_import_can_processable' in payload && payload.ask_for_import_can_processable == 0) {
            additional_desc = `
            <label style="display: block;">
              <input type="radio" id="not_processable" name="processable" value="false" checked> <strong>Do not apply my auto payment settings to newly imported reservations.</strong>
              <p style="margin: 0;">This will <u>not</u> trigger any notification to your customers.</p>
            </label> 
            <label style="display: block;">
              <input type="radio" id="is_processable" name="processable" value="true"> <strong>Apply my auto payment settings to newly imported reservations.</strong>
              <p style="margin: 0;">A notification will be sent to the customer to add a card if the customer's credit card is not available with the booking.</p>
            </label> 
            `;
        }
        if(!additional_desc){
            desc = `<p>A notification will be sent to your customer(s) to add a card if the customer’s credit card is not available with the booking.</p>`;
        }

        swal.fire({            
            html: `
            <div class="view-edit-body">                            
                <h3 class="view-edit-title">Import Existing Booking From ${window.pms_prefix.pms_full_name}</h3>
                <div class="view-edit-desc">          
                    <p>${additional_desc}</p>
                    <p>${desc}</p>                    
                </div>
            </div>
            `,
            // type: "warning",
            icon: "warning",
            showCancelButton: !0,
            confirmButtonText: "Import",
            cancelButtonText: window.app.$t("GENERAL.CANCEL"),
            customClass: {
                container: "rental-alert-wrap",
            },
        }).then(function (e) {
            let post_data = null;
            post_data = {property_info_id: payload.property_info_id}
            if (payload && 'ask_for_import_can_processable' in payload && payload.ask_for_import_can_processable == 0) {
                post_data = {
                    ...post_data,
                    is_processable: document.getElementById('is_processable').checked
                };
            }

            if (e.value == true) {
                commit('SHOW_LOADER', null, {root: true});
                axios({
                    url: "/client/v2/import-property-booking",
                    method: 'POST',
                    data: post_data
                }).then((resp) => {
                    if (resp.data.status) {
                        toastr.success(resp.data.message);
                        if (resp.data.data && resp.data.data.in_queue) {
                            if (document.getElementById(`syncing-${payload.index}`)) {
                                document.getElementById(`syncing-${payload.index}`).classList.remove('d-none');
                                setTimeout(() => {
                                    document.getElementById(`syncing-${payload.index}`).classList.add('d-none');
                                }, 10000)
                            }
                            if (document.getElementById(`import-booking-${payload.index}`)) {
                                document.getElementById(`import-booking-${payload.index}`).classList.add('d-none');
                                setTimeout(() => {
                                    document.getElementById(`import-booking-${payload.index}`).classList.remove('d-none');
                                }, 10000)
                            }
                        }
                        // document.querySelector('#sync-booking-modal-dismiss').click(); //
                        swal.mixin({
                            //title: resp.data.message,
                            html: `
                            <div class="view-edit-body">                        
                                <div class="view-edit-desc">          
                                    <p>${resp.data.message}</p>                                   
                                </div>
                            </div>
                            `,                           
                            showConfirmButton: true,
                            backdrop: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            customClass: {
                                container: "rental-alert-wrap",
                            },
                        }).fire();
                    } else {
                        toastr.error(resp.data.message);
                    }
                    commit('HIDE_LOADER', null, {root: true});
                }).catch((err) => {
                    console.log(err);
                    commit('HIDE_LOADER', null, {root: true});
                });
            }
        });
    },

    saveSyncTime: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/save-booking-sync-time",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status) {
                commit('CAN_SYNC_BOOKING', false);
                toastr.success(resp.data.message);
                document.querySelector('#sync-booking-modal-dismiss').click(); //
                swal.mixin({
                    //title: resp.data.message,
                    text: resp.data.message,
                    showConfirmButton: true,
                    backdrop: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).fire();
            } else {
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    verifyUpsellPurchase: ({commit, dispatch}, data)=>{

        commit('SHOW_LOADER', null, {root: true});
        let self = this;
        axios({
            url: '/verify_upsell_3ds_purchase',
            method: 'POST',
            data
        }).then((resp) => {
            commit('HIDE_LOADER', null, {root: true});
            if(resp.status) {
                if(resp.data.status) {
                    // toastr.success(resp.data.message);
                    commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "success", message: resp.data.message } });
                    if(resp.data.data.further_processing_required){
                        commit('THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED', null);

                        if(window.parent.location.href.includes('/payment-confirmation-upsell/') && resp.data.data.redirect_url){
                            commit('UPDATE_THREE_DS_ERROR', 'Payment was successfully charged.');
                            window.location.href = resp.data.data.redirect_url;
                        }
                    }else{

                        if(window.parent.location.href.includes('/payment-confirmation-upsell/') && resp.data.data.redirect_url){
                            commit('UPDATE_THREE_DS_ERROR', 'Payment was successfully charged.');

                            if(window === window.parent ) { //If not 3DS modal, Upsell email 3ds link redirection
                                window.location.href = resp.data.data.redirect_url;
                                return;
                            }

                            window.location.href = resp.data.data.redirect_url;
                        }

                        commit('THREE_DS_VERIFICATION_COMPLETED');
                    }

                    
                        console.log('Would reload parent window');
                        if (window.parent.location.href.includes('/pre-checkin-v2/pre-checkin-step-5/')) {
                            if(document.getElementById('close_3ds_modal_new')) {
                                document.getElementById('close_3ds_modal_new').click();
                            }
                            if(document.getElementById('close_3ds_modal')) {
                                document.getElementById('close_3ds_modal').click();
                            }
                        } else {
                            setTimeout(function (){
                                window.parent.location.reload();
                            },3000);
                        }

                } else {

                    if(window.parent.location.href.includes('/payment-confirmation-upsell/') && resp.data.data && resp.data.data.redirect_url){
                        setTimeout(function (){
                            window.location.href = resp.data.data.redirect_url;
                        },2000);
                    }
                    commit('UPDATE_THREE_DS_ERROR', resp.data.message);
                    if (resp.data.message){
                        // toastr.error(resp.data.message);
                        commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: resp.data.message } });
                    } else {
                        // toastr.error("Communication error from PaymentGateway. Please try again later.");
                        commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: "Communication error from PaymentGateway. Please try again later." } });
                    }
                    setTimeout(function (){
                        window.parent.location.reload()
                    },3000);
                }
            } else {
                // toastr.error("Communication error from PaymentGateway. Please try again later.");
                commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: "Communication error from PaymentGateway. Please try again later." } });
                setTimeout(function (){
                    window.parent.location.reload()
                },3000);
            }
            
        }).catch((err) => {
            console.log(err);
            // toastr.error('Something went wrong. Try again.');
            commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: "Something went wrong. Try again." } });
            commit('HIDE_LOADER', null, {root: true});
            setTimeout(function (){
                window.parent.location.reload()
            },3000);
        });
    },

    verifyReservationAndAuthPayment: ({commit, dispatch}, data, inIframe = true)=>{

        commit('SHOW_LOADER', null, {root: true});
        let self = this;
        return new Promise((resolve, reject) => {
            axios({
                url: '/verify-reservation-payment-and-auth',
                method: 'POST',
                data
            }).then((resp) => {
                commit('HIDE_LOADER', null, {root: true});
                if (resp.status) {
                    if (resp.data.status) {
                        // toastr.success(resp.data.message);
                        commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "success", message: resp.data.message } });
                        commit('UPDATE_THREE_DS_ERROR', resp.data.message);

                        /*
                        let payload = {};
                        if(resp.data.data && resp.data.data.authenticationInformation) {
                            payload.authenticationInformation = resp.data.data.authenticationInformation;
                            payload.booking_id = data.booking_id;
                        } else {
                            payload = data;
                        }
                        commit('THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED', payload);
                         */
                        commit('THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED', data);
                        
                        /* Forcefully close the 3ds modal on success */
                        if(resp.data.data && resp.data.data.length === 0 && resp.data.additional === null) {
                            commit('CLOSE_3DS_MODAL_NEW', {type: 'close_3ds_modal'});
                            bus.emit('close_3ds_modal', {msg: 'Reservation_Auth_Payment_Success'});
                        }
                        // setTimeout(() => {
                        //     window.parent.location.reload();
                        // }, 3000);
                    } else if(resp.data.status_code == 404) { //State still in action_require on PG
                        commit('THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED', data);
                        // toastr.error("Communication error from PaymentGateway. Please try again later.");
                        commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: "Communication error from PaymentGateway. Please try again later." } });
                        if(inIframe){
                            setTimeout(() => {
                                window.parent.location.reload();
                            }, 3000);
                        }

                    }else {
                        commit('UPDATE_THREE_DS_ERROR', resp.data.message);
                        // toastr.error(resp.data.message);
                        commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: resp.data.message } });
                        setTimeout(() => {
                            bus.emit('close_3ds_modal',{msg:'UPDATE_THREE_DS_ERROR'});
                            if(document.getElementById('close_3ds_modal_new')) {
                                document.getElementById('close_3ds_modal_new').click();
                            }
                            if (resp.data.status_code === 422) {
                                commit('CLOSE_3DS_MODAL_NEW', {type: '3ds_modal_close',});
                            }
                        }, 3000);
                    }
                } else {
                    // toastr.error("Something went wrong, Error in communication. Try after sometime.");
                    commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: "Something went wrong, Error in communication. Try after sometime." } });
                    setTimeout(() => {
                        bus.emit('close_3ds_modal',{msg:'Something went wrong, Error in communication. Try after sometime.'});
                        if(document.getElementById('close_3ds_modal_new')) {
                            document.getElementById('close_3ds_modal_new').click();
                        }
                    }, 3000);
                }
                
                return resolve(resp);
            }).catch((err) => {
                console.log(err);
                // toastr.error('Something went wrong. Try again.');
                commit('CLOSE_3DS_MODAL_NEW', { checkout_3ds_modal_message: { status: "error", message: "Something went wrong. Try again" } });
                setTimeout(() => {
                    bus.emit('close_3ds_modal',{msg:'Something went wrong. Try again.'});
                    if(document.getElementById('close_3ds_modal_new')) {
                        document.getElementById('close_3ds_modal_new').click();
                    }
                }, 3000);
            });
        });
    },
    logAddCardFEAttempt: ({commit}, data) => {
        axios({
            url: '/log-add-card-fe-attempt',
            method: 'POST',
            data: data
        }).then((resp) => {
            console.log('Log data successfully sent');
        }).catch((err) => {
            console.error('Error sending log data:', err);
        });
    },

    onSessionChargeAuth: ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        //return the promise so that await can work at synchronized call
        return new Promise((resolve, reject) => {
            let all_error_messages = window.app.$t("GENERAL.GUEST.ATTEMPT_CHARGE_AUTH.ERRORS");
            axios({
                url: '/v2/attempt-charge-auth/',
                method: 'POST',
                data
            }).then((resp) => {

                let message = typeof all_error_messages[resp.data.message] != 'undefined'
                || all_error_messages[resp.data.message] != null
                    ? all_error_messages[resp.data.message]
                    : resp.data.message;

                if(resp.data.status == true && resp.data.status_code == 200){

                    // if (resp.data.message !='')
                    //     toastr.success(message);

                    let source = data.source;

                    if(source == 'pre_checkin'){

                        commit('STEP_COMPLETED', resp.data.data);
                        bus.emit('reload_pg_terminal', true);
                        commit('HIDE_LOADER', null, {root: true});

                    } else if(source == 'guest_portal'){

                        dispatch('general/fetchGuestPortalData', data.booking_info_id, {root: true});
                        dispatch('general/fetchAddOnServices', {id: data.booking_info_id, source, current_step: "step_4"}, {root: true});
                        dispatch('general/getPaymentDetailTabData', data.booking_info_id, {root: true});
                        commit('HIDE_3DS_MODAL_BOX');
                        commit('HIDE_LOADER', null, {root: true});

                    } else if(source == 'client_side'){

                        commit('HIDE_3DS_MODAL_BOX');
                        document.getElementById('guest_credit_card_modal_close').click();
                        commit('HIDE_LOADER', null, {root: true});
                    }
                    if (Array.isArray(resp.data.data?.all_responses) && resp.data.data.all_responses.length > 0) {
                        for (let i = 0; i < resp.data.data.all_responses.length; i++) {
                            const item = resp.data.data.all_responses[i];
                            const message = typeof item.message === 'string' ? item.message : 'Unknown error';
                            if (item.status) {
                                toastr.success(message);
                            } else if (item.status_code === 407) {
                                toastr.warning(message);
                            } else {
                                toastr.error(message);
                            }
                        }
                    }
                }  else if(resp.data.status == false) {

                   if(resp.data.status_code == 406) {
                        toastr.error("Error! Please try again after one minute.");
                        setTimeout(() => {
                            top.location.reload(); // Reload the page
                        }, 1000);
                        // dispatch('general/onSessionChargeAuth', data, {root: true}); //re initiate on session charge process
                   }

                    bus.emit('reload_pg_terminal', true);

                    if (resp.data.data != null && resp.data.data.status == false && resp.data.data.status_code == 407
                        && resp.data.data.require_3ds == true) {

                        commit('HIDE_LOADER', null, {root: true});
                        commit('SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url);

                    } else {
                        commit('HIDE_LOADER', null, {root: true});
                    }

                    if(data.source == 'client_side'){
                        commit('HIDE_3DS_MODAL_BOX');
                        // document.getElementById('guest_credit_card_modal_close').click();
                        commit('HIDE_LOADER', null, {root: true});

                        if (resp.data.data.all_responses !== undefined && resp.data.data.all_responses.length){
                            resp.data.data.all_responses.forEach(item => {
                                if (item.status_code === 407) {
                                    toastr.warning(typeof all_error_messages[item.message] != 'undefined'
                                    || all_error_messages[item.message] != null
                                        ? all_error_messages[item.message]
                                        : item.message);
                                } else {
                                    toastr.error(typeof all_error_messages[item.message] != 'undefined'
                                    || all_error_messages[item.message] != null
                                        ? all_error_messages[item.message]
                                        : item.message);
                                }
                            });
                        }
                    } else if(data.source == 'pre_checkin' && resp.data.status_code === 407) {
                        toastr.warning(message);
                    } else if(data.source == 'guest_portal' && resp.data.status_code === 407) {
                        toastr.warning(message);
                    } else {
                        toastr.error(message);
                    }
                }

                if(resp.data.data) {
                    if(resp.data.data.require_cvv) {
                        // This commit of data will cause cvv modal to pop-up in BookingListPage.vue file.
                        let _data = resp.data.data.dataForCvvVerification;
                        
                        _data.meta = {
                            function: '_applyPayment',
                            t_id: data.t_id,
                            process_payment_without_3ds: 0,
                            booking_id: data.booking_info_id
                        };
                        // If the source is 'pre_checkin', emit an event to show the CVV verification modal
                        // specifically for the pre-checkin credit card step.
                        // Otherwise, set the CVV verification data to show the modal on the booking listing page or guest portal.
                        if(data.source == 'pre_checkin'){
                            bus.emit('show_cvv_verification_modal', _data);
                        }else{
                            commit('SET_CVV_VERIFICATION_DATA', _data);
                        }

                    }
                }
                
                return resolve(resp);
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
                return reject(err);
            });
        });

    },

    getTabIdempotency: async function ({commit, dispatch}){
        try {
            const key = 'IdempotencyKey';
            const ttl = 10 * 60 * 1000; // 10 minutes in milliseconds

            // Function to set the item with expiry
            function setItemWithExpiry(key, value, ttl) {
                const now = new Date();

                const item = {
                    value: value,
                    expiry: now.getTime() + ttl,
                };
                sessionStorage.setItem(key, JSON.stringify(item));
            }

            // Function to get the item with expiry check
            function getItemWithExpiry(key) {
                const itemStr = sessionStorage.getItem(key);

                // If the item doesn't exist, return null
                if (!itemStr) {
                    return null;
                }

                const item = JSON.parse(itemStr);
                const now = new Date();

                // Compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    sessionStorage.removeItem(key);
                    return null;
                }
                return item.value;
            }

            // Retrieve the tab ID and check if it's still valid
            let IdempotencyId = getItemWithExpiry(key);

            if (!IdempotencyId) {
                // If there's no valid tab ID, generate a new one
                IdempotencyId =  Math.random().toString(36).substring(2);
                setItemWithExpiry(key, IdempotencyId, ttl);
            }

            return IdempotencyId;

            } catch (error) {
                console.log(error);
            }

        return null;
    },

    getPGTerminalData: async function ({commit, dispatch}, data) {

        data.idempotencyKey = await dispatch('getTabIdempotency');
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        //commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/api/get-pg-terminal-data',
            method: 'POST',
            data
        }).then((resp) => {
            //commit('HIDE_LOADER', null, {root: true});

            const allowedSourcesToShowError = ['by_ca_checkout_iframe', 'by_user_account', 'pre_checkin', 'guest_portal'];

            if(resp.data.status === false &&  allowedSourcesToShowError.includes(data.source)) {
                toastr.error(resp.data.message);
            }else if(resp.data.data && resp.data.data.report_to_guest){
                toastr.error(resp.data.data.message);
            }

            commit('HIDE_LOADER', null, {root: true});
            const payload = { ...resp.data, pgTerminal: { ...resp.data.pgTerminal, caller: data.caller || '' } };
            commit('SET_PG_TERMINAL_DATA', payload);
            commit('SET_PG_TERMINAL_ADDITIONAL_FIELDS_ERRORS', []);
        }).catch((err) => {
            //commit('HIDE_LOADER', null, {root: true});
            console.log(err);
        });
    },

    resetPGTerminalData: async function ({commit, dispatch}) {
        commit('UNSET_PG_TERMINAL_DATA' );
    },

    validateTerminalAdditionalFields: async function ({state, commit}) {
        const validationResults = {}
        const validationSkipMapping = {}
        
        if(!_.isEmpty(state.pg_terminal.card_terminal_additional_fields)){
            Object.values(state.pg_terminal.card_terminal_additional_fields).forEach((field) => {

                const validators = [requiredValidator]

                if (field.name === 'email') {
                    validators.push(emailValidator)
                }

                if (field.name === 'phone') {
                    validators.push(phoneValidator)
                }
                if (field.name === 'document') {

                    const countryField = Object.values(
                        state.pg_terminal.card_terminal_additional_fields
                    ).find((field) => field.name === "country");

                    const selectedCountry = countryField ? countryField.value : null; // Fetch country value

                    validators.push((value) => documentValidator(value, selectedCountry));
                }

                validationResults[field.name] = validators.every(validator => validator(field.value))
            })
        }

        if(state?.pg_terminal?.category === 'pci') {
            validationSkipMapping['email'] = ['phone']
            validationSkipMapping['phone'] = ['email']
        }

        Object.entries(validationSkipMapping)?.map(([fieldKey, dependedOn], index)=> {
            if(validationResults[fieldKey] === false && _.isEmpty(state.pg_terminal.card_terminal_additional_fields[index].value)) {
                validationResults[fieldKey] = dependedOn.every(dependedKey => validationResults[dependedKey])
            }
        })
        const output = Object.values(validationResults).map(el=>!el)

        commit('SET_PG_TERMINAL_ADDITIONAL_FIELDS_ERRORS', output)
        return !output?.some((el) => el);
    },

    clientAvailableBookingSources: async function ({commit, dispatch}, data) {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-all-available-booking-sources',
            method: 'GET',
            data
        }).then((resp) => {
            commit('SET_ALL_BOOKING_SOURCES', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    clientAllCountries: async function ({commit}) {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/get-supported-nationalities',
            method: 'GET'
        })
            .then(async (resp) => {
                commit('SET_ALL_COUNTRIES', resp.data.data.options);
                commit('HIDE_LOADER', null, {root: true});
            })
            .catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
            });
    },

    getAllBookingTypes: async function ({commit, dispatch}) {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-all-booking-types',
            method: 'GET'
        }).then((resp) => {
            commit('SET_ALL_BOOKING_TYPES', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    addBookingSource: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        const url = data?.id ? `/client/v2/add-booking-source/${data.id}` : '/client/v2/add-booking-source';
        return new Promise((resolve, reject) => {
            axios({
                url,
                method: 'POST',
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {

                commit('HIDE_LOADER', null, {root: true});

                if (response.data.status) {
                    commit('REFRESH_BOOKING_SOURCES', true);
                    document.getElementById('close-booking-source-modal').click();
                    toastr.success(response.data.message);
                    return resolve(true);
                } else {
                    toastr.error(response.data.message);
                    return reject();
                }

            }).catch((err) => {

                let errors = err.response;
                let error_message = {};
                let error_status = {};
                if (errors.status == 422) {
                    if (errors.data) {
                        for (let k1 in errors.data) {
                            if (typeof errors.data[k1] == "object") {
                                let validation_errors = errors.data[k1];
                                for (let k2 in validation_errors) {
                                    toastr.error(validation_errors[k2][0]);
                                }
                            }
                        }
                    }
                }
                
                commit('HIDE_LOADER', null, {root: true});
                return reject();
            });

        });
    },

    deleteBookingSource: async function ({commit, dispatch}, booking_source_id) {

        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
            axios({
                url: '/client/v2/delete-booking-source/'+booking_source_id,
                method: 'DELETE'
            }).then((response) => {
                
                commit('HIDE_LOADER', null, {root: true});

                if (response.data.status) {
                    commit('REFRESH_BOOKING_SOURCES', true);
                    toastr.success(response.data.message);
                    return resolve(true);
                } else {
                    toastr.error(response.data.message);
                    return reject();
                }
            }).catch((errors) => {
                toastr.error(errors.data.message);
                commit('HIDE_LOADER', null, {root: true});
                return reject();
            });
        });
    },

     // Generating Unique token
    generateUniqueToken : async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: "/client/v2/generateuniquetoken",
            method: 'POST',
        })
            .then((resp) => {
                commit('CREATE_UNIQUE_TOKEN', resp.data.data);
                commit('HIDE_LOADER', null, {root: true});

            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
            });
    },

    getAllBookingCards: async function ({commit, dispatch}, data) {

        if (data.booking_info_id == 0) return ;

        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-booking-cards',
            method: 'POST',
            data
        }).then((resp) => {
            commit('SET_CARDS_DATA', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    toggleSaveCardOnly: async function ({
        commit,
        dispatch
    }, data) {
        commit('TOGGLE_SAVE_CARD', data);
    },

    saveDragPosition: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {

            axios({
                url: '/client/v2/save-draggable-elements-position',
                method: 'POST',
                data: data
            }).then((resp) => {
                commit('HIDE_LOADER', null, {root : true});
                resolve(true);
            }).catch((err)=>{
                commit('HIDE_LOADER', null, {root : true});
                reject(false);
            });

        });

        
    },

    updateCustomQuestionPosition: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {

            axios({
                url: '/client/v2/update-custom-question-position',
                method: 'POST',
                data: data
            }).then((resp) => {
                commit('HIDE_LOADER', null, {root : true});
                resolve(true);
            }).catch((err)=>{
                commit('HIDE_LOADER', null, {root : true});
                reject(false);
            });

        });


    },

    isSmsPlanSubscribed: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {

            axios({
                url: '/client/v2/sms-plan-subscribed-status',
                method: 'GET',
            }).then((resp) => {
                commit('SET_IS_SMS_PLAN_SUBSCRIBED', resp.data.data);
                commit('HIDE_LOADER', null, {root : true});
                resolve(true);
            }).catch((err)=>{
                commit('HIDE_LOADER', null, {root : true});
                reject(false);
            });
        });
    },

    customerPortal() {
        axios({
            url: '/client/v2/customer-portal',
            method: 'GET',
            dataType: 'JSON',
            headers: {
                'application': 'application/json',
                'content-type': 'application/json'
            },
            data: {'_token': myToken.csrfToken}
        })
            .then(function (response) {
                if(response.data.status_code == 200) {
                    window.open(
                        response.data.data,
                        '_blank'
                    );
                } else {
                    toastr.error(response.data.message);
                }
            })
            .catch(function (error) {
                toastr.error('Something went wrong. Contact support');
            });
    },

    setGuestPortalActiveTab: async ({commit}, data) => {

        commit('GUEST_PORTAL_ACTIVE_TAB', data);

        if (data === 'upsell-tab' && document.getElementById('collapseThree')) {
            document.getElementById('collapseThree').className = 'collapse m_tabs_7_3';
        } else if(data !== 'upsell-tab' && document.getElementById('collapseSeven')) {
            document.getElementById('collapseSeven').className = 'collapse upsell_purchased_tab';
        }

    },

    verifyBooking : async ({commit}, booking_id) => {

        await axios({url: '/client/v2/verify-booking/' + booking_id, method: 'GET'})
            .then((resp) => {
            }).catch((err) => {
                console.log(err);
            });
    },
    fetchCardDetails: async ({commit}) => {
        let self = this;
        await axios.get('/client/v2/client-payment-method-details')
            .then(response => {
                if (response.data.status_code == 200) {
                    commit('SET_IS_CARD_AVAILABLE', !!response.data.data.is_card_available);
                    commit('SET_PAYMENT_DETAILS', response.data.data);
                }
                commit('HIDE_LOADER', null, {root: true});
            }).catch((error) => {
                commit('HIDE_LOADER', null, {root: true});
                console.error(error);
            });
    },
    validateNewCard : async ({commit}, data) => {
        let self = this;
        if(!data.payment_intent_id){
            console.error('PaymentIntent is not provided')
            return;
        }

        commit('SHOW_LOADER', null, {root: true});
        axios.post('/client/v2/validate-billing-card', { 'payment_intent_id' : data.payment_intent_id})
            .then((response) => {
                if (response.data.status_code === 200) {
                    commit('SET_IS_CARD_AVAILABLE', response.data.data.is_card_available);
                    toastr.success(response.data.message);
                }else if(response.data.status_code === 422 && response.data.data.link){
                    toastr.warning(response.data.message)
                    // commit('SET_THREE_DS_PAYMENT_INTENT', response.data.data.payment_intent_id);
                } else {
                    toastr.error(response.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});

            }).catch((error) => {
            commit('HIDE_LOADER', null, {root: true});
            console.error(error);
        });
    },

    invoiceChargedStatus: async ({commit}, data) => {
        let self = this;
        if (!data.invoice_id) {
            console.error('invoice_id is not provided')
            return;
        }
        commit('SHOW_LOADER', null, {root: true});
        axios.post('/client/v2/invoice-charged-status', {'invoice_id': data.invoice_id, 'invoice_type' : data.invoice_type})
            .then((response) => {
                commit('SET_THREE_DS_LINK', '');
                if (response.data.status_code === 200) {
                    toastr.success(response.data.message);
                } else if (response.data.status_code === 422 && response.data.data.link) {
                    commit('SET_THREE_DS_LINK', response.data.data.link);
                    toastr.error(response.data.message)
                } else {
                    toastr.error(response.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});

            }).catch((error) => {
            commit('HIDE_LOADER', null, {root: true});
            console.error(error);
        });
    },



    // setDeviceFingerPrintingData: async ({commit}, data) => {
    //     commit('SET_DEVICE_FINGERPRINTING_DATA', data);
    // },


};

export default general_root_actions;
