let state = {

    login: {
        email: '',
        password: '',
        remember: true,
        recaptchaToken: '',
        client_ip: '',
        error_status: {
            email: false,
            password: false,
            recaptchaToken: false
        },

        error_message: {
            email: '',
            password: '',
            recaptchaToken: ''
        }
    },

    register: {
        name: '',
        companyname: '',
        phone: '',
        email: '',
        code: '',
        current_pms: '',
        recaptchaToken: '',
        password_confirmation: '',
        password: '',
        agree: '',
        client_ip: '',
        timezone: null, //default timezone of current Browser
        country: null, //browser country
        number_of_properties: '',
        rewardful_referral_id: '',
        error_status: {
            name: false,
            companyname: false,
            phone: false,
            email: false,
            code: false,
            current_pms: false,
            recaptchaToken: false,
            password_confirmation: false,
            password: false,
            agree: false,
            number_of_properties: false,
        },

        error_message: {
            name: '',
            companyname: '',
            phone: '',
            email: '',
            code: '',
            current_pms: '',
            recaptchaToken: '',
            password_confirmation: '',
            password: '',
            agree: '',
            number_of_properties: '',
        }

    },

    password_reset: {
        email: '',
        error_status: {
            email: false,
        },

        error_message: {
            email: '',
        }
    },

    client_ip_info: {
        ip: null,
        city: null,
        region: null,
        country: null,
        loc: null,
        org: null,
        postal: null,
        timezone: null,
        readme: null
    }
};

export default state;