export const init_state = () => {

    return {
        title: '',
        internal_name: '',
        meta: {
            description: '',
            note: '',
            from_time: '00:00',
            from_am_pm: 'am',
            to_time: '00:00',
            to_am_pm: 'am'
        },
        price: {
            is_tier: 'standard',
            per: "guest",
            tier_plans: [
                {night_stay_from: 1, night_stay_to: 5, value_type: 'percentage', value: 0},
                {night_stay_from: 6, night_stay_to: "infinite", value_type: 'percentage', value: 0}
            ],

            flat_plan: {period: 'onetime', value_type: 'flat', value: 0},
            additional_fees: []
        },
        availability: {
            availability_type: 'always',
            custom_date: {date_from: null, date_to: null, repeat_every_year: false}
        },

        attached_booking_sources: [],

        notify_guest: [],
        required: false,
        is_client_approval_required: 0,
        status: true,

        selected_properties: []
    }
};

const filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: [],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'id',
    },
    constraints: [],
    search: {
        searchInColumn: ['id', 'internal_name', 'title'],
        searchStr: ''
    },
    upsell_type: 'all',
    property: 'all',
    upsell: 'all',
    tag: 'all',
    dateOne: '',
    dateTwo: '',
    dateOneCheckout: '',
    dateTwoCheckout: '',
    purchaseDateOne: '',
    purchaseDateTwo: '',
    filter_count: 0,
}; //Datatable filters Object End

const advance_options = {
    booking_payment_types: true
};

let state = {
    upsell_types: [],
    form_data: init_state(),
    initial_state: init_state(),
    paginationResponse: {}, // List Records With Pagination Meta & Links
    filters: filters, // ALL Regarding Filters for datatable.
    advance_options: advance_options, // ALL Regarding Advance options show/hide
    upsell_config: {},
};


export default state;
