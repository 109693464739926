import {init_state} from './state';
let mutations = {
    SET_UPSELL_ADVANCE_OPTION_STATUS(state, data) {
        return state.upsell.advance_options.booking_payment_types = data.data.booking_payment_types;
    },
    GET_UPSELL_TYPES(state, data) {
        return state.upsell.upsell_types = data.data;
    },
    SET_UPSELL_TEMPLATES_LIST(state, data) {
        state.upsell.templates = data.templates;
        state.upsell.library_images = data.upsell_images;
        return state;
    },
    LOAD_UPSELL_FORM_DATA(state, data) {
        state.upsell.form_data = {...state.upsell.form_data, ...data};
        state.upsell.form_data.upsellImagesFiles = Array(state.upsell.form_data.upsell_images.length);
        return state;
    },
    RESET_UPSELL_FORM_DATA(state) {
        return state.upsell.form_data = {...state.form_data, ...init_state()};
    },

    SET_UPSELL_LIST(state, data) {
        state.upsell.paginationResponse = data;
        state.upsell.firstCallDone = true;

        //update intercom data
        updateIntercomData('upsell_listing_page_loaded');

        return state;
    },

    UPSELL_LIST_STATUS_UPDATE(state, data) {
        state.upsell.paginationResponse.data[data.index].status.value = data.status;

        //update intercom data
        updateIntercomData('upsell_listing_page_loaded');

        return state;
    },
    SET_UPSELL_CONFIG(state, data) {
        state.upsell.upsell_config = data;
        return state;
    },

    SHOW_ADD_UPSELL_ERRORS(state, {error_message, error_status}){
        console.log(error_message);

        state.upsell.form_data.error_message = init_state().error_message;
        state.upsell.form_data.error_status = init_state().error_status;
        state.upsell.form_data.error_message = error_message;
        state.upsell.form_data.error_status = { ...state.upsell.form_data.error_status, ...error_status };

        return state;
    },

    PUSH_UPSELL_IMAGE(state, {
        image,
        file
    }) {
        state.upsell.form_data.upsell_images.push(image);
        file && (state.upsell.form_data.upsellImagesFiles.push(file));
        return state;
    },

    SHOW_UPSELL_IMAGE_ERRORS(state, payload){
        state.upsell.upsell_image_errors = payload;
        return state;
    }
};

export default mutations;
