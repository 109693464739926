let base_url = '/client/v2';

let actions = {

    fetchBillingInformation:async ({commit,dispatch}, transaction_limit=10)=>{

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: base_url+'/billing-details/'+transaction_limit,
            method: 'GET'
        }).then((resp) => {
            commit('SET_BILLING_INFORMATION', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    updateUserBillingPlanType: async ({commit, dispatch}, billing_plan) => {

        if (!billing_plan.status) {
            return false;
        }

        commit('SHOW_LOADER', null, {root: true});
        await axios.post('/client/v2/update-user-billing-plan-type', {
            'billing_plan_type': billing_plan.billing_plan_type,
            'rental_details': billing_plan.rentalDetails ?? {},
            'card_details': billing_plan.cardDetails ?? {},
            'threeDS_invoice_link': billing_plan.threeDS_invoice_link ?? '',
            'payment_intent_id': billing_plan.payment_intent_id ?? '',
            'invoice_id': billing_plan.invoice_id ?? '',
            'pi_client_secret': billing_plan.pi_client_secret ?? '',
        }).then((response) => {
            commit('SET_THREE_DS_PAYMENT_INTENT', '');
            commit('SET_THREE_DS_LINK', '' ); //reset link before setting response link
            commit('SET_THREE_DS_INVOICE_ID', '' ); //reset link before setting response link
            commit('SET_THREE_DS_PI_CLIENT_SECRET', '');


            if (response.data.status_code === 200) {
                toastr.success(response.data.message);
                //update Intercom data
                updateIntercomData('subscription_plan',billing_plan.billing_plan_type);
                //Close model
                // document.getElementById('rentalModal').style.display = "none";
                const el = document.getElementById('closeBillingRentalModal');
                el && el.click();

                if(window.location.hash && window.location.hash.match('rentalModal')){
                    window.location.href = "/client/v2/properties";

                }else if (window.location.pathname == '/client/v2/billing') {
                    dispatch('fetchBillingInformation');
                }
            } else if(response.data.status_code === 422){
                let toastrTimeOut = 5000;

                if(response.data.data.link &&response.data.data.link.includes('https://invoice.stripe.com/')){
                    //Close model
                    // document.getElementById('rentalModal').style.display = "none";
                    const el = document.getElementById('closeBillingRentalModal');
                    el && el.click();

                    setTimeout(() => {
                        // Open the URL in a new tab
                        window.open(response.data.data.link, '_blank');
                    }, 3000); // Delay in milliseconds (3 seconds)

                    return;
                }

                if(!response.data.data.link){
                    toastrTimeOut = 8000;
                    const el = document.getElementById('closeBillingRentalModal');
                    el && el.click();
                }

                commit('SET_THREE_DS_LINK', response.data.data.link );

                //Set payment intent to Handle new card auth functionality
                if(response.data.data.invoice_id){
                    commit('SET_THREE_DS_INVOICE_ID', response.data.data.invoice_id);
                }
                //Set payment intent to Handle new card auth functionality
                if(response.data.data.payment_intent_id){
                    commit('SET_THREE_DS_PAYMENT_INTENT', response.data.data.payment_intent_id);
                }
                if(response.data.data.pi_client_secret){
                    commit('SET_THREE_DS_PI_CLIENT_SECRET', response.data.data.pi_client_secret);
                }
                toastr.warning(response.data.message,'',{timeOut: toastrTimeOut});
            }else {
                toastr.error(response.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});

        }).catch((error) => {
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(error.response.data.message);
            console.error(error);
        });

    },

    handlePlanAndCardSave: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios.post('/client/v2/add-billing-card', {
            'card_details': data.card_details ?? {},
            'plan_type' : data.plan_type ?? ''
        }).then((response) => {
            if (response.data.status_code === 200) {
                toastr.success(response.data.message);

                //After adding the card, set it to null to prevent unintended actions.
                commit('SET_THREE_DS_LINK', null);
                commit('SET_THREE_DS_PAYMENT_INTENT', null);
            } else if (response.data.status_code === 422) {
                toastr.warning(response.data.message)
                commit('SET_THREE_DS_LINK', response.data.data.link);
                commit('SET_THREE_DS_PAYMENT_INTENT', response.data.data.payment_intent_id);

                if(response.data.data.pi_client_secret){
                    commit('SET_THREE_DS_PI_CLIENT_SECRET', response.data.data.pi_client_secret);
                }

            } else {
                toastr.error(response.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});

        }).catch((error) => {
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(error.response.data.message);
            console.error(error);
        });
    },
    checkCustomCard: async ({commit, dispatch}, card_details) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios.post('/add-custom-card', {
            'payment_method_id': card_details.payment_method_id ?? {},
            'payment_intent_id': card_details.payment_intent_id ?? {},
        }).then((response) => {
            if (response.data.status_code === 200) {
                toastr.success(response.data.message);

            } else if (response.data.status_code === 422) {
                toastr.warning(response.data.message)
                commit('SET_THREE_DS_LINK', response.data.data.link);
                commit('SET_THREE_DS_PAYMENT_INTENT', response.data.data.payment_intent_id);

            } else {
                toastr.error(response.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});

        }).catch((error) => {
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(error.response.data.message);
            console.error(error);
        });
    },
    getSmartLockSubscriptionPlan: async ({commit, dispatch}) => {
        const self = this;
        await axios.get('/client/v2/get-all-smart-lock-plans').then(response => {
            commit('SET_SMART_LOCK_PLANS',  response?.data?.data ?? []);
            commit('SET_IS_SMART_LOCK_PLAN_SUBSCRIBED', response.data?.additional.is_smart_lock_plan_subscribed ?? false);
            commit('SET_IS_CARD_AVAILABLE', response.data?.additional.is_card_available);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((error) => {
            commit('HIDE_LOADER', null, {root: true});
            console.error(error);
        });
    },
};


export default actions;