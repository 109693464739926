import logger from '../../helpers/logger'
 
let actions = {
    showAutoLoader({
        commit
    }, {
        caller
    }) {
        // Dispatch the 'SHOW_AUTO_LOADER' action to show the loader
        commit('SHOW_AUTO_LOADER', {
            caller: caller
        }, {
            root: true
        });
    
        // Log that the loader has been shown
        logger.log('Finished showing Loader');
    },
    
    hideAutoLoader({
        commit
    }, {
        caller
    }) {
        // Dispatch the 'HIDE_AUTO_LOADER' action to hide the loader
        commit('HIDE_AUTO_LOADER', {
            caller: caller
        }, {
            root: true
        });
    
        // Log that the loader has been hidden
        logger.log('Finished hiding Loader');
    },
    

    makeBookingIdReactiveForAdditionalCharge(booking_id, is_payment_gateway_found, is_credit_card_available) {

        if (!is_payment_gateway_found) {
            swal.fire({
                title: "No payment gateway added!",
                type: "warning",
                html: '<p style="font-size: 0.95rem;">Click <a href="/client/v2/payment-gateway-connect">here</a> to add payment gateway.</p>',
                // showCancelButton: !0,
                confirmButtonText: "OK"
            });
            return;
        }
        if (!is_credit_card_available) {

            swal.fire({
                title: "Payment method not found.",
                type: "warning",
                html: '<p style="font-size: 0.95rem;">Please attach a Credit Card</p>',
                // showCancelButton: !0,
                confirmButtonText: "OK"
            });
            return;
        }

        document.getElementById('trigger_additional_charge').click();
        this.$store.dispatch('additionalChargeActiveId', booking_id);

    },
    updateBookingLanguage: function ({commit}, data) {
        // detect user's browser language
       // let lang = navigator.languages[0] || navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage;
        if (typeof data.lang !== 'undefined') {
            // update booking language with axios request
            axios.patch(`/v2/bookings/${data.booking_id}/update-language`, {
                language_code: data.lang
            }).then(response => {
                // console.log(response.data);
            }).catch(error => {
                // console.log(error);
            });
        }
    },
};


export default actions;