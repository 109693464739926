import {init_state} from './state';
let mutations = {

    SET_PAYMENT_REQUEST_LIST(state, data) {
        state.paymentRequests.paginationResponse = data;
        return state;
    },

    SHOW_ADD_PAYMENT_REQUEST_ERRORS(state, {error_message, error_status}){
        state.paymentRequests.form_data.error_message = init_state().error_message;
        state.paymentRequests.form_data.error_status = init_state().error_status;
        state.paymentRequests.form_data.error_message = {...state.paymentRequests.form_data.error_message, ...error_message };
        state.paymentRequests.form_data.error_status = { ...state.paymentRequests.form_data.error_status, ...error_status };

        return state;
    },

    SET_CREATED_PAYMENT_REQUEST_DATA(state, data){
        state.paymentRequests.created_payment_link.payment_request_id = data.payment_request_id;
        state.paymentRequests.created_payment_link.payment_request_request_id = data.payment_request_request_id;
        state.paymentRequests.created_payment_link.payment_request_link = data.payment_request_link;
        state.paymentRequests.created_payment_link.payment_request_schedule_datetime = data.payment_request_schedule_datetime;
        state.paymentRequests.created_payment_link.payment_request_customer_email = data.payment_request_customer_email;
        state.paymentRequests.created_payment_link.payment_request_customer_id = data.payment_request_customer_id;
        return state;
    },

    GET_CURRENCIES(state, data) {
        return state.paymentRequests.supported_currencies = data.data;
    },
    
    SET_IF_CHARGEBACK_SUPPORTED(state, data) {
        state.paymentRequests.if_chargeback_supported = data.data.is_chargeback_protection_supported;
        state.paymentRequests.is_pre_authorization_supported = data.data.is_pre_authorization_supported;
        return state.paymentRequests;
    },

    RESET_PAYMENT_REQUEST_FORM_DATA(state) {
        return state.paymentRequests.form_data = {...state.form_data, ...init_state()};
    },

    LOAD_PAYMENT_REQUEST_FORM_DATA(state, data) {
        state.paymentRequests.form_data = {...state.paymentRequests.form_data, ...data};
        return state;
    },

    PAYMENT_REQUEST_ACTIVE_LINK(state, payload) {
        state.paymentRequests.payment_request_active_link = payload;
        return state;
    },

};
export default mutations;