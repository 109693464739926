
import actions from './actions';
import mutations from './mutations';
import state from "./state";
import general from './general/index';
//import little_hotelier from './little_hotelier/index';

export default {
    namespaced: true,
    modules: {
        general,
        // little_hotelier
    },
    state,
    actions,
    mutations,
};
