let actions = {
    clientLoadDefaultEmails: async ({commit}, {type_id, recipient_id}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.get('/client/v2/client-get-default-emails/'+type_id+'/'+recipient_id).then((resp) => {
            let emailData = resp.data.data;
            
            // If temp_vars in emailData is an Object then converting it to an Array
            emailData.forEach(e => {
                // Check if 'temp_vars' exists in the current element
                if ('temp_vars' in e) {
                    // Check if 'temp_vars' is not an array and is an object
                    if (!Array.isArray(e['temp_vars']) && typeof e['temp_vars'] === 'object') {
                        // Convert 'temp_vars' from an object to an array of its values
                        e['temp_vars'] = Object.values(e['temp_vars']);
                    }
                }
            });
            
            commit('SET_DEFAULT_EMAILS_DATA', emailData);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    clientLoadDefaultEmailsTypes: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.get('/client/v2/client-get-email-types/').then((resp) => {
            commit('SET_EMAIL_TYPES', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    clientUpdateDefaultEmail:({commit},data) => {
        commit('SHOW_LOADER', null, {root: true});
        return new Promise((resolve, reject) => {
            axios.post('/client/v2/client-update-default-email',data).then((resp) => {
                if(resp.data.status){
                    toastr.success(resp.data.message);
                }else {
                    console.log(resp.data.data);
                }
                commit('HIDE_LOADER', null, {root: true});
               }).catch((err) => {
                   commit('HIDE_LOADER', null, {root: true});
                   if (err.response.status == 422) {
                       resolve(err.response.data.errors);
                   }else{
                       console.log(err)
                   }
               });
         });
    },
    revertToDefaultEmail:({commit},data) => {
        commit('SHOW_LOADER', null, {root: true});
        return new Promise((resolve, reject) => {
            axios.post('/client/v2/revert-to-default-email',data).then((resp) => {
                resolve(resp.data);
                toastr.success('Default Email Content Applied.');
                commit('HIDE_LOADER', null, {root: true});
               }).catch((err) => {
                   commit('HIDE_LOADER', null, {root: true});
                   if (err.response.status == 422) {
                       resolve(err.response.data.errors);
                   }else{
                      console.log(err)
                   }
               });
         });
    },


};

export default actions;
