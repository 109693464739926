import Papa from 'papaparse';

let actions = {
    fetchAllUsers : async ({commit}, dates) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: "/admin/get-all-useraccounts",
            method: 'GET',
            data: {}
        })
        .then((resp) => {
            if (resp.status == 200){
                commit('ALL_USER_ACCOUNTS', resp.data.data);
            } else {
                commit('ALL_USER_ACCOUNTS', false);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    requestFileResource : async ( {commit} ,payload) => {

        if(payload  && payload.resource_exports.userAccounts.length === 0) {
            toastr.error("Please Select at least One User Account");
            return;
        }
        if(payload  && payload.resource_exports.userAccounts.length > 5) {
            toastr.error("Please Select at most five User Accounts");
            return;
        }
        commit('SHOW_LOADER', null, {root: true});

        try {
            const requestCSVFileResult = await axios.post("/admin/export-to-file", {
                start_date      : '',
                end_date        : '',
                user_account_id : payload.user_account_id,
                resource_exports: payload ? payload.resource_exports: null,
            });

            commit('HIDE_LOADER', null, {root: true});

            if (requestCSVFileResult.status === 200) {
                const csvData = Papa.unparse(requestCSVFileResult.data);
                const url = "data:text/csv;charset=utf-8," + encodeURI(csvData);
                const link = document.createElement('a');
                const today = new Date();
                let date = payload.resource_exports.fileName ? `${payload.resource_exports.fileName.start_date}_to_${payload.resource_exports.fileName.end_date}`:
                            `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}_${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
                const fileName = `Export_Data_${date}.csv`;
                link.setAttribute('download', fileName);
                link.href = url;
                try{
                    document.body.appendChild(link);
                    link.click();
                    toastr.success("Downloading CSV File " +fileName);
                } catch (err) {
                    console.error(err);
                    toastr.error("Failed to Process CSV File ,Try again/Reload Page . ");
                }


            } else {
               commit('ONLINE_CHECKIN_CONVERSION_RATE', false);
                toastr.error("Failed to Process CSV File");
            }
        } catch (err) {
            console.error(err);
            commit('HIDE_LOADER', null, {root: true});
            toastr.error("Failed to Process CSV File ,Try again Later ");
        }

    },


    exportAutoPaymentRules : async ( {commit} ,payload) => {
        console.log(payload);

        if(payload  && payload.user_account_id == '') {
            toastr.error("Please Select at least One User Account");
            return;
        }
       
        commit('SHOW_LOADER', null, {root: true});

        try {
            const requestCSVFileResult = await axios.post("/admin/bs-auto-payment-rules", {
                user_account_id : payload.user_account_id,
            });

            commit('HIDE_LOADER', null, {root: true});

            if (requestCSVFileResult.status === 200 && requestCSVFileResult.data.status == false) {
                toastr.error(requestCSVFileResult.data.message);
            }
            else if (requestCSVFileResult.status === 200) {
                const csvData = Papa.unparse(requestCSVFileResult.data);
                const url = "data:text/csv;charset=utf-8," + encodeURI(csvData);
                const link = document.createElement('a');
                const today = new Date();
                let date =`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}_${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
                const fileName = `Export_Data_${date}.csv`;
                link.setAttribute('download', fileName);
                link.href = url;
                try{
                    document.body.appendChild(link);
                    link.click();
                    toastr.success("Downloading CSV File " +fileName);
                } catch (err) {
                    console.error(err);
                    toastr.error("Failed to Process CSV File ,Try again/Reload Page . ");
                }

            } else {
                toastr.error("Failed to Process CSV File");
            }
        } catch (err) {
            console.error(err);
            commit('HIDE_LOADER', null, {root: true});
            toastr.error("Failed to Process CSV File ,Try again Later ");
        }

    },

     clientDataCsvToEmail: async ({ commit }, email) => {
         try {
             
             if (email == '') {
                 toastr.error("Please  provide email address");
                return;
             }
           const requestCSVFileResult = await axios.post("/admin/client-data-csv-to-email", {
                "email": email
            });

             commit('HIDE_LOADER', null, { root: true });
             

            if (requestCSVFileResult.status === 200) {

                    toastr.success("Sending  CSV to your email");
             

            } else {
               commit('ONLINE_CHECKIN_CONVERSION_RATE', false);
                toastr.error("please try  again");
            } 
        } catch (err) {
            console.error(err);
            commit('HIDE_LOADER', null, {root: true});
            toastr.error(err.response.data.email);
        }
    },

    fetchOnlineCheckinConversionRate : async ({commit} , payload) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: "/admin/get-online-checkin-conversion-rate",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('ONLINE_CHECKIN_CONVERSION_RATE', resp.data.data.check_ins);
            } else {
                commit('ONLINE_CHECKIN_CONVERSION_RATE', false);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchUpsellsSummary : async ({commit}, payload) => {

        if (payload.call_custom_method) {
            commit('SHOW_LOADER', null, {root: true});
        }

        await axios({
            url: "/admin/get-upsells-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('UPSELLS_SUMMARY', resp.data.data.upsells_summary);
            } else {
                commit('UPSELLS_SUMMARY', false);
            }

            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {upsells_summary : false});

        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {upsells_summary : false});
        });

    },

    fetchPaymentRequestSummary : async ({commit}, payload) => {

        if (payload.call_custom_method) {
            commit('SHOW_LOADER', null, {root: true});
        }

        await axios({
            url: "/admin/get-payment-requests-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('PAYMENT_REQUESTS_SUMMARY', resp.data.data.payment_request_summary);
            } else {
                commit('PAYMENT_REQUESTS_SUMMARY', false);
            }

            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {payment_requests_summary : false});

        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {payment_requests_summary : false});
        });

    },

    fetchPaymentPagesSummary : async ({commit}, payload) => {

        if (payload.call_custom_method) {
            commit('SHOW_LOADER', null, {root: true});
        }

        await axios({
            url: "/admin/get-payment-pages-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('PAYMENT_PAGES_SUMMARY', resp.data.data.payment_page_summary);
            } else {
                commit('PAYMENT_REQUESTS_SUMMARY', false);
            }

            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {payment_pages_summary : false});

        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {payment_pages_summary : false});
        });

    },
    fetchGuidebookSummary : async ({commit}, payload) => {
        await axios({
            url: "/admin/get-guidebook-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('GUIDEBOOK_SUMMARY', resp.data.data.guidebook_summary);
            } else {
                commit('GUIDEBOOK_SUMMARY', false);
            }

            commit('SECTION_LOADERS', {guidebooks_summary : false});
        }).catch((err) => {
            console.log(err);
            commit('SECTION_LOADERS', {guidebooks_summary : false});
        });

    },

    fetchTransactoinSummary : async ({commit}, payload) => {

        if (payload.call_custom_method) {
            commit('SHOW_LOADER', null, {root: true});
        }

        await axios({
            url: "/admin/get-transactoins-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('TRANSACTION_SUMMARY', resp.data.data.transaction_summary);
            } else {
                commit('TRANSACTION_SUMMARY', false);
            }

            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {transactions_summary : false});

        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            commit('SECTION_LOADERS', {transactions_summary : false});
        });

    },
    fetchPropertySummary : async ({commit}, payload) => {
        await axios({
            url: "/admin/get-properties-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('PROPERTY_SUMMARY', resp.data.data.property_summary);
            } else {
                commit('PROPERTY_SUMMARY', false);
            }

            commit('SECTION_LOADERS', {properties_summary : false});
        }).catch((err) => {
            console.log(err);
            commit('SECTION_LOADERS', {properties_summary : false});
        });

    },

    fetchGuestExperienceSummary : async ({commit}, payload) => {
        await axios({
            url: "/admin/get-guest-experience-summary",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200){
                commit('GUEST_EXPERIENCE_SUMMARY', resp.data.data.guest_experience_summary);
            } else {
                commit('GUEST_EXPERIENCE_SUMMARY', false);
            }

            commit('SECTION_LOADERS', {guest_experience_summary : false});
        }).catch((err) => {
            console.log(err);
            commit('SECTION_LOADERS', {guest_experience_summary : false});
        });

    },
    fetchAccountDetails: async ({commit} , payload ) => {
        await axios({
            url: "/admin/get-account-details",
            method: 'POST',
            data: {
                'start_date': payload.dates.start_date,
                'end_date': payload.dates.end_date,
                'user_account_id': payload.user_account_id
            }
        })
        .then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                commit('ACCOUNT_DETAIL', resp.data.data.account_details);
            } else {
                commit('ACCOUNT_DETAIL', false);
            }

            commit('SECTION_LOADERS', {accounts_details_summary : false});
        }).catch((err) => {
            console.log(err);
            commit('SECTION_LOADERS', {accounts_details_summary : false});
        });
    },

};

export default actions;
