import validationHelper from "/resources/assets/js/helpers/validation-helpers.js";
import dateHelper from "/resources/assets/js/helpers/date-helpers.js";
import stringHelper from "/resources/assets/js/helpers/string-helpers.js";
import * as allStrings from "/resources/assets/js/constants/general.js";
import disabledFields from "/resources/assets/js/helpers/disabled-fields-helpers.js";
import translationHelper from "/resources/assets/js/helpers/translation-helpers.js";
import cookieHelper from '/resources/assets/js/helpers/cookie-helpers';
import logger from "/resources/assets/js/helpers/logger.js";
import { capitalizedFirstLetter } from "./capitalizedFirstLetter";

const stringConstants = {
    ...allStrings
};

export default {
    install: (app) => {
        app.config.globalProperties.$validationHelper = validationHelper;
        app.config.globalProperties.$dateHelper = dateHelper;
        app.config.globalProperties.$stringHelper = stringHelper;
        app.config.globalProperties.$disabledFields = disabledFields;
        app.config.globalProperties.$stringConstants = (key) => stringConstants[key];
        app.config.globalProperties.$translationHelper = translationHelper;
        app.config.globalProperties.$cookieHelper = cookieHelper;
        app.config.globalProperties.$logger = logger;
        app.config.globalProperties.$capitalizedFirstLetter = capitalizedFirstLetter;
    }
};
