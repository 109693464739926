let mutations = {

    GET_PROPERTIES_WITH_ROOMS(state, data) {
        state.client_list_select_properties.properties_with_rooms=[];
        // $.each(data.data, function (key, value) {
        //     data.data[key].all_rooms= (value.all_rooms_available? [{"name":"All Rentals","code":0}].concat(value.all_rooms) : value.all_rooms);
        //     data.data[key].all_units= (value.all_rooms_available? [{"name":"All Rentals","code":0}].concat(value.all_units) : value.all_units);
        // });

        state.client_list_select_properties.properties_pagination_links = data.links;
        state.client_list_select_properties.properties_pagination_meta = data.meta;
        // state.client_list_select_properties.properties_with_rooms_updated = []; // This array holds information for rooms after updates due to pagination,
        return state.client_list_select_properties.properties_with_rooms = data.data;
    },
    SET_SELECTED_PROPERTIES(state, count){
        state.client_list_select_properties.selected_properties_count = count;
    },

    RENTAL_SELECT_BULK_ACTION:function(state, attach_status = true){
        ['properties_with_rooms','properties_with_rooms_updated'].forEach(element => {
            
            let prop_rentals = state.client_list_select_properties[element];
            for (let i in prop_rentals) {
                if (attach_status) {
                    prop_rentals[i].attach_status = true;
                    // prop_rentals[i].attached_rooms = [{"name": "All Rentals", "code": 0}]
                    // prop_rentals[i].attached_units = [{"name": "All Rentals", "code": 0}]
                } else {
                    prop_rentals[i].attach_status = false;
                    prop_rentals[i].attached_rooms = [];
                    prop_rentals[i].attached_units = [];
                }
            }
            state.client_list_select_properties[element] = prop_rentals;
        });

        // state.client_list_select_properties.all_rentals_selected = attach_status;
        return state.client_list_select_properties.properties_with_rooms
    },
    ATTACH_ALL_RENTALS:function(state, attach_status = true){
        state.client_list_select_properties.all_rentals_selected = attach_status;
    },
    DETACH_ALL_RENTALS: function (state, attach_status = true) {
        state.client_list_select_properties.all_rentals_deselected = attach_status;
    },
    RESET_ALL_RENTAL_SELECTED:function(state){
        state.client_list_select_properties.all_rentals_selected = '';
    },
    RESET_ATTACHED_PROPERTIES: function (state) {
        state.client_list_select_properties.properties_with_rooms = [];
        state.client_list_select_properties.properties_with_rooms_updated = []; // This array holds information for rooms after updates due to pagination
        state.client_list_select_properties.properties_pagination_meta = [];
        state.client_list_select_properties.properties_pagination_links = [];
        state.client_list_select_properties.all_rentals_selected = '';
        state.client_list_select_properties.all_rentals_deselected = '';
        state.client_list_select_properties.properties_pagination_meta = [];
    },
    RESET_ATTACHED_PROPERTIES_FOR_CUSTOM_QS: function (state) {
        // TODO: remove this later & use resetAttachedPropertiesData only. Had added this as Custom QS display error if properties_with_rooms ins empty
        state.client_list_select_properties.properties_with_rooms_updated = []; // This array holds information for rooms after updates due to pagination
        state.client_list_select_properties.properties_pagination_meta = [];
        state.client_list_select_properties.properties_pagination_links = [];
        state.client_list_select_properties.all_rentals_selected = '';
        state.client_list_select_properties.all_rentals_deselected = '';
        state.client_list_select_properties.properties_pagination_meta = [];
    },
};

export default mutations;
