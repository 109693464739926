/**
 * ResuablePaymentRequestAddEdit.vue
 */

let mutations = {
    
    SET_VALUES:function (state, data) {
        state.products = data.products;
        state.company_info = data.company_info;
        // $.each(data, function(key, value) {
        //     state.products.push(value);
        // });
    },
    
    CLEAR_STATE: function(state) {
        state.reusablePaymentRequest.id = 0;
        state.reusablePaymentRequest.payment_type = 'C';
        state.reusablePaymentRequest.product_id = 0;
        state.reusablePaymentRequest.status = 1;
        state.reusablePaymentRequest.selectedProduct = null;
        state.reusablePaymentRequest.terms_and_conditions = '';
        return state;
    },
    
    SET_ERRORS:function (state, data) {   
        $.each(data, function(key, value){
            state.reusablePaymentRequest.error_message[key] = value[0];
            state.reusablePaymentRequest.error_status[key] = true;
        });
    },

    SET_SAVED_PAYMENT_PAGE: function (state, payload) {
        state.reusablePaymentRequest.id = payload.page.id;
        state.reusablePaymentRequest.payment_type = payload.page.payment_type;
        state.reusablePaymentRequest.terms_and_conditions = payload.page.terms_and_conditions;
        state.reusablePaymentRequest.selectedProduct = payload.product;
        state.reusablePaymentRequest.product_id = payload.product.id;
        state.reusablePaymentRequest.questions = payload.questions;
        return state;
    },

    SET_PRODUCT_LIST: function (state, payload) {
        state.product_listing = payload;
        return state;
    },

    SET_PRODUCT_TO_SELECTED(state, payload) {
        state.reusablePaymentRequest.selectedProduct = payload;
        state.reusablePaymentRequest.product_id = payload.id;
        return state;
    },

    ADD_TO_PRODUCT_LIST(state, payload) {
        state.products = [...state.products, payload]
        return state;
    },
    
    
};

export default mutations;
