let actions = {

    getPmsList: async ({state, commit}, filters) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/get-pms-list',
            method: 'POST',
            data: {filters: filters},
        }).then((response) => {

            commit('SET_PMS_LIST', response.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {

            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    changeCustomBsValue: async ({state, commit}, pms_id) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/custom-bs-on-off',
            method: 'POST',
            data: {pms_id: pms_id},
        }).then((response) => { 

            if(response.data.status)
                toastr.success(response.data.message);
            else
                toastr.error(response.data.message);

            dispatch('admin/getPmsList');
            commit('HIDE_LOADER', null, {root: true});

        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    addBookingSource: async ({state, commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
            axios({
                url: '/admin/pms-add-booking-source',
                method: 'POST',
                data: data,
            }).then((response) => {

                commit('HIDE_LOADER', null, {root: true});

                if(response.data.status)
                {
                    toastr.success(response.data.message);
                    return resolve(response);
                }
                else
                    toastr.error(response.data.message);
                
                return reject(response);
                
            }).catch((err) => {
                commit('HIDE_LOADER', null, {root: true});
                return reject(err);
            });
        });
    },
    
    adminDeleteBookingSource: async function ({commit, dispatch}, booking_source_id) {
        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
            axios({
                url: '/admin/delete-booking-source/'+booking_source_id,
                method: 'DELETE'
            }).then((response) => {
                
                commit('HIDE_LOADER', null, {root: true});

                if (response.data.status) {
                    toastr.success(response.data.message);
                    return resolve(true);
                }
                else
                {
                    toastr.error(response.data.message);
                }

            }).catch((errors) => {
                toastr.error(errors.data.message);
                commit('HIDE_LOADER', null, {root: true});
            });
        });
    },
    
    loadBookingSourceData: async ({state, commit, dispatch}, serve_id) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/admin/get-booking-source-data',
            method: 'POST',
            data: {serve_id: serve_id},
        }).then((resp) => {
            if (resp.data.status) {
                commit('LOAD_BOOKING_SOURCE_DATA', resp.data.data);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
};

export default actions;
