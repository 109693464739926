
import booking from './client/booking/actions';
import tac from './client/termAndConditions/actions'
import paymentRequests from './client/paymentRequests/actions'
//import reuseable_payment_request from './reuseable_payment_request/actions'
import guideBook from './client/guideBooks/actions';
import guideBookTypes  from  './client/guideBooks/types/actions'
import upsell from './client/upsell/actions';
import upsellRequests from './client/upsellRequests/actions';
import upsellTypes from './client/upsell/types/actions';
import client_list_select_properties from './client/client_list_select_properties/actions'
import emails_client from "./client/emails/actions";
import sms_client from "./client/sms/actions";
import auth from './auth/actions';
import team_member from './client/teammember/actions';
import notification from './client/notifications/actions';
import chat from './client/chat/actions';
import pre_checkin from './guest/pre_checkin/actions';
import guest_portal from './guest/guest_portal/actions';
import booking_detail from './client/booking_detail/actions';
import questions from './client/questions/actions';
import actions from './general_root_actions';
import dashboard from './client/dashboard/actions';
import billing from './client/billing/actions';
import property from './client/property/actions';
import tags from './client/tags/actions';


let module_actions = {
    ...actions,
    ...team_member,
    ...tac,
    ...upsell,
    ...upsellRequests,
    ...client_list_select_properties,
    ...upsellTypes,
    ...guideBook,
    ...guideBookTypes,
    ...emails_client,
    ...sms_client,
    ...auth,
    ...notification,
    ...chat,
    ...pre_checkin,
    ...guest_portal,
    ...booking_detail,
    ...paymentRequests,
//    ...reuseable_payment_request,
    ...questions,
    ...booking,
    ...dashboard,
    ...billing,
    ...property,
    ...tags,
};

export default module_actions;