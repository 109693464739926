/**
 * This function returns an object with the class 'notranslate' if the selected language matches any of the default languages.
 *
 * @param {string|string[]} [languages=['en', 'de', 'jp']] - The language(s) to compare against the selected language code.
 *                                                           Can be a single language code string or an array of language codes.
 *                                                           If not provided, default languages will be used.
 *
 * @returns {Object} - Returns an object with 'notranslate' as key and true as value if there is a match. Otherwise, an empty object.
 */
const denyTranslation = (languages = ['en', 'de', 'jp']) => {
    const match = document.cookie.match(/(^|;)\s*googtrans=([^;]+)/);
    const selectedLanguage = match?.[2]?.split('/')[2] || '';
    const languagesToCheck = Array.isArray(languages) ? languages : [languages];
    
    return languagesToCheck.includes(selectedLanguage) ? { 'notranslate': true } : {};
};

export default {
    denyTranslation,
};