const init_state = {
    upsell_type_id: '',
    status: false,
    internal_name: '',
    title: '',
    value: 0,
    per: 1,
    period: 1,
    notify_guest: 0,
    meta: {
        description: '',
        from_time: '00:00',
        from_am_pm: 'am',
        to_time: '00:00',
        to_am_pm: 'am',
        rules: [],
        logo: null
    },
    price: {
        is_tier: false,
        per: "booking",
        tier_plans: [
            {night_stay_from: 1, night_stay_to: 5, value_type: 'percentage', value: 0},
            {night_stay_from: 6, night_stay_to: "infinite", value_type: 'percentage', value: 0}
        ],

        flat_plan: {period: 'onetime', value_type: 'flat', value: 0},
        additional_fees: []
    },
    selected_properties: [],
};

const filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: [],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'id',
    },
    constraints: [],
    search: {
        searchInColumn: ['id', 'title'],
        searchStr: ''
    },
    user_account_id: 'all',
    upsell_type: 'all',
    filter_count: 0,
}; //Datatable filters Object End


let state = {
    upsell_types: [],
    form_data: init_state,
    initial_state: init_state, // TODO
    paginationResponse: [], // List Records With Pagination Meta & Links
    templateList: {
        meta: {
            total: 0
        }
    }, // List Records With Pagination for upsell templates & Links
    filters: filters, // ALL Regarding Filters for datatable.
    upsell_config :{},
    upsell_images: [],
    tags: [],
};


export default state;
