let mutations = {

    SET_USER_PROFILE(state, payload) {
        // NOTE: Storing only what is needed.
        if (payload && 'user' in payload) {
            state.userProfile.name = payload?.user?.name ?? "";
            state.billingPlanType = payload?.company?.billing_plan_type ?? "";
            state.userPms = payload.user_pms ?? "";
            state.c_id =  payload.c_id ?? "";
            state.userPaymentGateway = payload?.user_payment_gateway;

            state.currency = '';
            let pmsCapabilityWiseOptions = {};

            if (payload.user_pms && typeof payload.user_pms === 'object') {
                state.currency = payload?.user_pms?.default_currency ?? '';

                if (payload.user_pms.pms_form && 'pms_capability_wise_options_for_FE' in payload.user_pms.pms_form) {
                    pmsCapabilityWiseOptions = JSON.parse(payload.user_pms.pms_form.pms_capability_wise_options_for_FE);
                }
            }

            if (payload.company_initial === undefined || payload.company_initial == '') {
                state.companyLogo = payload.company_logo;
            } else {
                state.companyInitial = payload.company_initial;
            }
            
            state.userAccountName = payload.company.name;
            state.userInitial = payload.user_initial;
            state.userImage = payload.user_image;
            state.canAddTeamMember = payload.can_add_team_member;

            state.pmsPermissions = {
                can_sync_properties: pmsCapabilityWiseOptions?.can_sync_properties ?? false,
                can_add_property_manually: pmsCapabilityWiseOptions?.can_add_property_manually ?? false,
                show_property_external_id: pmsCapabilityWiseOptions?.show_property_external_id ?? false,
                can_edit_property_manually: pmsCapabilityWiseOptions?.can_edit_property_manually ?? false,
                can_delete_property_manually: pmsCapabilityWiseOptions?.can_delete_property_manually ?? false,
                can_import_existing_bookings: pmsCapabilityWiseOptions?.can_import_existing_bookings ?? false,
                can_import_existing_bookings_property_wise: pmsCapabilityWiseOptions?.can_import_existing_bookings_property_wise ?? false,
            };
        }
    },
};


export default mutations;