import reusable_payment_page from './reusable_payment_page/state';
import reusablePaymentPageList from './reusable_payment_page_list/state';

export const init_state = ()=> {
    return {
        email: '',
        currency_id: null,
        amount: 0,
        due_date: '',
        expiry_date: '',
        is_3ds: false,
        terms_and_conditions: '',
        description: '',
        payment_type: 'C',
        request_id: '',
        button_type_clicked: 'send_payment_request',

        error_message: {
            email: '',
            currency_id: '',
            amount: '',
            due_date: '',
            expiry_date: '',
            is_3ds: '',
            terms_and_conditions: '',
            description: '',
        },
        error_status: {
            email: false,
            currency_id: false,
            amount: false,
            due_date: false,
            expiry_date: false,
            is_3ds: false,
            terms_and_conditions: false,
            description: false
        }
    }
};
const filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: ['questions'],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'id',
    },
    payment_status: 'all',
    payment_status_name: '',
    constraints: [],
    search: {
        searchInColumn: ['id', 'request_id', 'product_name', 'amount'],
        searchStr: ''
    },
    payment_type: 'all',
    filter_count: 0,
    // Date Filters below
    due_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
    created_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
    reusable_payment_page_id: null,
};
let states = {
    // Add general properties here.
    reusable_payment_page,
    reusablePaymentPageList,
    filters: filters,
    paginationResponse: {},
    form_data: init_state(),

    payer: {
        user_account_id: 0,
        request: {},
        transactions: [],
        questions: [],
        selected_transactions: [],
        company_info: {
            logo: '',
            name: ''
        },
        amount_number: '',
        total_amount: 0,
        error_status: {
            card_error: false,
        },
        error_message: {
            card_error: ''
        }
    },
    auth_to_transform: [],
    available_cards: [],
};
export default states;
