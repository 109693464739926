import moment from "moment";

let actions = {

    fetchUpcomingArrivals: async ({commit}) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
                        url   : '/client/v2/get-upcoming-arrivals',
                        method: 'GET'
                    }).then((resp) => {
            //console.log({'api' : resp.data});
            commit('FETCH_UPCOMING_ARRIVALS', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            //console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchTodayBookingsRecord: async ({commit}) => {

        commit('SHOW_LOADER', null, {root: true});
        await axios({
                        url   : '/client/v2/get-today-bookings-record',
                        method: 'GET',
                        headers: {
                            'X-Skip': true // To indicate skipping interceptor
                        },
                    }).then((resp) => {
            commit('TODAY_BOOKINGS_DATA', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) =>{
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchTodayUpsellStats: async ({commit}) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios(
            {
                url   : '/client/v2/today-upsell-stats',
                method: 'GET',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
            }
        )
        .then((resp) => {
            commit('TODAY_UPSELL_STATS', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        })
        .catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchTodayReservationPaymentStats: async ({commit}) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios(
            {
                url   : '/client/v2/today-reservation-payment-stats',
                method: 'GET',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
            }
        )
        .then((resp) => {
            commit('TODAY_RESERVATION_PAYMENT_STATS', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        })
        .catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchTodaySecurityDepositStats: async ({commit}) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios(
            {
                url   : '/client/v2/today-security-deposit-stats',
                method: 'GET',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
            }
        )
        .then((resp) => {
            commit('TODAY_SECURITY_DEPOSIT_STATS', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        })
        .catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchDayWiseBookingData: async ({commit}, dates) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios(
            {
                url   : `/client/v2/day-wise-bookings-data?start_date=${dates.start_date}&end_date=${dates.end_date}`,
                method: 'GET',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
            }
        )
        .then((resp) => {
            commit('DAY_WISE_BOOKINGS_DATA', resp.data.data);
            // commit('HIDE_LOADER', null, {root: true});
        })
        .catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },


    dashboarCheckinAnalytics : async ({commit} , dates) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: "/client/v2/getDashboarcheckinAnalytics",
            method: 'POST',
            headers: {
                'X-Skip': true // To indicate skipping interceptor
            },
            data: {
                'start_date': dates.start_date,
                'end_date': dates.end_date,
            }
        })
            .then((resp) => {

                if (resp.data.status == true && resp.data.status_code == 200){
                    commit('DASHBOARD_CHECKIN_ANALYTICS', resp.data.data.check_ins);
                    //update intercom data
                    updateIntercomData('update_completion_rate');
                    // commit('HIDE_LOADER', null, {root: true});

                }
                else {
                    commit('DASHBOARD_CHECKIN_ANALYTICS', false);
                }

            }).catch((err) => {
                console.log(err);
                    // commit('HIDE_LOADER', null, {root: true});
            });

    },
    
    getTransactionSummaryReport : async ({commit}, dates) => {

        // commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: "/client/v2/getTrantionReport",
            method: 'POST',
            headers: {
                'X-Skip': true // To indicate skipping interceptor
            },
            data: {
                'start_date': dates.start_date,
                'end_date': dates.end_date,
            }
        })
        .then((resp) => {

            if (resp.data.status == true && resp.data.status_code == 200){
               

            commit('TRANSACTION_SUMMARY_REPORT', resp.data.data.transactionSummaryReport);
            // commit('HIDE_LOADER', null, {root: true});
            
            }else {
                commit('TRANSACTION_SUMMARY_REPORT', false);
                
            }
            
            }).catch((err) => { 
                console.log(err);
                // commit('HIDE_LOADER', null, {root: true});
            });
        

    },

    paymentRequestSummary : async ({commit}, dates) => {

        // commit('SHOW_LOADER', null, {root: true});
            await axios({
                url: "/client/v2/paymentRequestSummary",
                method: 'POST',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
                data: {
                    'start_date': dates.start_date,
                    'end_date': dates.end_date,
                }
            })
            .then((resp) => {
                
                 if (resp.data.status == true && resp.data.status_code == 200){
                
                commit('PAYMENT_REQUESTS_SUMMARY', resp.data.data.paymentRequestsAllTransaction);
                // commit('HIDE_LOADER', null, {root: true});
                
                }
                else {
                    commit('PAYMENT_REQUESTS_SUMMARY', false);
                    
                }
                
                }).catch((err) => { 
                    console.log(err);
                    // commit('HIDE_LOADER', null, {root: true});
                });

            },
    paymentPagesSummary : async ({commit}, dates) => {

        // commit('SHOW_LOADER', null, {root: true});
            await axios({
                url: "/client/v2/paymentPagesSummary",
                method: 'POST',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
                data: {
                    'start_date': dates.start_date,
                    'end_date': dates.end_date,
                }
            })
            .then((resp) => {
                
                    if (resp.data.status == true && resp.data.status_code == 200){
                
                commit('PAYMENT_PAGES_SUMMARY', resp.data.data.payment_pages_transactions);
                // commit('HIDE_LOADER', null, {root: true});
                
                }
                else {
                    commit('PAYMENT_REQUESTS_SUMMARY', false);
                    
                }
                
                }).catch((err) => { 
                    console.log(err);
                    // commit('HIDE_LOADER', null, {root: true});
                });

                },
    upsellsSummary : async ({commit}, dates) => {
        // commit('SHOW_LOADER', null, {root: true});
            await axios({
                url: "/client/v2/upsellsSummary",
                method: 'POST',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                },
                data: {
                    'start_date': dates.start_date,
                    'end_date': dates.end_date,
                }
            })
            .then((resp) => {
                
                    if (resp.data.status == true && resp.data.status_code == 200){
                
                commit('UPSELLS_SUMMARY', resp.data.data.upsells_summary);
                // commit('HIDE_LOADER', null, {root: true});
                
                }
                else {
                    commit('UPSELLS_SUMMARY', false);
                }
                
                }).catch((err) => { 
                    console.log(err);
                    // commit('HIDE_LOADER', null, {root: true});
                });

            },
    

    getRevenueOfPayment: async ({commit}) => {

        // commit('SHOW_LOADER', null, {root: true});
        await axios({
                        url   : '/client/v2/get-revenue-of-payments',
                        method: 'GET',
                        headers: {
                            'X-Skip': true // To indicate skipping interceptor
                        },
                    }).then((resp) => {
            commit('REVENUE_GENERATED', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },
    fetchUpcomingBookingsRecord:async({commit,dispatch}) =>{

    },
    fetchBookingSources        : async ({commit, dispatch}) => {

    },
    fetchCompletionTips: async ({commit}) => {
        await axios(
            {
              url   : '/client/v2/completion-tips-stats',
              method: 'GET',
              headers: {
                'X-Skip': true // To indicate skipping interceptor
              },
            }
        )
        .then(function (response) {
            commit('COMPLETION_TIPS_DATA', response.data);
        })
        .catch(function (error) {
        });
    },
    fetchPaymentPagesData      : async ({commit, dispatch}) => {

    },
    fetchPaymentRequestData    : async ({commit, dispatch}) => {

    },
    fetchPaymentRequestsData   : async ({commit}) =>{

        // commit('SHOW_LOADER', null, {root: true});
        await axios({
                        url   : '/client/v2/get-payment-request-data-of-month',
                        method: 'GET',
                        headers: {
                            'X-Skip': true // To indicate skipping interceptor
                        },
                    }).then((resp) => {
            commit('PAYMENT_REQUEST_DATA_OF_MONTH', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) =>{
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    getIncompletePreCheckin  : async ({commit}) => {
        // commit('SHOW_LOADER', null, {root: true});
        await axios({
                        url   : '/client/v2/get-incomplete-pre-checkin-data',
                        method: 'GET',
                        headers: {
                            'X-Skip': true // To indicate skipping interceptor
                        },
                    }).then((resp) => {
            commit('INCOMPLETE_PRE_CHECK_IN', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },
    getUpsellOrderDetailsData: async ({commit}) =>{
        // commit('SHOW_LOADER', null, {root: true});
        await axios({
                        url   : '/client/v2/get-upsell_order_details_data',
                        method: 'GET',
                        headers: {
                            'X-Skip': true // To indicate skipping interceptor
                        },
                    }).then((resp) => {
            commit('DASHBOARD_UPSELL_DATA', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) =>{
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchDashboardAnalytics: async ({commit}, dates) => {

        // commit('SHOW_LOADER', null, {root: true});

        await axios({
                        url   : '/client/v2/get-dashboard-analytics/'+ dates.start_date +'/'+dates.end_date,
                        method: 'GET',
                        headers: {
                            'X-Skip': true // To indicate skipping interceptor
                        },
                        data: {
                            'start_date': dates.start_date,
                            'end_date': dates.end_date,
                        },
                    }).then((resp) => {
            commit('FETCH_DASHBOARD_ANALYTICS', resp.data);
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            //console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });

    },
};

export default actions;
