let state = {
    meta: {
        need_to_update_card: false,
        pre_checkin_incomplete: false,
        missing_guest_pre_checkin: false,
        is_invalid_card: false,
        is_payment_failed: false,
        need_passport_scan: false,
        need_credit_card_scan: false,
        user_payment_gateway_found:true,
        is_process_able:true,
        required_guest_selfie: false,
        need_selfie: false,
        need_signature: false,
        required_passport_scan: false,
        required_credit_card_scan: false,
        guest_selfie: false,
        acceptable_document_types: [],
    },
    header: {
        brand: {
            name: '',
            logo: ''
        },
        booking_source: '',
        booking_source_logo: {
            booking_source_initial: '',
            booking_source_image: '',
            is_initial: false
        },
        external_link: '',
    },
    basic_info_on: 0,
    show_guest_tab: 0,
    address_1: '',
    address_2: '',
    booking_status: '',
    pms_booking_id: '',
    confirmationCode: '',
    check_in: '',
    check_out: '',
    email: '',
    phone: '',
    arrival_time: '',
    arriving_by: '',
    other_detail:'',
    flight_no: '',
    guest_id: '',
    guest_name: '',
    booking_dates: '',
    show_map: false,
    show_questionnaire_form: false,
    map_query: '',
    is_active_basic_info_email: false,
    is_active_basic_info_phone: false,
    is_active_basic_info_guest: false,
    section_details_active: false,
    basic_info_sections : [],
    section_detail_error_messages: [],
    card_info: {
        cc_last_digit: '',
        card_type: ''
    },
    card: {
        name: '',
        number: '',
        expiry: '',
        cvv: '',
        error_status: {
            name: false,
            number: false,
            expiry: false,
            cvv: false,
        },
        error_message: {
            name: '',
            number: '',
            expiry: '',
            cvv: '',
        }
    },
    basic_info_tab_data: {
        pms_booking_id: '',
        booking_status: '',
        confirmation_code: '',
        check_in: '',
        check_out: '',
        address_1: '',
        address_2: '',
        show_map: '',
        map_query: '',
        section_details_active: '',
        basic_info_sections: {section_details:[]},
        arriving_by: '',
        other_detail: '',
        flight_no: '',
        arrival_time: '',
        standard_check_in_time:null,
    },
    basic_info: {
        email: '',
        phone: '',
        arrival_time: '',
        other_detail: '',
        flight_no: '',
        arriving_by: '',
        error_status: {
            email: false,
            phone: false,
            arrival_time: false,
            arriving_by: false,
            other_detail: false,
            flight_no: false
        },
        error_message: {
            email: '',
            phone: '',
            arrival_time: '',
            other_detail: '',
            flight_no: ''
        }
    },
    payment_detail_tab_data: {
        payments: [],
        deposits: {
            sd_auth: [],
            cc_auth:[],
        },
        card_info: []
    },
    images: [],
    is_auto_payment_or_security_supported: false,
    is_security_deposit_supported: false,
    auth_info: {
        cc_auth: false,
        security_auth: false,
        security_auth_alert: '',
        cc_auth_alert: '',
    },
    guide_books: [],
    guide_book: [],
    show_contact_info_form: false,
    show_update_card_form: false,
    upsells: [],
    _3ds_modal: false,
    is_pg_active: false,
    guest_images_status: {},
    property_status : 1,
    terms_and_condition: null,
    sign_image: null,
    sign_date: null,
    terms_link: '#',
    required_document_count: null,
    property_address_1: '',
    property_address_2: '',
};


export default state;
