let states = {

    billingAddons: [],

    billingInfo: {
        paymentDetails: "",
        billingAddress: "",
        billingEmail: "",
        taxID: "",
        stripe_customer_id: null,
    },

    is_card_available : false,

    transactionHistory: [
        // {
        //     id: 0,
        //     date: "",
        //     plan: "",
        //     amount_paid: "",
        //     amount_remaining: "",
        //     amount_due: "",
        //     status: "",
        //     paynow_url: "",
        //     invoice_pdf: "",
        //     show_paynow_button: false
        // }
    ],

    currentPlanDetails: {
        plan_name: "",
        last_updated: "",
    },

    balanceInfo: {
        balance: '$0',
        due_date: ''
    },

    planDetails: {
        sms: '0',
        upsell: '0',
        main_plan: '0'
    },

    rentalDetails: {
        billing_plan_type: '',
        billing_plan_type_name: '',
        name: '',
        amount: '',
        status: '',
        active_rentals_count: '',
    },

    threeDS_invoice_id: '',
    threeDS_invoice_link : '',
    threeDS_payment_intent_id : '',
    threeDS_pi_client_secret : '',
    identity_verification_plans:{},
    selected_identity_verification_plan:{},
    is_identity_verification_plan_subscribed: false,
    is_smart_lock_plan_subscribed: false,
    smart_lock_plans:{},
    selected_smart_lock_plan:{},
}


export default states;