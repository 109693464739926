let mutations = {

    SET_DEFAULT_SMS_DATA(state,data) {
        state.sms_client.client_sms = data;
        return state;
    },

    SET_SMS_TYPES(state, data){
        state.sms_client.sms_types = data;
        return state;
    }
    ,
};

export default mutations;
