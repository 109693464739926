import _ from 'lodash'


const
    replaceTagsWithText = (m, g) => {
        if (m == 'gp_guidebook') {
            // Direct removal of all <br> tags
            g = g?.replace(/<br\s*\/?>/gi, '');

            // Replace all Anchor tags
            let anchorTags = g.match(/<a href=(.*?)>(.*?)<\/a\s*?>/g) || [];
            if (anchorTags.length) {
                let links = anchorTags.map(e => e.match(/href="([^"]*)/)[1]) || [];
                if (links.length) {
                    anchorTags.forEach((e, i) => {
                        g = g.replace(e, `<i class="fas fa-link"></i> ${links[i]} `);
                    })
                }
            }
            // Replace all Iframes
            g = g.replace(/<iframe .*?src="(.*?)"[ \t]*[^\>]?>/g, '<i class="fas fa-video"></i> Video&nbsp;');
            // Replace all Image tags
            g = g.replace(/<img.*?src="(.*?)"[ \t]*[^\>]?>/g, '<i class="far fa-images"></i> Image&nbsp;');
            // Strip all p tags.
            g = g.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "&nbsp;");

            // The following regex is designed to target and remove empty HTML tags, specifically <h1> to <h6>, <p>, and <span> tags.
            // It considers tags as empty if they contain only whitespace or non-breaking spaces (&nbsp;).
            // This can be useful for cleaning up HTML content by removing redundant or visually unnecessary elements.
            g = g.replace(/<(h[1-6]|p|span)>\s*(&nbsp;)*\s*<\/(h[1-6]|p|span)>/gi, '');
        }
        // Removing all leading &nbsp;
        do {
            g = g.replace(/^&nbsp;/, '')
        } while (/^&nbsp;/.test(g));
        return g.trim();
    },
    toStartCase = (g) => {
        return _.startCase(g)
    },
    toLowerCase = (g) => {
        return _.toLower(g)
    },
    toTitleCase = (g) => {
        return toStartCase(toLowerCase(g));
    },
    truncateText = (text, length = 0) => {
        return text.slice(0, +length) + (text.length > +length ? '...' : '');
    };

export default {
    replaceTagsWithText: replaceTagsWithText,
    toStartCase: toStartCase,
    toLowerCase: toLowerCase,
    toTitleCase: toTitleCase,
    truncateText: truncateText,
};