let base_url = '/client/v2';
let actions = {

    /*
    * This action fetches rentals from the server.
    *
    * @param {Object} context - The Vuex action context object.
    * @param {Object} params - An object containing optional `options` and `id` parameters.
    * @param {Object} params.options - Optional query parameters for the API request. This object is used for filtering and pagination.
    * @param {number} params.id - An ID used to fetch a specific rental.
    * @returns {Promise<void>} - A promise that resolves when the rentals have been successfully fetched and committed to the store.
    */

    async  fetchRentals({commit}, {options = {}, id = 0} = {}) {
        commit('SHOW_LOADER', null, {root: true});
        try {
          // Check if the id is truthy
          if (id) {
              // Get rentals with the given id and options
              const response = await axios.post(`/client/v2/get-rentals/${id}`, {
                  ...options
              });
              // Commit the FETCH_RENTALS mutation with the data fetched from the server
              commit('FETCH_RENTALS', [...response.data.data.data]);
          } else {
              // If there's no id, commit an empty array to FETCH_RENTALS
              commit('FETCH_RENTALS', []);
          }
        } catch (error) {
            console.log(error);
        } finally {
            commit('HIDE_LOADER', null, {root: true});
        }
    }
}

export default actions;