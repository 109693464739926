// import Vue from 'vue';
import {bus} from '../../../../../bus';

let actions = {
    getTransactionsList: function ({commit}, {customer_id, request_id}) {
        commit('SHOW_LOADER', null, {root: true});
        axios.get('/get-booking-additional-payment-requests/'+request_id)
            .then(resp => {
                if(resp.data.status)
                {
                    commit('SET_TRANSACTION_LIST', resp.data);

                    if(!resp.data.data.transactions.length
                        && resp.data.data.redirect_url != '')
                    {
                        window.location = resp.data.data.redirect_url;
                    }
                }
                commit('HIDE_LOADER', null, {root: true});
            });
    },

    attemptToPay: async function ({commit, dispatch}, data) {

        commit('SHOW_LOADER', null, {root: true});
        let response = undefined;

        await axios({
            url: '/booking-additional-attempt-to-pay',
            method: 'POST',
            data,
        }).then((resp) => {

            response = resp.data;
            let verification_model_is_open = false;

            if(resp.data.status == true && resp.data.status_code == 200){
                //toastr.success(resp.data.message);
                bus.emit('reload_pg_terminal', true);
                bus.emit('make_selected_transactions_empty', true);
                commit('HIDE_LOADER', null, {root: true});
                dispatch('getTransactionsList', {customer_id: data.customer_id, request_id: data.request_id});
                //commit('general/HIDE_3DS_MODAL_BOX', null, {root: true});
      
                  // Reload the Page
                  window.location.reload();

                /*setTimeout(() => {
                    window.location.reload();
                }, 5000)*/
            }

            if(resp.data.status == false) {
                bus.emit('reload_pg_terminal', true);
                if (
                    resp.data.data != null &&
                    resp.data.data.status == false &&
                    resp.data.data.status_code == 407 &&
                    resp.data.data.require_3ds == true
                ) {
                    toastr.warning(resp.data.message);
                    commit('general/SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url, {root: true});
                    verification_model_is_open = true;
                }else{
                    toastr.error(resp.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});

                if(!verification_model_is_open) {
                    setTimeout(() => {
                        if (document.getElementById('close_3ds_modal')) {
                            document.getElementById('close_3ds_modal').click();
                        }
                        if (document.getElementById('close_3ds_modal_new')) {
                            document.getElementById('close_3ds_modal_new').click();
                        }
                    }, 3000);
                }
            }

        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_PAYMENT_ATTEMPT_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });

        return response;
    },

    savePaymentRequestCard: async ({commit, dispatch}, payload) => {
        //commit('SHOW_LOADER', null, {root: true});

        await new Promise(resolve => setTimeout(resolve, 2000));

        await axios({
            url: '/booking-additional-update-payer-card',
            method: 'POST',
            data: payload
        }).then(async (resp) => {
            commit('SHOW_PAYMENT_REQUEST_CARD_ERRORS', {error_message: {}, error_status: {}});
            if (resp.data.status_code == 200 && resp.data.status == true) {
                // card successfully added
                bus.emit('reload_pg_terminal', true);
                if(resp.data.message != null){
                    toastr.success(resp.data.message);
                }
                commit('SET_AUTH_TO_TRANSFORM', []);
                if(payload.selected_transactions.length > 0) {
                    dispatch('attemptToPay', {
                        selected_card: payload.selected_card,
                        selected_transactions: payload.selected_transactions,
                        customer_id: payload.customer_id,
                        request_id : payload.request_id,
                        card_id : resp.data.data.id,
                        source: 'payer_side'
                    });
                }else{
                    dispatch('getTransactionsList', {customer_id: payload.customer_id, request_id: payload.request_id});
                    commit('HIDE_LOADER', null, {root: true});
                }
            }else{
                commit('HIDE_LOADER', null, {root: true});
                if(resp.data.message != null){
                    toastr.error(resp.data.message);
                }
            }

        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors && errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_PAYMENT_REQUEST_CARD_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    verifyPaymentRequestCharge: ({commit, dispatch}, data)=>{

        commit('SHOW_LOADER', null, {root: true});
        let self = this;
        axios({
            url: '/booking-additional-verify-charge',
            method: 'POST',
            data
        }).then((resp) => {
            commit('HIDE_LOADER', null, {root: true});
            if(resp.status) {
                if(resp.data.status) {
                    // toastr.success(resp.data.message);
                    commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "success", message: resp.data.message } }, {root: true});

                    if(resp.data.data && resp.data.data.pg === 'cs') {
                        window.parent.parent.location.reload();
                        return;
                    }

                    //show payment success modal box
                    swal.fire({
                        title: "Payment Successful.",
                        type: "success",
                        showCancelButton: 0,
                        //confirmButtonText: "Yes, do it!"
                    });

                    window.top.postMessage({status:'success', 'message':'paymentSuccess'}, '*');
                    window.top.postMessage({status:'success', 'message':'closeIframe'}, '*');
                    if(window.self !== window.top) {
                        window.parent.location.reload();
                    }

                    /*commit('general/THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED',
                        {id: data.resource_id, paid_transaction: resp.data.data.paid, payment_method_name : (data.payment_method_name) ? data.payment_method_name : null},
                        {root:true});*/

                } else {
                    // toastr.error(resp.data.message);
                    commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "error", message: resp.data.message } }, {root: true});

                    setTimeout(() => {
                        if(document.getElementById('close_3ds_modal_new')) {
                            document.getElementById('close_3ds_modal_new').click();
                        }
                    }, 3000);
                }
                commit('general/UPDATE_THREE_DS_ERROR',resp.data.message, {root:true});
            } else {
                // toastr.error("Something went wrong, Error in communication. Try after sometime.");
                commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "error", message: "Something went wrong, Error in communication. Try after sometime." } }, {root: true});

                setTimeout(() => {
                    if(document.getElementById('close_3ds_modal_new')) {
                        document.getElementById('close_3ds_modal_new').click();
                    }
                }, 3000);
            }

        }).catch((err) => {
            console.log(err);
            // toastr.error('Something went wrong. Try again.');
            commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "error", message: "Something went wrong. Try again." } }, {root: true});
            commit('HIDE_LOADER', null, {root: true});

            setTimeout(() => {
                if(document.getElementById('close_3ds_modal_new')) {
                    document.getElementById('close_3ds_modal_new').click();
                }
            }, 3000);
        });
    },
};
export default actions;