let mutations = {
        ONLINE_CHECKIN_CONVERSION_RATE(state , payload){
                state.checkin_analytics = payload;
                return state ;
        },
        UPSELLS_SUMMARY(state , payload) {
                return state.upsell_summary = payload;
        },
        PAYMENT_REQUESTS_SUMMARY(state , payload) {

                return state.payment_request_summary = payload;
        },
        PAYMENT_PAGES_SUMMARY(state , payload) {

                return state.payment_page_summary = payload;
        },
        ALL_USER_ACCOUNTS(state , payload) {
                return state.all_user_accounts = payload;
        },
        GUIDEBOOK_SUMMARY(state , payload) {

                return state.guidebook_summary = payload;
        },
        TRANSACTION_SUMMARY(state , payload) {

                return state.transaction_summary = payload;
        },
        PROPERTY_SUMMARY(state , payload) {

                return state.property_summary = payload;
        },
        GUEST_EXPERIENCE_SUMMARY(state , payload) {

                return state.guest_experience_summary = payload;
        },
        ACCOUNT_DETAIL(state , payload) {
                return state.Account_details = payload;
        },
        SECTION_LOADERS(state , payload) {
                return state.section_loaders = {...state.section_loaders, ...payload};
        },
};

export default mutations;
