import { getTranslatedMessage } from '../../helpers'

let actions = {
    fetchGuestDetail: async ({commit}, id) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/guest-detail/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('STEP_0_DETAIL', resp.data);
            // commit('general/SET_HEADER_META', resp.data, {root: true});
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchGuestCheckinMeta : async ({commit} , data) => {
        await axios({
            url: '/v2/guest-checkin-meta',
            method: 'POST',
            data : data
        }).then((resp) => {
            if(data.current_step == 'step_0' || data.current_step == 'pre_checkin_step_0'){
                commit('general/SET_HEADER_META', resp.data, {root: true});
            }else{
                commit('UPDATE_META', resp.data);
            }

            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchStepOneData: async ({commit, state}, id) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/guest-data-step-1/' + id,
            method: 'GET'
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('STEP_1_DATA', resp.data);
            }
            // commit('UPDATE_META', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchStepTwoData: async ({commit,state, dispatch}, {id, current_step}) => {
        commit('SHOW_LOADER', null, {root: true});

        dispatch('fetchGuestCheckinMeta',{
            booking_id: id,
            current_step: current_step,
        })

        await axios({
            url: '/v2/guest-data-step-2/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('STEP_2_DATA', resp.data);
            // commit('UPDATE_META', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchStepThreeData: async ({commit,dispatch}, {id, current_step}) => {
        commit('SHOW_LOADER', null, {root: true});

        dispatch('fetchGuestCheckinMeta',{
            booking_id: id,
            current_step: current_step,
        })

        await axios({
            url: '/v2/guest-data-step-3/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('STEP_3_DATA', resp.data.data);
            // commit('UPDATE_META', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchStepFiveData: async ({commit, dispatch}, {id, current_step, is_new}) => {
        commit('SHOW_LOADER', null, {root: true});

        await dispatch('fetchGuestCheckinMeta',{
            booking_id: id,
            current_step: current_step,
        })

        await axios({
            url: '/v2/guest-data-step-5/' + id + '/' + is_new,
            method: 'GET'
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('SUMMARY_DATA', resp.data);
                // commit('UPDATE_META', resp.data);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchStepFourData: async ({commit, dispatch,state}, {id, current_step}) => {
        commit('SHOW_LOADER', null, {root: true});

        await dispatch('fetchGuestCheckinMeta',{
            booking_id: id,
            current_step: current_step,
        })

        await axios({
            url: '/v2/guest-data-step-4/' + id,
            method: 'GET'
        }).then((resp) => {
            if (resp.data.status == true) {
                // meta is necessary for questions
                resp.data.meta = state.pre_checkin.meta;
                commit('CREDIT_CARD_STEP_DATA', resp.data);
                // commit('UPDATE_META', resp.data);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchStepSevenData: async ({commit,dispatch}, {id, current_step}) => {
        commit('SHOW_LOADER', null, {root: true});

        dispatch('fetchGuestCheckinMeta',{
            booking_id: id,
            current_step: current_step,
        })

        await axios({
            url: '/v2/guest-data-step-7/' + id,
            method: 'GET'
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('STEP_7_DATA', resp.data);
            }
            // commit('UPDATE_META', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    saveVerificationImage: async ({commit}, data) => {

        commit('SHOW_LOADER', null, {root: true});


        return await axios({
            url: '/v2/guest-images',
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((resp) => {
            if (resp.data.status == true) {
                const message = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${resp.data.message}`, resp.data.message)
                toastr.success(message);
                if(resp.data.additional && resp.data.additional.requested_by === 'additional_guest_uploaded'){
                    if(resp.data.additional.is_main_guest){
                        commit('IMAGES_UPDATED', { request_response: resp.data.data, image_type: data.type});
                    }
                    commit('ADDITIONAL_GUEST_DOCS_UPDATE', resp.data.data)
                }else{
                    commit('STEP_3_DATA', resp.data.data);
                    commit('UPDATE_META', resp.data.data);
                }
            } else {
                let message = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${resp?.data?.message}`, resp?.data?.message)
                toastr.error(message);
                commit('HIDE_LOADER', null, {root: true});
                return false;
            }

            commit('HIDE_LOADER', null, {root: true});

            return resp?.data?.data ?? []

        }).catch((err) => {
            let response = err.response;
            if (response.status == 422) {
                if (response.data) {
                    let errors = response.data.errors;
                    for(let key in errors){
                        if(errors.hasOwnProperty(key)) {
                            const message = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${errors[key]}`, errors[key]);
                            toastr.error(message);
                        }
                    }
                }
            }
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveGuestData: async ({commit}, payload) => {
        let result = { success: false }

        commit('SHOW_LOADER', null, {root: true});
        //remove validation error
        commit('SHOW_STEP_1_ERRORS', {
            error_message: {
                email: '',
                phone: '',
                adults: ''
            }, error_status: {
                email: false,
                phone: false,
                adults: false
            }
        });
        commit('SHOW_STEP_2_ERRORS', {
            error_message: {
                arriving_by: '',
                plane_number: '',
                arrival_time: '',
                other: ''
            }, error_status: {
                arriving_by: false,
                plane_number: false,
                arrival_time: false,
                other: false
            }
        });
        commit('SHOW_STEP_1_SECTION_DETAIL_ERRORS', {});
        await axios({
            url: '/v2/update-guest-data',
            method: 'POST',
            data: payload
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('STEP_COMPLETED', resp.data.data);
                result.success = true;
            } else {
                if(resp.data.status_code == 422){
                    let error_message = resp.data.data;
                    commit('SHOW_STEP_1_SECTION_DETAIL_ERRORS', {error_message});
                    // console.log(resp.data.data);
                    toastr.error(resp.data.message);
                }else{
                    const message = getTranslatedMessage(`PRE_CHECKIN.ERRORS.${resp.data.message}`, resp.data.message);
                    toastr.error(message);
                }
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                const message = getTranslatedMessage(`PRE_CHECKIN.ERRORS.${validation_errors[k2][0]}`, validation_errors[k2][0]);
                                error_message[k2] = message;
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }

            if (payload.current_tab == 1) {
                commit('SHOW_STEP_1_ERRORS', {error_message, error_status});
            } else if (payload.current_tab == 2) {
                commit('SHOW_STEP_2_ERRORS', {error_message, error_status});
            }

            commit('HIDE_LOADER', null, {root: true});
        });
        return result;
    },

   

    saveCardData: async ({commit, dispatch}, payload) => {
        commit('SHOW_LOADER', null, {root: true});
        let response = null;

        await axios({
            url: '/v2/update-guest-card',
            method: 'POST',
            data: payload
        }).then(async (resp) => {
            commit('SHOW_CREDIT_CARD_STEP_ERRORS', {error_message: {}, error_status: {}});
            if (resp.data.status_code == 200 && resp.data.status == true) {
                commit('STEP_COMPLETED', resp.data.data);
                if (resp.data.message != null) {
                    const key = `GENERAL.ADD_CREDIT_CARD.ERRORS.${resp.data.message}`
                    toastr.success(getTranslatedMessage(key, resp.data.message));
                }
                commit('HIDE_LOADER', null, {root: true});
            }
            // For status code '102' further processing is required
            else if (resp?.data?.status == true && resp?.data?.status_code == 102) {
                // call on session further processing
                let result = await dispatch('general/onSessionChargeAuth', {
                    'booking_info_id': payload.booking_info_id,
                    'meta': payload.meta,
                    'source': 'pre_checkin',
                    'cvvCardToken': payload.cvvCardToken
                }, {root: true});
                //no need to hide loader in this case
                resp.data.onSessionChargeAuth = result;

                if(result.data.data.require_cvv === true) {
                    resp.data.require_cvv = true;
                    resp.data.dataForCvvVerification = result.data.data.dataForCvvVerification;
                }
            } else {                
                let key = `GENERAL.ADD_CREDIT_CARD.ERRORS.${resp.data.message}`;
                let message = getTranslatedMessage(key, resp.data.message);
                
                if (resp?.data?.status_code == 440) {
                    if (resp.data.data.require_3ds) {
                        commit('SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url);
                    }
                    toastr.warning(message);
                } else {
                    toastr.error(message);
                }
                
                commit('HIDE_LOADER', null, { root: true });
                
                if (resp?.data?.message === "PLEASE_APPROVE_PENDING_TRANSACTIONS") {
                    dispatch('fetchPaymentSummary', payload.booking_info_id);
                }
            }

            response = resp.data;

        }).catch((err) => {
            console.error('saveCardData action: Caught error:', err);
            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors?.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                const key = `GENERAL.ADD_CREDIT_CARD.ERRORS.${validation_errors[k2][0]}`;
                                error_message[k2] = getTranslatedMessage(key, validation_errors[k2][0]);
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_CREDIT_CARD_STEP_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });

        return response;
    },

    saveDigitalSignature: ({commit}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        axios({
            url: '/v2/guest-digital-images',
            method: 'POST',
            data
        }).then((resp) => {
            // commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveSelfPortrait: async ({commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        return await axios({
            url: '/v2/guest-digital-images',
            method: 'POST',
            data,
        }).then((resp) => {

            if (resp.data.status == true) {
                toastr.success(resp.data.message);

                //When data is saved from guest portal. Update this state
                if(data.requested_by && data.requested_by === 'guest_portal'){
                    commit('IMAGES_UPDATED', { request_response: resp.data.data, image_type: data.type});
                }else{
                    commit('STEP_COMPLETED', resp.data.data);
                }
            }
            commit('HIDE_LOADER', null, {root: true});
            return  resp.data;
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    completePrecheckin: async ({commit}, data) => {
        let result = { success: false }
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/pre-checkin-complete',
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('STEP_COMPLETED', resp.data.data);
                result.success = true;
                result.data = resp.data.data;
            } else {
                toastr.error(resp.data.message);

                if(resp.data.status_code == 422 && resp.data.data.redirect_uri ){

                    setTimeout(function () {
                        window.location.href = resp.data.data.redirect_uri;
                    }, 2500);

                }

                if(resp.data.status_code == 302 && resp.data.data.redirect_uri)
                {
                    setTimeout(function () {
                        window.location.href = resp.data.data.redirect_uri;
                    }, 2500);
                }
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });

        return result;
    },

    goToPreviousStep: async ({commit}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/previous-step-meta',
            method: 'POST',
            data,
        }).then((resp) => {

            commit('STEP_COMPLETED', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    goToNextStep: async ({commit}, data) => {
        let result = { success: false }

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/next-step-meta',
            method: 'POST',
            data,
        }).then((resp) => {

            commit('STEP_COMPLETED', resp.data);
            commit('HIDE_LOADER', null, {root: true});
            result.success = true;
            result.data = resp.data;
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });

        return result;
    },

    fetchAddOnServices: async ({commit, dispatch}, {id, source, current_step}) => {
        commit('SHOW_LOADER', null, {root: true});

        if(!(window.location.href.toLowerCase().includes('/guest-portal/') || window.location.href.toLowerCase().includes('/guest-portal-new/'))) {
            await dispatch('fetchGuestCheckinMeta', {
                booking_id: id,
                current_step: current_step,
            })
        }

        await axios({
            url: '/v2/get-add-on-services/' + id+'?source='+source,
            method: 'GET'
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('ADD_ON_SERVICES_DATA', resp.data);

               /* if (source === 'pre_checkin') {
                    commit('UPDATE_META', resp.data);
                }*/

                dispatch('setCartTotalAmount');

            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchPaymentDetail: async ({commit}, {id, meta}) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/checkout/' + id + '/4/json',
            method: 'POST',
            data: {id, meta}
        }).then((resp) => {
            if (resp.data.status == true) {
                commit('_3DS_PAYMENT_DETAIL', resp.data);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveAddonsCart: async ({commit, state}, data) => {
        let result = { success: false };

        let upsell_listing_ids = [];
        let invalid = false;
        let available_addon = state.pre_checkin.add_on_service.data.available;
        for(let key in available_addon){
            if(available_addon.hasOwnProperty(key)){
                let value = available_addon[key];
                if (value.in_cart) {
                    if (value.show_guest_count) {
                        if (isNaN(value.upsell_price_details.persons) || value.upsell_price_details.persons === '' || value.upsell_price_details.persons <= '0') {
                            toastr.error(window.app.$t("PRE_CHECKIN.ADD_ON_SERVICES.ERRORS.VALUE_IS_NOT_ACCEPTABLE"));
                            invalid = true;
                        }
                    }
                    upsell_listing_ids.push({
                        id: value.id,
                        persons: value.upsell_price_details.persons,
                        show_guest_count: value.show_guest_count
                    });
                }
            }
        }

        if (!invalid) {

            data.upsell_listing_ids = upsell_listing_ids;

            commit('SHOW_LOADER', null, {root: true});

            await axios({
                url: '/v2/save-addons-cart/',
                method: 'POST',
                data
            }).then((resp) => {
                let message = getTranslatedMessage(`PRE_CHECKIN.ADD_ON_SERVICES.ERRORS.${resp.data.message}`, resp.data.message)
                if (resp.data.status == true) {
                    toastr.success(message);
                    commit('STEP_COMPLETED', resp.data.data);
                    result.success = true;
                    result.data = resp.data.data;
                } else {
                    toastr.error(message);
                }
                commit('HIDE_LOADER', null, {root: true});
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
            });
        }

        return result;
    },

    modifyTotalPrice: ({commit, state, dispatch}, index) => {

        commit('MODIFY_TOTAL_PRICE', index);

        dispatch('setCartTotalAmount');
    },

    setIncartAmount: ({commit, state, dispatch}, {index, event, val}) => {

        commit('UPDATE_IN_CART_STATUS', {index, event, val});

        dispatch('setCartTotalAmount');
    },

    setCartTotalAmount: ({commit, state}) => {

        let total_in_cart_amount = 0;
        for(let key in state.pre_checkin.add_on_service.data.available){
            if(state.pre_checkin.add_on_service.data.available.hasOwnProperty(key)){
                let value = state.pre_checkin.add_on_service.data.available[key];
                if (value.in_cart || value.required) {
                    if (value.show_guest_count) {
                        total_in_cart_amount += parseFloat(
                            (value.upsell_price_details.total_price / value.upsell_price_details.total_no_of_persons) * value.upsell_price_details.persons
                        );
                    } else {
                        total_in_cart_amount += parseFloat(value.upsell_price_details.total_price);
                    }
                }
            }
        }

        commit('SET_INCART_AMOUNT', Math.abs(total_in_cart_amount.toFixed(2)));
    },

    addItemToCart: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.post('/v2/add-upsell-addon-in-cart', {
            booking_info_id: data.booking_id,
            upsell_listing_id: data.upsell_ids,
            show_guest_count: data.show_guest_count,
            persons: data.persons,
        }).then(async (resp) => {
            const message = getTranslatedMessage(`PRE_CHECKIN.ADD_ON_SERVICES.ERRORS.${resp.data.message}`, resp.data.message)

            if (resp.data.status == true) {
                toastr.success(message);
                await dispatch('fetchAddOnServices', {id: data.booking_id, source: data.source, current_step: data.current_step});
            } else {
                toastr.error(message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    updateCartItem: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.post('/v2/update-remove-upsell-addon-quantity-in-cart', {
            booking_info_id: data.booking_id,
            upsell_id: data.upsell_id,
            quantity: data.quantity
        }).then(async (resp) => {
            let message = getTranslatedMessage(`PRE_CHECKIN.ADD_ON_SERVICES.ERRORS.${resp.data.message}`, resp.data.message)
            if (resp.data.status == true) {
                toastr.success(message);
                await dispatch('fetchAddOnServices', {id: data.booking_id, source: data.source, current_step: data.current_step});
            } else {
                toastr.error(message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    changeMode: async ({commit, state}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/change-mode',
            method: 'POST',
            data
        }).then((resp) => {
            if (resp.data.status == true) {
                //If Live mode is click and custom domain is Enabled the when open link in new tab
                if (resp.data.data.is_whitelabel_solution_enabled && resp.data.additional && !data.mode_value) {
                    window.open(resp.data.additional.custom_domain_checkin_link, data.current_url, '_blank')
                } else {
                    let update_read_write_mode = true;
                    if (resp.data.data.status === 0) {
                        let route = state.pre_checkin.meta.routes.filter((route) => route.is_step_completed === false);
                        if (route.length > 0 && window.location.href != route[0].url) {
                            update_read_write_mode = false;
                            window.location.href = route[0].url;
                        }
                    }
                    if (update_read_write_mode) {
                        commit('UPDATE_READ_WRITE_MODE', resp.data.data);
                    }
                    toastr.success(resp.data.message);
                }
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    deletePreCheckinImages: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/v2/pre-checkin-delete-document-image',
            method: 'POST',
            data
        }).then((resp) => {
            if (resp.data.status == true) {
                toastr.success(resp.data.message);
                dispatch('fetchStepThreeData', {id: data.booking_id, current_step: "step_3"});
                //window.location.reload();
            } else {
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchQuestionnaireStepData: async ({commit, state, dispatch}, {id, current_step}) => {

        commit('SHOW_LOADER', null, {root: true});

        if( !window.location.href.toLowerCase().includes('/guest-portal/')) {
            dispatch('fetchGuestCheckinMeta',{
                booking_id: id,
                current_step: current_step,
            })
        }

        await axios({
            url: '/v2/get-questionnaire-step/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('QUESTIONNAIRE_STEP_DATA', resp.data.data);
            // commit('UPDATE_META', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchAdditionalGuestsStepData: async ({commit, dispatch}, {id, current_step}) => {

        commit('SHOW_LOADER', null, {root: true});

        
        if( !window.location.href.toLowerCase().includes('/guest-portal/')) {
            await dispatch('fetchGuestCheckinMeta',{
                booking_id: id,
                current_step: current_step,
            })
        }

        let url = window.location.href;
        let api = '/v2/get-additional-guests-step/' + id;

        let isGuestPortalPage = url.includes('guest-portal');

        if(isGuestPortalPage){
            api = api + '?request_form=guest-portal';
        }
        await axios({
            url:  api,
            method: 'GET'
        }).then((resp) => {

            commit('ADDITIONAL_GUESTS_STEP_DATA', resp.data.data);

            if(isGuestPortalPage){
                commit('UPDATE_GUEST_PORTAL_META', resp.data.data);
            }
            /*else{
                commit('UPDATE_META', resp.data.data);
            }*/
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },
    fetchAdditionalGuestDetails: async ({commit}, id) => {
        commit('SHOW_LOADER', null, {root: true});
        commit('ADDITIONAL_GUEST_DETAILS', {
            guest_images: [],
            acceptable_document_types: {},
            document_upload_instructions: null,
        });
        commit('ADDITIONAL_GUEST_DETAIL_ERRORS', {});

        return await axios({
            url: '/v2/get-additional-guests-details/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('ADDITIONAL_GUEST_DETAILS', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
            return resp.data.data;
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    updateAdditionalGuestDetail : async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        commit('ADDITIONAL_GUEST_DETAIL_ERRORS', {});

        try {
            const resp = await axios({
                url: '/v2/update-additional-guests-details',
                method: 'POST',
                data : data
            });

            if (resp.data.status) {
                // Dispatch the timeDelay method and use 'await' to pause the execution
                await dispatch('timeDelay', {time: 10000});

                toastr.success(resp.data.data);
                // Fetch the additional guest step data based on the current step. The step payload is determined by whether it's a new pre-checkin or not
                dispatch('fetchAdditionalGuestsStepData', {id: data.guest_details.booking_info_id, current_step: data?.isNewPreCheckin ? "pre_checkin_step_10" : "step_10"});
                if (!['guest-portal', 'pre-checkin'].includes(data.source) && !data.from_link) {
                    dispatch('fetchGuestExperience', data.guest_details.booking_info_id);
                }
            } else {
                commit('ADDITIONAL_GUEST_DETAIL_ERRORS', resp.data?.data || resp?.data?.message);
                toastr.error(resp.data.message);
                // location.reload();
            }

            commit('HIDE_LOADER', null, { root: true });
        } catch (err) {
            console.log(err);
            commit('HIDE_LOADER', null, { root: true });
        }
    },

    timeDelay({commit, dispatch}, data){
        return new Promise(resolve => setTimeout(resolve, data.time));
    },

    deleteAdditionalGuestDetail : async ({commit}, id) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/delete-additional-guests-details/'+ id,
            method: 'GET'
        }).then((resp ) => {
            if(resp.data.status){
                toastr.success(resp.data.data);
            }else{
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    cancelNewGuest : async ({commit}, data) => {
        commit('RESET_NEW_GUEST', {});
        commit('SET_NEW_GUEST_ERROR_MESSAGE', {});
    },
    createNewGuest: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url : '/v2/add-additional-guest',
            method: 'POST',
            data : data
        }).then((resp) => {

            if(resp.data.status){
                toastr.success(resp.data.message);
                commit('RESET_NEW_GUEST', {});
            }else{
                toastr.error(resp.data.message);
            }
            commit('SET_NEW_GUEST_ERROR_MESSAGE', {});
            commit('HIDE_LOADER', null, {root: true});

        }).catch((err) => {

            toastr.error(err.response.data.message);
            commit('SET_NEW_GUEST_ERROR_MESSAGE',err.response.data.errors)
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveGuestAnswers: async ({dispatch, commit}, {data, isGuestPortal}) => {
        let result;

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/save-guest-answers/',
            method: 'POST',
            data
        }).then((resp) => {
            if (resp.data.status) {
                commit('SET_ANSWERED_STATUS', true);
                if (data.questionnaire.meta.is_guest || isGuestPortal) {
                    toastr.success('Questions updated.');
                }
                            
                if (data.guest_portal_form)
                    dispatch('fetchQuestionnaireStepData', {id: data.booking_id, current_step: "step_8"});
                else
                    commit('UPDATE_META', resp.data.data);

            } else {
                commit('QUESTIONNAIRE_STEP_DATA', resp.data.data.questionnaire);
                //toastr.error(resp.data.message);
            }

            commit('HIDE_LOADER', null, {root: true});
            result = resp.data.status;
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

        return result;
    },

    saveFileUploadAnswer: async ({commit}, data) => {

        commit('SHOW_LOADER', null, {root: true});
        
        return new Promise(function(resolve, reject) {
            axios({
                url: '/v2/guest-file-upload',
                method: 'POST',
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((resp) => {
                commit('HIDE_LOADER', null, {root: true});
                
                if (resp.data.status == true) {
                    return resolve(resp.data.data);
                } else {
                    toastr.error(resp.data.message);
                }

                return reject(resp);

            }).catch((err) => {
                let errors = err.response;
                if (errors.status == 422) {
                    if (errors.data) {
                        let all_errors = errors.data.errors;
                        for(let key in all_errors){
                            toastr.error(all_errors[key]);
                        }
                    }
                }

                commit('HIDE_LOADER', null, {root: true});
                return reject(err);
            });
        });
        
    },

    setStepFourCardData: async ({commit}, id) => {
        commit('SHOW_LOADER', null, {
            root: true
        });

        await axios({
            url: '/fetch-guest-cc-by-guest/' + id,
            method: 'GET'
        }).then((resp) => {

            commit('UPDATE_CREDIT_CARD_STEP_DATA', resp.data);
            commit('HIDE_LOADER', null, {
                root: true
            });
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {
                root: true
            });
        });
    },

    doNotShowNextTime: async ({commit}, data) => {
        axios({
            url: '/v2/set-admin-mode-notifier-popup-cookie',
            method: 'POST',
            data,
        }).then((resp) => {
            if (resp.data.status) {
                console.log(resp.data);
            }
        }).catch((err) => {
            console.log(err.response.data.message);
        });
    },

    checkAdminModeNotifierPopupAvailability: async ({commit}, id) => {
        commit('HIDE_LOADER', null, {root: true});

        return new Promise(function(resolve, reject) {
            axios({
                url: '/v2/check-admin-mode-notifier-popup-availability/' + id,
                method: 'GET',
            }).then((resp) => {
                if (resp.data.status) {
                    return resolve(resp.data.data);
                }
                commit('HIDE_LOADER', null, {root: true});

            }).catch((err) => {
                toastr.error(err.response.data.message);
                commit('HIDE_LOADER', null, {root: true});
            });
        });
    },



};


export default actions;
