
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from "./state";
import payment_request from './payment_request_functionality/index';
import reusable_payment_request from './reuseable_payment_request/index';
import booking_additional_payment_request from './guest/additional_payment_request/index';

export default {
    namespaced: true,
    modules: {
        payment_request,
        reusable_payment_request,
        booking_additional_payment_request,
    },
    state,
    actions,
    mutations,
    getters
};
