let state = {
        checkin_analytics : {
                full_completed : 0,
                full_completed_percentage : 0,
                partially_completed : 0,
                partially_completed_percentage : 0,
                incomplete : 0,
                incomplete_percentage : 0,
                conversion_rate : 0,
                values: [] ,
                labels : [],

        },
        upsell_summary : {
                published_upsells : 0,
                paid_upsell_order : {
                        count : 0,
                        amount : 0
                },
                pending_upsell_request : {
                        count : 0,
                        amount : 0
                },
                total_upsells_amount : 0 ,
                total_upsells_count : 0,
                currency : '',
                redirect_route : '',
                total_revenue : 0

        },
        payment_request_summary : {
                success_payment_request : {
                        amount : 0,
                        count  : 0,
                },
                success_auth_request : {
                        amount : 0,
                        count  : 0,
                },
                currency : '',
                redirect_route : ''
        },
        payment_page_summary : {
                success_payment : {
                        amount : 0,
                        count  : 0,
                },
                success_auth : {
                        amount : 0,
                        count  : 0,
                },
                currency : '',
                redirect_route : ''
        },
        guidebook_summary : {
                published_guidebook : 0,
                unpublished_guidebook : 0,
                total_guidebook_count : 0
        },
        all_user_accounts : [],
        transaction_summary : {
                paid_transactions : {
                        count : 0,
                        amount : 0
                },
                captured_auth : {
                        count : 0,
                        amount : 0
                },
                currency : '',
                redirect_route : ''
        },
        property_summary : {
                active_properties : 0,
                inactive_properties : 0,
                active_rentals : 0,
                inactive_rentals : 0,
                total_properties : 0,
        },
        guest_experience_summary : {
                auto_notify : 0,
                collect_basic_info : 0,
                collect_arrival_info : 0,
                collect_passport_id : 0,
                collect_credit_card_scan : 0,
                collect_selfie : 0,
                offer_ad_on : 0,
                collect_e_signature : 0,
                collect_acceptance_of_terms : 0,
                display_guidebook_enabled : 0,
                allow_chat_enabled : 0,
                custom_question_enabled : 0,
                terms_and_condition_enabled : 0
        },
        Account_details : {
                total_accounts : 0,
                user_account_id : 0,
                user_accoount_name : null,
                connected_gateway : 0,
                ap_credite_card_validation_enabled : 0,
                ap_reservation_payment_enabled : 0,
                ap_security_deposit_enabled : 0,
                ap_refund_enabled : 0,
                custom_auto_payment_enabled : 0,
                per_property_gateway_enabled : 0,
                total_payment_request_created : 0,
                auth_request_created : 0,
                charge_request_created : 0,
                payment_page_created : 0,
                total_payment_page_Trans : 0,
                payment_page_auth_trans : 0,
                payment_page_charge_trans : 0,

        },

        section_loaders : {
                upsells_summary : false,
                payment_pages_summary : false,
                payment_requests_summary : false,
                guidebooks_summary : false,
                accounts_details_summary : false,
                transactions_summary : false,
                properties_summary : false,
                guest_experience_summary : false,
        },
};

export default state;
