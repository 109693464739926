let mutations = {
    SET_GUIDEBOOK_FORM_DATA: function (state, data) {
        data = data[0];
        state.guideBook.formData = {
            ...state.guideBook.formData, ...{
                serve_id: data.id,
                status: data.status,
                icon: data.icon,
                type_id: data.guide_book_type_id,
                internal_name: data.internal_name,
                text_content: data.text_content,
                priority: data.priority,
                title: data.title
            }
        };

        if(data.display_criteria != null)
        {
            state.guideBook.formData.display_criteria =  data.display_criteria;
        }
        if(data.connected_tags != null)
        {
            state.guideBook.formData.connected_tags =  data.connected_tags;
        }
        
        return state;
    },
    RESET_GUIDEBOOK_FORM_DATA: function (state) {
        state.guideBook.formData = {
            serve_id: 0,
            status: true,
            internal_name: '',
            text_content: '',
            type_id:'',
            selected_properties: [],
            icon: 'fas fa-home',
            priority: 0,
            display_criteria: {
                id_approved: 0,
                sd_authorized: 0,
                reservation_fully_paid: 0,
                days_before_checkin: 0,
                number_of_days: 0
            },
        };
        // document.body.setAttribute("style", "padding-right: 0px;");
        return state;
    },
    SET_GUIDE_BOOK_TYPES: function (state, data) {
        state.guideBook.types = data;
        return state;
    },
    SET_LIST: async function (state, data) {
        state.guideBook.paginationResponse = data;
        state.guideBook.firstCallDone = true;

        //update intercom data
        await updateIntercomData('update_guide_data');

        return state;
    },

    UPDATE_GUIDE_BOOK_STATUS: async function (state, data) {
        state.guideBook.paginationResponse.data[data.index][data.updateWhat] = data.updateWith;

        //update intercom data
        await updateIntercomData('update_guide_data');

        return state;
    },

};
export default mutations;