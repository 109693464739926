import Vuex from 'vuex';
import state from "./root_state";
import mutations from "./root_mutations";
import actions from "./root_actions";
import admin from "./general/admin/index";
import general from "./general/index";
import ba from "./ba/index";
import cax from "./cax/index";
import hapicloud from "./hapicloud/index";
import hostfully from "./hostfully/index";
import apiConnection from "./apiConnection/index";
import hostaway from "./hostaway/index";
import tokeet from "./tokeet/index";
import smx from "./smx/index";
import impala from "./impala/index";
import guesty from "./guesty/index";
import smoobu from "./smoobu/index";
import nopms from "./nopms/index";
import syncbnb from "./syncbnb/index";
import profile from "./profile/index";
import lockSystem from "./lockSystem/index";
import customDomain from "./custom_domain";



import { createStore } from 'vuex';

const store = createStore({
    state,
    mutations,
    actions,
    modules: {
        admin,
        general,                     // CA General Components and re usable  //
        ba,                         // Booking Automation Custom Components //
        smx,
        impala,
        beds24: ba,
        cax, //store register
        hapicloud, //store register
        hostfully, //store register
        apiConnection, //store register
        hostaway, //store register
        tokeet, //store register
        guesty,
        smoobu,
        nopms,
        syncbnb,
        profile,
        lockSystem,
        customDomain
    },
});

export default store;
