import reusable_payment_page from './reusable_payment_page/actions'
// import Vue from 'vue';
import {bus} from '../../../../bus';


let module_actions = {
    getPageData: function ({commit, dispatch}, page_id) {
        commit('SHOW_LOADER', null, {root: true});
        axios.get('/get-reusable-page-data/'+page_id)
            .then(resp => {
                if(resp.data.status)
                {
                    commit('SET_PAGE_DATA', resp.data);
                }
                commit('HIDE_LOADER', null, {root: true});
            });
    },

    addPaymentRequestByReusablePaymentRequest: async ({state, commit, dispatch}, data) => {

        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
            axios({
                url: '/save-reusable-payment-request/',
                method: 'POST',
                data,
            }).then((resp) => {

                commit('HIDE_LOADER', null, {root: true});
                if (resp.data.status) {
                    if(resp.data.status == true && resp.data.status_code == 200){
                        let message = (resp.data.message) ? resp.data.message : 'Payment successful';

                        bus.emit('reload_pg_terminal', true);
                        commit('HIDE_LOADER', null, {root: true});
                        commit('general/HIDE_3DS_MODAL_BOX', null, {root: true});

                        // signal to clear gateway component's information if any stored on front-end.
                        bus.emit('clear_card', {});

                        //show payment success modal box
                        swal.fire({
                            title: message,
                            type: 'success',
                            icon: 'success',
                            showCancelButton: 0,
                            showConfirmButton: false,
                            timer: 2000
                        }).then(function (e) {
                            window.location.href = resp.data.data.thankyou_page_url;
                        });
                    }
                }

                if(resp.data.status ==false && resp.data.status_code==422){
                    commit('SHOW_VALIDATION_ERRORS', resp.data.data);
                    if (data.reload_on_cancel) {bus.emit('reload_pg_terminal', true);}
                }

                if(resp.data.status ==false && resp.data.status_code==410){
                    //page has expired
                    window.location.reload();
                }

                if(!_.isEmpty(resp.data.data) && resp.data.data.status == false) {
                    bus.emit('reload_pg_terminal', true);
                    if (
                        resp.data.data != null &&
                        resp.data.data.status == false &&
                        resp.data.data.status_code == 407 &&
                        resp.data.data.require_3ds == true
                    ) {
                        toastr.warning(resp.data.message);
                        commit('general/SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url, {root: true});
                    }else{
                        toastr.error(resp.data.message);
                    }
                    commit('HIDE_LOADER', null, {root: true});
                }else{
                    if(!resp.data.status){
                        toastr.error(resp.data.message);
                        if(resp.data.data !=null && resp.data.data.require_cvv){
                            bus.emit('show_cvv_verification_modal', true);
                        }
                    }
                    commit('HIDE_LOADER', null, {root: true});
                }
                return resolve(resp);

            }).catch((err) => {
                console.log(err);
                let errors = err.response;
                let error_message = {};
                let error_status = {};
                if (errors.status == 422 || errors.status == 429) {
                    if (errors.data) {
                        for (let k1 in errors.data) {
                            if (typeof errors.data[k1] == "object") {
                                let validation_errors = errors.data[k1];
                                let count = 0;
                                for (let k2 in validation_errors) {
                                    if (count === 0) {toastr.error(validation_errors[k2][0]);}
                                    error_message[k2] = validation_errors[k2][0];
                                    error_status[k2] = true;
                                    count++;
                                }
                            }
                        }
                    }
                }
                else
                {
                    toastr.error(errors.data.message);
                }
                if (data.reload_on_cancel) {bus.emit('reload_pg_terminal', true);}
                commit('HIDE_LOADER', null, {root: true});
            });

        });
    },

    /**
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    getReusableTransactionsList: async ({state, commit}) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/client/v2/get-reusable-payment-transactions-list',
            method: 'POST',
            data: {filters: state.filters},
        }).then((resp) => {
            commit('SET_REUSE_PAYMENT_TRANSACTION_LIST', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    manuallyVoidReuseablePaymentRequest: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/manually-void-reusable-payment-request",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('getReusableTransactionsList');
                commit('HIDE_LOADER', null, {root: true});
                toastr.success(resp.data.message);
            } else {
                commit('HIDE_LOADER', null, {root: true});
                toastr.error(resp.data.message);
            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    authReleaseReusablePaymentRequest: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        axios({
            url: "/client/v2/auth-release-reusable-payment-request",
            method: 'POST',
            data: data,
        }).then((resp) => {
            if (resp.data.status == true && resp.data.status_code == 200) {
                dispatch('getReusableTransactionsList');
                toastr.success(resp.data.message);
                commit('HIDE_LOADER', null, {root: true});
            } else {
                toastr.error(resp.data.message);
                commit('HIDE_LOADER', null, {root: true});

            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
};

export default module_actions;