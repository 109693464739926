import general from "../../index";

let  actions={
    addTag:({commit},data)=>{
        // commit('SHOW_LOADER', null, {root : true});
        let url='/client/v2/';
        let rest_form=true;
        if(data.id){
            url+='update-tag/'+ data.id;
        }else{
            url+='create-tag';
        }
       return new Promise((resolve,reject)=>{
           axios.post(url, {data: data}).then((response)=>{
               if(response.data.status){
                   toastr.success(response.data.message);
                   commit('RESET_FORM_DATA');
               }else{
                   toastr.error(response.data.message);
               }
               return resolve({err:0,response:response});
           }).catch((err)=>{
               if(err.response.status == 422) {
                   resolve({err:1,response:err.response.data.errors});
               }
           });

       });
    },
    loadTags : async ({state, commit}, serve_id)=> {
        commit('SET_FORM_DATA', [{
            id:2,
            prev:"test 1",
            title:"",
        },{
            id:2,
            prev:"test 2",
            title:"",
        }]);

        return;
        commit('SHOW_LOADER', null, {root : true});
        if (serve_id > 0){
            return axios.post('/client/v2/tags/',{"serve_id":serve_id}).then((response)=>{
                commit('SET_FORM_DATA', response.data.data);
                commit('HIDE_LOADER', null, {root : true});
            }).catch((err)=>{
                console.log(err.response.data.errors);
                if(err.response.status == 422) {
                    $.each(err.response.data.errors, function(key, value){
                        toastr.error(value[0]);
                    });
                }
                commit('HIDE_LOADER', null, {root : true});
            });
        } else {
            // create a new Promise and return it
            return new Promise((resolve, reject) => {
                commit('HIDE_LOADER', null, {root : true});
                commit('RESET_FORM_DATA');
                resolve();
            });
        }
    },
    getTagsList : async ({state, commit})=> {
        commit('SHOW_LOADER', null, {root : true});
        await axios({
            url : '/client/v2/tags-list',
            method : 'POST',
            data: {filters :state.tags.filters},
        }).then((response)=>{
            commit('SET_TAGS_LIST', response.data);
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
        });
    },

    updateTag : async ({state, commit, dispatch},tag)=> {
        commit('SHOW_LOADER', null, {root : true});

        await axios({
            url : '/client/v2/update-tag/'+ tag.id,
            method : 'POST',
            data: tag ,
        }).then((response)=>{
            console.log('response',response.data);
            if(response.data.status_code === 200){
                toastr.success(response.data.message);
            }else{
                toastr.error(response.data.message);
            }
            dispatch('getTagsList'); // Get all tags list
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
        });
    },
    removeTags : async ({state, commit, dispatch},tagIds)=> {
        commit('SHOW_LOADER', null, {root : true});

        await axios({
            url : '/client/v2/delete-tags',
            method : 'POST',
            data: tagIds ,
        }).then((response)=>{
            console.log('response',response.data);
            if(response.data.status_code === 200){
                toastr.success(response.data.message);
            }else{
                toastr.error(response.data.message);
            }
            dispatch('getTagsList'); // Get all tags list
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
        });
    },
 

    resetTagsData: async ({
        state,
        commit
    }, data) => {
        commit('SHOW_LOADER', null, {
            root: true
        });
        commit('RESET_FORM_DATA');
        commit('HIDE_LOADER', null, {
            root: true
        });

    },
    getAllAvailableTags({commit}) {
        commit('SHOW_LOADER', null, {root : true});
        let self = this;
        return axios.get("/client/v2/get-all-available-tags")
            .then(function (response) {
                commit('SET_ALL_TAGS', response.data.data.all_available_tags);
                commit('HIDE_LOADER', null, {root : true});
            })
            .catch(function (err) {
                console.log(err);
                commit('HIDE_LOADER', null, {root : true});
            });
    },
    async getConnectedTags({commit}, data) {
        commit('SHOW_LOADER', null, {root : true});
        let self = this;

        if(!data.column_name){
            console.error('Error: "column_name" is required.');
            return;
        }
        if(!Array.isArray(data.list)){
            console.error('Error: "list" should be an array.');
            return ;
        }

        let status = true;
        await axios.post("/client/v2/get-connected-tags", data)
            .then(function (response) {
                if(response.data.status_code !== 200){
                    toastr.error(response.data.message)
                    status = false;
                }
                commit('SET_ALL_TAGS', response.data.data.all_available_tags);
                commit('HIDE_LOADER', null, {root : true});
            })
            .catch(function (err) {
                console.log(err);
                commit('HIDE_LOADER', null, {root : true});
                status = false;
            });

        return status;
    },
};

export default actions;
