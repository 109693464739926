import { createApp, h } from "vue";
// import { createInertiaApp, Link } from '@inertiajs/vue3'; // This works but causes issue with Redirects from Laravel
import { createInertiaApp, Link } from "@inertiajs/inertia-vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { InertiaProgress } from "@inertiajs/progress";

// Import existing components, plugins, etc.
import { createStore } from "vuex";
import { createI18n } from "vue-i18n";
// import VCalendar from "v-calendar";
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import "v-calendar/style.css";
import TextClamp from "vue3-text-clamp";
import VueApexCharts from "vue3-apexcharts";
import { VueDraggableNext } from "vue-draggable-next";
import VueCarousel from "vue-carousel";
import JsonCSV from 'vue-json-csv';
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// Import global components and directives
import { chatScroll, checkVisibility } from "./directives/index";
import CacSelect from "@cac-ui/components/atoms/Select.vue";
import ToastPlugin from "vue-toast-notification";

// Import your locale messages
import en from "./languages/en.json";
import ja from "./languages/jp.json";
import de from "./languages/de.json";
import fr from "./languages/fr.json";
import fi from "./languages/fi.json";
import da from "./languages/da.json";
import nl from "./languages/nl.json";

// Import your custom plugins
import "./bootstrap.js";
// import './root_register.js';
import "./client.js";
import "./partner.js";
// import './admin.js';
import { registerGlobalComponents } from "./global_register.js";
// import './guest.js';
import "./utils/interceptor";
import helpers from "./helpers";

// Import plugins
// import VueTelInput from 'vue-tel-input';
// import 'vue-tel-input/dist/vue-tel-input.css';
import "intl-tel-input/build/css/intlTelInput.css";
// import BlockUI from 'vue-blockui';
// import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
// import CAcustomDatepicker from './plugins/vue-ca-custom-datepicker.es';
// import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';
import { VueMaskDirective } from "v-mask";
import Multiselect from "vue-multiselect";
import VueTooltip from "v-tooltip";
import VueSignaturePad from "vue-signature-pad";
// import Popover from 'vue-js-popover';
// import vSelect from 'vue-select';
// import 'vue-select/dist/vue-select.css';
import OpenIndicator from "./plugins/v-select/OpenIndicator.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import Permissions from "./mixins/Permissions.vue";

import "vue-toast-notification/dist/theme-bootstrap.css";

const i18n = createI18n({
  legacy: false,
  locale: "en", // Default locale
  fallbackLocale: "en",
  messages: {
    en,
    ja,
    de,
    fr,
    fi,
    da,
    nl,
  },
});

// Setup Vuex store
import store from "./components/store/index";

// Check if the current page is an Inertia request
// Check if the element exists and has the data-inertia attribute
const appElement = document.getElementById("app");

if (appElement && appElement.dataset.inertia === "true") {
  // Inertia app initialization
  createInertiaApp({
    resolve: (name) =>
      resolvePageComponent(
        `./pages/${name}.vue`,
        import.meta.glob(`./pages/**/*.vue`)
      ),
    setup({ el, App, props, plugin }) {

      // Dynamically import Component SCSS only for Inertia
      import("@cac-ui/styles/scss/main.scss").then(() => {
        console.log("Styles loaded for v3");
      });

      const inertiaApp = createApp({ render: () => h(App, props) });

      inertiaApp.use(plugin);
      inertiaApp.use(store);
      inertiaApp.use(i18n);
      inertiaApp.use(setupCalendar, {})
      inertiaApp.use(VueCarousel);
      inertiaApp.use(helpers);
      inertiaApp.use(VueTooltip);
      inertiaApp.use(VueSignaturePad);
      inertiaApp.component("draggable", VueDraggableNext);
      inertiaApp.component('VCalendar', Calendar)
      inertiaApp.component('VDatePicker', DatePicker)      
      inertiaApp.component("multiselect", Multiselect);
      inertiaApp.component("vue-google-autocomplete", VueGoogleAutocomplete);
      // inertiaApp.component("cac-select", CacSelect);

      inertiaApp.config.globalProperties.$translate = (messageKey, params = []) => {
        let message = i18n.global.t(messageKey);
        if (params.length > 0) {
          params.forEach((value, index) => {
            message = message.replace("[" + index + "]", value);
          });
        }
        return message;
      };
      inertiaApp.config.globalProperties.$translate = (messageKey, params = []) => {
        let message = i18n.global.t(messageKey);
        if (params.length > 0) {
          params.forEach((value, index) => {
            message = message.replace("[" + index + "]", value);
          });
        }
        return message;
      };
      inertiaApp.config.globalProperties.$pms_prefix = window.pms_prefix;
      window.app.$t = inertiaApp.config.globalProperties.$translate;
      window.$t = inertiaApp.config.globalProperties.$translate;

      // Register global components and directives
      registerGlobalComponents(inertiaApp);
      // inertiaApp.directive('mask', VueMaskDirective);
      inertiaApp.directive("check-visibility", checkVisibility);
      inertiaApp.component("Link", Link);
      inertiaApp.mount(el);
      inertiaApp.directive("chat-scroll", chatScroll);
    },
  });

  // Optional: Progress bar for Inertia navigation
  InertiaProgress.init();
} else {
  const app = createApp({
    data() {
      return {
        // Add your global data properties here
      };
    },
    methods: {
      shortTo13Character(value) {
        if (!value) return "";
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
        if (value.length > 13 && width > 768) {
          return value.substring(0, 22) + "...";
        } else if (value.length > 13 && width < 768) {
          return value.substring(0, 5) + "...";
        } else {
          return value;
        }
      },
      shortTo8Character(value) {
        if (!value) return "";
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
        if (value.length > 13 && width > 768) {
          return value.substring(0, 8) + "...";
        } else if (value.length > 13 && width < 768) {
          return value.substring(0, 5) + "...";
        } else {
          return value;
        }
      },
      formatDate(value) {
        if (value) {
          return moment(String(value)).format("MMMM D, YYYY");
        }
      },
      formatDateBookingLog(value) {
        if (value) {
          return moment(String(value)).format("MMM DD, YYYY HH:mm");
        }
      },
      capitalizedFirstLetter(value) {
        let str = value.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
      translate(messageKey, params = []) {
        let message = this.$t(messageKey);
        if (params.length > 0) {
          params.forEach((value, index) => {
            message = message.replace("[" + index + "]", value);
          });
        }
        return message;
      },
      imageValidator(file) {
        let errorFlag = false;
        let types = ["image/jpeg", "image/jpg", "image/png"];
        let fileSizeInMb = file.size / 1000 / 1000;
        if (fileSizeInMb > 5) {
          toastr.error("Image size must be less than 5 MB.");
          errorFlag = true;
        }
        if (!types.includes(file.type)) {
          toastr.error("Image type must be 'JPG', 'PNG', 'JPEG'.");
          errorFlag = true;
        }
        return errorFlag;
      },
      propertyLogoValidator(file) {
        let errorFlag = false;
        let types = ["image/jpeg", "image/jpg", "image/png"];
        if (!types.includes(file.type)) {
          toastr.error("Image type must be 'JPG', 'PNG', 'JPEG'.");
          errorFlag = true;
        }
        let fileSizeInMb = file.size / 1000 / 1000;
        if (fileSizeInMb > 2) {
          toastr.error("Image size must be less than 2 MB.");
          return true;
        }
        return errorFlag;
      },
    },
    mounted() {
      // Set global properties
      // this.$pms_prefix = window.pms_prefix;
      // this.$_ = _; // Lodash
      // this.$asset_url = window.asset_url;
      // this.$translate = this.translate;
      // this.$image_validator = this.imageValidator;
      // this.$property_logo_validator = this.propertyLogoValidator;
    },
  });

  app.config.globalProperties.$pms_prefix = window.pms_prefix;
  app.config.globalProperties.$_ = _; // Lodash
  app.config.globalProperties.$asset_url = window.asset_url;
  app.config.globalProperties.$translate = (messageKey, params = []) => {
    let message = i18n.global.t(messageKey);
    if (params.length > 0) {
      params.forEach((value, index) => {
        message = message.replace("[" + index + "]", value);
      });
    }
    return message;
  };
  if (window?.app) {
    window.app.$t = app.config.globalProperties.$translate;
  }
  window.$t = app.config.globalProperties.$translate;
  app.config.globalProperties.$image_validator = (file) => {
    let errorFlag = false;
    let types = ["image/jpeg", "image/jpg", "image/png"];
    let fileSizeInMb = file.size / 1000 / 1000;
    if (fileSizeInMb > 5) {
      toastr.error("Image size must be less than 5 MB.");
      errorFlag = true;
    }
    if (!types.includes(file.type)) {
      toastr.error("Image type must be 'JPG', 'PNG', 'JPEG'.");
      errorFlag = true;
    }
    return errorFlag;
  };
  app.config.globalProperties.$property_logo_validator = (file) => {
    let errorFlag = false;
    let types = ["image/jpeg", "image/jpg", "image/png"];
    if (!types.includes(file.type)) {
      toastr.error("Image type must be 'JPG', 'PNG', 'JPEG'.");
      errorFlag = true;
    }
    let fileSizeInMb = file.size / 1000 / 1000;
    if (fileSizeInMb > 2) {
      toastr.error("Image size must be less than 2 MB.");
      return true;
    }
    return errorFlag;
  };

  registerGlobalComponents(app);


  app.mixin(Permissions);

  // Import one of the available themes
  //import 'vue-toast-notification/dist/theme-default.css';
  app.use(ToastPlugin);
  app.use(VueApexCharts);
  app.use(VueSignaturePad);

  // Use plugins
  app.use(store);
  app.use(i18n);
  // app.use(VCalendar);
  // Use plugin defaults (optional)
  app.use(setupCalendar, {})

  app.use(VueCarousel);
  // app.use(PortalVue);
  app.use(helpers);
  // app.use(VueTelInput);
  // app.use(BlockUI);
  // app.use(AirbnbStyleDatepicker);
  // app.use(CAcustomDatepicker);

  // Register global components
  app.component("draggable", VueDraggableNext);
  app.component("multiselect", Multiselect);
  app.component("Datepicker", Datepicker);
  app.component("v-clamp", TextClamp);
  app.component('VCalendar', Calendar)
  app.component('VDatePicker', DatePicker)
  app.component('download-csv', JsonCSV)
  // app.component('v-select', vSelect);
  app.component("vue-google-autocomplete", VueGoogleAutocomplete);
  app.component("OpenIndicator", OpenIndicator);
  // app.component("cac-select", CacSelect);

  // Register global directives
  app.directive("mask", VueMaskDirective);
  app.directive("check-visibility", checkVisibility);
  app.directive("chat-scroll", chatScroll);

  // Setup tooltip options
  app.use(VueTooltip);
  VueTooltip.options.defaultTemplate =
    '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
  VueTooltip.options.defaultArrowSelector =
    ".tooltip-vue-arrow, .tooltip-vue__arrow";
  VueTooltip.options.defaultInnerSelector =
    ".tooltip-vue-inner, .tooltip-vue__inner";

  // Allow custom element
  app.config.ignoredElements = ["zapier-full-experience"];

  // Mount the app
  app.mount("#app");

  // Common event bus
  // export const bus = app.config.globalProperties.$bus = new Vue(); // Note: Replace Vue with createApp for Vue 3
}
