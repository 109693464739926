import booking_detail from "./state";
import validationHelper from "../../../../../../../../../resources/assets/js/helpers/validation-helpers.js";

let mutations = {

    BOOKING_DETAILS(state, payload) {
        state.booking_detail.booking_details = {...state.booking_detail.booking_details, ...payload};
        // If phone number is invalid then  returning empty.
        if (!validationHelper.isPhoneNumberLengthValid(state.booking_detail.booking_details.phone)) {
            state.booking_detail.booking_details.phone = "";
        }
        return true;
    },

    SHOW_BOOKING_DETAILS_ERRORS(state, payload) {

        return state.booking_detail.booking_details = {...state.booking_detail.booking_details, ...payload};
    },
    GUEST_INFO_SECTION_DETAIL_ERRORS(state, {error_message}) {

        state.booking_detail.booking_details.section_detail_error_messages = error_message;
        return state;
    },
    PAYMENT_TAB_DATA(state, payload) {
        return state.booking_detail.payments = {...state.booking_detail.payments, ...payload};
    },

};


export default mutations;