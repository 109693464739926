let actions = {

    attemptLogin: async function ({commit, state}) {

        commit('SHOW_LOADER', null, {root: true});
        commit('RESET_ERRORS');
        await axios({
            url: 'login',
            method: 'POST',
            data: state.auth.login
        }).then((resp) => {
            
            if(resp.status == 200 && resp.data.status){
                //redirecting back to login then middleware will decide where to redirect
                window.location = resp.data.url;

                commit('HIDE_LOADER', null, {root: true});
            }else{
                commit('HIDE_LOADER', null, {root: true});
            }
        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422 || errors.status == 429) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                if (validation_errors[k2][0]) {
                                    error_message[k2] = validation_errors[k2][0];
                                    error_status[k2] = true;
                                }
                            }

                        }
                    }
                    if (Object.keys(error_message).length === 0 && errors.data.message) {
                        window.toastr.error(errors.data.message);
                    }
                }
            }
            let source_state = 'login'; //sub state name i.e state.login will be created at "SHOW_AUTH_ERRORS" mutation
            commit('SHOW_AUTH_ERRORS', {error_message, error_status, source_state});
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    attemptRegister: async function ({commit, state}) {

        commit('SHOW_LOADER', null, {root: true});

        let url = new URL(window.location);
        let data = state.auth.register;
        data.billing_plan_type = new URLSearchParams(url.search).get('billing_plan_type') ?? 'basic';

        await axios({
            url: 'register',
            method: 'POST',
            data: data
        }).then((resp) => {

            if (resp.data.registered == 1) {
                // create user on intercom
                createUserOnIntercom(resp.data.user, resp.data.user_account, resp.data.user_hash);

                setTimeout(function() {
                    window.location = resp.data.url;
                    commit('HIDE_LOADER', null, {root: true});
                }, 2000);
            }else{
                commit('HIDE_LOADER', null, {root: true});
            }
        }).catch((err) => {
            console.log(err);
            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }else if(errors.status === 429 && errors.data && errors.data.message){
                window.toastr.error(errors.data.message);
            }
            let source_state = 'register'; //sub state name i.e state.register will be created at "SHOW_AUTH_ERRORS" mutation
            commit('SHOW_AUTH_ERRORS', {error_message, error_status, source_state});
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    getIpInfo: async function({commit, state}){
        $.get("https://ipinfo.io", function(response) {
            commit('SET_CLIENT_IP_INFO', response)
        }, "jsonp");

    }
};

export default actions;