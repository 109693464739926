let states = {
    // Add specific propertie of Add-Product
    name: '',
    supported_currency_id: 0,
    amount: 0,
    description: '',
    id: 0,
    selectedCurrency: null,
    product_image: null,

    error_message: {
        name: '',
        supported_currency_id: 0,
        amount: 0,
        description: ''
    },
    
    error_status: {
        name: false,
        supported_currency_id: false,
        amount: false,
        description: false
    }
};
export default states;
