export const BookingData = ()=>{
    return {
        booking_id: 0,
        rental: '',
        channelCode: '',
        guestFirstName: '',
        guestLastName: '',
        guestEmail: '',
        bookingStatus: 1,
        guestPhone: '',
        firstNight: '',
        lastNight: '',
        internal_notes: '',
        balancePrice: '',
        numberOfAdults: 1,
        numberOfChilds: 0,
        booking_access_code: '',
        confirmation_code:'',
        language_code: 'en',
        nationality : '',
        error_message: {},
        property:null,
    };
};
let state = {
    booking_list: {
        data: []
    },
    isSMSPlanActive: false,
    routes: {},
    booking_detail: {},
    additional_charge_active_booking_id: 0,
    add_booking : BookingData(),
    properties: [],
    rentals: [],
    sources: [],
    languages:[],
    nationalities : [],
    firstCallDone: false,
    dataForCvvVerification: null,
    isCvvVerificationModalMounted: false
};

export default state;
