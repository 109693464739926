/**
 * ResuablePaymentRequestAddEdit.vue
 */

import general from "../../index";

let  actions = {

    getReusablePaymentPage: async ({commit, state}, serve_id)=>{

        commit('SHOW_LOADER', null, {root : true});
        let url = '/client/v2/get-reusable-payment-page/'+serve_id;

        await axios.get(url).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            if(response.data.status) {
                if(response.data.status_code == 200) {
                    commit('SET_SAVED_PAYMENT_PAGE', response.data.data);
                } else {
                    toastr.info(response.data.message);
                }
            } else {
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },

    reusablePaymentProductList: async ({commit, state}, {filters})=>{

        commit('SHOW_LOADER', null, {root : true});
        let url = '/client/v2/reusable-payment-product-listing';

        await axios.post(url, {filters}).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            console.log(response);
            if(response.status) {
                commit('SET_PRODUCT_LIST', response.data);
            } else {
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },

    clonePaymentRequestPage: async ({commit, state, dispatch}, {page_id, filters})=>{

        commit('SHOW_LOADER', null, {root : true});
        let url = '/client/v2/clone-payment-request-page/';

        await axios.post(url, {page_id}).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            if(response.data.status) {
                if(response.data.status_code == 200) {
                    dispatch(
                        'general/getAllReusablePaymentPageList',
                        filters, {root: true});
                    toastr.success(response.data.message);
                } else {
                    toastr.info(response.data.message);
                }
            } else {
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },

    deletePaymentRequestPage: async ({commit, state, dispatch}, {page_id, filters})=>{

        commit('SHOW_LOADER', null, {root : true});
        let url = '/client/v2/delete-payment-request-page';

        await axios.post(url, {page_id}).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            if(response.data.status) {
                if(response.data.status_code == 200) {
                    dispatch(
                        'general/getAllReusablePaymentPageList',
                        filters, {root: true});
                    toastr.success(response.data.message);
                } else {
                    toastr.info(response.data.message);
                }
            } else {
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },

    deleteProduct: async ({commit, state, dispatch}, {product_id, filters})=>{

        commit('SHOW_LOADER', null, {root : true});
        let url = '/client/v2/delete-reusable-product';

        await axios.post(url, {product_id}).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            if(response.data.status) {
                if(response.data.status_code == 200) {
                    dispatch(
                        'general/reusablePaymentProductList',
                        {filters}, {root: true});
                    toastr.success(response.data.message);
                } else {
                    toastr.info(response.data.message);
                }
            } else {
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },

    UpdateStatusPaymentRequestPage: async ({commit, state, dispatch}, {page_id, filters, status})=>{

        commit('SHOW_LOADER', null, {root : true});
        let url = '/client/v2/update-status-payment-request-page';

        await axios.post(url, {page_id, status}).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            if(response.data.status) {
                if(response.data.status_code == 200) {
                    dispatch(
                        'general/getAllReusablePaymentPageList',
                        filters, {root: true});
                    toastr.success(response.data.message);
                } else {
                    toastr.info(response.data.message);
                }
            } else {
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },
    
    getAllProducts: async ({commit, state}) => {
        
        commit('SHOW_LOADER', null, {root : true});
        
        let url='/client/v2/reusable-payment-product-list';
        
        await axios.get(url).then((response) => {

            commit('HIDE_LOADER', null, {root : true});

            if(response.data.status) {
                
                if(response.data.status_code == 200) {
//                    toastr.success(response.data.message);
                    commit('SET_VALUES', response.data.data);
                } else {
                    // toastr.info(response.data.message);
                }
                
            } else {
                toastr.error(response.data.message);
            }
            
        }).catch((err) => {
            commit('HIDE_LOADER', null, {root : true});
            if(err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
        
    },
    
    saveReusablePaymentRequest: async ({commit, state}, reusablePaymentRequest) => {
        
        commit('SHOW_LOADER', null, {root : true});        
        let url='/client/v2/reusable-payment-page-save';
        
        await axios.post(url, reusablePaymentRequest).then((response) => {
            commit('HIDE_LOADER', null, {root : true});
            if(response.data.status) {
                if(response.data.status_code == 200) {
                    toastr.success(response.data.message);
                    commit('CLEAR_STATE');
                    window.location.href = '/client/v2/reusable-payment-page-list';
                } else {
                    toastr.info(response.data.message);
                }
            } else {
                toastr.error(response.data.message);
            }
            
        }).catch((err) => {
            
            commit('HIDE_LOADER', null, {root : true});
            
            if(err.response != undefined && err.response.status == 422) {
                commit('SET_ERRORS', err.response.data.errors);
            } else {
                toastr.error(err);
            }
        });
    },

    verifyReusablePaymentRequestChargeAndAuth: ({commit, dispatch}, data)=>{

        commit('SHOW_LOADER', null, {root: true});
        let self = this;
        axios({
            url: '/verify-reusable-payment-request-charge',
            method: 'POST',
            data
        }).then((resp) => {
            commit('HIDE_LOADER', null, {root: true});
            if(resp.status) {
                if(resp.data.status) {
                    // toastr.success(resp.data.message);
                    commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "success", message: resp.data.message } }, {root: true});
                    commit('general/THREE_DS_VERIFICATION_COMPLETED',
                        null,
                        {root:true});
                    window.top.location.href = resp.data.data.thank_you_page_url;
                } else {
                    // toastr.error(resp.data.message);
                    commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "error", message: resp.data.message } }, {root: true});
                }
                commit('general/UPDATE_THREE_DS_ERROR',resp.data.message, {root:true});
            } else {
                // toastr.error("Something went wrong, Error in communication. Try after sometime.");
                commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "ERROR", message: "Something went wrong, Error in communication. Try after sometime." } }, {root: true});
            }

        }).catch((err) => {
            console.log(err);
            // toastr.error('Something went wrong. Try again.');
            commit('general/CLOSE_3DS_MODAL_NEW', { verification_3d_modal_response: { status: "ERROR", message: "Something went wrong. Try again." } }, {root: true});
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
};

export default actions;
