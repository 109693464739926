let actions = {

    clientLoadDefaultSms: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.get('/client/v2/client-get-default-sms/'+data.sms_type_id+'/'+data.recipient_type_id).then((resp) => {
            commit('SET_DEFAULT_SMS_DATA', resp.data.data);
            let content = resp.data.data[0]['for_whom'].filter(function (item) {return item['receiver_id'] == data.recipient_type_id; })[0]['content']
            commit('SET_ATTACHED_BOOKING_SOURCES', content);

            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    clientLoadDefaultSmsTypes: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios.get('/client/v2/client-get-sms-types/').then((resp) => {
            commit('SET_SMS_TYPES', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    clientUpdateDefaultSms:({commit},data) => {
        commit('SHOW_LOADER', null, {root: true});
        return new Promise((resolve, reject) => {
            axios.post('/client/v2/client-update-default-sms',data).then((resp) => {
                if(resp.data.status){
                    toastr.success(resp.data.message);
                }else {
                    console.log(resp.data.data);
                }
                commit('HIDE_LOADER', null, {root: true});
               }).catch((err) => {
                   commit('HIDE_LOADER', null, {root: true});
                   if (err.response.status == 422) {
                       resolve(err.response.data.errors);
                   }else{
                       console.log(err)
                   }
               });
         });
    },
    revertToDefaultSms:({commit},data) => {
        commit('SHOW_LOADER', null, {root: true});
        return new Promise((resolve, reject) => {
            axios.post('/client/v2/revert-to-default-sms',data).then((resp) => {
                commit('SET_DEFAULT_SMS_DATA', resp.data);
                //commit('SET_ATTACHED_BOOKING_SOURCES', resp.data.content);

                toastr.success('Default SMS Content Applied.');

                commit('HIDE_LOADER', null, {root: true});
                resolve(resp.data);
               }).catch((err) => {
                console.log(err)
                   commit('HIDE_LOADER', null, {root: true});
                   if (err.response.status == 422) {
                       resolve(err.response.data.errors);
                   }else{
                      console.log(err)
                   }
               });
         });
    },
};

export default actions;
