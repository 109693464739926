const auth_base_path = "./components/general/auth";


import { defineAsyncComponent } from 'vue';

// Register auth components
export function registerAuthComponents(app) {
    app.component('login', defineAsyncComponent(() => import('@cac-auth/Login.vue')));
    app.component('new-login', defineAsyncComponent(() => import('@cac-auth/NewLogin.vue')));
    app.component('register', defineAsyncComponent(() => import('@cac-auth/Register.vue')));
    app.component('new-register', defineAsyncComponent(() => import('@cac-auth/NewRegister.vue')));
    app.component('forgot-password', defineAsyncComponent(() => import('@cac-auth/ForgotPassword.vue')));
    app.component('forgot-password-new', defineAsyncComponent(() => import('@cac-auth/ForgotPasswordNew.vue')));
}
