

import { defineAsyncComponent } from 'vue';

// Register core components
export function registerUIComponents(app) {
    app.component('cac-header', defineAsyncComponent(() =>  import('@cac-ui/components/molecules/Header.vue')));

    app.component('cac-input',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Input.vue')));
    app.component('cac-icon',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Icon.vue')));
    app.component('cac-segmented-button',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/SegmentedButton.vue')));
    app.component('cac-modal',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Modal.vue')));
    app.component('cac-badge',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Badge.vue')));
    app.component('cac-image',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Image.vue')));
    app.component('cac-card',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Card.vue')));
    app.component('cac-table',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Table.vue')));
    app.component('cac-dropdown',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Dropdown.vue')));
    app.component('cac-select',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Select.vue')));
    app.component('cac-btn',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Button.vue')));
    app.component('cac-list',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/List.vue')));
    app.component('cac-header',  defineAsyncComponent(() =>  import('@cac-ui/components/molecules/Header.vue')));
    app.component('cac-progress-stepper',  defineAsyncComponent(() =>  import('@cac-ui/components/molecules/ProgressStepper.vue')));
    app.component('cac-language-selector',  defineAsyncComponent(() =>  import('@cac-ui/components/molecules/LanguageSelector.vue')));
    app.component('cac-media-upload',  defineAsyncComponent(() =>  import('@cac-ui/components/molecules/MediaUploadComponent.vue')));
    app.component('cac-carousel',  defineAsyncComponent(() =>  import('@cac-ui/components/molecules/Carousel.vue')));
    app.component('cac-horizontal-scroller',  defineAsyncComponent(() =>  import('@cac-ui/components/molecules/HorizontalScroller.vue')));
    app.component('cac-tooltip',  defineAsyncComponent(() =>  import('@cac-ui/components/atoms/Tooltip.vue')));
}
