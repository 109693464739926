let mutations = {
    SET_ALL_EMAIL_TYPES(state,data) {
        state.all_email_types = data;
        return state;
    },
    SET_TEST_EMAIL_RESPONSE(state, data){
        state.response = data;
        return state;
    }
};

export default mutations;