let actions = {
    getPropertiesWithRooms : async ({commit, dispatch, state}, data)=>{
        if(data.reset){
            dispatch('resetAttachedPropertiesData')
            delete data.reset;
        }
        commit('SHOW_LOADER', null, {root : true});
        return await axios({
            url : '/client/v2/all-properties-with-rooms',
            method : 'POST',
            data: data,
        }).then((resp)=>{

            // [CV-6250] Setting attach_status to true for all except 'terms-and-conditions'
            if (data.model_name !== 'terms-and-conditions' && data.serve_id === 0 && resp?.data?.data?.length) {
                resp.data.data.forEach(e => {
                    e.attach_status = true;
                });
            }

            // Set attach_status to true based on conditions:
            // 1. If model_name isn't 'terms-and-conditions' and serve_id is 0,
            // 2. If all_rentals_selected is true.
            if (resp?.data?.data?.length) {
                resp.data.data.forEach(e => {
                    if (data.model_name !== 'terms-and-conditions' && data.serve_id === 0) {
                        e.attach_status = true; // Set attach_status to true for this element
                    } else if (state.client_list_select_properties.all_rentals_selected === true) {
                        e.attach_status = true; // Set attach_status to true for this element
                    }
                });
            }

            // If user has clicked "Detach All" then 
            if(state.client_list_select_properties.all_rentals_selected === false){
                resp?.data?.data.forEach(e => {
                    e.attach_status = false;
                });
            }

            commit('GET_PROPERTIES_WITH_ROOMS', resp.data);
            // dispatch('getSelectedPropertiesCount', data);

            // if (['up-sell', 'user-custom-question', 'terms-and-conditions', 'sms', 'guide-book'].includes(data.model_name) && data.serve_id == 0) {
            //     setTimeout(function () {
            //     if (!state.client_list_select_properties.all_rentals_deselected) {
            //         commit('RENTAL_SELECT_BULK_ACTION', true); //Default all rental active
            //     }
            //     }, 100);
            // }

            /* Set Select Property Count to 0, When first T&C creates */
            // if (['terms-and-conditions'].includes(data.model_name) && data.serve_id == 0) {
                // setTimeout(function () {
                    // if (state.tac.paginationResponse.data && state.tac.paginationResponse.data.length === 0)
                        // state.client_list_select_properties.selected_properties_count = 0;
                // }, 1000);
            // }

            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
        });
    },

    getSelectedPropertiesCount: function ({dispatch, state}, data) {
        if (data.serve_id <= 0) {
            dispatch('setActivePropertyCount', data.model_name === 'terms-and-conditions' ? 0 : state.client_list_select_properties.properties_pagination_meta.total);
            return;
        }

        return axios({
                  url: '/client/v2/get-all-selected-properties',
                  method: 'POST',
                  data: {'model_name': data.model_name, 'serve_id': data.serve_id},
              }).then((resp) => {
            console.log(resp.data.data)
            dispatch('setActivePropertyCount', resp.data.data.active_properties);
        }).catch((err) => {
            console.log(err);
        });
    },
    setActivePropertyCount:function({commit},count){
        commit('SET_SELECTED_PROPERTIES', count);
    },
    getPropertiesWithRoomsForRequest: ({state}) => {
        let properties = [];
         $.each(state.client_list_select_properties.properties_with_rooms, function (key, value) {
            if(value.attach_status) {
                let attach_property = {id: value.id, attach_status: value.attach_status, attached_rooms: value.attached_rooms}
                properties.push(attach_property);
            }
        });
         return properties;
    },
    setPropertiesWithRoomsForRequest:({state})=>{
        let properties = [];
        $.each(state.client_list_select_properties.properties_with_rooms_updated, function (key, value) {
            // if(value.attach_status) {
                let attached_rooms = [];
                let attached_units = [];

                $.each(value.attached_rooms, function (key2, room) {
                    attached_rooms.push(room.code);
                });

                $.each(value.attached_units, function (key2, unit) {
                    attached_units.push(unit.code);
                });

                let attach_property = {id: value.id, attach_status: value.attach_status, attached_rooms: attached_rooms, attached_units: attached_units}
                properties.push(attach_property);
            // }
        });
        return new Promise((resolve, reject) => {
            resolve(properties);
        });
    },
    resetProperties:({state})=>{
        $.each(state.properties_with_rooms, function (key, value) {
            state.client_list_select_properties.properties_with_rooms[key].attach_status=false;
            state.client_list_select_properties.properties_with_rooms[key].attached_rooms=[{"name":"All Rentals","code":0}];
            state.client_list_select_properties.properties_with_rooms[key].attached_units=[{"name":"All Rentals","code":0}];
        });
    },
    resetAttachedPropertiesData:  ({commit}, data)=>{
        commit('RESET_ATTACHED_PROPERTIES');
    },
    resetAttachedPropertiesDataForCustomQS: ({
        commit
    }, data) => {
        commit('RESET_ATTACHED_PROPERTIES_FOR_CUSTOM_QS');
    },
};

export default actions;
