/** Tags Component State*/
const basic_data={
    name:"",
};
const filters = {
    recordsPerPage:10,
    page:1,
    columns: '*',
    relations: [],
    sort:{
        sortOrder:'Desc',
        sortColumn:'id',
    },
    constraints:[],
    whereHas:[],
    search: {
        searchInColumn: ['id', 'name'],
        searchStr: ''
    },
    type_id:'all',
    property_info_id:'all',
    room_info_id:'all',
    filter_count:0,
}; //Datatable filters Object End

/** Main STATE */
let state={
    formData:[{...basic_data}],
    paginationResponse: {}, // List Records With Pagination Meta & Links
    filters : filters, // ALL Regarding Filters for datatable.
    firstCallDone: false,
    allTags: [],
};
export default state;