let mutations = {

    FETCH_UPCOMING_ARRIVALS(state, data) {
        state.booking.firstCallDone = true
        return state.dashboard.upcoming_arrivals = data.data;
    },

    FETCH_DASHBOARD_ANALYTICS(state, data) {
        return state.dashboard.analytics = data.data;
    },
    COMPLETION_TIPS_DATA(state, data) {
        return state.dashboard.completion_tips_data = data;
    },
    TODAY_BOOKINGS_DATA(state, data) {
        return state.dashboard.today_bookings_data = data;
    },
    TODAY_UPSELL_STATS(state, data) {
        return state.dashboard.today_upsell_stats = data;
    },
    TODAY_RESERVATION_PAYMENT_STATS(state, data) {
        return state.dashboard.today_reservation_payment_stats = data;
    },
    TODAY_SECURITY_DEPOSIT_STATS(state, data) {
        return state.dashboard.today_security_deposit_stats = data;
    },
    DAY_WISE_BOOKINGS_DATA(state, data) {
        return state.dashboard.day_wise_bookings_data = data;
    },
    EMPTY_DAY_WISE_BOOKINGS_DATA() {
        return state.dashboard.day_wise_bookings_data = [];
    },
    REVENUE_GENERATED(state, data) {
        return state.dashboard.revenue_generated = data;
    },
    PAYMENT_REQUEST_DATA_OF_MONTH(state, data) {
        return state.dashboard.payment_request_data_of_month = data;
    },
    INCOMPLETE_PRE_CHECK_IN(state, data) {
        return state.dashboard.incomplete_pre_check_in = data;
    },
    DASHBOARD_UPSELL_DATA(state, data) {
        return state.dashboard.upsells_data = data;

    },
    TRANSACTION_SUMMARY_REPORT(state , payload){
    
        state.dashboard.transaction_summary = payload;
        return state ;

    },
    PAYMENT_REQUESTS_SUMMARY(state , payload){
        
        state.dashboard.payment_request_summary = payload;
        return state ;
    },
    PAYMENT_PAGES_SUMMARY(state , payload){
        
        state.dashboard.payment_page_summary = payload;
        return state ;
    },
    UPSELLS_SUMMARY(state , payload){
        state.dashboard.upsell_summary = payload;
        return state ;
    },

    DASHBOARD_CHECKIN_ANALYTICS(state , payload){
        state.dashboard.checkin_analytics = payload;
        return state ;
    },

    PAYMENT_(state , payload){
    
        state.dashboard.transaction_summary = payload;
        return state ;

    },
};
export default mutations;
