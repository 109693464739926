// import Vue from 'vue';
import {bus} from '../../../../../bus';

// import consola from "consola";

let actions = {
    getTransactionsList: function ({commit}, {customer_id, request_id}) {
        commit('SHOW_LOADER', null, {root: true});
        if(customer_id){
            axios.get('/get-all-customer-payment-requests/'+customer_id+'/'+request_id)
                .then(resp => {
                    if(resp.data.status)
                    {
                        commit('SET_TRANSACTION_LIST', resp.data);

                        if(!resp.data.data.transactions.length
                            && resp.data.data.redirect_url != '')
                        {
                            window.location = resp.data.data.redirect_url;
                        }
                    }
                    commit('HIDE_LOADER', null, {root: true});
                });
        } else {
            commit('HIDE_LOADER', null, {
                root: true
            });
        }
    },

    attemptToPay: async function ({commit, dispatch}, data) {

        commit('SHOW_LOADER', {force: true}, {root: true});
        let response = undefined;

        await axios({
            url: '/attempt-to-pay',
            method: 'POST',
            data,
        }).then((resp) => {

            response = resp.data;

            if(resp.data.status == true && resp.data.status_code == 200){
                //toastr.success(resp.data.message);

                bus.emit('reload_pg_terminal', true);

                bus.emit('make_selected_transactions_empty', true);
                commit('HIDE_LOADER', {force: false}, {root: true});
                dispatch('getTransactionsList', {customer_id: data.customer_id, request_id: data.request_id});
                commit('general/HIDE_3DS_MODAL_BOX', null, {root: true});

                // signal to clear gateway component's information if any stored on front-end.
                bus.emit('clear_card', {});

                //show payment success modal box
                swal.fire({
                    title: "Payment successful",
                    type: "success",
                    showCancelButton: 0,
                    //confirmButtonText: "Yes, do it!"
                });
            }

            if(resp.data.status == false) {

                bus.emit('reload_pg_terminal', true);

                if (
                    resp.data.data != null &&
                    resp.data.data.status == false &&
                    resp.data.data.status_code == 407 &&
                    resp.data.data.require_3ds == true
                ) {
                    toastr.warning(resp.data.message);
                    if(resp.data.data.gateway === '3') {
                        // Show Cybersource 3ds Modal
                        // Note: Not hiding loader on purpose.
                        let dataForFingerPrinting = {res: resp.data.data, req: data};
                        commit('general/SET_DEVICE_FINGERPRINTING_PARAMETERS', dataForFingerPrinting, {root: true});

                    } else {
                        // Show Stripe 3ds Modal
                        commit('general/SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url, {root: true});
                        commit('HIDE_LOADER', {force: false}, {root: true});
                    }
                }else{
                    toastr.error(resp.data.message);
                    if(resp.data.data !=null && resp.data.data.require_cvv){
                        bus.emit('show_cvv_verification_modal', true);
                    }
                    commit('HIDE_LOADER', {force: true}, {root: true});
                }
            }

        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_PAYMENT_ATTEMPT_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', {force: false}, {root: true});
        });

        return response;
    },

    savePaymentRequestCard: async ({commit, dispatch}, payload) => {
        //commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/update-payer-card',
            method: 'POST',
            data: payload
        }).then(async (resp) => {
            commit('SHOW_PAYMENT_REQUEST_CARD_ERRORS', {error_message: {}, error_status: {}});
            if (resp.data.status_code == 200 && resp.data.status == true) {
                // card successfully added

                bus.emit('reload_pg_terminal', true);

                if(resp.data.message != null){
                    toastr.success(resp.data.message);
                }
                commit('SET_AUTH_TO_TRANSFORM', []);
                dispatch('getCustomerCardsList', {customer_id: payload.customer_id, request_id: payload.request_id});
                if(payload.selected_transactions.length > 0) {
                    dispatch('attemptToPay', {
                        selected_card: payload.selected_card,
                        selected_transactions: payload.selected_transactions,
                        customer_id: payload.customer_id,
                        request_id : payload.request_id,
                        source: 'payer_side'
                    });
                }else{
                    dispatch('getTransactionsList', {customer_id: payload.customer_id, request_id: payload.request_id});
                    commit('HIDE_LOADER', null, {root: true});
                }

                // id of added card. saving it for cybersource
                if(resp.data.data && resp.data.data.id) {
                    commit('general/SET_DEVICE_FINGERPRINTING_CARD_ADDED', resp.data.data.id, {root: true});
                }

                //After adding card unset postal code value
                var postalCode = document.getElementById("postal-code").value;
                if (postalCode) {
                    document.getElementById("postal-code").value = null;
                }

            }else{
                commit('HIDE_LOADER', null, {root: true});
                if(resp.data.message != null){
                    toastr.error(resp.data.message);
                }
            }

        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors && errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = validation_errors[k2][0];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('SHOW_PAYMENT_REQUEST_CARD_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    verifyPaymentRequestChargeAndAuth: ({commit, dispatch}, data)=>{

        commit('SHOW_LOADER', null, {root: true});
        let self = this;
        axios({
            url: '/api/verify-iframe-payment-request-charge',
            method: 'POST',
            data
        }).then((resp) => {
            commit('HIDE_LOADER', null, {root: true});
            if(resp.status) {
                if(resp.data.status) {
                    toastr.success(resp.data.message);
                    console.log(resp.data); // TODO: remove this log
                    let payLoadToPass = {
                        id: data.resource_id,
                        paid_transaction: resp.data.data.paid,
                        payment_method_name: (data.payment_method_name) ? data.payment_method_name : null,
                        transaction_ref_no: resp.data.additional.transaction_ref_no,
                        authenticationInformation: resp.data.data.authenticationInformation
                    };
                    commit('general/THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED', payLoadToPass, {root:true});

                } else {
                    toastr.error(resp.data.message);
                    if (data.source === 'payer_side' && resp.data.status_code === 422) {
                        setTimeout(() => {
                            commit('HIDE_3DS_MODAL_BOX');
                        }, 2000);
                    }
                }
                commit('general/UPDATE_THREE_DS_ERROR',resp.data.message, {root:true});
            } else {
                toastr.error("Something went wrong, Error in communication. Try after sometime.");
            }

        }).catch((err) => {
            console.log(err);
            toastr.error('Something went wrong. Try again.');
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    getCustomerCardsList: ({commit, dispatch}, {customer_id, request_id})=>{

        //commit('SHOW_LOADER', null, {root: true});
        axios.get('/get-customer-all-cards/'+customer_id+'/'+request_id)
            .then(resp => {
                if(resp.data.status)
                {
                    commit('SET_CUSTOMER_CARD_LIST', resp.data);
                }
                //commit('HIDE_LOADER', null, {root: true});
            });
    },

    makeDefaultCreditCard: ({commit, dispatch}, data)=>{

        commit('SHOW_LOADER', null, {root: true});
        let self = this;
        axios({
            url: '/make-default-credit-card',
            method: 'POST',
            data
        }).then((resp) => {
            commit('HIDE_LOADER', null, {root: true});
            if(resp.data.status) {
                commit('SET_AUTH_TO_TRANSFORM', []);
                dispatch('getCustomerCardsList', {customer_id: data.customer_id, request_id: data.request_id});
                dispatch('getTransactionsList', {customer_id: data.customer_id, request_id: data.request_id});
                bus.emit('CARD_MADE_AS_DEFAULT', true);
                swal.fire({
                    title: resp.data.message,
                    type: "success",
                    showCancelButton: 0,
                    //confirmButtonText: "Yes, do it!"
                });
            } else {
                toastr.error("Something went wrong, Error in communication. Try after sometime.");
            }

        }).catch((err) => {
            console.log(err);
            toastr.error('Something went wrong. Try again.');
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    createPaymentRequest : ({commit},{customer_id,request_id, amount , currency_code, paymentMethod }) => {

        return new Promise((resolve, reject) => {
            axios({
                url: '/create-payment-request',
                method: 'POST',
                data: {
                    client_id: customer_id,
                    request_id:request_id,
                    amount: amount,
                    currency: currency_code,
                    paymentMethodType: paymentMethod
                }
            }).then((resp) => {
                console.log('response in action', resp.data);
                if(resp.data.status){
                    console.log('return in action', resp.data.data.client_secret);
                     return  resolve(resp.data.data.client_secret);
                }else{
                    toastr.error(resp.data.message)
                }
            }).catch((err) => {
                console.error((err));
            })
           return resolve('');
        });

        /*
        *  return new Promise((resolve, reject) => {
            axios({
                url: '/verify-reservation-payment-and-auth',
                method: 'POST',
                data
            }).then((resp) => {
                commit('HIDE_LOADER', null, {root: true});
                if (resp.status) {
                    if (resp.data.status) {
                        toastr.success(resp.data.message);
                        commit('UPDATE_THREE_DS_ERROR', resp.data.message);
                        commit('THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED');
                    } else {
                        commit('UPDATE_THREE_DS_ERROR', resp.data.message);
                        toastr.error(resp.data.message);
                    }
                } else {
                    toastr.error("Something went wrong, Error in communication. Try after sometime.");
                }

                return resolve(resp);
            }).catch((err) => {
                console.log(err);
                toastr.error('Something went wrong. Try again.');
                commit('HIDE_LOADER', null, {root: true});
            });
        * */
    },

    iframeCheckout: function ({commit, dispatch}, data) {
        axios({
            url: '/api/iframe-attempt-to-pay',
            method: 'POST',
            data,
        }).then(async (resp) => {

            if (resp.data.status == true && resp.data.status_code == 200) {

                let header = new URLSearchParams(window.location.search);
                let msg = header.get("successMsg");

                let sweetAlertTimeOut = isNaN(header.get("sweetAlertTimeOut")) ? 5 : parseInt(header.get("sweetAlertTimeOut"));
                sweetAlertTimeOut = sweetAlertTimeOut * 1000;

                msg = (msg != null && msg != 'null' && msg.length) ? msg : "Payment Successful."
                bus.emit('reload_pg_terminal', true);

                commit('HIDE_LOADER', null, {root: true});
                commit('general/HIDE_3DS_MODAL_BOX', null, {root: true});

                //show payment success modal box
                swal.fire({title: msg, type: "success",});
                window.top.postMessage({status: 'success', message: 'paymentSuccess', transaction_ref_no:resp.data.additional.transaction_ref_no}, '*');

                setTimeout(() => {
                    swal.close();
                    window.top.postMessage({status: 'success', 'message': 'closeIframe'}, '*');
                    console.log('postMessage:success');

                }, sweetAlertTimeOut);



            } else if (resp.data.status == false) {

                bus.emit('reload_pg_terminal', true);

                if (resp.data.data != null && resp.data.data.status == false && resp.data.data.status_code == 407
                    && resp.data.data.require_3ds == true) {

                    toastr.warning(resp.data.message);
                    commit('general/SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url, {root: true});

                } else {

                    toastr.error(resp.data.message);
                    console.log('postMessage:failed');
                    window.top.postMessage({status: 'failed'}, '*');
                }

                commit('HIDE_LOADER', null, {root: true});
            }

        }).catch((err) => {
            let errors = err.response;
            let error_message = errors.data.message ? errors.data.message : 'Request forbidden.';

            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data.error) {
                        if (typeof errors.data.error[k1] == "object") {
                            let validation_errors = errors.error[k1];
                            for (let k2 in validation_errors) {
                                console.log(validation_errors[k2][0]);
                                error_message = validation_errors[k2][0];
                            }
                        }
                    }
                }
            }

            toastr.error(error_message);
            console.log([error_message,errors]);

            commit('HIDE_LOADER', null, {root: true});
        });
    },
};
export default actions;