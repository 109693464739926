import general from "../../index";
// import Vue from 'vue';
import {  bus  } from '../../../../../bus';

let  actions = {
    
    addProduct: async ({commit, state, dispatch}, {product, payment_gateway_connected, productImageFile}) => {

        if( payment_gateway_connected == 0){
            swal.fire({
                title: "Please connect your payment gateway to start using this functionality.",
                type: "warning",
                html: '<p style="font-size: 0.95rem;">Click <a href="/client/v2/payment-gateway-connect">here</a> to add payment gateway.</p>',
                // showCancelButton: !0,
                confirmButtonText: "OK"
            });
        } else{
            commit('SHOW_LOADER', null, {root : true});
            let url='/client/v2/reusable-payment-product-add';
            commit('RESET_ERRORS');

            let formData = new FormData();
            formData.append('reusable_product_image', productImageFile);
            formData.append('id', product.id);
            formData.append('name', product.name);
            formData.append('amount', product.amount);
            formData.append('description',  product.description ?? '');
            formData.append('supported_currency_id', product.supported_currency_id);


            axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {

                commit('HIDE_LOADER', null, {root : true});

                if(response?.data?.status) {
                    toastr.success(response?.data?.message);

                    commit('RESET_FORM_DATA');
                    let closeModelProductElement = document.getElementById('closeModelProduct');
                    if (closeModelProductElement) {
                        closeModelProductElement.click();
                    }

                    commit('ADD_TO_PRODUCT_LIST', response.data.data.product);
                    commit('SET_PRODUCT_TO_SELECTED', response.data.data.product);
                    if(response.data.data.product.id > 0 || window.location.pathname.split('/').includes('reusable-payment-product-list-page')){
                        bus?.emit('reload_product_list', true);
                    }

                } else {
                    toastr.error(response.data.message);
                }

            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root : true});
                if(err?.response?.status == 422) {
                    commit('SET_ERRORS', err?.response?.data?.errors);
                } else {
                    toastr.error(err);
                }
            });
        }
    },

    fetchProduct : async ({state, commit}, id) => {
        
        commit('SHOW_LOADER', null, {root : true});
        
        axios({
            
            url : '/client/v2/reusable-payment-product-list/'+id,
            method : 'GET'
        }).then((resp)=>{
            if (resp.data.status) {
                commit('SET_PRODUCT', resp.data);
            } else {
                toastr.error(resp.data.message);
            }
            
            commit('HIDE_LOADER', null, {root : true});
            return resp.data.status;
            
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
            return false;
        });
    }
    
};

export default actions;
