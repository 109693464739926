import {init_state} from './state';
let mutations = {
    
    SET_UPSELL_REQUEST_LIST(state, data) {
        state.upsellRequests.paginationResponse = data;
        return state;
    }
    
};

export default mutations;
