let state = {
    analytics                      : {
        all_properties     : 0,
        active_properties  : 0,
        all_booking_sources: 0,
        line               : {
            labels: [],
            values: []
        },
        pie                : {
            labels: [],
            values: []
        },
        total_sale         : [],
        client             : {},
        show_line_graph    : false
    },
    upcoming_arrivals              : [],
    completion_tips_data: {
        tips: [
        {
            "count": 0,
            "status": 'warning',
            "text": 'Logo data loading...',
            'flag': '',
            "link": '',
        },
        {
            "count": 0,
            "status": 'warning',
            "text": 'Property Logo data loading...',
            'flag': '',
            "link": '',
        },
        {
            "count": 0,
            "status": 'warning',
            "text": 'Guide data loading...',
            'flag': '',
            "link": '',

        },
        {
            "count": 0,
            "status": 'warning',
            "text": 'Check-in data loading...',
            'flag': '',
            "link": '',
        },
        {
            "count": 0,
            "status": 'warning',
            "text": 'Upsell data loading...',
            'flag': '',
            "link": '',
        }
        ],
        completed: 0,
    },
    today_bookings_data            : {
        today_check_in          : 0,
        completed_pre_checkin   : 0,
        in_completed_pre_checkin: 0,
        overdue_payments        : 0,
        overdue_security_deposit: 0,
        approved_upsells_orders : 0
    },
    today_upsell_stats             : {
        upsell_order_details_count         : 0,
        pending_upsell_order_details_count : 0,
        approved_upsell_order_details_count: 0
    },
    today_reservation_payment_stats: {
        transactions_count          : 0,
        paid_transactions_count     : 0,
        scheduled_transactions_count: 0,
        overdue_transactions_count  : 0
    },
    today_security_deposit_stats: {
        security_deposit_count          : 0,
        authorized_security_deposit_count     : 0,
        scheduled_security_deposit_count: 0,
        overdue_security_deposit_count  : 0
    },
    day_wise_bookings_data: [],
    revenue_generated              : {
        bookings_amount_of_one_month: 0,
        bookings_from               : '',
        bookings_to                 : '',
        payment_request_amount      : 0,
        upsell_orders_detail_amount : 0,
        successful_transactions     : {
            transactions_count : 0,
            transactions_amount: 0
        },
        future_transactions         : {
            transactions_count : 0,
            transactions_amount: 0
        },
        failed_transactions         : {
            transactions_count : 0,
            transactions_amount: 0
        }
    },
    payment_request_data_of_month  : {
        payment_requests_received_amount: 0,
        payment_requests_received_count : 0,
        payment_requests_pending_count  : 0,
        payment_requests_failed_count   : 0,
        date_from                       : '',
        date_to                         : '',
    },
    incomplete_pre_check_in        : {
        yesterday_date                   : '',
        yesterday_incomplete_pre_check_in: 0,
        current_date                     : '',
        current_incompelete_pre_check_in : 0,
        tomorrow_date                    : '',
        tomorrow_incompelete_pre_check_in: 0,
    },
    upsells_data : {
        received_upsells_count : 0,
        canceled_upsells_count : 0,
        pending_upsells_count : 0,
        upsell_received_amount : 0,
        properties : 0,
        rooms : 0,
        date_from : '',
        date_to : '',
    },

    transaction_summary : {
            successfull_transaction :{
                        amount : 0,
                        count  : 0 
                                     },
            upcoming_transaction : {
                        amount : 0,
                        count  : 0 
                                    },
            overdue_transaction : {
                        amount : 0,
                        count  : 0
                                    },
            success_payment_request :{
                        amount : 0,
                        count  : 0
                                     },
            pending_payment_request : {
                        amount : 0,
                        count  : 0
                                      },
            overdue_payment_request : {
                        amount : 0,
                        count  : 0
                                      },
            total_successful_transactions : 0 ,
            total_upcomming_transactions : 0 ,
            total_overdue_transactions : 0 ,
            currency : '',
            
        },
        payment_request_summary : {
            success_payment_request : {
                        amount : 0,
                        count  : 0,
                                      },
            pending_payment_request :{
                        amount : 0,
                        count  : 0,
                                      },
            overdue_payment_request :{
                        amount : 0,
                        count  : 0,
                                              },
            total_transaction_amount : 0,
            total_transaction_count : 0 ,
            currency : '',
            redirect_route : ''                                  
        },
        payment_page_summary : {
            successfull_transaction_amount : 0 ,
            total_count : 0 ,
            authorized : {
                count : 0,
                amount :0
                        },
            total_amount : 0,
            currency : '',
            redirect_route : ''
        },

        upsell_summary : {
            paid_upsell_order : {
                count : 0,
                amount : 0
            },
            request_upsell : {
                count : 0,
                amount : 0
            },
            decline_upsell : {
                count :0,
                amount : 0
            },

            total_upsells_amount : 0 ,
            total_upsells_count : 0,
            currency : '',
            redirect_route : ''


        },
    checkin_analytics : {
        full_completed : 0,
        full_completed_percentage : 0,
        partially_completed : 0,
        partially_completed_percentage : 0,
        incomplete : 0,
        incomplete_percentage : 0,
        conversion_rate : 0,
        values: [] ,
        labels : [],

    } ,

};

export default state;
