let actions = {

    fetchReservationKeys: async ({commit}, data) => {
        // console.warn('in method', data);
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/pms-reservation-raw-keys',
            method: 'POST',
            data: {'key': data}
        }).then((resp) => {
            // console.warn('in axios', resp.data);
            commit('RESERVATION_RAW_KEYS', resp.data[0].pms_reservation_raw_keys);
            commit('RESERVATION_KEYS_JSON', resp.data[0].pms_reservation_keys);
            commit('PROPERTY_RAW_KEYS', resp.data[0].pms_property_raw_keys);
            commit('PROPERTY_KEYS_JSON', resp.data[0].pms_property_keys);
            commit('WRITE_ACCESS', resp.data[0].write_access);
            commit('HIDE_LOADER', null, {root: false});
        }).catch((err) => {
            commit('RESERVATION_RAW_KEYS', []);
            commit('RESERVATION_KEYS_JSON', []);
            commit('WRITE_ACCESS', '');

            //console.error(err);
            commit('HIDE_LOADER', null, {root: false});

        })
    },
    setReservationKeys: async ({commit, state}, data) => {

        var jsonData = data[0];
        var keys = data[1];
        var backend_name = data[2];

        swal.fire({
            title: 'Are you sure to update Reservation keys',
            type: "warning",
            showCancelButton: !0,
            cancelButtonText: window.app.$t("GENERAL.CANCEL"),
            confirmButtonText: "Yes"
        }).then(function (e) {
            if (e.value == true) {
                axios({
                    url: '/admin/pms-reservation-keys',
                    method: 'POST',
                    data: {'data': jsonData , 'keys': keys, 'name': backend_name}
                }).then((resp) => {
                    if (resp.status == 200)
                        toastr.success(resp.data.message);
                    commit('HIDE_LOADER', null, {root: false});
                }).catch((err) => {
                    //console.error(err);
                    commit('HIDE_LOADER', null, {root: false});
                })
            }
        });

    },
    setPropertyKeys: async ({commit, state}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        var jsonData = data[0];
        var keys = data[1];
        var backend_name = data[2];

        swal.fire({
            title: 'Are you sure to update Property keys',
            type: "warning",
            showCancelButton: !0,
            cancelButtonText: window.app.$t("GENERAL.CANCEL"),
            confirmButtonText: "Yes"
        }).then(function (e) {
            if (e.value == true) {
                axios({
                    url: '/admin/pms-property-keys',
                    method: 'POST',
                    data: {'data': jsonData , 'keys': keys, 'name': backend_name}
                }).then((resp) => {
                    if (resp.status == 200)
                        toastr.success(resp.data.message);
                    commit('HIDE_LOADER', null, {root: false});
                }).catch((err) => {
                    //console.error(err);
                    commit('HIDE_LOADER', null, {root: false});
                })
            }
        });

    },
    keysForReservation: async ({commit}, keys) => {
        commit('KEYS_FOR_REASERVATION', keys);
    },
    keysForProperty: async ({commit}, keys) => {
        commit('KEYS_FOR_PROPERTY', keys);
    },
    getPmsPreferences: async ({commit}, name) => {
        await axios({
            url : '/admin/get-pms-modification',
            method: 'POST',
            data : {'pms_backend_name': name},
        }).then((resp)=>{
            // console.warn('in action', resp)
            commit('SET_PMS_PREFERENCES', resp);
        }).catch((err)=> {
            //console.error(err);
        })
    },
    setPmsPreferences: async ({commit} , payload) =>{
        var pms_backend_name = payload[1];
        var data =  payload[0];
        commit('SHOW_LOADER', null, {root: true});

        swal.fire({
            title: 'Are you sure to update Preferences status',
            type: "warning",
            showCancelButton: !0,
            cancelButtonText: window.app.$t("GENERAL.CANCEL"),
            confirmButtonText: "Yes"
        }).then(function (e) {
            if (e.value == true) {
                axios({
                    url: '/admin/set-pms-preferences-enabled-keys',
                    method: 'POST',
                    data: {'pms_backend_name': pms_backend_name, 'data': data}
                }).then((resp) => {
                    commit('HIDE_LOADER', null, {root: false});
                    if (resp.status == 200)
                        toastr.success(resp.data.message);
                }).catch((err) => {
                    //console.error(err);
                    commit('HIDE_LOADER', null, {root: false});
                    toastr.error(err);

                })
            }
        });


    },
    getPmsNames: async ({commit}) => {
        await axios({
            url : '/admin/get-pms-names',
            method: 'GET'
        }).then((resp)=>{
            // console.warn('in action', resp.data)
            commit('SET_PMS_NAMES', resp.data);
        }).catch((err)=> {
            //console.error(err);
        })
    },
    getQuestionnaire: async ({commit}, name) => {
        commit('CLEAR_QUESTIONNAIRE_KEYS');
        await axios({
            url : '/admin/get-questionnaire-keys',
            method: 'POST',
            data : {'pms_backend_name': name},
        }).then((resp)=>{
            commit('SET_QUESTIONNAIRE_KEYS', resp.data);
        }).catch((err)=> {
            //console.error(err);
        })
    },
    setQuestionnaire: async ({commit}, data) => {

        var questionnaire_enabled_keys = data[0];
        var pms_backend_name = data[1];

        swal.fire({
            title: 'Are you sure to update Questionnaire status',
            type: "warning",
            showCancelButton: !0,
            cancelButtonText: window.app.$t("GENERAL.CANCEL"),
            confirmButtonText: "Yes"
        }).then(function (e) {
            if (e.value == true) {
                axios({
                    url : '/admin/set-questionnaire-keys',
                    method: 'POST',
                    data : {'questionnaire_enabled_keys' : questionnaire_enabled_keys, 'pms_backend_name': pms_backend_name},
                }).then((resp)=>{
                    if (resp.status == 200)
                        toastr.success(resp.data.message);
                }).catch((err)=> {
                    toastr.error(err);
                    //console.error(err);
                })
            }
        });
    },
};

export default actions;
