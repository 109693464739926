let actions = {
    fetchUserDetails: function ({
        commit
    }) {
        return axios({
            url: '/client/v2/v2user_profile',
            method: 'GET'
        }).then((resp) => {
            commit('SET_USER_PROFILE', resp.data);
        }).catch((err) => {
            console.log("Unable to fetch User Profile: ", err);
        });
    },

};

export default actions;