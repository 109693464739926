let actions = {

    /**
     *
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    getUpsellRequestList: async ({state, commit}) => {
        
        commit('SHOW_LOADER', null, {root: true});
        
        await axios({
            url: '/client/v2/upsell-requests',
            method: 'POST',
            data: {filters: state.upsellRequests.filters},
        }).then((resp) => {
            commit("SET_UPSELL_REQUEST_LIST", {
              ...resp?.data,
              data: resp?.data?.data?.filter(Boolean) ?? []
            });
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    /**
     *
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    approveDeclineUpsellOrderRequest: async ({state, commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
            axios({
                url: '/client/v2/upsell-request-status-change',
                method: 'POST',
                data: data,
            }).then((resp) => {
                if (resp.data.status) {
                    dispatch('getUpsellRequestList');

                    if(resp.data.status_code === 440){
                        toastr.info(resp.data.message);
                    }else{
                        toastr.success(resp.data.message);
                    }
                } else {
                    if(resp.data.status_code == 201){
                        dispatch('getUpsellRequestList');
                    }
                    toastr.error(resp.data.message);
                }
                commit('HIDE_LOADER', null, {root : true});

                return resolve(resp);

            }).catch((err)=>{
                commit('HIDE_LOADER', null, {root : true});
                console.log(err);
            });
        });
    },

};

export default actions;
