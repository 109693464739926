
import actions from './admin_root_actions';
import mutations from './admin_root_mutations';
import state from "./admin_root_state";
import emails from './emails/index';
import bookings from './bookings/index';
import termAndConditions from './termAndConditions/index';
import pms_list from './pms_list/index';
import booking_sources from './booking_sources/index';
import guideBooks from './guideBooks/index';
import guideBookTypes from './guideBooks/types/index';
import upsells from './upsells/index';
import upsellTypes from './upsells/types/index';
import test_email from './test_emails/index';
import pms_modification_keys from './pms_modification_keys/index';
import dashboard from './dashboard/index'

export default {
    namespaced: true,
    modules: {
        emails,
        bookings,
        termAndConditions,
        pms_list,
        booking_sources,
        guideBooks,
        guideBookTypes,
        upsells,
        upsellTypes,
        test_email,
        pms_modification_keys,
        dashboard
    },
    state,
    actions,
    mutations,
};
