import _ from 'lodash';
import Popper from 'popper.js';
import toastr from 'toastr';
import Swal from 'sweetalert2';
// import Vue from 'vue'; // Since Vue 3 no longer uses the global Vue constructor so commenting it out
import moment from 'moment-timezone';

window._ = _;
window.Popper = Popper;
window.toastr = toastr;
window.swal = Swal;
// window.Vue = Vue;
window.moment = moment;

 // const creditCardType = require("credit-card-type");
 // const visaCards = creditCardType("4111");
 // console.log(visaCards); // 'visa'


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

// import jQuery from 'jquery';
// // import 'bootstrap'; // Uncomment if you need Bootstrap's JavaScript

// // Attach jQuery to the window object
// window.$ = window.jQuery = jQuery;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from 'axios';

// Attach axios to the window object
window.axios = axios;

// Set default headers for axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; // FIX for issue when upgrading axios to v1.x

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.default.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'
//
// window.Pusher = require('pusher-js');
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

