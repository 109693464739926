let state = {

    notifications: [],
    total_available_notifications: 0,
    total_unread_notifications: 0,
    current_showing_notification_count: 0,
    total_notifications: 0,
    notifications_counts: 0,
    isNotificationPanelOpen: false
};


export default state;