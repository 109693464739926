import logger from '../../../../../helpers/logger';

let actions = {
    addTac: ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        let url = '/client/v2/';
        let rest_form = true;
        if (data.serve_id != 0) {
            url += 'terms-and-conditions-update';
            rest_form = false;
        } else {
            url += 'terms-and-conditions-save';
        }
        
        /*
        * IF PROPERTY IS SELECTED
        *   1) attached_units is empty              INSERT {"name":"All Rentals","code":0} into attached_units
        *   2) attached_units is 1
        *       a) if all_units is 1                EMPTY attached_units & INSERT {"name":"All Rentals","code":0} into attached_units
        *       b) if all_units is 2 or more        DO NOTHING; SKIP ITERATION
        *   3) attached_units is 2 or more 
        *       a) if attached_units === all_units  EMPTY attached_units & INSERT {"name":"All Rentals","code":0} into attached_units
        *       b) if attached_units < all_units    DO NOTHING; SKIP ITERATION 
        */
        logger.groupCollapsed("🏠 Properties Update Process");
        data.properties_with_rooms_updated.forEach((property, index) => {
            logger.groupCollapsed(`Property #${index + 1}`);
            logger.log(`%cProperty #${index + 1}: ${property}`, "color: navy; font-weight: bold;");
            
            // IF PROPERTY IS SELECTED
            // if (property.attach_status) {
                logger.log("%c✅ Property is selected", "color: green;");
        
                // 1) attached_units is empty
                if (property.attached_units?.length === 0) {
                    logger.log("%c📦 Attached units are empty", "color: purple;");
                    // a) if all_units is 0, 1 or 2 or more
                    property.attached_units.push({"name":"All Rentals", "code":0});
                    logger.log("%c🔍 Inserting 'All Rentals' into empty attached_units", "color: blue;");
                }
                // 2) attached_units is 1
                else if (property.attached_units?.length === 1) {
                    logger.log("%c📦 One attached unit exists", "color: purple;");
        
                    // a) if all_units is 1
                    if (property.all_units?.length  === 1) {
                        property.attached_units = [{"name":"All Rentals", "code":0}];
                        logger.log("%c🔄 Replacing single attached unit with 'All Rentals'", "color: orange;");
                    }
                    // b) if all_units is 2 or more
                    // DO NOTHING; SKIP ITERATION
                    else {
                        logger.log("%c🛑 More than one unit, skipping...", "color: red;");
                    }
                }
                // 3) attached_units is 2 or more
                else if (property.attached_units?.length >= 2) {
                    logger.log("%c📦 Multiple attached units exist", "color: purple;");
                    
                    // a) if attached_units === all_units
                    if (property.attached_units?.length === property.all_units?.length) {
                        property.attached_units = [{"name":"All Rentals", "code":0}];
                        logger.log("%c🔄 Replacing all attached units with 'All Rentals'", "color: orange;");
                    }
                    // b) if attached_units < all_units
                    // DO NOTHING; SKIP ITERATION
                    else {
                        logger.log("%c🛑 Attached units less than total, skipping...", "color: red;");
                    }
                }
            // } else {
                // logger.log("%c❌ Property not selected", "color: grey;");
            // }
        
            logger.groupEnd();
        });
        logger.groupEnd();

        if (data.all_rentals_selected === true) {
            logger.groupCollapsed("%c🔍 All Rentals Selected", "color: darkblue; font-weight: bold;");
            logger.log("%c✅ All rentals have been selected.", "color: green;");
            
            data.selected_properties.forEach((e, index) => {
                logger.groupCollapsed(`%cProperty #${index + 1}`, "color: navy; font-weight: bold;");
                logger.log("%cBefore Update: ", "color: grey;", JSON.stringify(e.attached_units));
                
                e.attached_units = [0];
                
                logger.log("%cAfter Update: ", "color: blue;", JSON.stringify(e.attached_units));
                logger.groupEnd();
            });
            
            logger.groupEnd();
        } else if (data.all_rentals_selected === false) {
            data.selected_properties.forEach((property, index) => {
                const units = data.properties_with_rooms_updated[index].attached_units;
                if (units.length === 1 && units[0].name === "All Rentals" && units[0].code === 0) {
                    property.attached_units = [0];
                }
            });
        } else if (data.all_rentals_selected === '') {
            logger.log("%c🔍 Specific Properties Selection", "color: darkblue; font-weight: bold;");
            
            data.selected_properties.forEach((e, index) => {
                logger.groupCollapsed(`%cProperty #${index + 1}`, "color: navy; font-weight: bold;");
                
                const property = data.properties_with_rooms_updated.find(f => f.id === e.id) || null;
                if (property) {
                    logger.log("%cFound matching property:", "color: grey;", JSON.stringify(property));
                    
                    if ((property.all_units?.length === property.attached_units?.length) ||
                        (property.attached_units?.[0]?.name === "All Rentals" && property.attached_units?.[0]?.code === 0)) {
                        
                        logger.log("%cCondition met: Updating attached units.", "color: green;");
                        e.attached_units = [0];
                    } else {
                        logger.log("%cCondition not met: No updates made.", "color: red;");
                    }
                } else {
                    logger.log("%c⚠️ No matching property found.", "color: red;");
                }
                
                logger.groupEnd();
            });
        } else {
            logger.log("%c❌ No rental selection condition met", "color: red;");
        }

        return new Promise((resolve, reject) => {
            axios.post(url, data).then((response) => {
                commit('HIDE_LOADER', null, {root: true});
                // toastr.success(response.data.message);
                if (rest_form) {
                    commit('RESET_TAC_FORM_DATA');
                }
                return resolve({err:0,response:response,rest_form:rest_form});
            }).catch((err) => {
                commit('HIDE_LOADER', null, {root: true});
                if (err.response.status == 422) {
                    resolve({
                        err: 1,
                        response: err.response.data.errors,
                    });
                    // resolve(err.response.data.errors);
                } else{
                    toastr.error(err.response.data.message);
                }
            });

        });
    },
    loadOldData: async ({state, commit}, serve_id) => {

        if (serve_id > 0) {
            commit('SHOW_LOADER', null, {root: true});
            axios.post('/client/v2/terms-and-conditions-old/', {"serve_id": serve_id}).then((response) => {
                commit('SET_TAC_FORM_DATA', response.data.data);
                commit('HIDE_LOADER', null, {root: true});
            }).catch((err) => {
                console.log(err);
                if (err.response.status == 422) {
                    let errors = err.response.data.errors;
                    for (let key in errors){
                        if(errors.hasOwnProperty(key)){
                            toastr.error(errors[key][0]);
                        }
                    }
                }
                commit('HIDE_LOADER', null, {root: true});
            });
        } else {
            commit('RESET_TAC_FORM_DATA');
        }
    },
    getTacList: async ({state, commit}) => {

        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/terms-and-conditions-get-all',
            method: 'POST',
            data: {filters: state.tac.filters},
        }).then((response) => {

            commit('SET_TAC_LIST', response.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    updateTacStatus: async ({state, commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        return new Promise(resolve => {
            axios({
                url: '/client/v2/terms-and-conditions-update-status',
                method: 'POST',
                data: data,
            }).then((resp) => {
                if (resp.data.status) {
                    toastr.success(resp.data.message);
                } else {
                    toastr.error(resp.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});
                resolve(resp.data.status);
            }).catch((err) => {
                console.log(err);
                commit('HIDE_LOADER', null, {root: true});
                resolve(false);
            });
        })
    },

};

export default actions;
