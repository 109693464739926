const addCookie = ({ cookieName, value, days }) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Calculate expiration date
    const expires = `expires=${date.toUTCString()}`;

    // Set or update the cookie
    document.cookie = `${cookieName}=${value}; ${expires}; path=/`;
};

export default {
    addCookie,
};
