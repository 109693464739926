/**
 * ResuablePaymentRequestAddEdit.vue
 */
const product_listing_filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: [],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'id',
    },
    payment_status: 'all',
    payment_status_name: '',
    constraints: [],
    search: {
        searchInColumn: ['name'],
        searchStr: ''
    },
    payment_type: 'all',
    filter_count: 0,
    // Date Filters below
    due_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
    created_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
}; //Datatable filters Object End

let states = {
    // Add specific propertie of Reusable Payment Request Page.
    
    reusablePaymentRequest: {
        
        id: 0,
        payment_type: 'C',
        product_id: 0,
        status: 1,
        
        questions: [
            
        ],
        
        selected_question_index: -1,
        selected_question_id: 0,
        terms_and_conditions: '',
        
        selectedProduct: null,
        
        error_message: {
        // empty or null values
            payment_type: '',
            product_id: ''
        },
    
        error_status: {
            // boolean values.
            payment_type: false,
            product_id: false
        },
    
    },

    products: [],
    company_info: {logo: '', name: ''},

    product_listing: {},

    product_listing_filters: product_listing_filters
    /*
     {
        id: 0,
        name: '',
        currency: '',
        amount: 0
    },
     */
};
export default states;
