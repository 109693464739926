let mutations = {

    SET_LIST_VALUES:function (state, data) {
        state.reusablePaymentPageList = data;
        return state;
    },
    
    CLEAR_STATE: function(state) {
        state.reusablePaymentPages = [];
    },
    
    SET_ERRORS:function (state, data) {   
        $.each(data, function(key, value){
//            state.reusablePaymentRequest.error_message[key] = value[0];
//            state.reusablePaymentRequest.error_status[key] = true;
        });
    }
    
};

export default mutations;
