let mutations = {

    FETCH_USER_LOGS(state, {payload, index}) {
        state.team_member.user_logs = {...state.team_member.user_logs, [index]: payload};
        return state;
    },


};

export default mutations;
