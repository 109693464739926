import validationHelper from "../../../helpers/validation-helpers";
import {countryRules} from '../../../constants/dlocal_document_rules';
export const emailValidator = (value) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value);
};
export const phoneValidator = (value) => {
  return validationHelper.isPhoneNumberValid(value);
};

/**
 * Validates a DLocal PG document value for based on the provided country code and its corresponding rules.
 *
 * @param {string|null} value - The document value to validate. Defaults to null.
 * @param {string} countryCode - The country code used to determine validation rules mentioned in the
 *                                DLocal Country Reference. https://docs.dlocal.com/reference/country-reference
 *
 * @returns {boolean} - Returns `false` if the value or country code is invalid,
 *                      `true` if the value is not required or passes the validation rules,
 *                      otherwise validates the value against the regex format for the country.
 *
 * Validation logic:
 * 1. Fails (returns `false`) if both the `value` and `countryCode` are missing.
 * 2. Skips validation and returns `true` if no specific rules exist for the given country
 * 3. If validation rules exist, checks the value against the regex pattern defined for the country.
 * skipped countryRule mandate rule because we are facing document mandate validations against not mandate country document
 *
 */
export const documentValidator = (value =null, countryCode) => {
  if (!value && !countryCode) {
    return false; // Validation fails if value or country code is missing
  }

  const countryRule = countryRules[countryCode];

  if ((!countryRule && value)) {
    return true;
  }

  return countryRule.format.test(value); // Validate document using the regex
};
export const requiredValidator = (value) => {
  return !(value === undefined || value?.length === 0 || value?.trim() === "");
};

export const getTranslatedMessage = (key, fallback) => {
  const translatedMessage = window.app.$t(key);
  return translatedMessage !== key ? translatedMessage : fallback;
}