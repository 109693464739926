import axios from 'axios';
import store from '../components/store/index';
import logger from '../helpers/logger';

// Create an Axios instance
const axiosInstance = axios;

// Request interceptor
axiosInstance.interceptors.request.use(
    /**
     * Intercept the request before it is sent.
     *
     * @param {object} config - The Axios request config object.
     * @returns {object} - The modified request config.
     */
    function (config) {
        if (('X-Skip' in config.headers && config.headers['X-Skip']) || window.location.pathname === '/client/v2/dashboard') {
            return config;
        }

        // Log request details
        logger.group(`Axios Intercept Request:`);
        logger.log(`%cRequest URL: %c${config.url}`, 'color:#0779f0; font-weight: bold', 'color:#102a43; font-weight: normal');
        // logger.log(`%cRequest Method: %c${config.method}`, 'color:#0779f0; font-weight: bold','color:#102a43; font-weight: normal');
        // logger.log(`%cRequest Body: %c${config.data}`, 'color:#0779f0; font-weight: bold','color:#102a43; font-weight: normal');

        // Show loader or perform any other tasks before making the request
        store.dispatch('showAutoLoader', {
            caller: config.url
        });
        logger.groupEnd(`%c Axios Intercept Request:`);
        return config;
    },


    /**
     * Handle the request error.
     *
     * @param {object} error - The Axios request error object.
     * @returns {Promise} - A rejected Promise with the error.
     */
    function (error) {
        console.error(error);
        // Handle request error
        store.dispatch('hideAutoLoader', {
            caller: config.url
        });
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    /**
     * Intercept the response before it is resolved.
     *
     * @param {object} response - The Axios response object.
     * @returns {object} - The modified response object.
     */
    function (response) {

        const countryCode = response.headers['x-country-code'];
        const clientIP = response.headers['x-client-ip'];
        
        if (countryCode && countryCode !== "") {
            store.commit('SET_COUNTRY_CODE', countryCode);
        }

        if (clientIP && clientIP !== "") {
            store.commit('SET_CLIENT_IP', clientIP);
        }

        if (('X-Skip' in response.config.headers && response.config.headers['X-Skip']) || window.location.pathname === '/client/v2/dashboard') {
            return response;
        }

        if (response?.data?.status_code === 419 && response?.data?.error_code === 'csrf_expired') {
            // CSRF token expired, reload the page
            window.location.reload();
        }
        // Log response details
        logger.group(`Axios Intercept Response:`);
        logger.log(`%cResponse URL: %c${response.config.url}`, 'color:#a7af18; font-weight: bold', 'color:#102a43; font-weight: normal');
        logger.log(`%cResponse status: %c${response.status}`, 'color:#a7af18; font-weight: bold', 'color:#102a43; font-weight: normal');

        // Hide loader and process response data
        store.dispatch('hideAutoLoader', {
            caller: response.config.url
        });
        logger.groupEnd(`%c Axios Intercept Response:`);
        return response;
    },

    
    /**
     * Handle the response error.
     *
     * @param {object} error - The Axios response error object.
     * @returns {Promise} - A rejected Promise with the error.
     */
    function (error) {
        console.error(error);
        // Handle request error
        store.dispatch('hideAutoLoader', {
            caller: error?.response?.config?.url
        });


        /**
         * Handle 429 Too Many Requests
         */
        if (error.response.status === 429) {
            toastr.error(error.response.data.message);
        }

        /**
         * Handle other errors as usual
         */
        return Promise.reject(error);
    }
);


export default axiosInstance;