import logger from '../../helpers/logger'


let mutations = {
    //options is an object that has currently a single property 'force'
    SHOW_LOADER: (state, options) => {
        state.loader.block = true;
        if(options && options.force){
            state.loader.force = options.force; // Locks loader hide function.
        }
        return state;
    },
    HIDE_LOADER: (state, options) => {

        if(state.loader.force && options && options.force) {
            state.loader.force = false; // enable loader hide function for others.
            state.loader.block = false; // hide loader
            return state;
        }

        if(state.loader.force) {
            return state; // Not hiding loader
        }

        state.loader.block = false;
        return state;

        // Old logic
        // state.loader.block = false;
        // if(options && !options.force){
        //     state.loader.force = options.force;
        // }
        // return state;
    },

    SHOW_AUTO_LOADER: (state, options) => {
        // Log that the loader is being shown for the specified options
        logger.log(`%c Showing Loader for ${JSON.stringify(options)}`, 'color:green');

        // Set the block property of the loader object to true
        state.autoloader.block = true;

        // Increment the active loader count in the loader object
        state.autoloader.activeLoaders++;

        // Add the name of the caller function (if available) or 'unknown' to the callers array
        state.autoloader.callers.push(options && options.caller || 'unknown');

        // Log the count of callers
        logger.log("caller count is", state.autoloader.callers.length);

        // If options.force is truthy, set the force property of the loader object
        // if (options && options.force) {
        //     state.autoloader.force = options.force; // Locks loader hide function.
        // }

        return state;
    },
    
    HIDE_AUTO_LOADER: (state, options) => {
        // Log that the loader is being hidden for the specified options
        logger.log(`%c Hiding loader for ${JSON.stringify(options)}`, 'color:pink');

        // Decrement the active loader count in the loader object
        state.autoloader.activeLoaders--;

        // Find the index of the caller function name (if available) or 'unknown' in the callers array
        const index = state.autoloader.callers.lastIndexOf(options && options.caller || 'unknown');
        // If found, remove it from the callers array
        if (index !== -1) {
            state.autoloader.callers.splice(index, 1);
        }

        // Log the count of callers
        logger.log("caller count is", state.autoloader.callers.length);

        // If there are no more active loaders, set the block property of the loader object to false
        if (state.autoloader.activeLoaders === 0) {
            state.autoloader.block = false;
        }

        // If options.force is falsy, set the force property of the loader object
        // if (options && !options.force) {
        //     state.autoloader.force = options.force;
        // }

        return state;
    },
    
    RELOAD_PROPERTIES_WITH_ROOMS: (state) => {
        state.reload_properties_with_rooms++;
        return state;
    },

    SET_COUNTRY_CODE: (state, data) => {
        state.countryCode = data;
    },

    SET_CLIENT_IP: (state, data) => {
        state.clientIp = data;
    }
};

export default mutations;