export const countryRules = {
    AR: {
        name: "Argentina",
        documentName: "DNI or CUIT",
        format: /^\d{7,9}$|^\d{11}$/,
        message: "Between 7 to 9 or 11 digits.",
        required: true,
    },
    BD: {
        name: "Bangladesh",
        documentName: "NID Card or National Identity Card",
        format: /^[0-9]{13,17}$/,
        message: "Must be 13-17 digits.",
        required: true,
    },
    BO: {
        name: "Bolivia",
        documentName: "CI",
        format: /^[0-9]{5,20}$/,
        message: "Must be 5-20 digits.",
        required: true,
    },
    BR: {
        name: "Brazil",
        documentName: "CPF or CNPJ",
        format: /^[0-9]{11,14}$/,
        message: "Must be 11-14 digits.",
        required: true,
    },
    CM: {
        name: "Cameroon",
        documentName: "CNI or ID",
        format: /^[0-9]{8}$/,
        message: "Must be 8 digits.",
        required: true,
    },
    CL: {
        name: "Chile",
        documentName: "CI or RUT",
        format: /^[0-9A-Za-z]{8,9}$/,
        message: "Must be 8-9 characters.",
        required: true,
    },
    CN: {
        name: "China",
        documentName: "公民身份号码 (Citizen ID Number)",
        format: /^[0-9]{17}[0-9X]$/,
        message: "Must be 17 digits + X or 18 digits.",
        required: true,
    },
    CO: {
        name: "Colombia",
        documentName: "CC",
        format: /^[0-9]{6,11}$/,
        message: "Must be between 6 to 11 digits.",
        required: true,
    },
    CR: {
        name: "Costa Rica",
        documentName: "CI",
        format: /^[0-9]{9}$/,
        message: "Must be exactly 9 digits.",
        required: true,
    },
    DO: {
        name: "Dominican Republic",
        documentName: "ID",
        format: /^[0-9]{11}$/,
        message: "Must be exactly 11 digits.",
        required: false,
    },
    EC: {
        name: "Ecuador",
        documentName: "CI",
        format: /^[0-9]{5,20}$/,
        message: "Must be between 5 to 20 digits.",
        required: true,
    },
    SV: {
        name: "El Salvador",
        documentName: "DUI",
        format: /^[0-9]{9}$/,
        message: "Must be exactly 9 digits.",
        required: true,
    },
    EG: {
        name: "Egypt",
        documentName: "ID",
        format: /^[0-9]{14}$/,
        message: "Must be exactly 14 digits.",
        required: true,
    },
    GH: {
        name: "Ghana",
        documentName: "Ghana Card",
        format: /^[A-Za-z]{3}[0-9]{10}$/,
        message: "Must be 13 characters (3 letters + 10 numbers).",
        required: true,
    },
    GT: {
        name: "Guatemala",
        documentName: "CUI",
        format: /^[0-9]{13}$/,
        message: "Must be exactly 13 digits.",
        required: true,
    },
    HN: {
        name: "Honduras",
        documentName: "DNI",
        format: /^[0-9]{13}$/,
        message: "Must be exactly 13 digits.",
        required: true,
    },
    IN: {
        name: "India",
        documentName: "PAN",
        format: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        message: "Must be 10 characters (5 letters, 4 numbers, and 1 letter).",
        required: true,
    },
    ID: {
        name: "Indonesia",
        documentName: "NIK",
        format: /^[0-9]{16}$/,
        message: "Must be exactly 16 digits.",
        required: true,
    },
    CI: {
        name: "Ivory Coast",
        documentName: "National Identity Card (CNI)",
        format: /^(C[0-9]{10}|CI[0-9]{9})$/,
        message: "Must be C + 10 numbers or CI + 9 numbers.",
        required: true,
    },
    JP: {
        name: "Japan",
        documentName: "National Identification Number ('My Number')",
        format: /^[0-9]{12}$/,
        message: "Must be exactly 12 digits.",
        required: true,
    },
    KE: {
        name: "Kenya",
        documentName: "National ID Card",
        format: /^[0-9]{8}$/,
        message: "Must be exactly 8 digits.",
        required: true,
    },
    MY: {
        name: "Malaysia",
        documentName: "NRIC",
        format: /^[0-9]{12}$/,
        message: "Must be exactly 12 digits.",
        required: true,
    },
    MX: {
        name: "Mexico",
        documentName: "CURP",
        format: /^[A-Za-z0-9]{10,18}$/,
        message: "Must be between 10 to 18 characters.",
        required: true,
    },
    MA: {
        name: "Morocco",
        documentName: "CNIE",
        format: /^[A-Za-z0-9]{5,20}$/,
        message: "Must be between 5 to 20 characters.",
        required: true,
    },
    NI: {
        name: "Nicaragua",
        documentName: "DNI",
        format: /^[0-9]{13}[A-Za-z]{1}$/,
        message: "Must be 14 digits (13 numbers + 1 letter).",
        required: true,
    },
    NG: {
        name: "Nigeria",
        documentName: "NIN",
        format: /^[0-9]{11}$/,
        message: "Must be exactly 11 digits.",
        required: true,
    },
    PK: {
        name: "Pakistan",
        documentName: "CNIC",
        format: /^[0-9]{13}$/,
        message: "Must be exactly 13 digits.",
        required: true,
    },
    PA: {
        name: "Panama",
        documentName: "Cédula de Identidad",
        format: /^[0-9]{8}$/,
        message: "Must be exactly 8 digits.",
        required: false,
    },
    PY: {
        name: "Paraguay",
        documentName: "CI",
        format: /^[0-9]{5,20}$/,
        message: "Must be between 5 to 20 digits.",
        required: true,
    },
    PE: {
        name: "Peru",
        documentName: "DNI",
        format: /^[0-9]{8,9}$/,
        message: "Must be between 8 to 9 digits.",
        required: true,
    },
    PH: {
        name: "Philippines",
        documentName: "PSN (PhilSys Number)",
        format: /^[0-9]{12}$/,
        message: "Must be exactly 12 digits.",
        required: true,
    },
    RW: {
        name: "Rwanda",
        documentName: "National Identity Card",
        format: /^[0-9]{16}$/,
        message: "Must be exactly 16 digits.",
        required: true,
    },
    SA: {
        name: "Saudi Arabia",
        documentName: "National ID Card",
        format: /^[0-9]{10}$/,
        message: "Must be exactly 10 digits.",
        required: true,
    },
    SN: {
        name: "Senegal",
        documentName: "CNI or ECOWAS ID Card",
        format: /^[0-9]{13,17}$/,
        message: "Must be between 13 to 17 digits.",
        required: true,
    },
    ZA: {
        name: "South Africa",
        documentName: "South African Identity Card",
        format: /^[0-9]{13}$/,
        message: "Must be exactly 13 digits.",
        required: true,
    },
    TZ: {
        name: "Tanzania",
        documentName: "National Identity Card",
        format: /^[0-9]{20}$/,
        message: "Must be a unique 20-digit national ID number.",
        required: true,
    },
    TH: {
        name: "Thailand",
        documentName: "Thai Identity Card",
        format: /^[0-9]{13}$/,
        message: "Must be exactly 13 digits.",
        required: true,
    },
    TR: {
        name: "Turkey",
        documentName: "T.C. Kimlik No.",
        format: /^\d{5,20}$/,
        message: "Must be between 5 to 20 digits.",
        required: true,
    },
    UG: {
        name: "Uganda",
        documentName: "Ndaga Muntu - National ID Number (NIN)",
        format: /^[0-9]{14,17}$/,
        message: "Must be between 14 to 17 digits.",
        required: true,
    },
    UY: {
        name: "Uruguay",
        documentName: "CI or RUT",
        format: /^(?:[0-9]{6,8}|[0-9]{12})$/,
        message: "Must be between 6 to 8 digits or exactly 12 digits.",
        required: true,
    },
    VN: {
        name: "Vietnam",
        documentName: "VNID",
        format: /^[0-9]{9,13}$/,
        message: "Must be between 9 to 13 digits.",
        required: true,
    },
    ZM: {
        name: "Zambia",
        documentName: "National Registration Card",
        format: /^[0-9]{9}$/,
        message: "Must be exactly 9 digits (all numeric).",
        required: false,
    },
};