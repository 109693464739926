let base_url = '/client/v2';
// import Vue from 'vue';
import {bus} from '../../../../../bus';
import _ from 'lodash';

let actions = {
    fetchBookingList: async ({commit}, data) => {

        /**
         * Filter Customization:
         * Ensure 'data' is a valid object with a 'filter' property and customize it accordingly.
         * 
         * - Verify 'data' exists and is of type 'object' with a 'filter' property.
         * - Create a deep copy of 'data.filter' into 'customizedFilter' to avoid mutating original data.
         * - Convert specified array values(because multiselect component return array while server wants string) within 'customizedFilter' to comma-separated strings.
         * - Update 'data.filter' with the modified 'customizedFilter'.
         */
        if (data && typeof data === 'object' && 'filter' in data) {
            const customizedFilter = {
                ...data.filter
            };
            const keys = ['property_id', 'booking_status', 'precheckin', 'payment_status', 'filter_channel', 'sd_status'];
            keys.forEach((e, i) => {
                if (Array.isArray(customizedFilter[e])) {
                    customizedFilter[e] = customizedFilter[e].join(",");
                }
            })

            data.filter = customizedFilter;
        }

        commit('SHOW_LOADER', null, {root: true});

        return await axios({
            /* route name  get.bookings*/
            url: base_url+'/booking',
            method: 'POST',
            data
        }).then((resp) => {
            commit('FETCH_BOOKING_LIST', resp.data);
            commit('HIDE_LOADER', null, {root: true});
            //update intercom data
            updateIntercomData('update_bookings_data');
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
        commit('HIDE_LOADER', null, {root: true});

    },

    fetchBookingStatus:async ({commit,dispatch}, {id, type, counter = -1})=>{
        // Note: The counter is used to track the number of times this API is called from the Booking Listing page when the payment status of a booking is pending and in the queue.
        return new Promise((resolve, reject) => {
            axios({
                url: base_url + '/booking/' + id + '?type=' + type + '&count=' + counter,
                method: 'GET',
                headers: {
                    'X-Skip': true // To indicate skipping interceptor
                  }
            }).then((resp) => {
                console.log('watcherr request in action', resp);
                commit('FETCH_BOOKING_DETAIL', {'payload': resp.data, 'index': id});
                if(!_.isEmpty(resp.data.data.booking_detail.booking_list)) {
                    commit('UPDATE_BOOKING_LIST', {'payload': resp.data.data.booking_detail.booking_list, 'index': id});
                }
                resolve(resp);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    fetchBookingDetail: async ({commit, dispatch}, id) => {

        commit('RESET_BOOKING_DETAIL', {'index': id});
        //commit('SHOW_LOADER', null, {root: true});
        dispatch('general/fetchBookingPaymentSchedules',id, {root:true});
        //commit('HIDE_LOADER', null, {root: true});
        dispatch('general/fetchActivityLogs',id, {root:true});

        await axios({
            url: base_url+'/booking/' + id,
            method: 'GET'
        }).then((resp) => {
            //const currentDate2 = new Date();
            commit('FETCH_BOOKING_DETAIL', {'payload': resp.data, 'index': id});

            if(!_.isEmpty(resp.data.data.booking_detail.booking_list)) {
                commit('UPDATE_BOOKING_LIST', {'payload': resp.data.data.booking_detail.booking_list, 'index': id});
            }

            if (resp.data.data.booking_detail.id) {
                commit('UPDATE_SINGLE_BOOKING', {'payload': resp.data.data.booking_detail, 'index': id});
            }
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    fetchBookingPaymentSchedules: async ({commit, dispatch}, id) => {
        //payment_status
        await axios({
            url: base_url+'/booking-payment-schedules/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('FETCH_BOOKING_DETAIL_PAYMENT_SCHEDULE', {'payload': resp.data, 'index': id});
        }).catch((err) => {
            console.log(err);
        });
    },

    fetchInitialBookingData: async ({state, commit, dispatch}, booking_id=0) => {

        commit('SHOW_LOADER', null, {root: true});
        state.booking.add_booking.booking_id = booking_id;
        commit('RESET_ADD_BOOKING_FORM');
        return await axios({
            url: base_url+'/get-initial-booking-data/'+booking_id,
            method: 'GET'
        }).then((resp) => {
            commit('SET_INITIAL_BOOKING_DATA', resp.data);
            dispatch("fetchRentals", {
                id: resp.data.booking.property,
                options: {
                    filters: {
                        per_page: 9999,
                        search: "",
                        sortOrder: "ASC",
                        sortColumn: "id",
                        customSorting: true
                    },
                    sync: false,
                    page: 1
                }
            });

            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    fetchNationalities: async ({state, commit, dispatch}) => {

        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: base_url+'/get-supported-nationalities',
            method: 'GET'
        }).then((resp) => {
            commit('SET_NATIONALITIES', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    fetchBookingLanguages: async ({state, commit, dispatch}) => {

        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: base_url+'/get-supported-booking-languages',
            method: 'GET'
        }).then((resp) => {
            commit('SET_BOOKING_LANGUAGES', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },



    saveBooking: async ({state, commit, dispatch}) => {

        commit('SHOW_LOADER', null, {root: true});
        let data = state.booking.add_booking;

        await axios({
            url: base_url+'/save-booking',
            method: 'POST',
            data
        }).then((resp) => {
            if(resp.data.status){
                bus.emit('reload_booking_list', true);
                toastr.success(resp.data.message);
                commit('RESET_ADD_BOOKING_FORM');
                document.getElementById('add_booking_close_modal').click();
            }else{
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if(!_.isEmpty(errors)) {
                if (typeof errors['status'] != "undefined" && errors.status == 422) {
                    if (errors.data) {
                        for (let k1 in errors.data) {
                            if (typeof errors.data[k1] == "object") {
                                let validation_errors = errors.data[k1];
                                for (let k2 in validation_errors) {
                                    error_message[k2] = validation_errors[k2][0];
                                    error_status[k2] = true;
                                }
                            }
                        }
                    }
                }
            }
            commit('SET_ADD_BOOKING_ERROR_MESSAGES', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });
    },
};

export default actions;
