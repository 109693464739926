import moment from "moment";
export const init_custom_question_errors = () => {
    return {'title': false, 'answer_type': false, 'options': []}
};

export const init_state = () => {
    return {
        custom_question_id:0,
        publish_status: true,
        question_type_id: 9, //Set default to short-text
        selected_type_options:{},
        backend_name: 'short_text',
        form_data: {
            type: 'textarea',
            min_characters: 3,
            max_characters: 60,
            question: '',
            guest_answer: '',
            required: true,
            editable: true,
        },
        map_to_pms_key :{
            key:"",
            belongsTo:"pms_reservation_keys",
        },
        attached_booking_sources:[{"name": "All Booking Source", "id": 0}],
        selected_properties:[],
        connected_tags:[]
    };
};

/** Main STATE */
let state = {

    question_group_form_data: {
        serve_id: 0,
        title: '',
        description: '',
        status: true,
        is_map_response_to_pms: 1,
        questions: [],
    },

    question_types: [],
    pms_reservation_keys: [],
    custom_question: init_state(),
    custom_question_index: -1, //serve_id_custom_question
    active_custom_question_id:0,
    custom_question_errors: init_custom_question_errors(),
    question_groups: [],
};

export default state;