let mutations = {

    SET_BILLING_INFORMATION(state, payload) {
        return state.billing = {...state.billing, ...payload};
    },

    SET_PAYMENT_DETAILS(state,payload){
        return state.billing.billingInfo.paymentDetails = payload;
    },
    SET_IS_CARD_AVAILABLE(state,payload){
        return state.billing.is_card_available = payload;
    },

    SET_THREE_DS_LINK(state,payload){
        return state.billing.threeDS_invoice_link = payload;
    },
    SET_THREE_DS_INVOICE_ID(state,payload){
        return state.billing.threeDS_invoice_id = payload;
    },
    SET_THREE_DS_PAYMENT_INTENT(state,payload){
        return state.billing.threeDS_payment_intent_id = payload;
    },
    SET_THREE_DS_PI_CLIENT_SECRET(state,payload){
        return state.billing.threeDS_pi_client_secret = payload;
    },
    SET_IS_IDENTITY_PLAN_SUBSCRIBED(state,payload){
        return state.billing.is_identity_verification_plan_subscribed = payload;
    },
    SET_IDENTITY_VERIFICATION_PLANS(state,payload){
        state.billing.identity_verification_plans = payload;
        return state.billing.selected_identity_verification_plan = state.billing.identity_verification_plans[0] ?? [];
    },
    SET_IS_SMART_LOCK_PLAN_SUBSCRIBED(state,payload){
        return state.billing.is_smart_lock_plan_subscribed = payload;
    },
    SET_SMART_LOCK_PLANS(state,payload){
        state.billing.smart_lock_plans = payload;
        state.billing.selected_smart_lock_plan = state.billing.smart_lock_plans[0] ?? [];
        return state.billing.is_smart_lock_plan_subscribed = state.billing.selected_smart_lock_plan.is_subscribed ?? false;
    },

};

export default mutations;