
import tac from './client/termAndConditions/state';
import paymentRequests from './client/paymentRequests/state';
import reuseable_payment_request from './reuseable_payment_request/state';
import guideBook from './client/guideBooks/state';
import guideBookTypes  from  './client/guideBooks/types/state';
import upsell from './client/upsell/state';
import upsellRequests from './client/upsellRequests/state';
import upsellTypes from './client/upsell/types/state';
import client_list_select_properties from './client/client_list_select_properties/state';
import emails_client from "./client/emails/state";
import sms_client from "./client/sms/state";
import auth from './auth/state';
import team_member from './client/teammember/state';
import notification from './client/notifications/state';
import chat from './client/chat/state';
import pre_checkin from './guest/pre_checkin/state';
import guest_portal from './guest/guest_portal/state';
import booking_detail from './client/booking_detail/state';
import iframe_booking_detail from './client/iframe_booking_detail/state';
import state from './general_root_state';
import payment_request from './payment_request_functionality/payer/states';
import questions from './client/questions/state';
import booking from './client/booking/state';
import dashboard from './client/dashboard/state';
import billing from './client/billing/state';
import property from './client/property/state';
import tags from './client/tags/state';


let module_state = {
    ...state,
    team_member,
    tac,
    upsell,
    upsellRequests,
    client_list_select_properties,
    upsellTypes,
    guideBook,
    guideBookTypes,
    emails_client,
    sms_client,
    auth,
    notification,
    chat,
    pre_checkin,
    guest_portal,
    booking_detail,
    iframe_booking_detail,
    paymentRequests,
    reuseable_payment_request,
    state,
    questions,
    booking,
    dashboard,
    billing,
    property,
    languageList: [{
        text: "English",
        value: "English",
        flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gb.svg",
        lang: "en",
        iso: "eng"
        },
        {
            text: "Spanish",
            value: "Spanish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/es.svg",
            lang: "es",
            iso: "spa"
        },
        {
            text: "French",
            value: "French",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/fr.svg",
            lang: "fr",
            iso: "fre"
        },
        {
            text: "Portuguese",
            value: "Portuguese",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/pt.svg",
            lang: "pt",
            iso: "por"
        },
        {
            text: "Italian",
            value: "Italian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/it.svg",
            lang: "it",
            iso: "ita"
        },
        {
            text: "Japanese",
            value: "Japanese",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/jp.svg",
            lang: "ja",
            iso: "jpn"
        },
        {
            text: "Bulgarian",
            value: "Bulgarian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/bg.svg",
            lang: "bg",
            iso: "bul"
        },
        {
            text: "Croatian",
            value: "Croatian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/hr.svg",
            lang: "hr",
            iso: "hrv"
        },
        {
            text: "Czech",
            value: "Czech",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/cz.svg",
            lang: "cs",
            iso: "ces"
        },
        {
            text: "Danish",
            value: "Danish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/dk.svg",
            lang: "da",
            iso: "dan"
        },
        {
            text: "Dutch",
            value: "Dutch",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/nl.svg",
            lang: "nl",
            iso: "nld"
        },
        {
            text: "Estonian",
            value: "Estonian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ee.svg",
            lang: "et",
            iso: "est"
        },
        {
            text: "Finnish",
            value: "Finnish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/fi.svg",
            lang: "fi",
            iso: "fin"
        },
        {
            text: "German",
            value: "German",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/de.svg",
            lang: "de",
            iso: "deu"
        },
        {
            text: "Greek",
            value: "Greek",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gr.svg",
            lang: "el",
            iso: "ell"
        },
        {
            text: "Hungarian",
            value: "Hungarian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/hu.svg",
            lang: "hu",
            iso: "hun"
        },
        {
            text: "Irish",
            value: "Irish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ie.svg",
            lang: "ga",
            iso: "gle"
        },
        {
            text: "Latvian",
            value: "Latvian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/lv.svg",
            lang: "lv",
            iso: "lav"
        },
        {
            text: "Lithuanian",
            value: "Lithuanian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/lt.svg",
            lang: "lt",
            iso: "lit"
        },
        {
            text: "Maltese",
            value: "Maltese",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/mt.svg",
            lang: "mt",
            iso: "mlt"
        },
        {
            text: "Polish",
            value: "Polish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/pl.svg",
            lang: "pl",
            iso: "pol"
        },
        {
            text: "Romanian",
            value: "Romanian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ro.svg",
            lang: "ro",
            iso: "ron"
        },
        {
            text: "Slovak",
            value: "Slovak",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/sk.svg",
            lang: "sk",
            iso: "slk"
        },
        {
            text: "Slovenian",
            value: "Slovenian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/si.svg",
            lang: "sl",
            iso: "slv"
        },
        {
            text: "Korean",
            value: "Korean",
            flag: "https://freesvg.org/img/jp-draws-South-Korean-Flag.png",
            lang: "ko",
            iso: "kor"
        },
        {
            text: "Chinese",
            value: "Chinese",
            flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
            lang: "zh-CN",
            iso: "Chi"
        },
        {
            text: "Russian",
            value: "Russian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ru.svg",
            lang: "ru",
            iso: "rus"
        },
        {
            text: "Arabic",
            value: "Arabic",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/sa.svg",
            lang: "ar",
            iso: "ara"
        },
        {
            text: "Hindi",
            value: "Hindi",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/in.svg",
            lang: "hi",
            iso: "hin"
        },
        // {
        //   text: "Punjabi",
        //   value: "Punjabi",
        //   flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/in.svg",
        //   lang: "pa",
        //   iso: "pan"
        // },
        {
            text: "Telugu",
            value: "Telugu",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/in.svg",
            lang: "te",
            iso: "tel"
        },
        {
            text: "Tamil",
            value: "Tamil",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/in.svg",
            lang: "ta",
            iso: "tam"
        },
        {
            text: "Marathi",
            value: "Marathi",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/in.svg",
            lang: "mr",
            iso: "mar"
        },
        {
            text: "Gujarati",
            value: "Gujarati",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/in.svg",
            lang: "gu",
            iso: "guj"
        },
        {
            text: "Bengali",
            value: "Bengali",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/bd.svg",
            lang: "bn",
            iso: "bn"
        },
        {
            text: "Urdu",
            value: "Urdu",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/pk.svg",
            lang: "ur",
            iso: "urd"
        },
        {
            text: "Indonesian",
            value: "Indonesian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/id.svg",
            lang: "id",
            iso: "ind"
        },
        {
            text: "Javanese",
            value: "Javanese",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/id.svg",
            lang: "jw",
            iso: "jav"
        },
        {
            text: "Vietnamese",
            value: "Vietnamese",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/vn.svg",
            lang: "vi",
            iso: "vie"
        },
        {
            text: "Turkish",
            value: "Turkish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/tr.svg",
            lang: "tr",
            iso: "tur"
        },
        {
            text: "Thai",
            value: "Thai",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/1x1/th.svg",
            lang: "th",
            iso: "tha"
        },
        {
            text: "Ukrainian",
            value: "Ukrainian",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/4x3/ua.svg",
            lang: "uk",
            iso: "ukr"
        },
        {
            text: "Norwegian ",
            value: "Norwegian ",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/4x3/no.svg",
            lang: "no",
            iso: "nor"
        },
        {
            text: "Swedish",
            value: "Swedish",
            flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.11.0/flags/4x3/se.svg",
            lang: "sv",
            iso: "swe"
        },        
    ],
    tags,
    isNationalityChanged: false,
};

export default module_state;