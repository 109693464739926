import { defineAsyncComponent } from 'vue';
const admin_base_path = './components/general/admin';


// Register auth components
export function registerAdminComponents(app) {
    app.component('admin-create-new-pms-files-page', defineAsyncComponent(() => import('@cac-adm/CreateNewPmsFiles.vue')));
    app.component('admin-booking-list-page', defineAsyncComponent(() => import('@cac-adm/bookings/AdminBookingListPage.vue')));
    app.component('admin-booking-detail', defineAsyncComponent(() => import('@cac-adm/bookings/AdminBookingDetail.vue')));
    app.component('transaction-detail-modal', defineAsyncComponent(() => import('@cac-adm/reusables/TransactionDetailModal.vue')));
    app.component('booking-property-detail-modal', defineAsyncComponent(() => import('@cac-adm/reusables/BookingPropertyDetailModal.vue')));
    app.component('booking-payment-gateway-detail-modal', defineAsyncComponent(() => import('@cac-adm/reusables/BookingPaymentGatewayDetailModal.vue')));
    app.component('booking-ccinfo-detail-modal', defineAsyncComponent(() => import('@cac-adm/reusables/BookingCCInfoDetailModal.vue')));

    // email
    app.component('test-email', defineAsyncComponent(() => import('@cac-adm/TestEmail/TestEmail.vue')));

    //properties
    app.component('property-list', defineAsyncComponent(() => import('@cac-adm/properties/PropertyList.vue')));
    app.component('property-detail', defineAsyncComponent(() => import('@cac-adm/properties/PropertyDetail.vue')));

    app.component('emails', defineAsyncComponent(() => import('@cac-adm/emails/emails.vue')));

    //Stripe
    app.component('assign-plans-to-user', defineAsyncComponent(() => import('@cac-adm/stripeCommissionBilling/AssignPlansToUser.vue')));

    //User Accounts
    app.component('user-account-list', defineAsyncComponent(() => import('@cac-adm/userAccounts/UserAccountList.vue')));
    app.component('user-list', defineAsyncComponent(() => import('@cac-adm/userAccounts/UserList.vue')));

    //PMS create booking source enable disable
    app.component('pms-bs-enable-disable', defineAsyncComponent(() => import('@cac-adm/settings/PMSCreateBookingSourceOnOff.vue')));
    app.component('manage_booking_source', defineAsyncComponent(() => import('@cac-adm/booking_sources/ManageBookingSource.vue')));
    app.component('admin-add-booking-source-modal', defineAsyncComponent(() => import('@cac-adm/booking_sources/PMSAddBookingSourceModal.vue')));

    //Admin Listings
    app.component('admin-list', defineAsyncComponent(() => import('@cac-adm/admins/AdminList.vue')));
    app.component('admin-admin-modal', defineAsyncComponent(() => import('@cac-adm/admins/AddAdminModal.vue')));
    app.component('edit-admin-modal', defineAsyncComponent(() => import('@cac-adm/admins/EditAdminModal.vue')));

    //Terms And Conditions
    app.component('term-list', defineAsyncComponent(() => import('@cac-adm/termsAndConditions/termAndConditionList.vue')));

    //Guide Books
    app.component('guide-book-list', defineAsyncComponent(() => import('@cac-adm/guideBooks/guideBookList.vue')));
    app.component('guide-book-type-list', defineAsyncComponent(() => import('@cac-adm/guideBooks/guideBookTypeList.vue')));

    //Upsells
    app.component('upsell-list', defineAsyncComponent(() => import('@cac-adm/upsells/upsellList.vue')));
    app.component('upsell-templates-list', defineAsyncComponent(() => import('@cac-adm/upsells/upsellTemplateList.vue')));
    app.component('admin-add-edit-upsell-template', defineAsyncComponent(() => import('@cac-adm/upsells/AddEditUpsellTemplateModal.vue')));
    app.component('upsell-type-list', defineAsyncComponent(() => import('@cac-adm/upsells/upsellTypeList.vue')));
    app.component('upsell-order-list', defineAsyncComponent(() => import('@cac-adm/upsells/upsellOrderList.vue')));
    app.component('upsell-image-library', defineAsyncComponent(() => import('@cac-adm/upsells/UpsellImageLibrary.vue')));

    //Audits
    app.component('audit-page', defineAsyncComponent(() => import('@cac-adm/audits/AuditPage.vue')));

    // PMS requests: Impala, SMX
    app.component('impala-requests', defineAsyncComponent(() => import('@cac-adm/pms_requests/ImpalaRequests.vue')));
    app.component('smx-requests', defineAsyncComponent(() => import('@cac-adm/pms_requests/SmxRequests.vue')));


    //Pms ModificationKeys
    app.component('pms-modification-keys-mapping', defineAsyncComponent(() => import('@cac-adm/pmsKeys/PmsModificationKeysMapping.vue')));

    //Pms Modification and Questionnair
    app.component('pms-modification-and-questionnair', defineAsyncComponent(() => import('@cac-adm/pmsKeys/PmsModificationAndQuestionnaireKeysFilters.vue')));

    //Pms Actions
    app.component('generate-pms-form', defineAsyncComponent(() => import('@cac-adm/pms/GeneratePmsForm.vue')));
    app.component('generate-endpoint-form', defineAsyncComponent(() => import('@cac-adm/pms/GenerateEndpointForm.vue')));


    // Platform Fee
    app.component('platform-fee-default', defineAsyncComponent(() => import('@cac-adm/platform_fee/PlatformFeeDefault.vue')));
    app.component('platform-fee-custom', defineAsyncComponent(() => import('@cac-adm/platform_fee/PlatformFeeCustom.vue')));

    //Admin Dashboard
    app.component('dashboard', defineAsyncComponent(() => import('@cac-adm/dashboard/Dashboard.vue')));
    app.component('update-property-gateway', defineAsyncComponent(() => import('@cac-adm/settings/ChangePaymentGateway.vue')));
}
