import { defineAsyncComponent } from 'vue';

// Import specific component groups
import { registerAuthComponents } from './auth.js';
import { registerClientComponents } from './client.js';
import { registerGuestComponents } from './guest.js';
import { registerCoreComponents } from './regs/core.js';
import { registerAdminComponents } from './admin.js';
import { registerPartnerComponents } from './partner.js';
import { registerUIComponents } from './regs/ui.js';


export function registerGlobalComponents(app) {
    // Register components
    registerAuthComponents(app);
    registerClientComponents(app);
    registerGuestComponents(app);
    registerCoreComponents(app);
    registerAdminComponents(app);
    registerPartnerComponents(app);
    registerUIComponents(app);
}