/** Payment Request Component State*/

export const init_state = ()=> {
    return {
        email: '',
        currency_id: null,
        amount: 0,
        due_date: '',
        expiry_date: '',
        is_3ds: false,
        terms_and_conditions: '',
        description: '',
        payment_type: 'C',
        request_id: '',
        button_type_clicked: 'send_payment_request',

        error_message: {
            email: '',
            currency_id: '',
            amount: '',
            due_date: '',
            expiry_date: '',
            is_3ds: '',
            terms_and_conditions: '',
            description: '',
        },
        error_status: {
            email: false,
            currency_id: false,
            amount: false,
            due_date: false,
            expiry_date: false,
            is_3ds: false,
            terms_and_conditions: false,
            description: false
        }
    }
};

const filters = {
    recordsPerPage: 10,
    page: 1,
    columns: '*',
    relations: [],
    sort: {
        sortOrder: 'Desc',
        sortColumn: 'id',
    },
    payment_status: 'all',
    payment_status_name: '',
    constraints: [],
    search: {
        searchInColumn: ['id', 'request_id', 'amount'],
        searchStr: ''
    },
    payment_type: 'all',
    filter_count: 0,
    // Date Filters below
    due_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
    created_date: {
        is_custom_date: false,
        date: '',
        dateOne: '',
        dateTwo: '',
    },
}; //Datatable filters Object End

const created_payment_link = {
    payment_request_id: '',
    payment_request_request_id: '',
    payment_request_link: '',
    payment_request_schedule_datetime: '',
    payment_request_customer_email: '',
    payment_request_customer_id: ''
};

/** Main STATE */
let state = {
    form_data: init_state(),
    paginationResponse: {}, // List Records With Pagination Meta & Links
    filters: filters, // ALL Regarding Filters for datatable.
    initial_state: init_state(),
    created_payment_link: created_payment_link,
    supported_currencies: [

    ],
    payment_request_active_link: '',
    if_chargeback_supported: false,
    is_pre_authorization_supported: true
};
export default state;