import { getTranslatedMessage } from '../../helpers'
 
let actions = {

    fetchGuestPortalData: async ({commit}, id) => {
        
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/guest-portal/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('GUEST_PORTAL_DATA', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    fetchAddCardTerminalData: async ({commit}, payload) => {
        commit('SHOW_LOADER', null, {root: true});
        
        let result = await axios({
            url: '/v2/fetch-add-card-terminal-data',
            method: 'POST',
            data: payload
        }).then((resp) => {
            commit('HIDE_LOADER', null, {root: true});
            return resp.data.data.pgTerminal;
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
            return 'Something went wrong. Try again.';
        });
        
        return result;
    },

    saveGuestPanelCardData: async ({commit, dispatch}, payload) => {
        commit('SHOW_LOADER', null, {root: true});        

        let all_error_messages = window.app.$t("GENERAL.ADD_CREDIT_CARD.ERRORS");

        await axios({
            url: '/v2/update-guest-card',
            method: 'POST',
            data: payload
        }).then((resp) => {

            if (resp.data.status_code == 200 && resp.data.status == true) {
                dispatch('general/fetchGuestPortalData', payload.booking_info_id, {root: true});
                commit('GUEST_CARD_UPDATE');
                if(resp.data.message != null){
                    const key = `GENERAL.ADD_CREDIT_CARD.ERRORS.${resp.data.message}`;
                    let message = getTranslatedMessage(key, resp.data.message);
                    toastr.success(message);
                }
                commit('HIDE_LOADER', null, {root: true});
            }
            // For status code '102' further processing is required
            else if(resp.data.status == true && resp.data.status_code == 102){
                // call on session further processing
                
                dispatch('general/onSessionChargeAuth', {'booking_info_id': payload.booking_info_id, 'meta': payload.meta, 'source': 'guest_portal'}, {root: true});
                // No need to hide loader in this case
            } else{

                if(resp.data.status_code == 440) {
                    if(resp.data.data.require_3ds) {
                        commit('SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url);
                    }
                }
                const key = `GENERAL.ADD_CREDIT_CARD.ERRORS.${resp.data.message}`;
                let message = getTranslatedMessage(key, resp.data.message);
                toastr.error(message);
                commit('HIDE_LOADER', null, {root: true});
            }

            commit('TOGGLE_CARD_ERRORS', {error_message: {}, error_status: {}});

        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                let message = all_error_messages[validation_errors[k2][0]] ? all_error_messages[validation_errors[k2][0]] : validation_errors[k2][0];
                                error_message[k2] = message;
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('TOGGLE_CARD_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    saveBasicInfo: async ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        commit('BASIC_INFO_SECTION_DETAIL_ERRORS', {});

        await axios({
            url: '/v2/update-basic-info',
            method: 'POST',
            data
        }).then((resp) => {
            if (resp.data.status) {
                const key = `GUEST_PORTAL.ERRORS.${resp.data.message}`
                toastr.success(getTranslatedMessage(key, resp.data.message));
                commit('BASIC_INFO_UPDATE', data);
                dispatch('getBasicInfoTabData', data.booking_id);
                dispatch('fetchGuestPortalData', data.booking_id);
            } else {
                if(resp.data.status_code == 422){
                    let error_message = resp.data.data;
                    commit('BASIC_INFO_SECTION_DETAIL_ERRORS', {error_message});
                    toastr.error(resp.data.message);
                }else{
                    const key = `GUEST_PORTAL.ERRORS.${resp.data.message}`;
                    toastr.error(getTranslatedMessage(key, resp.data.message));
                }
            }
            commit('TOGGLE_BASIC_INFO_ERRORS', {error_message: {}, error_status: {}});
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {

            let errors = err.response;
            let error_message = {};
            let error_status = {};
            if (errors.status == 422) {
                if (errors.data) {
                    for (let k1 in errors.data) {
                        if (typeof errors.data[k1] == "object") {
                            let validation_errors = errors.data[k1];
                            for (let k2 in validation_errors) {
                                error_message[k2] = all_error_messages[validation_errors[k2][0]];
                                error_status[k2] = true;
                            }
                        }
                    }
                }
            }
            commit('TOGGLE_BASIC_INFO_ERRORS', {error_message, error_status});
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    deleteImage: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/guest-image-delete',
            method: 'POST',
            data
        }).then((resp) => {
            if (resp.data.status) {
                commit('IMAGES_UPDATED', resp.data.data);
            } else {
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveImageDocument: async ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/v2/guest-images/',
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((resp) => {
            if (resp.data.status == true) {
                const message = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${resp.data.message}`, resp.data.message);
                toastr.success(message);
                commit('IMAGES_UPDATED', { request_response: resp.data.data, image_type: data.get('alert_type') });
            } else {
                const errorMessage = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${resp.data.message}`, resp.data.message)
                toastr.error(errorMessage);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((error) => {
            let response = error.response;
            if(response.status == 422) {
                if (response.data) {
                    let errors = response.data.errors;
                    for(let key in errors){
                        if(errors.hasOwnProperty(key)){
                            const errorMessage = getTranslatedMessage(`GENERAL.UPLOADED_DOCUMENT.ERRORS.${errors[key]}`, errors[key]);
                            toastr.error(errorMessage);
                        }
                    }
                }
            }

            console.log(error);
            commit('HIDE_LOADER', null, {root: true});
        });

    },

    purchaseAddOnService: async  ({commit, dispatch }, data)=> {

        commit('SHOW_LOADER', null, {root: true});
        return new Promise((resolve, reject) => {
            axios({
                url: '/v2/purchase-add-on-service/',
                method: 'POST',
                data,
            }).then((resp) => {
                if (resp.data.status == true) {
                    const key = `GUEST_PORTAL.ERRORS.${resp.data.message}`
                    toastr.success(getTranslatedMessage(key, resp.data.message));
                    dispatch('fetchAddOnServices', {id: data.booking_info_id, source: 'guest_portal', current_step: "step_4"});
                    //update intercom data
                    if (typeof updateIntercomData === 'function') {
                        updateIntercomData('upsell_listing_page_loaded');
                    }
                    
                } else {
                    if(resp.data.status_code == 440){
                        if(resp.data.data.require_3ds) {
                            commit('SHOW_3DS_MODAL_BOX', resp.data.data.iframe_url);
                            // toastr.warning(all_error_messages[resp.data.message]);
                            dispatch('emitToastMessage', {toastType: 'warning', messageKey: resp.data.message})
                        }
                    } else {
                        const message = getTranslatedMessage(`GUEST_PORTAL.ERRORS.${resp.data.message}`, resp.data.message)
                        toastr.error(message);
                    }
                }
                
                commit('HIDE_LOADER', null, {root: true});

                resolve(resp);
                
            }).catch((error) => {
                console.log(error);
                commit('HIDE_LOADER', null, {root: true});
                reject(error);
            });
        });

    },

    fetchPaymentSummary: async ({commit}, id) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/payment-summary/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('SET_PAYMENT_SUMMARY', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    /**
     *  Get 3DS challenge window link from BE.
     *  If PG is stripe and guest tried to attempt the 3DS challenge window more than 2 time and failed
     *  then CA will auto generate the new intent and challenge url moreover void the old intent on PG
     * @param commit
     * @param data
     * @returns {Promise<*>}
     */
    getIntentAuthenticationUrl: async ({commit}, data) => {

        return new Promise((resolve, reject) => {
            let authentication_link = data.authentication_link;
            commit('SHOW_LOADER', null, {root: true});
              axios({
                url: '/v2/get-intent-authentication-url/',
                method: 'POST',
                data:data
            }).then((resp) => {
                //console.log([data, resp.data]);
                if(resp.data.status && resp.data.data && resp.data.data.status) {
                    if (resp.data.data.authentication_url) {
                        authentication_link = resp.data.data.authentication_url;
                    } else if (resp.data.data.message) {
                        authentication_link = null;
                        setTimeout(() => {
                            window.parent.location.reload();
                        }, 1500);
                        toastr.success(resp.data.data.message);
                    }

                } else if(resp.data.data && resp.data.data.message){
                    toastr.error(resp.data.data.message);
                }
                 commit('HIDE_LOADER', null, {root: true});
                 resolve({authentication_link:authentication_link});

             }).catch((err) => {
                commit('HIDE_LOADER', null, {root: true});
                console.log(err);
                 resolve({authentication_link:authentication_link});

             });

        });
    },

    getBasicInfoTabData: async ({commit}, id) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/get-basic-info-tab-data/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('SET_BASIC_INFO_TAB_DATA', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    fetchGuidebooks: async ({commit}, id) => {
        // commit('SHOW_LOADER', null, {root: true});
        return await axios({
            url: '/v2/guest-portal/' + id + '/guidebooks',
            method: 'GET',
            headers: {
                'X-Skip': true // To indicate skipping interceptor
            },
        }).then((resp) => {
            if (resp.data.data) {
                commit('SET_GUIDEBOOKS', Object.values(resp.data.data));   
            }
        }).catch((err) => {
            console.log(err);
            // commit('HIDE_LOADER', null, {root: true});
        });
    },

    getPaymentDetailTabData: async ({commit}, id) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/v2/get-payment-detail-tab-data/' + id,
            method: 'GET'
        }).then((resp) => {
            commit('SET_PAYMENT_DETAIL_TAB_DATA', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    emitToastMessage ({}, {toastType, messageKey}) {
        toastr[toastType](window.app.$t(`GUEST_PORTAL.ERRORS.${messageKey}`));
    }

};


export default actions;
