let mutations = {
    RESERVATION_RAW_KEYS(state, payload) {
        state.reservation_raw_keys = payload;
        // console.warn(state.reservation_raw_keys);
        return state
    },
    RESERVATION_KEYS_JSON(state, payload) {
        state.reservation_keys_json = payload;
        // console.warn(state.reservation_raw_keys);
        return state
    },
    PROPERTY_RAW_KEYS(state, payload) {
        state.property_raw_keys = payload;
        // console.warn(state.reservation_raw_keys);
        return state
    },
    PROPERTY_KEYS_JSON(state, payload) {
        state.property_keys_json = payload;
        // console.warn(state.reservation_raw_keys);
        return state
    },
    KEYS_FOR_REASERVATION(state, payload){
        state.rKeys = payload;
        return state;
    },
    KEYS_FOR_PROPERTY(state, payload){
        state.pKeys = payload;
        return state;
    },
    WRITE_ACCESS(state, payload){
        state.write_access = payload;
        return state;
    },
    SET_PMS_PREFERENCES(state, payload){
        state.pms_preferences_data = payload.data;
        return state;
    },
    SET_PMS_NAMES (state , payload){
        state.pms_names = payload;
        return state;
    },
    SET_QUESTIONNAIRE_KEYS(state, payload){
        state.questionnaire_keys = payload;
        return state;
    },
    CLEAR_QUESTIONNAIRE_KEYS(state){
        state.questionnaire_keys = [];
        return state;
    }
};

export default mutations;
