import {ccRiskScore} from './constants';

const PG_TERMINAL = {
    cc_form_name: '',
    is_token: false,
    is_redirect: false,
    redirect_link: '',
    public_key: '',
    client_secret: '',
    account_id: '',
    first_name: '',
    last_name: '',
    booking_id: '',
    with3DsAuthentication: true,
    show_authentication_button: false,
    attempt_charge: false,
    src: '',
    terminal: '',
    card_terminal_additional_fields: [],
    additional_fields_errors: [],
    user_pg_id: null,
    caller: '',
}

let general_root_mutations = {

    SET_COMPANY_TIMEZONE(state, payload) {
        state.company_timezone = payload;
        state.is_company_timezone_set = true;
        return state;
    },

    REFRESH_BOOKING_SOURCES(state, payload) {
        return state.refresh_booking_sources = payload;
    },
    REFUND_AMOUNT_ACTIVE_ID(state, payload) {
        state.refund_amount_active_booking_id = payload.id;
        state.disable_partial_refund = payload.disable_partial_refund;
        return state;
    },
    REFUND_AMOUNT_ACTIVE_TRANSACTION_ID(state, payload) {
        return state.refund_amount_active_transaction_id = payload;
    },

    REFUND_AMOUNT_ACTIVE_PAYMENT_REQUEST_ID(state, payload) {
        return state.refund_amount_active_payment_request_id = payload;
    },
    REFUND_AMOUNT_ACTIVE_REUSABLE_PAYMENT_REQUEST_ID(state, payload) {
        return state.refund_amount_active_reusable_payment_request_id = payload;
    },
    REFUND_AMOUNT_MAX_VALID_VALUE(state, payload) {
        return state.amount_valid_to_refund = payload;
    },

    REFUND_UPSELL_ACTIVE_ID(state, payload) {
        return state.upsell_refund_active_order_detail_id = payload;
    },
    REFUND_UPSELL_MAX_VALID_VALUE(state, payload) {
        return state.upsell_amount_valid_to_refund = payload;
    },
    REFUND_UPSELL_ACTIVE_BOOKING_ID(state, payload) {
        return state.upsell_refund_active_booking_id = payload;
    },

    GUEST_CREDIT_CARD_ID(state, payload) {
        state.guest_credit_card_id = payload.booking_id;
        state.info_of_credit_card = payload;
        return state;
    },
    ADDITIONAL_CHARGE_ACTIVE_ID(state, payload) {

        state.additional_charge_active_booking_id = payload;
        return state;
    },
    TOGGLE_SAVE_CARD(state, payload) {
        state.addNewCardOnly = payload;
    },
    BOOKIN_ID_ACTION_CHAT(state, payload) {
        state.booking_id_action_chat = payload;
        return state;
    },

    BOOKING_NEW_BALANCE_AND_TOTAL(state, payload) {
        state.reduce_amount_data.new_amount = payload.data.new_amount;
        state.reduce_amount_data.new_balance = payload.data.new_balance;
        return state;
    },
    BOOKING_NEW_SD_AMOUNT(state, payload) {
        state.change_sd_amount_data.new_amount = payload.data.new_amount;
        return state;
    },
    GUEST_UPLOAD_DOC_ID(state, payload) {
        state.guest_upload_doc_id = payload;
        return state;
    },
    REDUCE_AMOUNT_DATA(state, payload) {
        state.reduce_amount_data.booking_id = payload.booking_id;
        state.reduce_amount_data.tran_id = payload.tran_id;
        state.reduce_amount_data.current_amount = payload.current_amount;
        return state;
    },


    CHANGE_AMOUNT_DATA(state, payload) {
        state.change_amount_data.booking_id = payload.booking_id;
        state.change_amount_data.tran_id = payload.tran_id;
        state.change_amount_data.current_amount = payload.current_amount;
        return state;
    },

    
    CHANGE_SD_AMOUNT_DATA(state, payload) {
        state.change_sd_amount_data.booking_id = payload.booking_id;
        state.change_sd_amount_data.cc_auth_id = payload.cc_auth_id;
        state.change_sd_amount_data.current_amount = payload.current_amount;
        return state;
    },

    CHANGE_DUE_DATE_DATA(state, payload) {
        state.change_due_date_data = payload;
        return state;
    },

    CAPTURE_AMOUNT_ACTIVE_BOOKING_ID(state, payload) {
        state.capture_amount_active_booking_id = payload.id;
        state.disable_partial_capture_and_release = payload.disable_partial_capture_and_release
        return state;
    },

    CAPTURE_AMOUNT_ACTIVE_AUTH_ID(state, payload) {
        state.capture_amount_active_cc_auth_id = payload;
        return state;
    },

    CAPTURE_AMOUNT_MAX_VALID_VALUE(state, payload) {
        state.amount_valid_to_capture = payload;
        return state;
    },

    CAPTURE_AMOUNT_ACTIVE_PAYMENT_REQUEST_ID(state, payload) {
        state.capture_amount_active_payment_request_id = payload;
        return state;
    },

    CAPTURE_AMOUNT_ACTIVE_REUSABLE_PAYMENT_REQUEST_ID(state, payload) {
        state.capture_amount_active_reusable_payment_request_id = payload;
        return state;
    },

    CAPTURE_AMOUNT_ACTIVE_MODAL_TITLE(state, payload) {
        state.capture_amount_active_modal_title = payload;
        return state;
    },

    SHOW_CAPTURE_AMOUNT_ERRORS(state, {error_message, error_status}) {

        if (error_message !== {}) {
            state.capture_amount.error_message = {...state.capture_amount.error_message, ...error_message};
        }
        if (error_status !== {}) {
            state.capture_amount.error_status = {...state.capture_amount.error_status, ...error_status};
        }
        return state;
    },


    SHOW_TOAST_MESSAGE(state, payload) {
        if (payload.status == true) {
            toastr.success(payload.msg);
        } else {
            toastr.error(payload.msg);
        }
    },

    SET_GUEST_DOCUMENT(state, payload){
        return state.images = payload.images;
    },

    SET_GUEST_EXPERIENCE_SETTING(state, payload){
        return state.meta = payload.meta;
    },

    CAN_SYNC_BOOKING(state, payload){
        return state.can_sync_booking = payload;
    },

    IS_SYNCING_BOOKING(state, payload){
        return state.is_syncing_booking = payload;
    },

    IS_PROPERTY_SYNCING(state, payload){
        return state.is_property_syncing = payload;
    },

    ASK_FOR_IMPORT_CAN_PROCESSABLE(state, payload){
        return state.ask_for_import_can_processable = payload;
    },

    PROPERTY_UPSELL_GUIDE(state , payload){
    
        state.property_upsell_guide = payload;
        return state ;

    },

    GUEST_DOCUMENTS_TAB_DATA(state, payload) {
        return state.documents = payload.images;
    },

    RESET_GUEST_DOCUMENTS_TAB_DATA(state, payload) {
        return state.documents = {
            documents_to_check: [],
            accepted_documents: [],
            rejected_documents: [],
            deleted_documents: [],
        };
    },

    SET_GUESTS(state, payload) {
        return state.guests = payload;
    },

    RESET_GUESTS(state, payload) {
        return state.guests = [];
    },

    SET_DOCUMENT_DESCRIPTION_DATA(state, payload){
        return state.document_description = payload;
    },
    SET_DEFAULT_TAB_TO_OPEN(state, payload){
        //Tab object that is present at 0 index will be the default tab to open
        state.booking_detail.tab_section.current_tab = state.booking_detail.tab_section.tabs[payload][0];
        state.pms_prefix = payload;
        return state
    },
    SET_DEFAULT_TAB_TO_OPEN_IFRAME(state, payload){
        //Tab object that is present at 0 index will be the default tab to open
        state.iframe_booking_detail.tab_section.current_tab = state.iframe_booking_detail.tab_section.tabs[payload][0];
        state.pms_prefix = payload;
        return state
    },

    THREE_DS_VERIFICATION_COMPLETED(state, payload)
    {
        /**
         *  "window.top.postMessage" raise a true status flag
         *  this flag is listened by Checkout3DsModal.vue
         *  in order to redirect guest to next page
         * */
        let message = {
            move_next: true,
            further_processing: false
        };
        window.top.postMessage(message, '*');
        return state;
    },

    THREE_DS_VERIFICATION_FURTHER_PROCESSING_REQUIRED(state, payload) {
        /**
         *  "window.top.postMessage" raise a true status flag
         *  this flag is listened by Checkout3DsModal.vue
         *  this will require further processing of payment
         *  verification
         * */

        let message = {
            move_next: false,
            further_processing: true,
            payload
        };

        if (window.parent && window.parent.location.href.includes('payment-request-checkout-iframe')) {
            window.parent.postMessage(message, location.origin);
        } else if (window.parent.parent) {
            window.parent.parent.postMessage(message, location.origin);
        } else {
            window.parent.postMessage(message, location.origin);
        }

        return state;
    },

    CLOSE_3DS_MODAL_NEW(state, payload) {
        try {
            const targetWindow = window.parent.parent ?? window.parent;
            targetWindow.postMessage(payload, location.origin);
        } catch (error) {
            console.error('Error posting message:', error);
        }
        return state;
    },

    SET_DEVICE_FINGERPRINTING_CARD_ADDED(state, payload) {  // TODO: verify and remove it
        state.deviceFingerPrinting.added_card = payload; // id of added card.
        return state;
    },

    SET_DEVICE_FINGERPRINTING_PARAMETERS(state, payload) { // TODO: verify and remove it
        state.deviceFingerPrinting = {

            accessToken: payload.res.access_code,
            // deviceDataCollectionUrl: payload.res.iframe_url,
            deviceDataCollectionUrl: payload.res.cybersourceAuthenticationApiUrl,
            hash: payload.res.hash,
            miscellaneous: payload.res.miscellaneous,
            loadDeviceFingerprintingIframe: true,

            // TODO: Verify and remove below fields if not required/used.
            pgId: payload.res.pgId,

            status: true,
            referenceId: payload.res.reference_id,
            orderId: payload.res.order_id,
            customer: payload.res.customer,
            source: payload.req.source,
            paymentRequest: {
                request_id: payload.req.request_id, // Payment Request Id
                customer_id: payload.req.customer_id, // Payment Request Customer Id
                selected_card: payload.req.selected_card[0] ? payload.req.selected_card[0] : state.deviceFingerPrinting.added_card
            },

        };
        return state;
    },

    // SET_DEVICE_FINGERPRINTING_DATA(state, resp) { // TODO: verify and remove it
    //
    //     state.deviceFingerPrinting = {
    //         status: true,
    //         orderId: resp.data.order_id,
    //         accessToken: resp.data.access_code,
    //         referenceId: resp.data.reference_id,
    //
    //         // deviceDataCollectionUrl: resp.data.iframe_url, Now iframe_url will hold url for guest in case of cybersource
    //         deviceDataCollectionUrl: resp.data.cybersourceAuthenticationApiUrl, // for client side only
    //
    //         customer: resp.data.customer,
    //         hash: resp.data.hash,
    //         miscellaneous: resp.data.miscellaneous,
    //         // source: payload.req.source,
    //         paymentRequest: {},
    //         paymentBooking: {},
    //         source: resp.data.source,
    //         loadDeviceFingerprintingIframe: true, // Load hidden Iframe code
    //         pgId: resp.data.pgId,
    //     };
    //
    //     if(resp.isBooking) {
    //         // Booking Client Side
    //         state.deviceFingerPrinting.paymentBooking.selected_card = resp.data.attempted_card;
    //         state.deviceFingerPrinting.paymentBooking.booking_info_id = resp.data.booking_info_id;
    //     } else {
    //         // Payment Request
    //         state.deviceFingerPrinting.paymentRequest.request_id = resp.request_id; // Payment Request Id
    //         state.deviceFingerPrinting.paymentRequest.customer_id = resp.customer_id;
    //         state.deviceFingerPrinting.paymentRequest.selected_card = resp.selected_card ? resp.selected_card : state.deviceFingerPrinting.added_card;
    //     }
    //
    //     return state;
    // },

    SHOW_3DS_MODAL_BOX(state, iframe_urls){
        
        state._3ds.open_modal_box = true;
        state._3ds.iframe_url = Array.isArray(iframe_urls) && iframe_urls[0] !== undefined ? iframe_urls[0] : iframe_urls;

        if (state._3ds.iframe_url != null)  {
            state._3ds.iframe_urls = Array.isArray(iframe_urls) ? iframe_urls : [state._3ds.iframe_url];
        }

        return state;
    },

    HIDE_3DS_MODAL_BOX(state){
        state._3ds.open_modal_box = false;
        state._3ds.iframe_url = '';
        let has_class = document.getElementById("_3ds_verification")?.classList.contains('show');
        if(has_class) {
            document.getElementById("_3ds_verification_trigger").click();
        }
        return state;
    },

    RESET_3DS_MODAL_BOX(state){
        state._3ds.open_modal_box = false;
        state._3ds.iframe_url = '';
        return state;
    },
    
    UPDATE_THREE_DS_ERROR(state, payload) {
        state.page_status = payload;
        return state;
    },

    PAYMENT_REQUEST_ACTIVE_ID(state, payload) {
        return state.payment_request_active_id = payload;
    },

    PAYMENT_REQUEST_ACTIVE_ACTION_TYPE(state, payload) {
        return state.payment_request_active_action_type = (payload > 0) ? 'update' : 'new';
    },
    
    PAYMENT_REQUEST_ACTIVE_DATA(state, payload) {
        state.payment_request_active_email = payload.email;
        state.payment_request_active_currency_id = payload.currency_id;
        state.payment_request_active_amt = payload.amt;
    },

    SET_PG_TERMINAL_ADDITIONAL_FIELDS_ERRORS(state, payload){
        state.pg_terminal.additional_fields_errors = payload;
        return state;
    },

    SET_PG_TERMINAL_DATA(state, payload){

        if(payload.pgTerminal) {
            state.pg_terminal = {...state.pg_terminal, ...payload.pgTerminal};
        } else {
            if(payload.status !== undefined && payload.status_code !== undefined && payload.message !== undefined) {
                state.pg_terminal = {...state.pg_terminal, ...payload};
            }
        }
        return state;
    },

    UNSET_PG_TERMINAL_DATA(state, payload){
        state.pg_terminal = {...PG_TERMINAL};
        return state;
    },

    SET_ALL_BOOKING_SOURCES(state, payload) {
        return state.all_booking_sources = payload;
    },
    
    SET_ALL_COUNTRIES(state, payload) {
        return state.all_countries = payload;
    },

    SET_ALL_BOOKING_TYPES(state, payload) {
        return state.all_booking_types = payload;
    },
    
    SET_ATTACHED_BOOKING_SOURCES(state, payload) {
        if(payload.attached_booking_sources != undefined && payload.attached_booking_sources.length > 0) {
            state.attached_booking_sources = payload.attached_booking_sources;
            return state.attached_booking_sources
        }

        state.attached_booking_sources = [{"name": "All Booking Source", "id": 0}];
        return state.attached_booking_sources;
    },
    
    SET_ATTACHED_BOOKING_TYPES(state, payload) {
        if(payload.attached_booking_types != undefined && payload.attached_booking_types.length > 0)
            return state.attached_booking_types = payload.attached_booking_types;
        
        state.attached_booking_types = [{"name": "All Booking Payment Types", "code": 0}];
        return state;
    },

    SET_CARDS_DATA(state, payload){
        state.booking_cards = payload.cards;
        state.is_booking_email_available = payload.is_booking_email_available;
        state.is_chargeback_protection_supported = payload.is_chargeback_protection_supported;
        return state;
    },

    SET_IS_SMS_PLAN_SUBSCRIBED(state, payload){
        state.is_sms_plan_subscribed = payload.is_sms_plan_subscribed;
        return state;
    },
    GUEST_PORTAL_ACTIVE_TAB(state, payload) {
        //console.log([state.guest_portal_terminal_active_tab, payload]);
        state.guest_portal_terminal_active_tab = payload;
        return state;
    },
    SET_CVV_VERIFICATION_DATA(state, payload) {
        state.booking.dataForCvvVerification = payload;
        return state;
    },
    SET_CVV_VERIFICATION_MODAL_MOUNTED(state, payload) {
        state.booking.isCvvVerificationModalMounted = payload;
        return state;
    },
    CREATE_UNIQUE_TOKEN(state, payload) {

        state.generate_unique_token = payload;
        return state;
    },

    // TODO: Complete the functions after API is created.
    SET_CC_FRAUD_CHECK_DATA(state, payload){
        //  SET_CC_FRAUD_CHECK_DATA here
    },
    RESET_CC_FRAUD_CHECK_DATA(state, payload){
        //  RESET_CC_FRAUD_CHECK_DATA here
    },
    set_terminal_concurrency:  (state, payload)=> {
        return state.pg_terminal.concurrency = payload;
    },
    set_terminal_status: (state, payload) => {
        return state.pg_terminal.status = payload;
    },
    SET_NATIONALITY_CHANGED: (state, payload) => {
        return state.isNationalityChanged = payload;
    }
};

export default general_root_mutations;
