import {init_custom_question_errors, init_state} from './state';
import logger from '../../../../../helpers/logger';

let actions = {

    setQuestionType: ({commit}, data) => {
        commit('SET_QUESTION_TYPE', data);
    },

    getQuestionTypes: async ({commit}, {apply_ds_filter}) => {
        commit('SHOW_LOADER', null, {root: true});
        let url = '';
        if(apply_ds_filter){
            url = '/client/v2/custom-question-types/true';
        }else{
            url = '/client/v2/custom-question-types';
        }
        axios.get(url).then((response) => {

            commit('SET_QUESTION_TYPES', response.data);
            commit('SET_PMS_RESERVATION_KEYS', response.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err.response.data.errors);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    validateQuestionsBeforeAdding: async ({state, commit, dispatch}) => {
        
        commit('RESET_CUSTOM_QUESTION_ERRORS');

        let valid = true;
        let errors = init_custom_question_errors();

        if (state.questions.custom_question.form_data.question == '') {
            errors.title = true;
            valid = false;
        }

        if (!(state.questions.custom_question.question_type_id > 0)) {
            errors.answer_type = true;
            valid = false;
        }

        if (['multi_select', 'choice'].includes(state.questions.custom_question.backend_name)) {
            await $.each(state.questions.custom_question.form_data.options, (index, option) => {
                if (option == '') {
                    valid = false;
                    errors.options[index] = true;
                } else {
                    errors.options[index] = false;
                }
            });
        }

        commit('SET_CUSTOM_QUESTION_ERRORS', errors);
        
        if (valid) {

            let data = state.questions.custom_question;
            data.id = state.questions.active_custom_question_id;
            return data;
            
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            $('.modal-body').animate({scrollTop: 0},400);
        }
        
        return null;
    },

    applyCustomQuestionChange: async ({state, commit, dispatch},payload) => {

        commit('RESET_CUSTOM_QUESTION_ERRORS');

        let valid = true;
        let errors = init_custom_question_errors();

        if (!state.questions.custom_question.form_data.question || /^\s*$/.test(state.questions.custom_question.form_data.question)) {
            errors.title = true;
            valid = false;
        }

        if (!(state.questions.custom_question.question_type_id > 0)) {
            errors.answer_type = true;
            valid = false;
        }

        if (['multi_select', 'choice'].includes(state.questions.custom_question.backend_name)) {
            await $.each(state.questions.custom_question.form_data.options, (index, option) => {
                if (option == '') {
                    valid = false;
                    errors.options[index] = true;
                } else {
                    errors.options[index] = false;
                }
            });
        }

        commit('SET_CUSTOM_QUESTION_ERRORS', errors);

        if (valid) {

            let data = state.questions.custom_question;
            data.id = state.questions.active_custom_question_id;
            data.all_rentals_selected=state.client_list_select_properties.all_rentals_selected;
            data.properties_with_rooms_updated = state.client_list_select_properties.properties_with_rooms_updated;
            data.attached_booking_sources = payload.booking_source_list?.length === data.attached_booking_sources.length ? [{ id : 0, name: "All Booking Source" }] : data.attached_booking_sources


            /*
            * IF PROPERTY IS SELECTED
            *   1) attached_units is empty              INSERT {"name":"All Rentals","code":0} into attached_units
            *   2) attached_units is 1
            *       a) if all_units is 1                EMPTY attached_units & INSERT {"name":"All Rentals","code":0} into attached_units
            *       b) if all_units is 2 or more        DO NOTHING; SKIP ITERATION
            *   3) attached_units is 2 or more 
            *       a) if attached_units === all_units  EMPTY attached_units & INSERT {"name":"All Rentals","code":0} into attached_units
            *       b) if attached_units < all_units    DO NOTHING; SKIP ITERATION 
            */
            logger.groupCollapsed("🏠 Properties Update Process");
            data.properties_with_rooms_updated.forEach((property, index) => {
                logger.groupCollapsed(`Property #${index + 1}`);
                logger.log(`%cProperty #${index + 1}: ${property}`, "color: navy; font-weight: bold;");
                
                // IF PROPERTY IS SELECTED
                // if (property.attach_status) {
                    logger.log("%c✅ Property is selected", "color: green;");
            
                    // 1) attached_units is empty
                    if (property?.attached_units?.length === 0) {
                        logger.log("%c📦 Attached units are empty", "color: purple;");
                        // a) if all_units is 0, 1 or 2 or more
                        property.attached_units.push({"name":"All Rentals", "code":0});
                        logger.log("%c🔍 Inserting 'All Rentals' into empty attached_units", "color: blue;");
                    }
                    // 2) attached_units is 1
                    else if (property?.attached_units?.length === 1) {
                        logger.log("%c📦 One attached unit exists", "color: purple;");
            
                        // a) if all_units is 1
                        if (property?.all_units?.length  === 1) {
                            property.attached_units = [{"name":"All Rentals", "code":0}];
                            logger.log("%c🔄 Replacing single attached unit with 'All Rentals'", "color: orange;");
                        }
                        // b) if all_units is 2 or more
                        // DO NOTHING; SKIP ITERATION
                        else {
                            logger.log("%c🛑 More than one unit, skipping...", "color: red;");
                        }
                    }
                    // 3) attached_units is 2 or more
                    else if (property?.attached_units?.length >= 2) {
                        logger.log("%c📦 Multiple attached units exist", "color: purple;");
                        
                        // a) if attached_units === all_units
                        if (property?.attached_units?.length === property?.all_units?.length) {
                            property.attached_units = [{"name":"All Rentals", "code":0}];
                            logger.log("%c🔄 Replacing all attached units with 'All Rentals'", "color: orange;");
                        }
                        // b) if attached_units < all_units
                        // DO NOTHING; SKIP ITERATION
                        else {
                            logger.log("%c🛑 Attached units less than total, skipping...", "color: red;");
                        }
                    }
                // } else {
                    // logger.log("%c❌ Property not selected", "color: grey;");
                // }
            
                logger.groupEnd();
            });
            logger.groupEnd();
    
            if (data.all_rentals_selected === true) {
                logger.groupCollapsed("%c🔍 All Rentals Selected", "color: darkblue; font-weight: bold;");
                logger.log("%c✅ All rentals have been selected.", "color: green;");
                
                data.selected_properties.forEach((e, index) => {
                    logger.groupCollapsed(`%cProperty #${index + 1}`, "color: navy; font-weight: bold;");
                    logger.log("%cBefore Update: ", "color: grey;", JSON.stringify(e.attached_units));
                    
                    e.attached_units = [0];
                    
                    logger.log("%cAfter Update: ", "color: blue;", JSON.stringify(e.attached_units));
                    logger.groupEnd();
                });
                
                logger.groupEnd();
            } else if (data.all_rentals_selected === false) {
                data.selected_properties.forEach((property, index) => {
                    const units = data.properties_with_rooms_updated[index].attached_units;
                    if (units.length === 1 && units[0].name === "All Rentals" && units[0].code === 0) {
                        property.attached_units = [0];
                    }
                });
            } else if (data.all_rentals_selected === '') {
                logger.log("%c🔍 Specific Properties Selection", "color: darkblue; font-weight: bold;");
                
                data.selected_properties.forEach((e, index) => {
                    logger.groupCollapsed(`%cProperty #${index + 1}`, "color: navy; font-weight: bold;");
                    
                    const property = data.properties_with_rooms_updated.find(f => f.id === e.id) || null;
                    if (property) {
                        logger.log("%cFound matching property:", "color: grey;", JSON.stringify(property));
                        
                        if ((property?.all_units?.length === property.attached_units?.length) || 
                            (property.attached_units?.[0] && property?.attached_units?.[0].name === "All Rentals" && property?.attached_units[0]?.code === 0)) {
                            
                            logger.log("%cCondition met: Updating attached units.", "color: green;");
                            e.attached_units = [0];
                        } else {
                            logger.log("%cCondition not met: No updates made.", "color: red;");
                        }
                    } else {
                        logger.log("%c⚠️ No matching property found.", "color: red;");
                    }
                    
                    logger.groupEnd();
                });
            } else {
                logger.log("%c❌ No rental selection condition met", "color: red;");
            }
            
            data.connected_tags = data.connected_tags != null ? data.connected_tags : [];
            commit('SHOW_LOADER', null, {root: true});
            axios.post('/client/v2/custom-question-save', data).then((response) => {
                if (response.data.status) {
                    commit('APPLY_CUSTOM_QUESTION_CHANGE');
                    commit('RESET_CUSTOM_QUESTION_FORM_DATA');
                    window.toastr.success(response.data.message);
                    dispatch('loadCustomQuestionGroupData', 0);
                    updateIntercomData('guest_experience');
                    document.getElementById('user-custom-question-update-modal-close-btn').click();
                    if(document.getElementById('custom-questions')) {
                        document.getElementById('custom-questions').click();
                    }
                    commit('UPDATE_INTERCOM_DATA');
                } else {
                    window.toastr.error(response.data.message);
                }

                commit('HIDE_LOADER', null, {root: true});
                // Redirect to custom question tab if users creates question from Guest Experience page
                if ('redirectTo' in payload && payload.redirectTo.trim() != '') {
                    !window.location.href.includes(payload.redirectTo) && (window.location.href = payload.redirectTo)
                }
            }).catch((err) => {
                commit('HIDE_LOADER', null, {root: true});
                if (err.response.status == 422) {
                    $.each(err.response.data.errors, (index, option) => {
                        window.toastr.error(option[0]);
                    });
                }
            });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            $('.modal-body').animate({scrollTop: 0},400);
        }
    },

    cancelCustomQuestionChanges: async ({commit}) => {
        commit('RESET_CUSTOM_QUESTION_ERRORS');
        commit('RESET_CUSTOM_QUESTION_FORM_DATA');
        document.getElementById('user-custom-question-update-modal-close-btn').click();
    },

    deleteCustomQuestion: async ({commit}) => {
        commit('DELETE_CUSTOM_QUESTION');
        window.toastr.info('Question group updated, Please save your Settings');
        commit('RESET_CUSTOM_QUESTION_ERRORS');
        commit('RESET_CUSTOM_QUESTION_FORM_DATA');
        document.getElementById('user-custom-question-update-modal-close-btn').click();
    },

    LoadCustomQuestionData: async ({state, commit, dispatch}) => {

        commit('RESET_CUSTOM_QUESTION_ERRORS');

        if (state.questions.custom_question_index > -1) {

            const question = state.questions.question_group_form_data.questions[state.questions.custom_question_index];

            if (question.question_type_id != state.questions.custom_question.question_type_id) {

                const question_type_id = state.questions.custom_question.question_type_id;
                const question_ = (question_type_id > 0)
                    ? state.questions.question_types.filter(question_type => question_type.id == question_type_id)[0]
                    : state.questions.question_types[0];

                let form = {
                    id:question.id,
                    publish_status: question.publish_status,
                    question_type_id: question_.id,
                    backend_name: question_.backend_name,
                    description: question_.description,
                    form_data: question_.form_data,
                    attached_booking_sources:question.attached_booking_sources,
                    selected_properties:question.selected_properties,
                    map_to_pms_key :{
                        key:"",
                        belongsTo:"pms_reservation_keys",
                    },
                };

                form.map_to_pms_key.key = state.questions.custom_question.map_to_pms_key.key;

                // TODO Fix MIN-daTE mAX-DATE
                if (question_.backend_name === 'date') {
                    form.form_data.min_date = init_state().form_data.min_date;
                    form.form_data.max_date = init_state().form_data.max_date;
                }

                form.form_data.required = state.questions.custom_question.form_data.required;
                form.form_data.question = state.questions.custom_question.form_data.question;


                commit('LOAD_CUSTOM_QUESTION_DATA', JSON.parse(JSON.stringify(form)));
            } else {
                commit('LOAD_CUSTOM_QUESTION_DATA', JSON.parse(JSON.stringify(question)));
            }

        } else {
            dispatch('generateSelectedForm');
        }

        return state;
    },


    generateSelectedForm: async ({commit, state}) => {

        let question_type_id = state.questions.custom_question.question_type_id;
        if (!question_type_id) return;
        
        const question = (question_type_id > 0)
            ? state.questions.question_types.filter(question_type => question_type.id == question_type_id)[0]
            : state.questions.question_types[0];

        let form = {
            id:0,
            publish_status: state.questions.custom_question.publish_status,
            question_type_id: question.id,
            backend_name: question.backend_name,
            description: question.description,
            form_data: question.form_data,
            attached_booking_sources:init_state().attached_booking_sources,
            selected_properties:[],
            map_to_pms_key :{
                key:"",
                belongsTo:"pms_reservation_keys",
            },
        };

        if(state.questions.custom_question.map_to_pms_key != undefined){
            form.map_to_pms_key.key = state.questions.custom_question.map_to_pms_key.key;
        }

        if (question.backend_name === 'date') {
            form.form_data.min_date = init_state().form_data.min_date;
            form.form_data.max_date = init_state().form_data.max_date;
        }
        form.form_data.question = state.questions.custom_question.form_data.question;
        form.form_data.required = state.questions.custom_question.form_data.required;

        commit('LOAD_CUSTOM_QUESTION_DATA', JSON.parse(JSON.stringify(form)));
    },


    loadCustomQuestionGroupData: async ({state, commit}, serve_id=0) => {
        //if (serve_id > 0) {
            commit('SHOW_LOADER', null, {root: true});
            axios.get('/client/v2/custom-question-group/' + serve_id).then((response) => {
                commit('SET_CUSTOM_QUESTION_GROUP_DATA', response.data.data);
                commit('HIDE_LOADER', null, {root: true});
                commit('UPDATE_INTERCOM_DATA');
            }).catch((err) => {
                console.log(err.response.data.errors);
                if (err.response.status == 422) {
                    $.each(err.response.data.errors, function (key, value) {
                        toastr.error(value[0]);
                    });
                }
                commit('HIDE_LOADER', null, {root: true});
            });
        //}
    },

    loadGroupQuestionData: async ({state, commit}) => {

        commit('SHOW_LOADER', null, {root: true});
        axios.get('/client/v2/get-custom-questions').then((response) => {
            commit('SET_QUESTION_GROUPS', response.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err.response.data.errors);
            if (err.response.status == 422) {
                $.each(err.response.data.errors, function (key, value) {

                    toastr.error(value[0]);

                });
            }
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    createUpdateQuestionGroup: ({commit}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        let data_ = {title:data.title, description:data.description};
        return new Promise((resolve, reject) => {
            axios.post('/client/v2/custom-question-group-save', data_).then((response) => {
                if (response.data.status) {
                    toastr.success(response.data.message);
                } else {
                    toastr.error(response.data.message);
                }

                commit('HIDE_LOADER', null, {root: true});
                //commit('RESET_QUESTION_GROUP_FORM_DATA');
            }).catch((err) => {
                commit('HIDE_LOADER', null, {root: true});
                if (err.response.status == 422) {
                    resolve(err.response.data.errors);
                }
            });

        });
    },

    deleteQuestion: ({commit, dispatch}, data) => {
        commit('SHOW_LOADER', null, {root: true});
        return new Promise((resolve, reject) => {

            axios.post('/client/v2/delete-question', data).then((response) => {
                if (response.data.status) {
                    toastr.success(response.data.message);
                    commit('RESET_CUSTOM_QUESTION_FORM_DATA');
                    dispatch('loadCustomQuestionGroupData', 0);
                    document.getElementById('user-custom-question-update-modal-close-btn').click();
                } else {
                    toastr.error(response.data.message);
                }
                commit('HIDE_LOADER', null, {root: true});

            }).catch((err) => {
                commit('HIDE_LOADER', null, {root: true});
                if (err.response.status == 422) {
                    resolve(err.response.data.errors);
                }
            });

        });
    },
};

export default actions;
