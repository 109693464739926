import {init_state, init_custom_question_errors} from './state';

let mutations = {

    SET_QUESTION_TYPES: function (state, payload) {
        state.questions.question_types = payload['question_types'];
        return state;
    },

    SET_PMS_RESERVATION_KEYS: function (state, payload) {
        state.questions.pms_reservation_keys = payload['pms_reservation_keys'];
        return state;
    },


    SET_CUSTOM_QUESTION_ERRORS: function (state, payload) {
        state.questions.custom_question_errors = payload;
        return state;
    },

    RESET_CUSTOM_QUESTION_ERRORS: function (state, payload) {
        state.questions.custom_question_errors = init_custom_question_errors();
        return state;
    },

    APPLY_CUSTOM_QUESTION_CHANGE: function (state) {
        if (state.questions.custom_question_index > -1) {
            state.questions.question_group_form_data.questions =
                {
                    ...state.questions.question_group_form_data.questions,
                    [state.questions.custom_question_index]: state.questions.custom_question
                };
        } else {
            state.questions.question_group_form_data.questions.push(state.questions.custom_question);
        }

        return state.questions.question_group_form_data.questions;
    },
    
    UPDATE_INTERCOM_DATA: function (state) {
        
        //update intercom data
        // updateIntercomData('custom_question_page_loaded', 
        //     {
        //         "custom_question_added" : (state.questions.question_group_form_data.questions.length > 0) ? true : false
        //     });

        return state;
    },


    DELETE_CUSTOM_QUESTION: function (state) {
        if (state.questions.custom_question_index > -1) {
            state.questions.question_group_form_data.questions.splice(state.questions.custom_question_index, 1);
        }

        return state.questions.question_group_form_data.questions;
    },

    LOAD_CUSTOM_QUESTION_DATA: function (state, payload) {
        return state.questions.custom_question = payload;
    },


    RESET_CUSTOM_QUESTION_FORM_DATA: function (state) {
        state.questions.custom_question_index = -1;
        state.questions.act = -1;
        if(typeof state.questions.custom_question.system_generated != "undefined" && state.questions.custom_question.system_generated){
            state.questions.custom_question = init_state();
            return state;
        }
        return state.questions.custom_question = {...state.questions.custom_question, ...init_state()};
    },


    SET_CUSTOM_QUESTION_ACTIVE_ID: function (state, active_custom_question_id) {
        state.questions.active_custom_question_id = active_custom_question_id;
        return state;
    },

    SET_CUSTOM_QUESTION_INDEX: function (state, custom_question_index) {
        state.questions.custom_question_index = custom_question_index;

        if (custom_question_index > -1) {
            state.questions.custom_question.question_type_id =
                state.questions.question_group_form_data.questions[custom_question_index].question_type_id
        }

        return state;
    },


    SET_CUSTOM_QUESTION_GROUP_DATA: function (state, payload) {
        return state.questions.question_group_form_data = payload
    },

    SET_QUESTION_GROUPS: function (state, payload) {
        return state.questions.question_groups = payload;

    }


    /*SET_GUIDE_BOOK_TYPES: function (state, data) {
        state.guideBook.types = data;
        return state;
    },*/
    /*SET_LIST: function (state, data) {
        state.guideBook.paginationResponse = data;

        //update intercom data
        updateIntercomData('guidebook_listing_page_loaded');

        return state;
    },*/
    /*UPDATE_GUIDE_BOOK_STATUS: function (state, data) {
        state.guideBook.paginationResponse.data[data.index][data.updateWhat] = data.updateWith;

        //update intercom data
        updateIntercomData('guidebook_listing_page_loaded');

        return state;
    },*/

};
export default mutations;