const auth_base_path = "./components/general/auth";


import { defineAsyncComponent } from 'vue';

// Register core components
export function registerCoreComponents(app) {
    app.component('BlockUI', defineAsyncComponent(() =>  import('@cac-core/Blocker.vue')));
    app.component('pagination', defineAsyncComponent(() =>  import('@cac-core/Pagination.vue')));    
}
