let state = {
    properties_with_rooms: [],
    properties_with_rooms_updated: [], // This array holds information for rooms after updates due to pagination
    properties_pagination_meta: [],
    properties_pagination_links: [],
    all_rentals_selected: '', //By Default empty
    all_rentals_deselected: '', //By Default empty
    selected_properties_count: 0
};

export default state;
