let actions = {
    getAllUpsellTypes: async ({state, commit},{for_filters,serve_id}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/get-all-upsell-types/'+for_filters+"/"+serve_id+"/"+state.filters.user_account_id,
            method: 'GET'
        }).then((resp) => {
            commit('SET_ALL_UPSELL_TYPES', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    /**
     *
     * @param state
     * @param commit
     * @param list_type | 'upsell' | 'upsell-orders'
     * @returns {Promise<void>}
     */
    getAllList: async ({state, commit}, list_type) => {
        commit('SHOW_LOADER', null, {root: true});
        let url = '/admin/';
        url += list_type == 'upsell-orders' ? 'get-upsell-order-list' : 'get-all-upsell-list';

        await axios({
            url: url,
            method: 'POST',
            data: {filters: state.filters},
        }).then((resp) => {
            commit('SET_ALL_UPSELL_LIST', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },
    
    /**
     *
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
     getUpsellTemplatesList: async ({state, commit}) => {
        
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/admin/get-upsell-templates-list',
            method: 'POST',
            data: {filters: state.filters},
        }).then((resp) => {
            commit('SET_UPSELL_TEMPLATES_LIST', resp.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    getUpsellLibraryImages: async ({state, commit}) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/admin/get-upsell-images',
            method: 'GET',
        }).then((resp) => {
            commit('SET_UPSELL_IMAGES_LIST', resp.data.data);
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    addUpsellImage: async ({commit, dispatch}, form_data) => {

        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/add-upsell-image',
            method: 'POST',
            data : form_data
        }).then((resp) => {
            console.log(resp);
            if(resp.data.status) {
                dispatch('getUpsellLibraryImages');
                toastr.success(resp.data.message);
            }else{
                toastr.error(resp.data.message);
            }
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            console.log(err);
            commit('HIDE_LOADER', null, {root : true});
            //commit('SHOW_UPSELL_IMAGE_ERRORS', err.response.data.errors);
        });
    },

    deleteUpsellLibraryImage: async ({state, commit, dispatch}, id) => {

        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/admin/delete-upsell-image',
            method: 'POST',
            data: {id}
        }).then((resp) => {
            dispatch('getUpsellLibraryImages');
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    saveUpsellTemplate: async ({state, commit, dispatch}, formData) => {

        commit('SHOW_LOADER', null, {root: true});

        return new Promise((resolve, reject) => {
             axios({
                url: '/admin/save-upsell-template/',
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {

                commit('HIDE_LOADER', null, {root: true});

                if (response.data.status) {
                    toastr.success(response.data.message);
                    return resolve(true);
                } else {
                    toastr.error(response.data.message);
                    return reject();
                }
                
                return resolve(true);

            }).catch((err) => {

                let errors = err.response;
                let error_message = {};
                let error_status = {};
                if (errors.status == 422) {
                    if (errors.data) {
                        for (let k1 in errors.data) {
                            if (typeof errors.data[k1] == "object") {
                                let validation_errors = errors.data[k1];
                                for (let k2 in validation_errors) {
                                    toastr.error(validation_errors[k2][0]);
                                }
                            }
                        }
                    }
                }
                
                commit('HIDE_LOADER', null, {root: true});
                return reject();
            });

        });
    },

    loadUpsellTemplateData: async ({state, commit, dispatch}, serve_id) => {
        commit('SHOW_LOADER', null, {root: true});

        await axios({
            url: '/admin/get-upsell-template-data',
            method: 'POST',
            data: {serve_id: serve_id},
        }).then((resp) => {
            if (resp.data.status) {
                commit('LOAD_UPSELL_TEMPLATE_DATA', resp.data.data);
            }
            commit('HIDE_LOADER', null, {root: true});
        }).catch((err) => {
            console.log(err);
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    changeUpsellTemplateStatus: async ({state, commit}, template_id) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/admin/change-upsell-template-status',
            method: 'POST',
            data: {template_id: template_id},
        }).then((response) => { 

            if(response.data.status)
                toastr.success(response.data.message);
            else
                toastr.error(response.data.message);

            dispatch('admin/getUpsellTemplatesList');
            commit('HIDE_LOADER', null, {root: true});

        }).catch((err) => {
            commit('HIDE_LOADER', null, {root: true});
        });
    },

    getUpsellConfig: async ({commit}) => {
        commit('SHOW_LOADER', null, {root: true});
        await axios({
            url: '/client/v2/get-upsell-config',
            method: 'POST',
        }).then((resp) => {
            commit('SET_UPSELL_CONFIG', resp.data.data);
            commit('HIDE_LOADER', null, {root : true});
        }).catch((err)=>{
            commit('HIDE_LOADER', null, {root : true});
            console.log(err);
        });
    },

};

export default actions;
