import actions from './actions';
import mutations from './mutations';
import state from "./state";
import product from './product/index';
import reusablePaymentPage from './reusable_payment_page/index';
import reusablePaymentPageList from './reusable_payment_page_list/index';
import payer from './payer/index';

export default {
    namespace: true,
    modules: {
        product,
        reusablePaymentPage,
        reusablePaymentPageList,
        payer
    },
    state,
    actions,
    mutations
}