/** Term and Conditions Component State*/
const basic_data={
    serve_id:0,
    status:true,
    title:'',
    text_content:'',
    selected_properties:[],
    icon: 'fas fa-home',
    // priority: 0,
    display_criteria: {
        id_approved: 0,
        sd_authorized: 0,
        reservation_fully_paid: 0,
        days_before_checkin: 0,
        number_of_days: 0
    },
    connected_tags:[]
};
const filters = {
    recordsPerPage:10,
    page:1,
    columns: '*',
    relations: [],
    sort:{
        sortOrder:'Desc',
        sortColumn:'id',
    },
    constraints:[],
    whereHas:[],
    search: {
        searchInColumn: ['id', 'title'],
        searchStr: ''
    },
    type_id:'all',
    property_info_id:'all',
    room_info_id:'all',
    filter_count:0,
}; //Datatable filters Object End

/** Main STATE */
let state={
    types:[],
    formData:basic_data,
    paginationResponse: {}, // List Records With Pagination Meta & Links
    filters : filters, // ALL Regarding Filters for datatable.
    firstCallDone: false,
};
export default state;