let state = {
    userProfile: {
        name: ''
    },
    billingPlanType: '',
    userPms:'',
    c_id: '',
    currency: '',
    userPaymentGateway: null,
    pmsPermissions: {},
    companyLogo: null,
    companyInitial: '',
    userAccountName: '',
    userImage: '',
    userInitial: '',
    canAddTeamMember: false,
};


export default state;