import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    parsePhoneNumber,
    validatePhoneNumberLength,
    AsYouType,
} from 'libphonenumber-js'

const isPhoneNumberLengthValid = g => g && (g.length > 5) && (g.length < 21),
    isPhoneNumberValid = (g = '') => isValidPhoneNumber(g),
    customParsePhoneNumber = (g = '') => {
        try {
            return parsePhoneNumber(g);
        } catch (error) {
            return {
                error: true,
                errorMessage: error.message
            };
        }
    },

    isValidImage = g => g && g.match(/\.(jpeg|jpg|gif|png|svg)$/) != null,
    isArrayEqual = (x, y) => _(x).differenceWith(y, _.isEqual).isEmpty();

export default {
    isPhoneNumberLengthValid: isPhoneNumberLengthValid,
    isPhoneNumberValid,
    parsePhoneNumber: customParsePhoneNumber,
    isValidImage: isValidImage,
    isArrayEqual: isArrayEqual,
};