import { defineAsyncComponent } from 'vue';
/*
*
* These components will compile under "guest" chunk.
*
* */


const smx_client_base_path = './components/smx/general/guest';
const general_guest_base_path = './components/general/guest';

const ba_guest_base_path = './components/ba/guest';
const smx_general_guest_base_path = './components/smx/general';
const impala_guest_base_path = './components/impala/general/guest';
const guesty_guest_base_path = './components/guesty/guest';

const payment_request_functionality_base_path = './components/PaymentRequestFunctionality';
const payment_request_functionality_payer_base_path = './components/PaymentRequestFunctionality/Payer';
const reusable_payment_request_client_base_path = './components/ReusablePaymentRequestFunctionality/Payer';

const booking_additional_payment_request_payer_base_path = './components/general/guest/additional_payment_request';

/*
* General registered components
* */
export function registerGuestComponents(app) {
    app.component('guest-form-for-portal',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/GuestFormForPortal.vue')));
    
    app.component('guest-pre-checkin',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/GuestPreCheckin.vue')));
    app.component('basic-info-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/BasicInfoStep.vue')));
    
    app.component('guest-info-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/GuestInfo.vue')));
    app.component('arrival-info-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/Arrival.vue')));
    app.component('verification-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/Verification.vue')));
    app.component('add-on-services',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/AddOnServices_new.vue')));
    app.component('pre-checkin-thank-you',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/PreCheckInThankYou.vue')));
    app.component('guest-portal',defineAsyncComponent(() => import('@cac-gg/guest_portal/GuestPortal_new.vue')));
    app.component('guest-portal-mobile',defineAsyncComponent(() => import('@cac-gg/guest_portal/GuestPortal_mobile.vue')));
    app.component('guest-portal-mgr',defineAsyncComponent(() => import('@cac-gg/guest_portal/GuestPortal_Manager.vue')));
    app.component('guest-chat-panel',defineAsyncComponent(() => import('@cac-gg/reuseables/GuestChatPanel.vue')));
    app.component('property-guide-book-detail-model',defineAsyncComponent(() => import('@cac-gg/reuseables/PropertyGuideBookDetailModel.vue')));
    app.component('guest-header',defineAsyncComponent(() => import('@cac-gg/includes/Header.vue')));
    app.component('header-steps',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/HeaderSteps.vue')));
    app.component('PreCheckinFooter',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/PreCheckinFooter.vue')));
    app.component('signature-pad',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/SignaturePad.vue')));
    app.component('photo-booth',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/Photobooth.vue')));
    app.component('term-and-condation-popup-modal',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/TermAndCondationPopupModal.vue')));
    app.component('read-only-mode',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/ReadOnlyMode.vue')));
    app.component('pre-checkin-chat-button',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/ChatButton.vue')));
    app.component('summary-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/Summary.vue')));
    app.component('checkout-3ds',() => import('@cac-gg/checkout/Checkout3DS.vue'));
    app.component('credit-card-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/CreditCard.vue')));
    app.component('questionnaire-from',defineAsyncComponent(() => import('@cac-gg/reuseables/QuestionnaireForm.vue')));
    app.component('questionnaire-step',defineAsyncComponent(() => import('@cac-gg/guest_pre_checkin/Questionnaire.vue')));
    app.component('upsell-3ds-verification',defineAsyncComponent(() => import('@cac-gg/reuseables/Upsell3dVerification.vue')));
    
    /*Check Page Iframe*/
    app.component('payment-request-iframe-checkout-screen',defineAsyncComponent(() => import('@cac-pqfpg/iframes/checkoutScreen.vue')));
    
    /* Payment Request */
    app.component('payment-request-checkout',defineAsyncComponent(() => import('@cac-pqfpg/paymentScreen.vue')));
    app.component('payment-request-charge-confirmation',defineAsyncComponent(() => import('@cac-prf/reusables/PaymentRequestChargeConfirmation.vue')));
    app.component('reservation-payment-and-auth-confirmation',defineAsyncComponent(() => import('@cac-gg/reuseables/ReservationPaymentAndAuthConfirmation.vue')));
    app.component('payer-chat-panel',defineAsyncComponent(() => import('@cac-prf/reusables/PayerChatPanel.vue')));
    app.component('payment-request-chat-button',defineAsyncComponent(() => import('@cac-prf/reusables/PaymentRequestChatButton.vue')));
    app.component('payment-request-social-icon',defineAsyncComponent(() => import('@cac-prf/reusables/SocialIcons.vue')));
    /* Reusable Payment Request */
    app.component('payment-request-page',defineAsyncComponent(() => import('@cac-rprg/ReusablePaymentRequestPage.vue')));
    
    /* Booking Additional Payment Request */
    app.component('booking-addition-payment-request-checkout',defineAsyncComponent(() => import('@cac-bapr/paymentScreen.vue')));
    app.component('booking-addition-payment-request-charge-confirmation',defineAsyncComponent(() => import('@cac-bapr/reusables/PaymentRequestChargeConfirmation.vue')));
}



