import booking_detail from './client/booking_detail/getters';
import iframe_booking_detail from './client/iframe_booking_detail/getters';
import payment_request from './payment_request_functionality/getters';
import general from './general_root_getters';

let getters = {
    ...booking_detail,
    ...iframe_booking_detail,
    ...payment_request,
    ...general,
};

export default getters;