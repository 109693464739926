const state = {
    isWhiteLabelSolutionPlanActive: false,
    whiteLabelSolution: {
        custom_domain: "",
        is_domain_enabled: false,
        host_details: [],
        subscriptionData: {},
    },
    trigger_3ds2_action_modal : false,
    threeDS_pi_client_secret: '',
};
const mutations = {
    SET_CUSTOM_DOMAIN_DATA (state, payload) {
        state.whiteLabelSolution.custom_domain = payload.custom_domain !== null && payload.custom_domain !== "" ? payload.custom_domain.replace(/^https?:\/\//, "") : "" ;
        state.whiteLabelSolution.is_domain_enabled = !!payload.is_domain_enabled;
        state.whiteLabelSolution.host_details = Object.keys(payload.host_details).length > 0 ?  payload.host_details : [];
    },

    SET_HOST_DETAILS (state, payload) {
        state.whiteLabelSolution.host_details = payload;
    },

    SET_WHITELABEL_SUBSCRIPTION_DATA (state, payload) {
        state.whiteLabelSolution.subscriptionData = payload;
    },
    SET_WHITELABEL_SOLUTION_ACITVE_PLAN (state, payload) {
        state.isWhiteLabelSolutionPlanActive = payload;
    },
    SET_TRIGGER_FOR_3DS2_ACTION_MODAL(state, payload){
        state.trigger_3ds2_action_modal = payload;
    },
    SET_THREE_DS2_PI_CLIENT_SECRET(state, payload){
        state.threeDS_pi_client_secret = payload;
    }
};
const actions = {
    customDomainData: function ({commit}) {
        return axios({
            url: '/client/v2/custom-domain-data',
            method: 'GET'
        }).then((response) => {
            commit('SET_CUSTOM_DOMAIN_DATA', response.data);
            commit('SET_WHITELABEL_SOLUTION_ACITVE_PLAN', response.data.is_whitelabel_solution_plan_active)
        }).catch((err) => {
            console.error("Unable to fetch custom domain data: ", err);
        });
    },

    getWhiteLabelSubscriptionData: function ({commit}) {
        return axios({
            url: '/client/v2/whitelabel-subscription-data',
            method: 'POST'
        }).then((response) => {
            commit('SET_WHITELABEL_SUBSCRIPTION_DATA', response.data);
        }).catch((err) => {
            console.error("Unable to fetch whitelabel subscription data: ", err);
        });
    },

    checkDomainStability: function () {
        return axios({
            url: '/client/v2/check-custom-domain-stability',
            method: 'POST'
        }).then((response) => {
            if(response.data.status_code == 200) {
                toastr.success(response.data.message);
                //update Intercom data
                updateIntercomData('update_custom_domain');
            } else{
                toastr.error(response.data.message);
            }
        }).catch((err) => {
            console.error("Unable to pay: ", err);
        });
    },

    payCustomDomain: function ({dispatch,commit}) {
        commit('SET_THREE_DS2_PI_CLIENT_SECRET', '');
        commit('SET_TRIGGER_FOR_3DS2_ACTION_MODAL', false);
        return axios({
            url: '/client/v2/pay-custom-domain',
            method: 'POST'
        }).then((response) => {
            if (response.data.status) {
                toastr.success(response.data.message);
                dispatch('checkDomainStability');
                //update Intercom data
                updateIntercomData('update_custom_domain');
            } else if(response.data.status_code === 422 && response.data.data){
                toastr.warning(response.data.message);

                if (response.data.data.pi_client_secret) {
                    commit('SET_TRIGGER_FOR_3DS2_ACTION_MODAL', true);
                    commit('SET_THREE_DS2_PI_CLIENT_SECRET', response.data.data.pi_client_secret);
                } else if(response.data.data.link && response.data.data.link.includes('https://invoice.stripe.com/')){
                  setTimeout(() => {
                    window.open(response.data.data.link, '_blank');
                  }, 3000);
                }
            } else {
                toastr.error(response.data.message);
            }
            dispatch('customDomainData')
            dispatch('getWhiteLabelSubscriptionData')
        }).catch((err) => {
            console.error("Unable to pay: ", err);
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};